import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'

import FileDropzone from './FileDropzone'
import AssetPreview from './assetPreview'
import FileUploadApi from '../../../data/api/fileUpload'
import MediaApi from '../../../data/api/media'
import { Container } from 'components/MediaUploadModal/components/Container'

const CancelToken = axios.CancelToken
const mediaApi = new MediaApi()
const fileUploadApi = new FileUploadApi()

const getPresignedUrl = (accountId, filename, mediaType) => {
  return fileUploadApi.getPresignedUploadUrl(accountId, filename, mediaType)
}

const MediaUpload = ({
  accountId,
  currentFolderId,
  close,
  setShowUploadWarning,
  setCancelSource,
  multiple = true,
  onSelect,
  dropZoneFileTypes,
  uploadPreSignMethod = getPresignedUrl,
}) => {
  const [showDropzone, setShowDropzone] = useState(false)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({})

  const onDrop = (newFiles) => {
    setFiles([
      ...files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
      ...newFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ])
    setShowDropzone(false)
  }

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  const handleDropError = (errors) => {
    console.error(errors)
  }

  const removeFile = (name) => {
    setFiles((lastFiles) => {
      const newFiles = lastFiles.filter((f) => f.name !== name)
      if (newFiles.length === 0) {
        setShowUploadWarning(false)
      }
      return newFiles
    })
  }

  const uploadMedia = async () => {
    const source = CancelToken.source()
    setLoading(true)
    setShowUploadWarning(true)
    setCancelSource(source)
    const presignedUrls = await Promise.all(
      files.map((file) => {
        const ts = Math.round(new Date().getTime() / 1000)
        const split = file.name.split('.')
        const ext = split.pop()
        const name = split.join('.') + '-upload-' + ts + '.' + ext
        return uploadPreSignMethod(accountId, name, file.type)
      })
    )
    try {
      try {
        await Promise.all(
          presignedUrls.map(({ signedPutUrl }, idx) => {
            const uploadConfig = {
              headers: { 'Content-Type': files[idx]?.type },
              onUploadProgress: (progressEvent) => {
                const progressUpload = (progressEvent.loaded * 100) / progressEvent.total
                setUploadProgress((prev) => ({
                  ...prev,
                  [idx]: progressUpload,
                }))
              },
              cancelToken: source.token,
            }
            return fileUploadApi.uploadFileToS3(signedPutUrl, files[idx], uploadConfig)
          })
        )
      } catch (e) {
        console.error(e)
      }

      const result = presignedUrls.map(({ key, signedPutUrl }, idx) => {
        return {
          account_id: accountId,
          parent: currentFolderId,
          name: files[idx].name,
          media_type: files[idx].type,
          s3_key: key,
          url: signedPutUrl.split('?')[0],
        }
      })

      if (onSelect && typeof onSelect === 'function') {
        await onSelect(result)
      } else {
        await Promise.all(
          result.map(({ s3_key, account_id, parent, name, media_type }) =>
            mediaApi.post({
              account_id,
              parent,
              name,
              media_type,
              s3_key,
            })
          )
        )
      }
      setShowUploadWarning(false)
      close()
    } catch (err) {
      console.log(err)
    }
  }

  const addMore = !multiple && !(files?.length > 0)

  if (showDropzone || !files.length) {
    return (
      <FileDropzone
        onDrop={onDrop}
        onDropRejected={handleDropError}
        title="Upload Media from your Device"
        uploading={true}
        acceptedTypes={dropZoneFileTypes}
        multiple={multiple}
      />
    )
  }
  return (
    <Container>
      <div className="table">
        <h1>Selected Media</h1>
        {files.map((file, idx) => (
          <Fragment key={file.name}>
            {uploadProgress[idx] && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress[idx]}
                style={{ flex: 1 }}
              />
            )}
            <div className="asset">
              <AssetPreview
                url={file.preview}
                mediaType={file.type}
                maxWidth={100}
                maxHeight={50}
              />
              <div className="label">
                <span title={file.name}>{file.name}</span>
              </div>
              <IconButton onClick={() => removeFile(file.name)} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </Fragment>
        ))}
      </div>
      <div className="footer">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowDropzone(true)}
          disabled={loading || !addMore}
        >
          Upload More
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={uploadMedia}
          disabled={loading}
        >
          Upload
        </Button>
      </div>
    </Container>
  )
}

export default MediaUpload
