import { useEffect } from 'react'

const GoogleCallback = () => {
  const url = new URL(window.location.href)
  const token = url.searchParams.get('token')
  const name = url.searchParams.get('name')
  const email = url.searchParams.get('email')

  useEffect(() => {
    if (token) {
      const bc = new BroadcastChannel('auth-channel')
      bc.postMessage({ token, name, email })
      window.close()
    }
  }, [])

  return null
}

export default GoogleCallback
