import Api from './api';

export default class NewsApi extends Api {
  
  // Read
  async get(topic, keywords = '') {
    const url = `news/${topic}`
    return this.getResource(url, `keywords=${keywords}` );
  }
}
