import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ChevronRight from '@mui/icons-material/ChevronRight'
import DeleteOutline from '@mui/icons-material/DeleteOutline'

interface IProps {
  onBack: () => void
  styles: any
  handleClick: (url: string) => () => void
}

export const StyleguideSubmenu = ({ onBack, styles, handleClick }: IProps) => {
  return (
    <Box>
      <MenuItem onClick={onBack} disableRipple sx={{ justifyContent: 'center' }}>
        <ChevronLeft style={{ left: 0, color: 'GrayText' }} />
        <Typography sx={{ fontWeight: 'bold' }}>Styleguides</Typography>
      </MenuItem>
      <MenuItem onClick={handleClick('cms/styles/add')} disableRipple>
        Add New Style
        <ChevronRight />
      </MenuItem>
      {styles?.map((style) => (
        <>
          <MenuItem key={style.name} onClick={handleClick(`cms/styles/edit/${style.id}`)}>
            {style.name}
            <ChevronRight />
          </MenuItem>
        </>
      ))}
    </Box>
  )
}
