import { useState, useEffect } from 'react'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Row, Col, Label } from 'reactstrap'
import styled from 'styled-components'
import { types } from '../index'
import { logoLocations } from '../constants'
import Preview from '../common/Preview'
import WeatherApi from 'data/api/weather'

const weatherApi = new WeatherApi()
const labelStyle = { color: '#464D69CC', marginBottom: '0px', minWidth: 72 }

export const WeatherSceneEditor = ({
  name,
  config,
  onSave,
  saving,
  styles,
  sceneUrl,
}) => {
  delete config?.sceneName
  const [error, setError] = useState('')
  const [formData, setFormData] = useState({
    sceneName: name,
    background: 'auto',
    styleId: '',
    displayLocationDate: true,
    logoLocation: '',
    ...config,
  })

  useEffect(() => {
    if (config) {
      setFormData((prev) => ({ ...prev, ...config }))
    }
  }, [config])

  const handleChange = (field) => (e) =>
    setFormData((prev) => ({
      ...prev,
      [field]: typeof e?.target?.value === 'string' ? e.target.value : e,
    }))

  const save = async () => {
    setError('')
    try {
      await weatherApi.get(formData.zip, true)
      onSave({
        name: formData.sceneName,
        type: types.WEATHER,
        config: formData,
      })
    } catch (err) {
      setError('Zip code not found in database.')
    }
  }

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col sm="7" style={{ display: 'block' }}>
          <h2>{name ? 'Edit' : 'New'} Weather Scene</h2>
          <br />
          <AvForm>
            <Row>
              <Col sm="4">
                <StyledGroup>
                  <Label for="name" style={labelStyle}>
                    Name
                  </Label>
                  <AvInput
                    type="text"
                    value={formData.sceneName}
                    name="name"
                    id="name"
                    required
                    onChange={handleChange('sceneName')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    Please enter a name for the scene
                  </AvFeedback>
                </StyledGroup>
              </Col>
              <Col sm="4">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Style
                  </Label>
                  <AvInput
                    id="styleId"
                    name="styleId"
                    type="select"
                    onChange={handleChange('styleId')}
                    validate={{ min: { value: 1 } }}
                    value={Number(formData.styleId)}
                  >
                    <option value="">- Select Style -</option>
                    {styles &&
                      styles.map((style) => (
                        <option value={style.id} key={style.id}>
                          {style.name}
                        </option>
                      ))}
                  </AvInput>
                </AvGroup>
              </Col>
              <Col sm="3">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Show Logo
                  </Label>
                  <AvInput
                    id="logoLocation"
                    name="logoLocation"
                    type="select"
                    onChange={handleChange('logoLocation')}
                    value={formData.logoLocation}
                  >
                    {logoLocations.map((location) => (
                      <option value={location.value} key={location.value}>
                        {location.label}
                      </option>
                    ))}
                  </AvInput>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <AvGroup>
                  <Label for="url" style={labelStyle}>
                    Zip Code
                  </Label>
                  <AvInput
                    type="text"
                    value={formData.zip}
                    name="url"
                    id="url"
                    required
                    onChange={handleChange('zip')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    Please enter the URL
                  </AvFeedback>
                </AvGroup>
              </Col>
              <Col sm="10" style={{ display: 'flex', alignItems: 'center' }}>
                Display:
                <FormControlLabel
                  style={{ marginLeft: 20, marginBottom: 0 }}
                  control={
                    <Checkbox
                      checked={formData.displayCurrent}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          displayCurrent: e.target.checked,
                        }))
                      }
                      value="displayCurrent"
                    />
                  }
                  label="Current"
                />
                <FormControlLabel
                  style={{ marginBottom: 0 }}
                  control={
                    <Checkbox
                      checked={formData.displayForecast}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          displayForecast: e.target.checked,
                        }))
                      }
                      value="displayForecast"
                    />
                  }
                  label="Forecast"
                />
                <FormControlLabel
                  style={{ marginBottom: 0 }}
                  control={
                    <Checkbox
                      checked={formData.displayLocationDate}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          displayLocationDate: e.target.checked,
                        }))
                      }
                      value="displayLocationDate"
                    />
                  }
                  label="Location, Date, Time"
                />
              </Col>
            </Row>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={save}
              style={{ marginTop: '20px' }}
            >
              {saving ? (
                <CircularProgress color="secondary" size={22} />
              ) : (
                <div>SAVE</div>
              )}
            </Button>
            {error && <div style={{ color: 'red', marginTop: 10 }}>{error}</div>}
          </AvForm>
        </Col>
        <Col sm="5" style={{ paddingTop: 35, minHeight: 800 }}>
          <Preview saving={saving} sceneUrl={sceneUrl} />
        </Col>
      </Row>
    </div>
  )
}

const StyledGroup = styled(AvGroup)`
  margin-bottom: 5px;
  align-items: center;
`
