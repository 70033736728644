import Api from './api';
import WebsocketHeartbeatJs from 'websocket-heartbeat-js';
import {shallowEqual} from "react-redux";

const WEBSOCKET_STATE_CLOSING = 2;
const WEBSOCKET_STATE_CLOSED = 3;

let client;
let $this;

class WebsocketAPI extends Api {
  constructor(options, onMessage) {
    super();

    this.onMessageHandlers = new Map();
    this.connection = undefined;

    if (onMessage) {
      this.onMessageHandlers.set(onMessage, onMessage)
    }
    $this = this;
    this.options = options;
    this.options.pingMsg = this.options.pingMsg || JSON.stringify({action: 'heartbeat'})
  }

  connect() {
    if (client && (![WEBSOCKET_STATE_CLOSING, WEBSOCKET_STATE_CLOSED].includes(client?.ws.readyState))) {
      return;
    }

    client = new WebsocketHeartbeatJs(this.options);
    client.onopen = () => {
      console.log('WebSocket Client Connected');

      client.send(
        JSON.stringify({
            action: 'authorize',
            token: this.config.headers.Authorization,
            activeAccount: this.options.activeAccount
          }
        )
      );
    }

    client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      this._onMessage(dataFromServer);
    }
  }

  _onMessage(message) {
    for (let [handler] of this.onMessageHandlers) {
      typeof handler === "function" && handler.call(undefined, message);
    }
  }

  addHandler(handler) {
    if (handler) {
      this.onMessageHandlers.set(handler, handler);
    }
  }

  send(message) {
    client.send(message);
  }

  close() {
    console.log("Request to close the WebSocket connection");
    client?.close();
    client = null //gc
  }

  removeHandler(handler) {
    if (this.onMessageHandlers.has(handler)) {
      console.log("Had a handler and removing it from the map")
    }
    this.onMessageHandlers.delete(handler);
  }
}

const WebSocketModule = (function () {
  let instance;


  const createInstance = function (options, onMessage) {
    return new WebsocketAPI(options, onMessage);
  };

  return {
    getInstance: function (options, onMessage) {
      if (instance && shallowEqual(options, instance.options)) {
        return instance;
      } else if (instance) {
        instance.close()
      }
      return (instance = createInstance(options, onMessage));
    }
  }
})();

export { WebSocketModule };
