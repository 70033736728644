import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import MediaPlayer from 'components/MediaPreviewModal/mediaPlayer'
import MediaApi from 'data/api/media'
import AesendLogo from 'assets/img/aesend-logo.svg'

const mediaApi = new MediaApi()

const SharedMediaViewer = () => {
  const { shareKey } = useParams()
  const [asset, setAsset] = useState()

  useEffect(() => {
    if (shareKey) {
      mediaApi
        .getSharedMedia(shareKey)
        .then(setAsset)
        .catch((err) => console.log(err))
    }
  }, [shareKey])

  if (asset) {
    return (
      <Box sx={{ height: '100vh' }}>
        <div
          className="app-header"
          style={{ position: 'static', paddingTop: 5, paddingLeft: 5 }}
        >
          <img src={AesendLogo} alt="site-logo" width={125} />
        </div>
        {asset.media_type?.includes('video') ? (
          <Box
            sx={{
              marginTop: 2,
              video: { maxHeight: 'calc(100vh - 50px)', maxWidth: '100vw' },
            }}
          >
            {asset.url ? (
              <>
                <MediaPlayer url={asset.url} />
                {/* <MediaResSelect
                    options={links?.map((link) => ({
                      label: link?.label?.split(' ')[0]?.split('(').join(' ('),
                      value: link?.url,
                    }))}
                    onSelect={setMediaUrl}
                    value={mediaUrl ?? ''}
                    sx={{ float: 'right' }}
                  /> */}
              </>
            ) : (
              <Box sx={{ height: 200, background: 'black' }} />
            )}
          </Box>
        ) : asset.media_type?.includes('audio') ? (
          asset.url ? (
            <audio controls style={{ width: '100%' }}>
              <source src={asset.url} type={asset.media_type} />
            </audio>
          ) : (
            'Loading...'
          )
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3">Asset Not Available</Typography>
          </Box>
        )}
      </Box>
    )
  } else {
    return <></>
  }
}

export default SharedMediaViewer
