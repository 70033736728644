/**
 * Helpers Functions
 */
import moment from 'moment'

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')'
    )
  }
  throw new Error('Bad Hex')
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
  let time = timestamp * 1000
  let formatDate = format ? format : 'MM-DD-YYYY'
  return moment(time).format(formatDate)
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : 'YYYY-MM-DD'
  return moment(date, formatDate).unix()
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
  let location = url.pathname.split('/')
  return location[1]
}
export function msToTime(duration) {
  if (isNaN(duration)) return ''
  var milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds + '.' + milliseconds
}

export const YYYYMMDD_hhMMSSA = (date) => {
  return moment(date).format('YYYY-MM-DD hh:mm:ss a')
}
