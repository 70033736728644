const approximateQuality = 720

export const selectDefaultVideoFile = (list, preferred) => {
  let prev,
    current,
    found = false
  const reference = preferred || approximateQuality

  const sorted = [...list].sort((a, b) => {
    return a.height > b.height ? 1 : -1
  })

  for (let i = 0; i < sorted.length && !found; i++) {
    current = sorted[i]
    if (current.height === reference) {
      found = current
    }
    if (current.height > reference) {
      found = prev || current
    }
    prev = current
  }

  return found || current
}

export const isFirefox = typeof InstallTrigger !== 'undefined'
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window['safari'] ||
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification)
  )
