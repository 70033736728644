/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'

import CanvaLogo from 'assets/img/Canva-Logo.png'

export const useCanva = () => {
  const canvaApi = useRef()

  const initCanva = async () => {
    if (window.Canva && window.Canva.DesignButton) {
      canvaApi.current = await window.Canva.DesignButton.initialize({
        apiKey: 'EABxpMBgbC8M6SarMu3zVkNf',
      });
      console.log(canvaApi.current)
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sdk.canva.com/designbutton/v2/api.js';
    script.onload = () => initCanva()
    document.body.appendChild(script);
  }, [])

  return canvaApi
}

export const CanvaButton = ({ onClick }) =>
  <StyledButton
    variant="outlined"
    style={{ backgroundImage: CanvaLogo}}
    onClick={onClick}
  >
    <img src={CanvaLogo} css={{ height: '45px' }}/>
  </StyledButton>

const StyledButton = styled(Button)`
  padding-right: 16px;
  padding-left: 16px;
  max-height: 50px;
`