import React, { useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

import noPreviewImg from './previewNotAvailable.png'
import { AUTH_CONFIG } from '../../Auth/auth-variables'
import { PlaylistContentItem } from 'backend-v2/src/modules/library/services/types/lists'

const objectFit = {
  zoom: 'cover',
  scale: 'contain',
  stretch: 'fill',
}

interface IProps {
  playlistItem: PlaylistContentItem
  width: number
  height: number
  url: string
  mediaSeekPosition: number
  onVideoProgress: (progress: any) => void
  onVideoEnded: () => void
  style?: any
}

const MediaPlayer = ({
  playlistItem,
  width,
  height,
  url,
  mediaSeekPosition,
  onVideoProgress,
  onVideoEnded,
  style,
}: IProps) => {
  const cleanPreviewUrl = url && typeof url === 'string' && url.match(/([^?]+)/)[0]
  const ext = cleanPreviewUrl && cleanPreviewUrl.split('.').pop().toLowerCase()
  const playerRef = useRef()

  useEffect(() => {
    if (url && mediaSeekPosition && playerRef.current) {
      playerRef.current.seekTo(mediaSeekPosition, 'seconds')
    }
  }, [url])

  return (
    <>
      {playlistItem?.source === 'scenes' && (
        <iframe
          title="preview"
          src={`${AUTH_CONFIG.urlBase}/scene/${playlistItem.id}`}
          style={{
            width,
            height,
          }}
        />
      )}
      {(ext === 'mp4' || ext === 'webm' || ext === 'mov') && (
        <StyledReactPlayer
          ref={playerRef}
          width={`${width}px`}
          height={`${height}px`}
          progressInterval={500}
          autoPlay
          playing={true}
          muted
          volume={0}
          controls
          playsInline
          key={cleanPreviewUrl}
          url={url}
          onProgress={onVideoProgress}
          onEnded={onVideoEnded}
          objectFit={objectFit[playlistItem?.fit] || 'contain'}
        />
      )}
      {['jpeg', 'jpg', 'png', 'gif'].includes(ext) && (
        <img
          src={url}
          style={{
            width: width,
            height: height,
            objectFit: objectFit[playlistItem?.fit] || 'contain',
          }}
          alt="preview"
          onLoad={(e) => console.log(e)}
        />
      )}
      {!cleanPreviewUrl && !playlistItem?.source === 'scenes' && (
        <img
          src={noPreviewImg}
          alt="no preview"
          style={{
            display: 'block',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        />
      )}
    </>
  )
}

const StyledReactPlayer = styled(ReactPlayer)`
  video {
    object-fit: ${({ objectFit }) => objectFit};
  }
`

export default React.memo(MediaPlayer)
