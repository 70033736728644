import React from 'react'
import { ConfigInput } from './ConfigInput'
import { ConfigRoutesList } from './ConfigRoutesList'
import { ConfigRoute } from './ConfigRoute'
import { ConfigCheckbox } from './ConfigCheckbox'
import { ConfigMediaItem } from 'components/SceneComponents/TouchScene/fields/ConfigMediaItem'
import { ConfigRichText } from 'components/SceneComponents/TouchScene/fields/ConfigRichText'

export * from './ConfigInput'
export * from './ConfigRoutesList'
export * from './ConfigCheckbox'

export const chooseComponent = (config, key) => {
  const type = config[key]?.type || 'input'
  if (config[key]?.__showInEditor === false) {
    return false
  }

  if (['pageType', 'app_generic_settings'].includes(key)) {
    return false
  }

  switch (type) {
    case 'input':
    case 'text':
      return [ConfigInput, type]
    case 'text/rich':
      return [ConfigRichText, type]
    case 'URL/Image':
      return [ConfigMediaItem, type]
    case 'URL/Video':
      return [ConfigMediaItem, type]
    case 'List/URL+Title':
      return [ConfigRoutesList, type]
    case 'URL+Title':
      return [ConfigRoute, type]
    case 'Boolean':
      return [ConfigCheckbox, type]
    default:
      return ['div', type]
  }
}
