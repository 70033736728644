import {useEffect, useState} from "react";

const messageSignatureSource = "@aesend-config";

const storage = {};

const sendMessage = (message) => {
    message.source = messageSignatureSource;
    document.getElementById("angular-scene")?.contentWindow?.postMessage(message, "*");
}

const requestConfigPull = (onPullConfig) => {
    storage.onPullConfig = onPullConfig;
    sendMessage({event: "pullConfig"});
}


/**
 * @param config
 * @param callback
 * @return {{screenConfig: {}, setScreenConfig: (value: (((prevState: {}) => {}) | {})) => void, sendMessage: (value: {event: string, [payload]: {}, [target]: string})=>void, requestConfigPull: (value) => void}}
 */
export const useMessaging = (config, callback) => {
    const [screenConfig, setScreenConfig] = useState({});

    useEffect(() => {
        const listener = (messageEvent) => {
            const data = messageEvent.data;
            if (data.source === messageSignatureSource) {
                console.log("Heard in React", data);

                if (data.event === "angularReady") {
                    sendMessage(
                        {
                            event: "pushConfigR2A",
                            payload: config
                        }
                    )
                }
                if (data.event === "changePage") {
                    setScreenConfig(data.payload);
                }
                if (data.event === "updateConfig") {
                    setScreenConfig({});
                    setScreenConfig(data.payload);
                }
                if (data.event === "pushConfigA2R") {
                    // (typeof onPullConfig === "function") && onPullConfig(data.payload);
                    (typeof storage.onPullConfig === "function") && storage.onPullConfig.call(this, data.payload);
                }

                typeof callback === "function" && callback(messageEvent);
            }
        }
        window.addEventListener("message", listener);

        return () => {
            window.removeEventListener("message", listener);
        }
    }, []);

    return {sendMessage, screenConfig, setScreenConfig, requestConfigPull};
}
