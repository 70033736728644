import React from 'react';
import Dropzone from 'react-dropzone';
import ReactPlayer from 'react-player';
import { FormText } from 'reactstrap';
import { AvGroup } from 'availity-reactstrap-validation';

import dropzoneStyle from './dropzoneStyle';

const SoundUploadForm = ({ formSpec, parentState, handleChange }) => {
  return (
    <div>
      { !parentState.soundFiles &&
      <Dropzone
        id='dropzone'
        multiple={false}
        accept="audio/*"
        onDrop={handleChange}
      >
        { () => <div style={dropzoneStyle}>Drop your mp3 file here...</div> }
      </Dropzone>
      }
      { parentState.soundFiles &&
        <ReactPlayer
          url={parentState.soundFiles[0].preview}
          controls
          width='100%'
        />
      }
      { <AvGroup style={{ paddingTop: '30px'}}>
        <FormText color="muted">
          Please select the mp3 soundtrack for the video.
          Max file size {formSpec.max_size} MB.
        </FormText>
      </AvGroup>}
    </div>);
}

export default SoundUploadForm;
