import React from 'react'

import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { SortableItem } from './SortableItem'
import { CardContent, IconButton, Typography } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import styled from 'styled-components'

const Symbol = ({ value, onRemove, id }) => {
  return (
    <SortableItem id={id} onClick={() => {
      console.log('????')
    }}>
      <CardContent>
        <div className="value">
          <TypographyStyled variant='h5'>
            {value}
          </TypographyStyled>
        </div>
        <IconButton className='delete-button' onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </SortableItem>
  )
}

export const SymbolsList = ({ value, onChange }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 10 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const removeSymbol = (index) => {
    const current = [...value];
    current.splice(index, 1);
    onChange(current);
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = value.indexOf(active.id);
      const newIndex = value.indexOf(over.id);

      const newList = arrayMove(value, oldIndex, newIndex);
      onChange(newList);
    }
  }

  if (!value || !value.length) {
    return null;
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <StyledList>
        <SortableContext items={value} strategy={rectSortingStrategy}>
          {value.map((symbol, index) => <Symbol
            key={symbol}
            id={symbol}
            onRemove={() => removeSymbol(index)}
            value={symbol} />)}
        </SortableContext>
      </StyledList>
    </DndContext>
  )
}

const TypographyStyled = styled(Typography)`
  text-transform: uppercase;
`

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & > div {
    margin: 16px 16px 0 0;
    position: relative;

    .delete-button {
      transition: all .2s ease;
      opacity: .2;

      width: 30px;
      height: 30px;

      &:hover {
        opacity: 1;
      }
    }

    width: 130px;

    cursor: pointer;

    & > div {
      padding-bottom: 16px !important;

      display: flex;
      justify-content: space-between;

      .value {
        flex-grow: 10;
        text-align: center;
      }

      svg {
        font-size: 1.3rem;
      }
    }
  }
`
