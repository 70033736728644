import React from 'react'
import { Form } from 'react-bootstrap'
import cls from 'classnames'
import ImageUploadForm from './components/imageUploadForm'
import { SIDE_PANEL_TYPES } from 'util/constants'

const isVideo = (type) => {
  return type === 'URL/Video'
}

const isImage = (type) => {
  return type === 'URL/Image'
}

const chooseAccept = (type) => {
  switch (type) {
    case isImage(type):
      return ['image/*']
    case isVideo(type):
      return ['video/*']
    default:
      return undefined
  }
}

const getFilter = (type) => {
  let filterFunction = (item) => item //bypass function, return what was given

  if (isVideo(type)) {
    filterFunction = (item) => item.media_type.indexOf('video') >= 0
  }

  if (isImage(type)) {
    filterFunction = (item) => item.media_type.indexOf('image') >= 0
  }

  return (media) => {
    return media.filter((item) => {
      return filterFunction(item)
    })
  }
}

export const ConfigMediaItem = ({
  elementKey,
  config,
  setSidePanelParams,
  className,
  setAsset,
  type,
  openLibrary,
}) => {
  const _onChange = (fileUrl) => {
    setAsset({ url: fileUrl }, { mediaType: 's3_upload', elementKey })
  }

  const openDrawer = ({ type: dialogType = SIDE_PANEL_TYPES.mediaLibrary }) => {
    openLibrary({
      type: dialogType,
      open: true,
      elementKey,
      filter: getFilter(type),
    })
  }

  // const showMediaContentButton = isVideo(type)

  return (
    <div className={cls(className, 'library')}>
      <Form.Label>{config.name}</Form.Label>
      <ImageUploadForm
        handleChange={_onChange}
        setSidePanelParams={openDrawer}
        previewImage={config.value}
        fileUrl={config.value}
        fieldName={config.name}
        withTest={false}
        accept={chooseAccept(type)}
        showMediaLibraryButton={true}
        showMediaContentButton={false}
      />
    </div>
  )
}
