import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw'
import IconButton from '@mui/material/IconButton'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import styled from 'styled-components'
import screenfull from 'screenfull'

import { useWindowSize } from 'hooks/useWindowSize'

const Preview = ({ saving, sceneUrl, children }) => {
  const [windowWidth, windowHeight] = useWindowSize()
  const [dims, setDims] = useState()
  const [width, setWidth] = useState(1920)
  const [height, setHeight] = useState(1080)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const containerRef = useRef()
  const aspectRatio = width / height
  const maxPreviewHeight = window.innerHeight - (isFullscreen ? 80 : 300)

  useEffect(() => {
    if (containerRef.current) {
      let previewWidth = containerRef.current.offsetWidth
      let previewHeight = previewWidth / aspectRatio

      if (previewHeight > maxPreviewHeight) {
        previewHeight = maxPreviewHeight
        previewWidth = previewHeight * aspectRatio
      }
      setDims({ width: previewWidth, height: previewHeight })
    }
  }, [containerRef, aspectRatio, windowWidth, windowHeight])

  const rotate = () => {
    setWidth(height)
    setHeight(width)
  }

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(containerRef.current)
      setTimeout(() => setIsFullscreen((i) => !i), 1000)
    }
  }

  return (
    <Container
      ref={containerRef}
      style={isFullscreen ? { background: 'white', padding: 10 } : {}}
    >
      <Header>
        <span>Preview:</span>
        <div className="d-flex align-items-center">
          <StyledTextField
            margin="dense"
            size="small"
            variant="outlined"
            className="mr-1"
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            style={{ maxWidth: 80 }}
          />{' '}
          W
          <StyledTextField
            margin="dense"
            size="small"
            variant="outlined"
            className="ml-3 mr-1"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            style={{ maxWidth: 80 }}
          />{' '}
          H
          <IconButton onClick={rotate} style={{ marginLeft: 10 }} size="large">
            <Rotate90DegreesCcwIcon/>
          </IconButton>
          <IconButton onClick={toggleFullScreen} style={{ marginLeft: 10 }} size="large">
            {isFullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
          </IconButton>
        </div>
      </Header>
      <div key={aspectRatio} style={{ height: dims?.height, width: dims?.width }}>
        {children ? children :
          <iframe
            key={`${saving} ${isFullscreen}`}
            title="preview"
            src={sceneUrl}
            style={{ width: '100%', height: '100%' }}
          />
        }
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 0 10px #00000030;
  padding-left: 10px;
  border-radius: 10px;
`

const StyledTextField = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export default Preview
