import {
  GET_TEMPLATES,
  GET_TEMPLATE_CATEGORIES,
  CREATE_RENDER_REQUEST,
  SET_TEMPLATE_SORT,
} from './types'
import TemplateApi from '../api/templates'
const templateApi = new TemplateApi()

/**
 * Redux Action Get Templates
 */
export const getTemplates = (filter) => (dispatch, getState) => {
  const {
    authUser: { activeAccount },
  } = getState()
  templateApi
    .get(filter, activeAccount)
    .then((response) => {
      dispatch({ type: GET_TEMPLATES, payload: response })
    })
    .catch((errors) => {
      console.log(errors)
      // error handling
    })
}

export const getTemplateCategories = () => (dispatch, getState) => {
  const {
    authUser: { activeAccount },
  } = getState()
  templateApi
    .getCategories(activeAccount)
    .then((response) => dispatch({ type: GET_TEMPLATE_CATEGORIES, payload: response }))
    .catch((errors) => {
      console.log(errors)
      // error handling
    })
}

export const addRenderRequest = (data, navigate) => (dispatch, getState) => {
  const {
    authUser: { activeAccount },
  } = getState()
  templateApi
    .addRenderRequest(data, activeAccount)
    .then((response) => {
      dispatch({ type: CREATE_RENDER_REQUEST, payload: response })
      navigate(`/app/templates/template-customization/rendering/${response}`)
    })
    .catch((errors) => {
      console.log(errors)
    })
}

export const addPreviewRenderRequest = (data) => async (dispatch) => {
  const result = await templateApi.addRenderRequest(data)
  return result
}

export const setTemplateSort = (sort) => ({
  type: SET_TEMPLATE_SORT,
  payload: sort,
})
