import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { logoutUser } from 'data/actions'
import { AUTH_CONFIG } from 'Auth/auth-variables'

const baseQuery = fetchBaseQuery({
  baseUrl: AUTH_CONFIG.apiEndpointV2,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('id_token')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(logoutUser())
  }
  return result
}

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: ['Tags', 'Assets', 'Playlist', 'SharedAccounts'],
  baseQuery: baseQueryWithLogout,
  endpoints: () => ({}),
})
