import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'

import TemplateApi from 'data/api/templates'
import MediaApi from 'data/api/media'
import AssetPreview from './assetPreview'
import { AssetsScroll, Container, NoAssetsStyled } from './Container'

const templateApi = new TemplateApi()
const mediaApi = new MediaApi()

enum Views {
  RENDER_LIST = 'RENDER_LIST',
  SELECTION = 'SELECTION',
}

const PriorRenders = ({
  accountId,
  currentFolderId,
  close,
  multiple = true,
  onSelect,
}) => {
  const [currView, setCurrView] = useState<Views>(Views.RENDER_LIST)
  const [selectedAssets, setSelectedAssets] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [renders, setRenders] = useState([])
  const [checked, setChecked] = useState([])
  const [loading, setLoading] = useState(false)
  const templates = useSelector((state) => state.templates.list)

  const templatesById = useMemo(() => _.keyBy(templates, 'id'), [templates])
  const filteredRenders = renders.filter((r) =>
    selectedTemplate ? r.template_id === selectedTemplate : true
  )

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const renders = await templateApi.getRenderDetails(undefined, undefined, accountId)
      setRenders(
        renders
          .filter((r) => !!r.output_url && !r.is_preview)
          .sort((a, b) => (b.completed_date > a.completed_date ? 1 : -1))
          .map((render) => ({
            ...render,
            templateName: templatesById[render.template_id]?.name,
          }))
      )
      setLoading(false)
    })()
  }, [])

  const handleChange = (value) => setSelectedTemplate(value)

  const handleToggle = (value) => () => {
    let newChecked = []
    if (multiple) {
      const currentIndex = checked.indexOf(value)
      newChecked = [...checked]
      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }
    } else {
      newChecked = [value]
    }

    setChecked(newChecked)
  }

  const makeSelection = () => {
    setSelectedAssets(
      renders
        .filter((r) => checked.includes(r.template_hash))
        .map((r) => {
          let title = `${r.templateName} ${
            r.completed_date ? r.completed_date.slice(0, 10) : ''
          } `
          title = r.name ? `${title} - (${r.name})` : title
          return { ...r, title }
        })
    )
    setCurrView(Views.SELECTION)
  }

  const removeAsset = (id: number) => {
    setSelectedAssets(selectedAssets.filter((a) => a.template_hash !== id))
    handleToggle(id)()
    if (selectedAssets?.length === 1) {
      setCurrView(Views.RENDER_LIST)
    }
  }

  const uploadMedia = async () => {
    await Promise.all(
      checked.map((renderId) =>
        mediaApi.import({
          source: 'aesend-render',
          renderId,
          accountId,
          currentFolderId,
        })
      )
    )
    close()
  }

  return (
    <Container>
      {currView === Views.SELECTION && (
        <>
          <div className="table">
            <h1>Selected Media</h1>
            {(!selectedAssets || !selectedAssets.length) && (
              <NoAssetsStyled>
                <p>
                  No media have been added. Click the Add More button to select previously
                  rendered media.
                </p>

                <div className="with-button">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setCurrView(Views.RENDER_LIST)}
                  >
                    Add More
                  </Button>
                </div>
              </NoAssetsStyled>
            )}
            <AssetsScroll>
              {selectedAssets.map((asset) => (
                <div className="asset" key={asset.output_url}>
                  <AssetPreview url={asset.output_url} maxWidth={100} maxHeight={50} />
                  <div className="label">{asset.title}</div>
                  <IconButton
                    onClick={() => removeAsset(asset.template_hash)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
            </AssetsScroll>
          </div>
          <div className="footer">
            {selectedAssets?.length > 0 ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setCurrView(Views.RENDER_LIST)}
              >
                Add More
              </Button>
            ) : (
              <div />
            )}
            <Button variant="contained" color="primary" onClick={uploadMedia}>
              Upload
            </Button>
          </div>
        </>
      )}

      {currView === Views.RENDER_LIST && (
        <>
          <Paper sx={{ padding: 0, marginBottom: 1, mt: -1 }} elevation={0}>
            <FormControl variant="standard" style={{ minWidth: 270 }}>
              <InputLabel id="template-select">Template</InputLabel>
              <Select
                variant="standard"
                id="template-select"
                value={selectedTemplate}
                onChange={(e) => handleChange(e.target.value)}
              >
                <MenuItem value="">- Select -</MenuItem>
                {templates?.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
          <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {loading && <LinearProgress />}
            <StyledList dense>
              {filteredRenders.map(
                ({ templateName, template_hash, name, completed_date }) => {
                  const labelId = `checkbox-list-secondary-label-${template_hash}`
                  let label = `${templateName} ${
                    completed_date ? completed_date.slice(0, 10) : ''
                  } `
                  label = name ? `${label} - (${name})` : label
                  return (
                    <ListItem
                      key={template_hash}
                      button
                      onClick={handleToggle(template_hash)}
                    >
                      <ListItemText id={labelId} primary={label} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(template_hash)}
                          checked={checked.indexOf(template_hash) !== -1}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                }
              )}
              {(!filteredRenders || !filteredRenders.length) && !loading && (
                <ListItem button>
                  <ListItemText id={0} primary={'No Matching Renders'} />
                </ListItem>
              )}
            </StyledList>
          </Box>
          <Box className="d-flex justify-content-end" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!checked || !checked.length}
              onClick={makeSelection}
            >
              Select
            </Button>
          </Box>
        </>
      )}
    </Container>
  )
}

const StyledList = styled(List)`
  width: 100%;
  background-color: white;

  li {
    border-bottom: 1px solid #ddd;
  }
`

export default PriorRenders
