import { useState, useEffect, useRef, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
// import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride'
import { Box, Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

import AesendLogo from 'assets/img/aesend-logo.svg'
import SpotDirectLogo from 'assets/img/SpotDirectLogo.svg'
import { useStylesActions } from 'hooks/useActions'
import HorizontalMenu from 'components/Menu/HorizontalMenu/HorizontalMenu'
import { ProfileMenu } from './ProfileMenu'
import UserRoles from 'constants/UserRoles'
import { useUpdateOnboardingDateMutation, useGetMeQuery } from 'store/services/user'
import Responsive from 'react-responsive'
import { HamburgerMenu } from 'components/Menu/HamburgerMenu/HamburgerMenu'

interface IProps {}

const Mobile = (props) => <Responsive {...props} maxWidth={767} />
const Desktop = (props) => <Responsive {...props} minWidth={768} />

const Header = ({}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [steps, setSteps] = useState<Step[]>([])
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const { user, styles } = useSelector(({ authUser, styles }) => {
    return { user: authUser, styles: styles?.list }
  })
  const menuRef = useRef<HTMLButtonElement>(null)
  const stylesActions = useStylesActions()
  const [updateOnboardingDate] = useUpdateOnboardingDateMutation()
  const { data: me } = useGetMeQuery()
  const roles = user?.profile?.map((profile) => profile.role_name)
  const permissions = {
    isSuperAdmin: roles?.some((role) => role === UserRoles.SUPER_ADMIN),
    isAgencyAdmin: roles?.some((role) => role === UserRoles.AGENCY_ADMIN),
    isAccountAdmin: roles?.some((role) => role === UserRoles.CUSTOMER_ADMIN),
  }

  const currentAccountName = user.activeAccount
    ? user.profile.find((account) => account.account_id === user.activeAccount)
        ?.account_name
    : null

  useEffect(() => {
    stylesActions.getStyles()
  }, [user.activeAccount])

  const handleProfileClick = (e: MouseEvent) => {
    setAnchorEl(e.currentTarget)
    if (stepIndex === 1 && run) {
      setTimeout(() => {
        setStepIndex(2)
      }, 100)
    }
  }
  const handleProfileClose = () => setAnchorEl(null)

  useEffect(() => {
    if (!me) return
    const { onboardingViewedDate, termsAgreedDate } = me
    if (onboardingViewedDate || !termsAgreedDate) return

    setSteps([
      {
        content: (
          <Box>
            <h1>Welcome to aesend 2.0!</h1>
            <br />
            <p>
              We've made some changes to the aesend interface.
              <br /> Let us show you how to access some tutorial content.
            </p>
          </Box>
        ),
        locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
        spotlightClicks: true,
        disableOverlayClose: true,
        disableBeacon: true,
        placement: 'center',
        target: 'body',
      },
      {
        title: 'New Profile Menu',
        content: (
          <div>
            Several functions have moved into your profile menu. Click to open the menu.
          </div>
        ),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        target: menuRef.current!,
      },
      {
        title: 'Help',
        content: 'Click help to view some introductory videos',
        placement: 'left',
        target: '#help',
      },
    ])
    setRun(true)
  }, [me])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      updateOnboardingDate()
    }
  }

  return (
    <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none' }}>
      {/* <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        styles={{ options: { zIndex: 10000, width: 400 } }}
      /> */}
      <Toolbar
        className="d-flex justify-content-between w-100 px-0"
        sx={{ minHeight: '60px!important', height: '60px' }}
      >
        <Grid container flexWrap="nowrap" justifyContent="space-between">
          <Grid container flexWrap="nowrap">
            <div className="site-logo">
              <Link to="/app/templates/templates-list" className="logo-normal">
                <img
                  src={user.activeAccount === 26 ? SpotDirectLogo : AesendLogo}
                  alt="site-logo"
                  width={125}
                />
              </Link>
            </div>
            <Desktop>
              <HorizontalMenu location={location} />
            </Desktop>
          </Grid>
          <Grid container justifyContent="flex-end" flexWrap="nowrap">
            <div className="mr-3 d-flex align-items-center">
              <Button onClick={handleProfileClick} ref={menuRef}>
                <Avatar src={user?.user?.idTokenPayload?.picture} />
              </Button>
              <Box onClick={handleProfileClick} sx={{ cursor: 'pointer' }}>
                <Typography sx={{ color: '#000' }}>
                  {user?.user?.idTokenPayload?.name}
                </Typography>
                <Typography sx={{ color: '#666', fontSize: '0.8rem' }}>
                  {currentAccountName}
                </Typography>
              </Box>
              <ProfileMenu
                anchorEl={anchorEl}
                handleClose={handleProfileClose}
                user={user}
                permissions={permissions}
                styles={styles}
              />
            </div>
            <Mobile>
              <HamburgerMenu />
            </Mobile>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
