import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'

const CardContentNoPadding = styled(CardContent)(`
  padding: 8px 10px;
  &:last-child {
    padding-bottom: 8px;
  }
`)
export default CardContentNoPadding
