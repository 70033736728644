import { baseApi } from './baseApi'
import { AUTH_CONFIG } from 'Auth/auth-variables'
import { AccountId, LibraryRequest, LibraryAsset, AssetType } from 'store/types'
import {
  AssetIdentifier,
  CreateAssetTagsDto,
  DeleteAssetTagsDto,
} from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import {
  CreateMediasDto,
  LibraryListPaginated,
  MediaListEntry,
} from 'backend-v2/src/modules/library/dtos/library.dto'
import { PlaylistItemExtended } from 'backend-v2/src/modules/library/services/types/lists'

const assetTypesRoutes = ['media', 'playlists', 'scenes']

const getAssetTypeRoute = (assetType: AssetType) =>
  ({
    [AssetType.MEDIA]: 'media',
    [AssetType.PLAYLIST]: 'playlists',
    [AssetType.APP]: 'scenes',
  }[assetType])

export const libraryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLibrary: builder.query<LibraryListPaginated<any>, LibraryRequest>({
      query: ({ accountId, ...params }) => {
        return {
          url: `/v2/${accountId}/library`,
          params,
        }
      },
      providesTags: ['Assets'],
    }),
    getAsset: builder.query<LibraryAsset, AccountId & { asset: AssetIdentifier }>({
      query: ({ accountId, asset, ...params }) => ({
        url: `${AUTH_CONFIG.apiEndpoint}/${accountId}/${
          assetTypesRoutes[asset.assetType]
        }/${asset.assetId}`,
        params,
      }),
    }),
    createAsset: builder.mutation<void, AccountId & CreateMediasDto>({
      query: (body) => ({
        method: `POST`,
        url: `/v2/${body.accountId}/library`,
        body,
      }),
      invalidatesTags: () => ['Assets'],
    }),
    createAssetTags: builder.mutation<void, AccountId & CreateAssetTagsDto>({
      query: ({ accountId, ...data }) => ({
        method: `PUT`,
        url: `/v2/${accountId}/library/asset-tags`,
        body: data,
      }),
      invalidatesTags: () => ['Assets', 'Tags'],
    }),
    removeAssetTags: builder.mutation<void, AccountId & DeleteAssetTagsDto>({
      query: ({ accountId, ...data }) => ({
        method: `DELETE`,
        url: `/v2/${accountId}/library/asset-tags`,
        body: data,
      }),
      invalidatesTags: () => ['Assets', 'Tags'],
    }),
    removeAssets: builder.mutation<void, AccountId & { assets: AssetIdentifier[] }>({
      query: ({ accountId, ...body }) => ({
        method: `DELETE`,
        url: `/v2/${accountId}/library`,
        body,
      }),
      invalidatesTags: () => ['Assets', 'Tags'],
    }),
    getPlaylist: builder.query<
      MediaListEntry<PlaylistItemExtended>,
      AccountId & { playlistId: number }
    >({
      query: ({ accountId, playlistId }) => ({
        method: `GET`,
        url: `/v2/${accountId}/library/playlist/${playlistId}`,
      }),
      providesTags: ['Playlist'],
    }),
    deleteAsset: builder.mutation<void, { asset: AssetIdentifier }>({
      query: ({ asset }) => ({
        method: `DELETE`,
        url: `${AUTH_CONFIG.apiEndpoint}/${getAssetTypeRoute(asset.assetType)}/${
          asset.assetId
        }`,
      }),
      invalidatesTags: () => ['Assets', 'Tags'],
    }),
    updateAsset: builder.mutation<void, { asset: AssetIdentifier; data: any }>({
      query: ({ asset, data }) => {
        return {
          method: `PATCH`,
          url: `${AUTH_CONFIG.apiEndpoint}/${assetTypesRoutes[asset.assetType]}/${
            asset.assetId
          }`,
          body: data,
        }
      },
      invalidatesTags: () => ['Assets', 'Tags'],
    }),
    cloneAsset: builder.mutation<
      void,
      { asset: AssetIdentifier; data?: any; accountId: number }
    >({
      query: ({ asset, data, accountId }) => {
        if (assetTypesRoutes[asset.assetType] === 'playlists') {
          return {
            method: `POST`,
            url: `v2/${accountId}/library/playlist/${asset.assetId}/duplicate`,
          }
        }
        return {
          method: `POST`,
          url: `${AUTH_CONFIG.apiEndpoint}/${accountId}/${
            assetTypesRoutes[asset.assetType]
          }/${asset.assetId}/clone`,
          body: data,
        }
      },
      invalidatesTags: () => ['Assets', 'Tags'],
    }),
  }),
})

export const {
  useGetLibraryQuery,
  useCreateAssetMutation,
  useGetAssetQuery,
  useLazyGetAssetQuery,
  useCreateAssetTagsMutation,
  useRemoveAssetTagsMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useCloneAssetMutation,
  useGetPlaylistQuery,
  useRemoveAssetsMutation,
} = libraryApi
