import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled, alpha } from '@mui/material/styles'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import LogoutIcon from '@mui/icons-material/Logout'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { logoutUser, switchActiveAccount } from 'data/actions'
import { AccountSelectSubmenu } from './AccountSelectSubmenu'
import { StyleguideSubmenu } from './StyleguideSubmenu'

const StyledButton = styled(Button)`
  border-color: rgba(0, 0, 0, 0.25);
  font-weight: bold !important;
`
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 10,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      paddingLeft: '4px',
      minWidth: '300px',
      marginBottom: 3,
      '& >.MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 0,
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

interface IProps {
  anchorEl: null | HTMLElement
  handleClose: () => void
  user: any
  permissions: { [type: string]: boolean }
  styles: any
}

enum SubMenu {
  STYLE_GUIDE = 'STYLE_GUIDE',
  ACCOUNTS = 'ACCOUNTS',
}

export const ProfileMenu = ({
  anchorEl,
  handleClose,
  user,
  permissions,
  styles,
}: IProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [subMenu, setSubMenu] = useState<undefined | SubMenu>()
  const currentAccount = user?.profile?.find((p) => p.account_id === user.activeAccount)

  useEffect(() => {
    if (anchorEl) setSubMenu(undefined)
  }, [anchorEl])

  const handleClick = (url: string) => () => {
    navigate(url)
    handleClose()
  }

  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {!subMenu ? (
        [
          <MenuItem key="profile" onClick={handleClick('user-management')} disableRipple>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={user?.user?.idTokenPayload?.picture} sx={{ marginRight: 2 }} />
              <Box>
                <Typography>
                  {user?.profile?.[0]?.first_name} {user?.profile?.[0]?.last_name}
                </Typography>
                <Link href="#">View Profile</Link>
              </Box>
            </Box>
            <ChevronRight />
          </MenuItem>,
          ...(user?.profile?.length >= 2
            ? [
                <MenuItem
                  key="currAcct"
                  onClick={() => setSubMenu(SubMenu.ACCOUNTS)}
                  disableRipple
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                >
                  Current Account
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginRight: 1,
                      color: 'primary.main',
                    }}
                  >
                    {currentAccount?.account_name}
                  </Box>
                  <ChevronRight />
                </MenuItem>,
              ]
            : []),
          ,
          <Divider key="divider" sx={{ my: 0.5 }} />,
          <MenuItem
            key="styles"
            onClick={() => setSubMenu(SubMenu.STYLE_GUIDE)}
            disableRipple
          >
            Manage Styleguide
            <ChevronRight />
          </MenuItem>,
          permissions.isSuperAdmin || permissions.isAgencyAdmin
            ? [
                <MenuItem
                  key="manageAccts"
                  onClick={handleClick('admin/accounts/account-list')}
                  disableRipple
                >
                  Manage Accounts
                  <ChevronRight />
                </MenuItem>,
              ]
            : [],
          permissions.isSuperAdmin ||
          permissions.isAgencyAdmin ||
          permissions.isAccountAdmin
            ? [
                <MenuItem
                  key="manageCollaborators"
                  onClick={handleClick('admin/users/user-list')}
                  disableRipple
                >
                  Manage Collaborators
                  <ChevronRight />
                </MenuItem>,
              ]
            : [],
          permissions.isSuperAdmin
            ? [
                <MenuItem
                  key="manageTempl"
                  onClick={handleClick('admin/templates/templates-list')}
                  disableRipple
                >
                  Manage Templates
                  <ChevronRight />
                </MenuItem>,
                <MenuItem
                  key="manageSw"
                  onClick={handleClick('admin/software-releases/list')}
                  disableRipple
                >
                  Manage Software
                  <ChevronRight />
                </MenuItem>,
              ]
            : [],
          <MenuItem
            key="help"
            id="help"
            onClick={() => window.open('https://vimeo.com/showcase/10399924')}
            disableRipple
          >
            Help
            <ChevronRight />
          </MenuItem>,
          <StyledButton
            key="logout"
            variant="outlined"
            sx={{ marginTop: 2 }}
            onClick={() => dispatch(logoutUser(navigate))}
          >
            <LogoutIcon sx={{ marginRight: 1 }} />
            Log out
          </StyledButton>,
        ]
      ) : subMenu === SubMenu.ACCOUNTS ? (
        <AccountSelectSubmenu
          user={user}
          onSelect={(id) => {
            dispatch(switchActiveAccount(id))
            setSubMenu(undefined)
          }}
          onBack={() => setSubMenu(undefined)}
        />
      ) : (
        <StyleguideSubmenu
          onBack={() => setSubMenu(undefined)}
          handleClick={handleClick}
          styles={styles}
        />
      )}
    </StyledMenu>
  )
}
