import React from "react";
import styled, { css, keyframes } from "styled-components";
import cls from 'classnames';

const Preloader = ({ active, className, color = '#FFFFFF99', size = 24, items = 5 }) => {
  if (!active) {
    return null
  }

  return (
    <PreloaderStyled className={ cls(className) } $loading={ active } $color={ color } $size={ size } $item={items}>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </PreloaderStyled>
  )
}

const loader = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    opacity: .3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
`

const itemsCss = (count = 5) => {
  let styles = '';

  for (let i = 0; i < count; i++) {
    styles += `
      > div:nth-child(${ i }) {
        animation-delay: ${ .1 * i }s;
      }
    `;
  }

  return css`${ styles }`;
}

const PreloaderStyled = styled.div`
  display: ${ ({ $loading }) => $loading ? 'flex' : 'none' };
  justify-content: center;

  > div {
    width: ${({$size})=>$size}px;
    height: ${({$size})=>$size}px;
    margin-right: ${({$size})=>$size}px;
    background: ${ ({ $color }) => $color };
    animation: ${ loader } 1s infinite;
  }

  ${ ({$items}) => itemsCss($items) }
`;

export { Preloader };
