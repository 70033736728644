import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { SxProps } from '@mui/material'

const ITEM_HEIGHT = 48
export type ContextMenuOption = {
  label: string
  icon?: string
  onClick: () => any
  skip?: boolean
}
export type ContextMenuProps = {
  options: ContextMenuOption[]
  sx?: SxProps
}

const ContextMenu = ({ options, sx }: ContextMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleKabobClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent) => {
    event?.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        sx={{...sx}}
        onClick={handleKabobClick}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        size="small"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: '10rem',
            borderRadius: '10px',
          },
        }}
      >
        {options.map(({ label, icon: Icon, onClick }) => (
          <MenuItem
            sx={{
              display: 'flex',
              columnGap: '.5rem',
              fontFamily: 'DMSans',
              fontSize: '14px',
            }}
            key={label}
            onClick={(event) => {
              event.stopPropagation()
              onClick()
              handleClose(event)
            }}
          >
            {Icon && <img src={Icon} alt={label} height={'18px'} width={'18px'} />}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ContextMenu
