import React from 'react'
import { keyBy } from 'lodash'
import styled from 'styled-components'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { emphasize } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const FolderBreadcrumb = ({ folders, currentFolderId, onClick }) => {
  const foldersById = keyBy(folders, 'id')
  const path = foldersById[currentFolderId]?.path

  const folderIds = currentFolderId
    ? [
        ...(path ? path.split('/').filter(i => i).map(i => Number(i)) : []),
        currentFolderId
      ]
    : []
  // console.log(path, currentFolderId, folders, foldersById, folderIds)

  folderIds.map(id => {
    if (!foldersById[id]?.name) {
      console.error('!!! folder name is missing', id, foldersById[id]);
    }
  })


  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      <StyledBreadcrumb
        component="a"
        href="#"
        label="Media Library"
        icon={<HomeIcon fontSize="small" />}
        onClick={e => {
          e.preventDefault()
          onClick(null)
        }}
      />
      {folderIds.map(id =>
        <StyledBreadcrumb
          key={foldersById[id]?.name}
          href="#"
          label={foldersById[id]?.name}
          onClick={e => {
            e.preventDefault()
            onClick(id)
          }}
        />
      )}
    </StyledBreadcrumbs>
  )
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
  background: white;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
`

export default FolderBreadcrumb
