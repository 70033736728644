import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputLabel: {
    transform: 'translate(14px, 10px) scale(1)',
  },
  select: {
    padding: '8.5px 14px'
  }
});

const TagSelect = ({ classes, value, tags, onSelect }) =>
  <FormControl
    variant="outlined"
    className={classes.formControl}>
    <InputLabel className={classes.inputLabel}>Tag Filter</InputLabel>
    <Select
      variant="standard"
      native
      // dense
      value={value}
      onChange={e => onSelect(e.target.value)}
      classes={{ select: classes.select}}
      input={
        <OutlinedInput
          style={{ background: '#eee' }}
          labelWidth={30}
          name="Sort"
          id="outlined-sort-native"
        />
      }>
      <option value="" />
      {tags.map(tag => <option value={tag.id} key={tag.id}>{tag.name}</option>)}
    </Select>
  </FormControl>

TagSelect.propTypes = {
  classes: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default withStyles(styles)(TagSelect);
