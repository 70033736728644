import React, {useState} from 'react';
import {Form} from 'react-bootstrap';

export const ConfigInput = ({config, onFocus, onBlur, onChange, className}) => {
    const [value, setValue] = useState(config.value)
    const _onChange = (event) => {
        setValue(event.target.value);
        typeof onChange === "function" && onChange(event.target.value);
    }
    return (
        <div className={className}>
            <Form.Label>{config.name}</Form.Label>
            <Form.Control value={value} onFocus={() => onFocus()} onBlur={onBlur} onChange={_onChange}/>
        </div>
    )
}
