import { useState } from 'react'
import axios from 'axios'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDropzone, FileWithPath } from 'react-dropzone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import { getFileTypeFromMediaType, MediaType } from 'routes/cms/library/types'
import TileAsset from 'components/Library/Tile/TileAsset'
import AssetPreview from './assetPreview'
import { AssetObject, SourceSection } from 'components/FormSlider.v2'
import FileUploadApi from 'data/api/fileUpload'

const fileUploadApi = new FileUploadApi()
const CancelToken = axios.CancelToken

type FileWithPathAndPreview = FileWithPath & {
  preview: string
}

type MyDeviceProps = {
  pickerMode?: boolean
  currentTag?: number | string
  multiple?: boolean
  accountId?: number
  onDoneClick:
    | ((files?: FileWithPathAndPreview[]) => any)
    | ((assets: AssetObject[]) => void)
  uploadPreSignMethod?: (
    accountId: number,
    name: string,
    mediaType: string
  ) => Promise<string>
}

const PreviewImage = ({ file }: { file: FileWithPathAndPreview }) => {
  const fileType = getFileTypeFromMediaType(file.type as MediaType)
  if (['Image', 'Video'].includes(fileType)) {
    return (
      <AssetPreview
        url={file.preview}
        mediaType={file.type}
        maxWidth={100}
        maxHeight={70}
      />
    )
  }
  return (
    <Box sx={{ width: '60px', height: '32px' }}>
      <TileAsset
        assetInfo={{
          type: fileType,
          mediaType: file.type as MediaType,
          aspectRatio: 1,
        }}
        iconSize={15}
        height={80}
      />
    </Box>
  )
}
const MyDevice = ({
  onDoneClick,
  currentTag,
  pickerMode,
  accountId,
  multiple,
  uploadPreSignMethod,
}: MyDeviceProps) => {
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPathAndPreview[]>([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setAcceptedFiles((p) => [
        ...p,
        ...(acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            currentTag,
          })
        ) as FileWithPathAndPreview[]),
      ])
    },
  })

  const removeAcceptedFile = (index: number) => {
    setAcceptedFiles((prev) => prev.filter((_, idx) => idx !== index))
  }

  const uploadMedia = async () => {
    const source = CancelToken.source()
    const presignedUrls = await Promise.all(
      acceptedFiles.map((file) => {
        const ts = Math.round(new Date().getTime() / 1000)
        const split = file.name.split('.')
        const ext = split.pop()
        const name = split.join('.') + '-upload-' + ts + '.' + ext
        return uploadPreSignMethod(accountId, name, file.type)
      })
    )
    try {
      try {
        await Promise.all(
          presignedUrls.map(({ signedPutUrl }, idx) => {
            const uploadConfig = {
              headers: { 'Content-Type': acceptedFiles[idx]?.type },
              cancelToken: source.token,
            }
            return fileUploadApi.uploadFileToS3(
              signedPutUrl,
              acceptedFiles[idx],
              uploadConfig
            )
          })
        )
      } catch (e) {
        console.error(e)
      }

      const result = presignedUrls.map(({ key, signedPutUrl }, idx) => {
        return {
          account_id: accountId,
          name: acceptedFiles[idx].name,
          media_type: acceptedFiles[idx].type,
          s3_key: key,
          url: signedPutUrl.split('?')[0],
        }
      })

      await onDoneClick(result)
      close()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {acceptedFiles?.length > 0 && (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 250px)',
            overflow: 'scroll',
            marginBottom: '2rem',
            padding: '0 15px',
          }}
        >
          <Grid container direction="column" rowGap="20px">
            {acceptedFiles.map((file: FileWithPathAndPreview, index) => {
              return (
                <Grid
                  key={file.path}
                  item
                  xs={12}
                  container
                  alignItems="center"
                  columnGap={'10px'}
                  flexWrap="nowrap"
                  justifyContent={'space-between'}
                >
                  <Grid container columnGap={'10px'} alignItems="center">
                    <div style={{ width: '100px' }}>
                      <PreviewImage file={file} />
                    </div>
                    <Typography variant="subtitle1">
                      <b>{file.name}</b>
                    </Typography>
                  </Grid>
                  <IconButton onClick={() => removeAcceptedFile(index)}>
                    <DeleteIcon htmlColor="red" />
                  </IconButton>
                </Grid>
              )
            })}
            <Button
              variant="contained"
              sx={{ position: 'absolute', right: '20px', bottom: '20px' }}
              onClick={() => (pickerMode ? uploadMedia() : onDoneClick(acceptedFiles))}
            >
              Done
            </Button>
          </Grid>
        </Box>
      )}
      {(acceptedFiles?.length === 0 || multiple) && (
        <Box
          sx={{
            border: `${isDragActive ? '2px' : '1px'} dashed ${
              isDragActive ? '#1b75b5' : 'rgba(0, 0, 0, 0.15)'
            }`,
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            flex: '1',
            display: 'flex',
            marginBottom: acceptedFiles?.length > 0 ? '2rem' : '0',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon fontSize="large" />
            <Typography>
              <b>Upload media from your device</b>
            </Typography>
            <Typography>Drag and drop here, or browse files</Typography>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default MyDevice
