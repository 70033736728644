import { useState } from 'react'
import { Container } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import Modal from 'react-responsive-modal'

import ContactForm from './index'
import ContactApi from 'data/api/contact'

const contactApi = new ContactApi()

const ContactModal = ({ open, close, user }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const location = useLocation()

  const {
    name,
    email,
    // role_name: role,
    // company_name: company,
  } = user?.idTokenPayload

  const onSubmit = async (event, errors, values) => {
    try {
      if (!errors.length) {
        setLoading(true)
        setError('')
        console.log({
          ...values,
          name,
          email,
          // role,
          // company,
          sourcePage: location?.path,
        })
        await contactApi.post({
          ...values,
          name,
          email,
          // role,
          // company,
          sourcePage: location?.path,
        })
        close()
      }
    } catch (err) {
      console.log(err)
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => close()}
      closeIcon={
        <svg fill="currentColor" viewBox="3 3 30 30" width={28} height={28}>
          <path
            d="M26 12 L24 10 L18 15.88 L12 10 L10 12 L15.88 18 L10 24 L12 26 L18 20.12 L24 26 L26 24 L20 18 Z"
            stroke="none"
            fill="white"
            strokeLinecap="round"
          />
        </svg>
      }
      styles={{
        root: {
          zIndex: 10000,
        },
        closeButton: {
          cursor: 'pointer',
          backgroundColor: '#46c8f0',
          borderRadius: '20px',
          position: 'absolute',
          right: '-12px',
          top: '-12px',
          fontColor: '#FFF',
        },
        modal: {
          overflow: 'visible',
          borderRadius: '10px',
          padding: '10px',
        },
      }}
    >
      <Container style={{ marginTop: 20, minWidth: '30vw', minHeight: '30vh' }}>
        <ContactForm loading={loading} onSubmit={onSubmit} user={user} />
        {error}
      </Container>
    </Modal>
  )
}

export default ContactModal
