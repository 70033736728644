import React, { useState, useMemo } from 'react'
import TagOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import Tooltip from '@mui/material/Tooltip'
import styled from 'styled-components'

import TileButton from './tileButton'
import { RctCard, RctCardContent } from 'components/RctCard'
import { getFileType } from 'util/pathUtils'
import MediaPreviewModal from 'components/MediaPreviewModal'
import pdfIcon from 'assets/img/pdf-icon.png'
import wordIcon from 'assets/img/word.png'
import excelIcon from 'assets/img/Excel.png'
import pptIcon from 'assets/img/powerpoint.png'
import selectIcon from 'assets/img/DirectSelect-Hover.svg'

const FileListing = ({ files, sortFunc, search, tagFilter, pickAsset }) => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')
  const [mouseOver, setMouseOver] = useState()
  const sortedFiles = useMemo(
    () =>
      search || tagFilter
        ? files
            .filter((f) => {
              const matchSearch =
                !search || f.name.toLowerCase().includes(search.toLowerCase())
              const matchTag =
                !tagFilter || (f.tags && f.tags.some((t) => t.id === tagFilter))

              return matchSearch && matchTag
            })
            .sort(sortFunc)
        : files.sort(sortFunc),
    [sortFunc, search, tagFilter, files]
  )

  return (
    <div className="shop-grid">
      <div className="row">
        {sortedFiles.map(
          ({
            id,
            name,
            url,
            preview_url,
            thumbnail_url,
            media_type: mediaType,
            tags,
            playlists,
            screens,
          }) => {
            const { type } = getFileType(url)

            const itemKey = `${name}-${id}`
            return (
              <div className="col-sm-6 col-md-4 col-xl-4" key={itemKey}>
                <RctCard customClasses="rct-block-2">
                  <RctCardContent>
                    <div
                      className="product-detail d-flex justify-content-between align-items-center"
                      onMouseEnter={() => setMouseOver(id)}
                      onMouseLeave={() => setMouseOver(false)}
                      style={{ minHeight: 35 }}
                    >
                      <h5 className="mb-0">{name}</h5>
                    </div>
                    <div
                      className="product-image mb-20 d-flex justify-content-center bg-white"
                      onMouseEnter={() => setMouseOver(id)}
                      onMouseLeave={() => setMouseOver(false)}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {}}
                    >
                      {type && type.includes('image') && (
                        <img
                          src={url}
                          alt="media"
                          style={{ maxHeight: 180, objectFit: 'contain' }}
                          className="img-fluid"
                        />
                      )}
                      {type && type.includes('video') && thumbnail_url && (
                        <img
                          src={thumbnail_url}
                          alt="media"
                          style={{ maxHeight: 180, objectFit: 'contain' }}
                          className="img-fluid"
                        />
                      )}
                      {type && type.includes('pdf') && (
                        <img
                          style={{ margin: '0 auto', padding: 35, objectFit: 'contain' }}
                          src={pdfIcon}
                          alt="pdf"
                          width="80%"
                        />
                      )}
                      {type && type.includes('doc') && (
                        <img
                          style={{ margin: '0 auto', padding: 35, objectFit: 'contain' }}
                          src={wordIcon}
                          alt="pdf"
                          width="80%"
                        />
                      )}
                      {type && type.includes('xls') && (
                        <img
                          style={{ margin: '0 auto', padding: 35, objectFit: 'contain' }}
                          src={excelIcon}
                          alt="pdf"
                          width="80%"
                        />
                      )}
                      {type && type.includes('ppt') && (
                        <img
                          style={{ margin: '0 auto', padding: 35, objectFit: 'contain' }}
                          src={pptIcon}
                          alt="pdf"
                          width="80%"
                        />
                      )}
                      {type && type.includes('video') && !thumbnail_url && (
                        <Video
                          key={preview_url || url}
                          style={{ maxHeight: 180, objectFit: 'contain' }}
                          controls={false}
                        >
                          <source
                            src={preview_url ? preview_url + '#t=2' : url + '#t=2'}
                            type="video/mp4"
                          />
                        </Video>
                      )}
                      {/* <TileButton 
                      onPreview={() => {
                        setPreviewUrl(preview_url || url)
                        setPreviewModalOpen(true)
                      }}
                      selectUrl=""
                      active={mouseOver === id}
                    /> */}
                      <SelectButton
                        alt="select icon"
                        src={selectIcon}
                        onClick={() => pickAsset(id)}
                        hovered={mouseOver === id}
                      />
                    </div>
                  </RctCardContent>
                </RctCard>
                {tags && !!tags.length && (
                  <Tooltip
                    title={tags.map((t) => t.name).join(', ')}
                    style={{ marginTop: -15 }}
                  >
                    <TagOutlinedIcon />
                  </Tooltip>
                )}
                {mouseOver === id && (
                  <PlaylistCounter>
                    {playlists.length} playlists / {screens.length} screens
                  </PlaylistCounter>
                )}
              </div>
            )
          }
        )}
        {(!files || !files.length) && (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: '30vh' }}
          >
            <em>
              There are no files in this folder. Please click the Upload button to begin
            </em>
          </div>
        )}
      </div>
    </div>
  )
}

const Video = styled.video`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`

const PlaylistCounter = styled.div`
  display: flex;
  margin-top: -22px;
  font-size: 10px;
  width: 100%;
  justify-content: flex-end;
`

const SelectButton = styled.img`
  position: absolute;
  width: 70px;
  bottom: 10px;
  right: -12px;
  justify-content: center;
  align-items: center;
  display: ${({ hovered }) => (hovered ? 'flex' : 'none')};
  cursor: pointer;
  filter: grayscale(1);

  :hover {
    filter: none;
  }
`

export default FileListing
