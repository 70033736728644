export const ROLES = {
  SUPER_ADMIN: 'super_admin',
  AGENCY_ADMIN: 'agency_admin',
  CUSTOMER_ADMIN: 'customer_admin',
  USER: 'user',
}

export const PLATFORMS = [
  'win',
  'eInk',
  'Android',
]

export const STAGES = [
  'Alpha',
  'Beta',
  'GA',
  'Latest Stable',
]

// All fonts are imported in: src/assets/scss/custom/_common.scss
export const FONTS = [
  {
    name: 'Alegreya',
    label: 'Alegreya',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Archivo Narrow',
    label: 'Archivo Narrow',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Arial',
    label: 'Arial',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Balthazar',
    label: 'Balthazar',
    weights: [
      { label: 'Regular', value: '400', hasItalic: false },
    ]
  },
  {
    name: 'Brush Script MT',
    label: 'Brush Script MT',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Cormorant',
    label: 'Cormorant',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Courier New',
    label: 'Courier New',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Eczar',
    label: 'Eczar',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Garamond',
    label: 'Garamond',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Georgia',
    label: 'Georgia',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Heebo',
    label: 'Heebo' ,
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Helvetica',
    label: 'Helvetica',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700' },
    ]
  },
  {
    name: 'Libre Caslon Text',
    label: 'Libre Caslon Text',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700' },
    ]
  },
  {
    name: 'Libre Caslon Display',
    label: 'Libre Caslon Display',
    weights: [
      { label: 'Regular', value: '400' },
    ],
  },
  {
    name: 'Libre Franklin',
    label: 'Libre Franklin',
    weights: [
      { label: 'Thin', value: '100', hasItalic: true },
      { label: 'Extra-light', value: '200', hasItalic: true },
      { label: 'Light', value: '300', hasItalic: true },
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Medium', value: '500', hasItalic: true },
      { label: 'Semi-bold', value: '600', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
      { label: 'Extra-bold', value: '800', hasItalic: true },
      { label: 'Black', value: '900', hasItalic: true },
    ],
  },
  { name:
    'Lora',
    label: 'Lora',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name:
    'Montserrat',
    label: 'Montserrat',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  {
    name: 'Open Sans',
    label: 'Open Sans',
    weights: [
      { label: 'Light', value: '300', hasItalic: true },
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Semi-bold', value: '600', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
      { label: 'Extra-bold', value: '800', hasItalic: true },
    ],
  },
  {
    name: 'Oswald',
    label: 'Oswald',
    weights: [
      { label: 'Extra-light', value: '200' },
      { label: 'Light', value: '300' },
      { label: 'Regular', value: '400' },
      { label: 'Medium', value: '500' },
      { label: 'Semi-bold', value: '600' },
      { label: 'Bold', value: '700' },
    ]
  },
  {
    name: 'Poppins',
    label: 'Poppins',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'Proza Libre',
    label: 'Proza Libre',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'PT Sans',
    label: 'PT Sans',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ]
  },
  {
    name: 'PT Sans Narrow',
    label: 'PT Sans Narrow',
    weights: [
      { label: 'Regular', value: '400' },
      { label: 'Bold', value: '700' },
    ]
  },
  { name: 'Raleway',
    label: 'Raleway',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Roboto',
    label: 'Roboto',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  {
    name: 'Sign Painter House Script',
    label: 'Sign Painter House Script',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Source Sans Pro',
    label: 'Source Sans Pro',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Source Serif Pro',
    label: 'Source Serif Pro',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Space Grotesk',
    label: 'Space Grotesk',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Space Mono',
    label: 'Space Mono',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Tahoma',
    label: 'Tahoma',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Times New Roman',
    label: 'Times New Roman',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Trebuchet MS',
    label: 'Trebuchet MS',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
  { name: 'Verdana',
    label: 'Verdana',
    weights: [
      { label: 'Regular', value: '400', hasItalic: true },
      { label: 'Bold', value: '700', hasItalic: true },
    ],
  },
]

export const SIDE_PANEL_TYPES = {
  mediaContent: 'mediaContent',
  mediaLibrary: 'mediaLibrary'
}

