import { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import { Filters, getDefaultFilters, removeDefaultFilters } from './Filters'

import { ResultsGrid } from 'components/MediaContentModal/resultsGrid'
import cls from 'classnames'
import { DetailsPane as VideoDetailsPane } from 'components/MediaContentModal/detailsPane'
import { selectDefaultVideoFile } from 'components/MediaContentModal/utils'
import { useProvider } from 'components/MediaContentModal/providers/useProvider'
import MediaContentAPI from 'data/api/mediaContent'
import SearchIcon from '@mui/icons-material/Search'

const api = new MediaContentAPI()

const MediaContentSearch = ({
  onSelect,
  provider = 'pexels',
  type = 'videos',
  multiple = true,
}) => {
  const [keywords, setKeywords] = useState('')
  const [page, setPage] = useState(1)
  const [, setLoadedPage] = useState(0)
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)
  const [filters, setFilters] = useState({})
  const [defaultFilters, setDefaultFilters] = useState({})
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [searchId, setSearchId] = useState(0)
  const [noResults, setNoResults] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(true)

  const [detailsItem, setDetailsItem] = useState(false)
  const [mode, setMode] = useState('search')
  const [contentType, setContentType] = useState(type)

  const [providerConfig, setProviderConfig] = useProvider(provider, (configuration) => {
    setProviderConfig(configuration)
    const filterDefaults = getDefaultFilters(configuration.filters[contentType])
    setFilters({ ...filterDefaults })
    setDefaultFilters({ ...filterDefaults })
  })

  useEffect(() => {
    if (!search.length) return
    setActive(true)

    const getHasMore = (result) => {
      return result
        .map((providerResults) => {
          const totalResults = providerResults['total_results']
          const page = providerResults['page']
          const perPage = providerResults['per_page']

          return page * perPage < totalResults
        })
        .reduce((accumulator, has) => accumulator || has, false)
    }

    async function _search() {
      try {
        if (hasMore) {
          setError(false)
          const response = await api.search({
            search,
            page,
            provider,
            type: contentType,
            ...removeDefaultFilters(filters, defaultFilters),
          })
          setResults((current) => {
            const results = response.map((providerData, providerIndex) => {
              const mediaCurrent = current[providerIndex]?.media || []
              const mediaNew = providerData[contentType] || []
              const media = [...mediaCurrent, ...mediaNew]
              return { ...current[providerIndex], ...providerData, media }
            })

            // All arrays in results['video/photo'] are empty
            const gotNothing = !!(
              results.length && !results.flatMap((item) => item.media).length
            )
            setNoResults(gotNothing)

            return results
          })

          setLoadedPage(response[0].page)
          setHasMore(getHasMore(response))
        } else {
          setResults((old) => {
            return old ? [...old] : []
          })
        }
      } catch (e) {
        console.log('Catching error', e)
        setResults([])
        setError(e.message)
      } finally {
        setActive(false)
      }
    }

    _search().then(() => {})

    return () => {
      setActive(false)
    }
  }, [search, page, filters, searchId, contentType])

  const newSearch = () => {
    setPage(1)
    setLoadedPage(0)
    setResults([])
    setHasMore(true)
    setMode('search')
    setNoResults(false)
  }

  const changeFilters = (filter, value) => {
    setFilters((old) => {
      old[filter] = value
      return { ...old }
    })
    newSearch()
  }

  const clearFilters = () => {
    setFilters({ ...defaultFilters })
    newSearch()
  }

  const doSearch = () => {
    setSearch((keywords || '').toString().trim())
    setSearchId((current) => ++current)
    newSearch()
  }

  const changeKeyword = (event) => {
    setKeywords(event.target.value)
    setNoResults(false)
  }

  const loadMore = () => {
    setLoadedPage((current) => {
      setPage(current + 1)
      return current
    })
  }

  const showAssetDetails = (item) => {
    setDetailsItem(item)
    setMode('details')
  }

  const closeAssetDetails = () => {
    setMode('search')
  }

  const clearSearch = () => {
    setKeywords('')
    setPage(1)
    setResults([])
    setNoResults(false)
  }

  const handleToggleChange = (_event, newContentType) => {
    contentType !== newContentType && newSearch()
    setContentType(newContentType)
  }

  const selectAsset = (item, resolution) => {
    let response = {
      id: item.id,
      source: provider,
    }

    if (item.type === 'photo') {
      response = {
        ...response,
        thumbnailUrl: item.src.small,
        previewUrl: item.src.small,
        type: item.type,
        alt: item.alt,
      }
    } else {
      const defaultResolution = resolution === undefined

      if (!resolution) {
        // no specific resolution was selected by user, we need to select a resolution that's close to the selected value in filters
        resolution = selectDefaultVideoFile(
          item.video_files,
          providerConfig.getFilterResolution(filters)
        )
      }

      response = {
        ...response,
        resolution,
        defaultResolution,
        previewUrl: item.image,
      }
    }
    onSelect(response)
  }

  if (
    !(providerConfig && Object.keys(filters).length && Object.keys(defaultFilters).length)
  ) {
    return null
  }

  const notStarted = !keywords && !results.length

  const showingDetails = mode === 'details' && contentType === 'videos'

  return (
    <Grid container direction="column">
      {showingDetails ? (
        <VideoDetailsPane
          item={detailsItem}
          onClose={closeAssetDetails}
          selectCB={selectAsset}
          infoProperties={providerConfig.infoProperties}
          renderResolutionLabel={providerConfig.renderResolutionLabel}
        />
      ) : (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{ padding: '8px 0', position: 'relative' }}
            flexWrap="nowrap"
            columnGap={'10px'}
          >
            <Tooltip
              title="Enter keyword to get started"
              arrow
              open={tooltipOpen}
              placement="bottom"
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    height: '70px',
                    width: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    font: 'normal 400 14px/18px DMSans',
                    background: '#C4C4C4',
                    color: '#00000099',
                    '& .MuiTooltip-arrow:before': {
                      background: '#C4C4C4',
                    },
                  },
                },
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                type="text"
                placeholder="Enter Keyword"
                size="small"
                value={keywords}
                onChange={(e) => changeKeyword(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    setTooltipOpen(false)
                    doSearch()
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      sx={{ color: '#000000', opacity: '.6', marginRight: '5px' }}
                    />
                  ),
                }}
              />
            </Tooltip>
            <Filters
              config={providerConfig.filters[contentType]}
              onChange={changeFilters}
              clearFilters={clearFilters}
              values={filters}
            />
            <ToggleButtonGroup
              exclusive
              value={contentType}
              onChange={handleToggleChange}
              sx={{ height: '35px' }}
            >
              <ToggleButton value="videos">Videos</ToggleButton>
              <ToggleButton value="photos">Photos</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <div className="pane">
            <div className={cls('results', { loading: active, 'has-error': error })}>
              {!noResults &&
                results.map((item) => (
                  <ResultsGrid
                    type={contentType}
                    key={item.provider}
                    content={item.media}
                    loadMoreCB={loadMore}
                    showMoreCB={showAssetDetails}
                    selectCB={selectAsset}
                    active={active}
                    hasMore={hasMore}
                    multiple={multiple}
                  />
                ))}
              {page === 1 && active && (
                <CircularProgress
                  sx={{ position: 'absolute', left: '50%', top: '50%', zIndex: '100' }}
                />
              )}
              {noResults && <div className="no-results">No results found</div>}
            </div>
            {error && <div className="error">{error}</div>}
          </div>
        </>
      )}
    </Grid>
  )
}

export default MediaContentSearch
