import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import BasicProfile from './basicProfile';
import PasswordUpdate from './passwordUpdate';
import AccountList from './accountsList';

const UserProfile = ({ user }) => {
  const profile = useSelector(state => state.authUser.profile)

  return (
    <div className="p-20">
      <Row>
        <Col xs="1" sm="3"/>
        <Col xs="10" sm="6">
          <BasicProfile profile={profile} user={user} />
          <PasswordUpdate user={user} />
          <AccountList profile={profile}/>
        </Col>
        <Col xs="1" sm="3"/>
      </Row>
    </div>
  )
}

export default UserProfile