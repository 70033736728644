import { SxProps, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Gallery, Image } from 'react-grid-gallery'
import { useResizeDetector } from 'react-resize-detector'

import { Asset } from 'routes/cms/library/types'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { GalleryTileWrapper } from 'components/Library/Tile/GalleryTileWrapper'

interface IProps {
  assets: Asset[]
  fetching?: boolean
  toggleCheckedItem?: (v: AssetIdentifier) => void
  checkedItems?: AssetIdentifier[]
  onAssetClick: (v: AssetIdentifier) => void
  unPin?: (assetIds: AssetIdentifier[]) => void
  pin?: (assetIds: AssetIdentifier[]) => void
  zoom: number
  sx?: SxProps
  cloneAsset?: (asset: AssetIdentifier) => void
  updateAsset?: (asset: AssetIdentifier) => void
  deleteAsset?: (asset: AssetIdentifier) => void
  hideContextMenu?: boolean
}

export const GridListing = ({ sx, ...props }: IProps) => {
  const { width, height, ref } = useResizeDetector()
  const { assets, zoom } = props

  const images: Image[] = assets.map((asset) => {
    const {
      assetInfo: { thumbnailUrl, previewUrl, originalUrl, aspectRatio, mediaType },
      title,
    } = asset

    return {
      src: mediaType === 'video/mp4' ? thumbnailUrl : previewUrl ?? originalUrl,
      width: (aspectRatio || 1.7777) * 1000,
      height: 1000,
      caption: title,
      asset,
    }
  })

  return (
    <Box sx={{ width: '100%', height: '100%', ...sx }} ref={ref}>
      <Gallery
        key={width}
        defaultContainerWidth={width}
        margin={zoom + (zoom > 4 ? 2 : 0)}
        images={images}
        enableImageSelection={false}
        rowHeight={50 * zoom}
        thumbnailImageComponent={(p) => <GalleryTileWrapper {...{ ...p, ...props }} />}
        tileViewportStyle={(asset) => ({
          overflow: 'unset',
          height: asset.item.scaledHeight,
          width: asset.item.viewportWidth,
        })}
      />
      {props.fetching && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
