import { baseApi } from './baseApi'
import { Tag, CreateTag, AccountId, UpdateTag } from 'store/types'

export const tagsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<Tag[], AccountId & { namespace?: string }>({
      query: ({ accountId, namespace }) => ({
        url: `/v2/${accountId}/tags`,
        params: { namespace },
      }),
      providesTags: ['Tags'],
    }),
    createTag: builder.mutation<Tag[], AccountId & CreateTag>({
      query: ({ accountId, ...data }) => ({
        method: `POST`,
        url: `/v2/${accountId}/tags`,
        body: data,
      }),
      invalidatesTags: () => ['Tags'],
    }),
    updateTag: builder.mutation<Tag[], AccountId & UpdateTag & { tagId: number }>({
      query: ({ accountId, tagId, ...body }) => ({
        method: `PATCH`,
        url: `/v2/${accountId}/tags/${tagId}`,
        body,
      }),
      invalidatesTags: () => ['Tags'],
    }),
    deleteTag: builder.mutation<Tag[], AccountId & { tagId: number }>({
      query: ({ accountId, tagId, ...body }) => ({
        method: `DELETE`,
        url: `/v2/${accountId}/tags/${tagId}`,
      }),
      invalidatesTags: () => ['Tags'],
    }),
  }),
})

export const {
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagsApi
