import Card from '@mui/material/Card'
import CardContent from './CustomCardContent'
import {
  capitalize,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
  Chip,
} from '@mui/material'
import { AssetInfo } from 'routes/cms/library/types'
import TileAsset from './TileAsset'
import backAssetOne from 'assets/img/GroupTile/back-asset-one.svg'
import backAssetTwo from 'assets/img/GroupTile/back-asset-two.svg'
import PencilIcon from 'assets/img/pencil.svg'
import ShareIcon from 'assets/img/share.svg'
import DeleteIcon from 'assets/img/delete.svg'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import { TagNamespace } from 'store/types'
import { BackAsset } from './BackAsset'
import ContextMenu, { ContextMenuProps } from './ContextMenu'
import { useLibrary } from 'contexts/library-context'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import { SharedIcon } from '../utils/SharedIcon'
import React from 'react'

type GroupTileProps = {
  title: string
  asset?: AssetInfo
  icon?: string
  onClick: () => any
  type: TagNamespace
  shared?: boolean
  width?: string
  height?: number
  color?: string
  contextMenuProps?: ContextMenuProps
  isEditing?: boolean
  onEditSave?: (newTitle: string) => void
  onEditCancel?: () => void
  checkbox?: {
    checked: boolean
    onChange: (checked: boolean) => any
  }
}

export const GalleryGroupTileWrapper = ({
  item,
  height,
  onClick,
  renameGroupingTag,
  deleteGroupingTag,
  checkedGroup,
  toggleCheckedGroup,
  openShareModal,
}) => {
  const { title, asset, type, id, icon, color, shared } = item
  const {
    dispatch,
    state: { editingGroupTitle },
  } = useLibrary()

  const getOptions = () => {
    switch (type.toUpperCase()) {
      case TagNamespace.COLLECTION:
        return [
          {
            label: 'Rename',
            icon: PencilIcon,
            onClick: () => {
              dispatch({
                type: 'setEditingGroupTitle',
                payload: id,
              })
            },
          },
          {
            label: 'Share',
            icon: ShareIcon,
            onClick: () => openShareModal(id),
          },
          {
            label: 'Delete',
            icon: DeleteIcon,
            onClick: () => deleteGroupingTag(id),
          },
        ]
      default:
        return [
          {
            label: 'Rename',
            icon: PencilIcon,
            onClick: () => {
              dispatch({
                type: 'setEditingGroupTitle',
                payload: id,
              })
            },
          },
          {
            label: 'Delete',
            icon: DeleteIcon,
            onClick: () => deleteGroupingTag(id),
          },
        ]
    }
  }

  const groupTileMenuProps: ContextMenuProps = {
    options: getOptions(),
  }

  const clearEditingGroupTitle = () =>
    dispatch({ type: 'setEditingGroupTitle', payload: null })
  const onEditSave = (newTitle: string) => {
    renameGroupingTag(id, newTitle)
    clearEditingGroupTitle()
  }
  return (
    <GroupTile
      title={title}
      asset={asset}
      icon={icon}
      type={type}
      shared={shared}
      width="100%"
      height={height}
      onClick={editingGroupTitle === id ? () => {} : () => onClick(id)}
      color={color}
      contextMenuProps={
        type.toUpperCase() !== TagNamespace.TYPE && !shared
          ? groupTileMenuProps
          : undefined
      }
      isEditing={editingGroupTitle === id}
      onEditSave={onEditSave}
      onEditCancel={clearEditingGroupTitle}
      checkbox={
        type.toUpperCase() !== TagNamespace.TYPE && !shared
          ? {
              onChange: () => toggleCheckedGroup(id),
              checked: checkedGroup?.includes(id),
            }
          : undefined
      }
    />
  )
}

const GroupTile = ({
  title,
  asset,
  icon,
  onClick,
  type,
  width,
  height,
  color,
  contextMenuProps,
  isEditing,
  onEditSave,
  onEditCancel,
  checkbox,
  shared,
}: GroupTileProps) => {
  const [renameValue, setRenameValue] = useState(title)
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
      sx={(theme) => ({
        cursor: 'pointer',
        ...(width
          ? { width }
          : {
              [theme.breakpoints.up('sm')]: {
                width: 'calc(50% - 10px)',
              },
              [theme.breakpoints.up('md')]: {
                width: 'calc(33.333333333% - 14px)',
              },
              [theme.breakpoints.up('lg')]: {
                width: 'calc(25% - 15px)',
              },
            }),
      })}
    >
      {asset ? (
        <>
          <img src={backAssetTwo} style={{ width: '85%' }} />
          <img
            src={backAssetOne}
            style={{ width: '90%', marginBottom: '-1px', zIndex: 2 }}
          />
        </>
      ) : (
        <BackAsset fillColor={color} />
      )}

      <Card
        sx={{
          width: '100%',
          boxShadow: 0,
          position: 'relative',
          background: 'transparent',
          '&:hover': {
            '& .checkbox': {
              visibility: 'visible',
            },
          },
        }}
      >
        {checkbox && (
          <Checkbox
            sx={(theme) => ({
              position: 'absolute',
              color: '#FFFFFF',
              '&.Mui-checked': {
                color: '#FFFFFF',
              },
              left: '10px',
              top: '10px',
              height: '24px',
              width: '24px',
              visibility: 'visible',
              [theme.breakpoints.up('lg')]: {
                visibility: 'hidden',
              },
              '&.checked': {
                visibility: 'visible',
              },
              zIndex: '50',
            })}
            checked={checkbox.checked}
            onChange={(event) => {
              checkbox.onChange(event.target.checked)
            }}
            color="default"
            className={`checkbox ${checkbox.checked ? 'checked' : ''}`}
          />
        )}
        {asset ? (
          <TileAsset
            onClick={onClick}
            assetInfo={{
              previewUrl: asset,
              mediaType: 'image',
            }}
            height={height - 20}
            roundBorders
          />
        ) : (
          <Grid
            container
            flexDirection="column"
            onClick={onClick}
            justifyContent={'center'}
            sx={{
              background: color || '#F4F5F7',
              borderRadius: '15px 15px 0 0',
              border: '1px solid #0000001a',
              borderBottom: '0',
              width: '100%',
              height: `${height - 56}px`,
            }}
            alignItems={'center'}
          >
            {icon ? (
              <img src={icon} width="50px" height="50px" />
            ) : (
              <>
                <CollectionsOutlinedIcon sx={{ color: '#666666' }} />
                <Typography
                  sx={{
                    maxWidth: '90%',
                    font: 'normal 400 14px/18px DMSans',
                    color: '#666666',
                  }}
                >
                  Empty {capitalize(type?.toLowerCase() ?? 'Group')}
                </Typography>
              </>
            )}
          </Grid>
        )}
        <CardContent
          onClick={onClick}
          sx={{
            border: '1px solid #0000001a',
            borderTop: '0',
            background: 'white',
            borderRadius: '0 0 15px 15px',
            position: 'relative',
            padding: '10px',
          }}
        >
          {isEditing ? (
            <Grid container alignItems="center" wrap="nowrap" sx={{ width: '100%' }}>
              <TextField
                variant="standard"
                value={renameValue}
                onChange={(e) => setRenameValue(e.target.value)}
                size="small"
                fullWidth
                placeholder="New Name"
                sx={{
                  minWidth: '70%',
                  '& .MuiInput-root': {
                    fontSize: '11px',
                  },
                  '& input': { paddingBottom: '1px' },
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    onEditSave && onEditSave(renameValue)
                  }
                }}
              />
              <IconButton
                onClick={() => onEditSave && onEditSave(renameValue)}
                disableRipple
                sx={{
                  padding: '1px',
                  opacity: title === renameValue ? '.3' : '1',
                }}
                disabled={title === renameValue}
              >
                <DoneIcon fontSize="small" htmlColor="green" />
              </IconButton>
              <IconButton
                disableRipple
                sx={{ padding: '1px' }}
                onClick={() => {
                  onEditCancel && onEditCancel()
                  setRenameValue(title)
                }}
              >
                <CloseIcon fontSize="small" htmlColor="red" />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              container
              columnGap=".3rem"
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
              paddingRight={shared ? `1.6rem` : '0'}
            >
              <Typography
                className={'ellipsis'}
                sx={{
                  maxWidth: '90%',
                  fontFamily: 'DMSans',
                  fontSize: '0.85rem',
                }}
                variant="h5"
                component="div"
              >
                {renameValue}
              </Typography>
              {shared && <SharedIcon />}
            </Grid>
          )}
          {contextMenuProps && !isEditing && (
              <ContextMenu 
                sx={{
                  zIndex: 100,
                  position: 'absolute',
                  right: '0',
                  top: '2px',
                }}
                options={contextMenuProps.options}
              />
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default GroupTile
