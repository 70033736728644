import React from 'react';
import { ChromePicker } from 'react-color';

const ColorPickerForm = ({ formSpec, parentState, handleChange }) => 
  <div style={{ textAlign: 'center' }}>
    <img src={formSpec.guide_image || 'https://react.semantic-ui.com/images/wireframe/image-text.png'}
      alt='img' width='100%' 
      style={{ paddingBottom: '10px', display: 'block', margin: 'auto' }} />
    <p>Choose your theme color:</p>
    <ChromePicker
      style={{ margin: '20px' }} 
      width='100%' color={parentState[formSpec.ae_field_name]}
      onChangeComplete={handleChange}
    />
  </div>

export default ColorPickerForm;