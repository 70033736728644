import { Row, Col, Container } from 'reactstrap'
import Modal from 'react-responsive-modal'
import ReactPlayer from 'react-player/file'
import styled from 'styled-components'

import noPreviewImg from './previewNotAvailable.png'

const MediaPreviewModal = ({ flip, open, close, previewUrl }) => {
  const cleanPreviewUrl =
    previewUrl && typeof previewUrl === 'string' && previewUrl.match(/([^?]+)/)[0]
  const ext = cleanPreviewUrl && cleanPreviewUrl.split('.').pop().toLowerCase()

  return (
    <Modal
      open={open}
      onClose={() => close()}
      center
      closeIcon={
        <svg fill="currentColor" viewBox="3 3 30 30" width={28} height={28}>
          <path
            d="M26 12 L24 10 L18 15.88 L12 10 L10 12 L15.88 18 L10 24 L12 26 L18 20.12 L24 26 L26 24 L20 18 Z"
            stroke="none"
            fill="white"
            strokeLinecap="round"
          />
        </svg>
      }
      styles={{
        closeButton: {
          cursor: 'pointer',
          backgroundColor: '#46c8f0',
          borderRadius: '20px',
          position: 'absolute',
          right: '-12px',
          top: '-12px',
          fontColor: '#FFF',
        },
        modal: {
          borderRadius: '10px',
          padding: '10px',
          overflow: 'visible',
        },
      }}
    >
      <Container style={{ marginTop: 20 }}>
        <Row>
          <StyledCol xs="12">
            {(ext === 'mp4' ||
              ext === 'mov' ||
              ext === 'webm' ||
              ext === 'mp3' ||
              ext === 'wav' ||
              ext === 'm4a' ||
              ext === 'aiff') && (
              <ReactPlayer
                width={['mp3', 'wav', 'm4a'].includes(ext) ? 400 : '100%'}
                height="100%"
                autoPlay
                playing={true}
                muted
                volume={0}
                controls
                playsInline
                key={cleanPreviewUrl}
                url={previewUrl}
              />
            )}
            {['jpeg', 'jpg', 'png', 'gif', 'svg'].includes(ext) && (
              <img src={previewUrl} style={{ width: '100%' }} alt="preview" />
            )}
            {previewUrl?.includes('scene') && (
              <iframe
                src={previewUrl}
                title="preview"
                style={{
                  width: 'calc(50vw)',
                  height: 'calc(50vh)',
                  border: 'none',
                }}
              />
            )}
            {!cleanPreviewUrl && !previewUrl?.includes('scene') && (
              <img
                src={noPreviewImg}
                alt="no preview"
                style={{
                  display: 'block',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
            )}
          </StyledCol>
        </Row>
      </Container>
    </Modal>
  )
}

const StyledCol = styled(Col)`
  video {
    max-height: 80vh;
    max-width: 90vw;
  }
`

export default MediaPreviewModal
