import { ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { Box } from '@mui/material'

import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'

const getPadding = (pathname: string) => (pathname.includes('schedule') ? 'p-0' : '')

interface IProps {
  openContactModal: (v: boolean) => void
  children: ReactNode
  setScrolledToBottom: (v: boolean) => void
}

const RctHorizontalLayout = ({
  children,
  openContactModal,
  setScrolledToBottom,
}: IProps) => {
  const location = useLocation()

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop()
    }
  }, [location.pathname])

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 0.5
    if (bottom) {
      setScrolledToBottom(true)
    }
  }

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <div className="app-header" style={{ position: 'static', top: 0 }}>
        <Header openContactModal={openContactModal} />
      </div>
      <div className="rct-page">
        <Scrollbars
          className="rct-scroll"
          ref={scrollRef}
          autoHide
          autoHideDuration={100}
          style={{ height: 'calc(100vh - 100px)' }}
          onScroll={handleScroll}
        >
          <div className={`rct-page-content ${getPadding(location.pathname)}`}>
            {children}
            <Footer openContactModal={openContactModal} />
          </div>
        </Scrollbars>
      </div>
    </Box>
  )
}

export default RctHorizontalLayout
