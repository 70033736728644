import React, { useEffect, useState } from 'react'
import { AvFeedback, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup, } from 'availity-reactstrap-validation'
import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Col, Label, Row } from 'reactstrap'
import styled from 'styled-components'

import { types } from '../index'
import { getStyleColors } from '../common/utils'
import ColorSwatch from 'routes/cms/scenes/addEditStyle/colorSwatch'
import { StocksSearch } from './components/StocksSearch'
import Slider from '@mui/material/Slider'
import { StocksSceneRenderer2 } from './sceneRenderer2'
import Preview from '../common/Preview'
import Stack from '@mui/material/Stack'

const labelStyle = { color: '#464D69CC', marginBottom: '0px', minWidth: 72 }

export const StocksSceneEditor2 = ({
  name,
  config,
  onSave,
  saving,
  sceneUrl,
  styles,
}) => {
  const [formData, setFormData] = useState({
    sceneName: name,
    stocks: config?.stocks?.join(',') || '',
    backgroundColorOverlay: '#00664e',
    tileMode: 'dark',
    size: 50,
    gutter: 50,
    ...config,
  })

  const style = styles.find(s => s.id == formData.styleId)?.config
  const styleColors = getStyleColors(style)

  useEffect(() => {
    if (config) {
      setFormData(prev => ({ ...prev, ...config }))
    }
  }, [config])

  const handleChange = (field, stripSpaces = false) => e =>
    setFormData(prev => ({
      ...prev,
      [field]: typeof e?.target?.value === 'string'
        ? stripSpaces ? e.target.value.replace(' ', '') : e.target.value
        : e,
    }))

  const save = () => onSave({
    name: formData.sceneName,
    type: types.STOCKS2,
    config: {
      ...formData,
      stocks: getStocks(),
    },
  })

  const getStocks = () => {
    const stocks = formData.stocks?.split
      ? formData.stocks?.split(',')?.filter(i => i)
      : formData.stocks?.length ? formData.stocks : []
    if (stocks?.length) {
      return stocks
    }
    return []
  }

  const handleSizeSliderChange = (event, value) => {
    setFormData((d) => ({ ...d, size: value }))
  }

  const handleGutterSliderChange = (event, value) => {
    setFormData((d) => ({ ...d, gutter: value }))
  }

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col sm="7" style={{ display: 'block' }}>
          <h2>{name ? 'Edit' : 'New'} Stocks Scene</h2><br/>
          <AvForm>
            <Row>
              <Col sm="8">
                <StyledGroup>
                  <Label for="name" style={labelStyle}>Name</Label>
                  <AvInput
                    type="text"
                    value={formData.sceneName}
                    name="name"
                    id="name"
                    required
                    onChange={handleChange('sceneName')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    Please enter a name for the scene
                  </AvFeedback>
                </StyledGroup>
              </Col>
              <Col sm="4">
                <AvGroup>
                  <Label for="style" style={labelStyle}>Style</Label>
                  <AvInput
                    id="styleId"
                    name="styleId"
                    type="select"
                    onChange={handleChange('styleId')}
                    validate={{ min: { value: 1 } }}
                    value={Number(formData.styleId)}>
                    <option value="">
                      None
                    </option>
                    {styles && styles.map(style =>
                      <option
                        value={style.id}
                        key={style.id}>
                        {style.name}
                      </option>
                    )}
                  </AvInput>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <AvGroup>
                  <Label for="url" style={labelStyle}>
                    Stock Symbols
                  </Label>
                  <StocksSearch value={formData.stocks} onChange={handleChange('stocks', true)}></StocksSearch>
                </AvGroup>
              </Col>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>Background Color Overlay:</span>
              <ColorSwatch
                size="sm"
                colors={['#00664e', '#555', ...styleColors]}
                onClick={handleChange('backgroundColorOverlay')}
                selected={formData.backgroundColorOverlay}
                style={{ background: 'transparent' }}
                separator={styleColors?.length && 2}
              />
            </div>
            <StyledAvRadioGroup
              inline
              required
              name="tileMode"
              label="Stock Tile Style"
              value={formData.tileMode}
              onChange={e => {
                e.persist()
                const { name, value } = e.target
                setFormData(prev => ({
                  ...prev,
                  [name]: value,
                }))
              }}
            >
              <AvRadio label="Dark" value="dark"/>
              <AvRadio label="Light" value="light"/>
            </StyledAvRadioGroup>
            <Stack direction='column'>
              <SliderLabel>Tile Size</SliderLabel>
              <Slider onChange={handleSizeSliderChange} defaultValue={formData.size} aria-label="Tile Size"/>
            </Stack>
            <Stack direction='column'>
              <SliderLabel>Gutter Size</SliderLabel>
              <Slider onChange={handleGutterSliderChange} defaultValue={formData.gutter} aria-label="Gutter Size"/>
            </Stack>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={save}
              style={{ marginTop: '20px' }}
              // disabled={!validatedUrl || !sceneName}
            >
              {saving ?
                <CircularProgress color="secondary" size={22}/> :
                <div>SAVE</div>
              }
            </Button>
          </AvForm>
        </Col>
        <Col sm="5" style={{ paddingTop: 35, minHeight: 500 }}>
          <Preview saving={saving} sceneUrl={sceneUrl}>
            <StocksSceneRenderer2 {...formData}/>
          </Preview>
        </Col>
      </Row>
    </div>
  )
}

const StyledGroup = styled(AvGroup)`
  margin-bottom: 5px;
  align-items: center
`

const SliderLabel = styled.div`
  font-weight: normal;
`

const StyledAvRadioGroup = styled(AvRadioGroup)`
  legend {
    margin-bottom: 0.2rem;
    font-size: 1rem;
    color: rgba(70, 77, 105, 0.8);
  }

  > div {
    padding-top: 5px !important;
    box-shadow: none !important;
    background: transparent;
  }
`
