import {
  CreateTagDto,
  CreateAssetTagsDto,
  UpdateTagDto,
} from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { FileWithPath } from 'react-dropzone'
import { MediaType } from 'routes/cms/library/types'

export type Tag = {
  id: number
  accountId: number
  namespace: string
  assetCount?: number
  thumbnailUrl?: string
  name: string
  createdDate: string
  createdBy: number
  representativeAssetId: number
  representativeAssetType: number
}

export type CreateTag = CreateTagDto
export type UpdateTag = UpdateTagDto
export enum TagNamespace {
  COLLECTION = 'COLLECTION',
  CATEGORY = 'CATEGORY',
  TAG = 'TAG',
  TYPE = 'TYPE',
  ASPECT_RATIO = 'ASPECT-RATIO',
}

export type AccountId = {
  accountId: number
}
export type CreateAssetTag = CreateAssetTagsDto

export type ListRequestMeta = {
  page?: number
  size?: number
  sortField?: string
  sortOrder?: 'asc' | 'desc'
}

export type LibraryFilters = {
  tags?: number[]
  tagUserId?: number
  tagsLogic?: 'and' | 'or'
  mediaType?: 'media' | 'playlist' | 'app'
}

export type LibraryRequest = AccountId & ListRequestMeta & LibraryFilters

export type LibraryAsset = {
  error: boolean
  data: {
    id: number
    account_id: number
    is_folder: boolean
    aspect_ratio: number
    parent: any | null
    path: string | null
    name: string
    media_type: MediaType
    description: string
    size: number
    transcodes: {
      name: string
      size: number
      codec: string
      s3Key: string
      width: number
      height: number
    }[]
    md5: string | null
    origin_details: string | null
    preview_url: string
    thumbnail_url: string
    created_date: string
    s3_key: string
    url: string
    playlists: []
    screens: []
  }[]
}

export type LibraryFileUploadRequest = FileWithPath & {
  preview: string
  tags?: number[]
}

export enum LibraryEntryKind {
  MEDIA = 'Media',
  PLAYLIST = 'Playlist',
  APP = 'App',
}

export enum AssetType {
  'MEDIA' = 0,
  'PLAYLIST' = 1,
  'APP' = 2,
}

export enum AddMediaMethod {
  IMPORT = 'import',
  AESEND_RENDER = 'aesend-render',
  UPLOAD = 'upload',
  CANVA = 'canva',
}

export type Account = {
  id: number
  name: string
}

export type ScreenCapture = {
  url: string
  lastModified: string
}
