import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import navLinks from '../HorizontalMenu/NavLinks'
import { useNavigate } from 'react-router-dom'
import ChevronRight from '@mui/icons-material/ChevronRight'

export const HamburgerMenu = () => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        id="hamburger-button"
        aria-controls="hamburger-menu"
        onClick={handleClick}
        sx={{ padding: '0px 15px' }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="hamburger-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'hamburger-button',
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          },
        }}
      >
        {navLinks.category1.map((menu) => (
          <MenuItem
            key={menu.menu_title}
            onClick={() => {
              navigate(menu.path)
              handleClose()
            }}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 8px',
            }}
          >
            {menu.menu_title}
            <ChevronRight htmlColor="#1b75b5" />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
