import { baseApi } from './baseApi'

interface GetMeReponse {
  id: number
  auth0Id: string
  firstName: string
  lastName: string
  company: string | null
  userEmailMain: string
  phoneNumberMain: string | null
  photoUrl: string | null
  createdDate: string
  updatedDate: string
  deleted: string | null
  suspended: string | null
  termsAgreedDate: string | null
  onboardingViewedDate: string | null
}

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<GetMeReponse, void>({
      query: () => ({ url: `/v2/users/me` }),
    }),
    agreeToTerms: builder.mutation<void, void>({
      query: () => ({
        method: `PATCH`,
        url: `/v2/users/me/agree-to-terms`,
      }),
    }),
    updateOnboardingDate: builder.mutation<void, void>({
      query: () => ({
        method: `PATCH`,
        url: `/v2/users/me/update-onboarding-date`,
      }),
    }),
  }),
})

export const {
  useLazyGetMeQuery,
  useGetMeQuery,
  useAgreeToTermsMutation,
  useUpdateOnboardingDateMutation,
} = userApi
