
export const colMap = {
  1: 1,
  2: 2,
  3: 3,
  4: 2,
  5: 3,
  6: 3,
  7: 4,
  8: 4,
  9: 3,
  10: 4,
  11: 4,
  12: 4,
}

export const portraitColMap = {
  1: 1,
  2: 1,
  3: 1,
  4: 2,
  5: 2,
  6: 2,
  7: 3,
  8: 2,
  9: 3,
  10: 4,
  11: 4,
  12: 4,
}
