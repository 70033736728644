import { useState, useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import cls from 'classnames'

import GeographyApi from 'data/api/geography'
import WeatherApi from 'data/api/weather'
import { importAll } from 'util/helpers'
import LogoDisplay from '../common/LogoDisplay'

const weatherApi = new WeatherApi()
const geoApi = new GeographyApi()
const WEATHER_BG_URL =
  'https://aesend-resources.s3-us-west-2.amazonaws.com/weather-backgrounds/'

const weatherIcons = importAll(
  require.context('assets/img/weather/icons', false, /\.png/)
)

const isProduction = process.env.ENVIRONMENT === 'production'

const ControlElement = ({ section, onChange, value }) => {
  return (
    <label>
      <input
        type="checkbox"
        name="current"
        checked={value[section]}
        onChange={(elem) => onChange(elem, section)}
      />{' '}
      <span style={{ textTransform: 'capitalize', marginRight: '10px' }}>{section}</span>
    </label>
  )
}

const calcSize = () => {
  const container = document.getElementById('container')
  if (!container) return
  const height = container.clientHeight
  const width = container.clientWidth

  // console.log(width / height, height / width)

  return width / height
}

const getRatioClass = (setter) => {
  const ratio = calcSize()

  let res = 'default'
  if (ratio > 125 / 1000) {
    res = '125-1000'
  }
  if (ratio > 2 / 10) {
    res = '2-100'
  }
  if (ratio > 4 / 10) {
    res = '4-10'
  }
  if (ratio > 45 / 100) {
    res = '45-100'
  }
  if (ratio > 6 / 10) {
    res = '6-10'
  }
  if (ratio > 7 / 10) {
    res = '7-10'
  }
  if (ratio > 10 / 10) {
    res = '10-10'
  }
  if (ratio > 16 / 10) {
    res = '16-10'
  }
  if (ratio > 20 / 10) {
    res = '20-10'
  }
  if (ratio > 25 / 10) {
    res = '25-10'
  }
  if (ratio > 30 / 10) {
    res = '30-10'
  }
  if (ratio > 40 / 10) {
    res = '40-10'
  }
  setter(`ratio-${res}`)
}

export const WeatherSceneRenderer = ({
  zip,
  displayCurrent,
  displayForecast,
  displayLocationDate,
  logoLocation,
  style = {},
}) => {
  const [weather, setWeather] = useState()
  const [sections, setSections] = useState({
    current: !!displayCurrent,
    forecast: !!displayForecast,
    location: !!displayLocationDate,
  })
  const [location, setLocation] = useState()
  const [time, setTime] = useState(moment())
  const [refresh, setRefresh] = useState(0)
  const [ratioClass, setRatioClass] = useState('ratio-default')
  const { headlineFont, subheadFont, bodyFont } = style

  useEffect(() => {
    if (!zip || zip.length < 5) return
    weatherApi.get(zip).then(setWeather)
    geoApi.getZip(zip).then((data) => {
      setLocation({
        city: data?.city,
        state: data?.state_id,
      })
    })
    setTimeout(() => setRefresh((p) => p + 1), 600000)
  }, [zip, refresh])

  useEffect(() => {
    setTimeout(() => setTime(moment()), 60000)
  }, [time])

  useEffect(() => {
    window.addEventListener('resize', () => getRatioClass(setRatioClass))

    return () => {
      window.removeEventListener('resize', () => getRatioClass(setRatioClass))
    }
  }, [ratioClass])

  useLayoutEffect(() => {
    getRatioClass(setRatioClass)
  })

  const changeValue = (element, section) => {
    setSections((value) => {
      const newValue = !value[section]
      // console.log(newValue);
      return { ...value, [section]: newValue }
    })
  }

  const display = (section) => {
    return {
      display: sections[section] ? 'flex' : 'none',
    }
  }

  const getFeatureSetClass = () => {
    const classed = []
    if (sections.location) {
      classed.push('l')
    }
    if (sections.current) {
      classed.push('c')
    }
    if (sections.forecast) {
      classed.push('f')
    }

    return classed.join('')
  }

  const forecastCircle = (index) => {
    const daysWeather = weather?.daily[index]
    const icon = daysWeather.weather[0].icon
    return (
      <div className="element">
        <div className="circle-container">
          <div className="circle" />
          <img src={weatherIcons[`${icon}.png`]} alt="weather icon" />
        </div>
        <div className="details">
          <div className="day">{moment(daysWeather?.sunrise * 1000).format('ddd')}</div>
          <div className="temps">
            {daysWeather?.temp?.min?.toFixed(0)} / {daysWeather?.temp?.max?.toFixed(0)}
            <div className="fg">&deg;F</div>
          </div>
        </div>
      </div>
    )
  }

  if (!weather) return <div />

  return (
    <>
      <LogoDisplay logoLocation={logoLocation} logoUrl={style.logoUrl} />
      <Container
        id="container"
        className={cls(getFeatureSetClass(), ratioClass, logoLocation)}
        bg={weather?.current.weather[0].icon}
        headlineFont={headlineFont}
        bodyFont={bodyFont}
        subheadFont={subheadFont}
      >
        {location && (
          <Location className="location" style={display('location')}>
            <div className="loc">
              {location.city}, {location.state}
            </div>
            <div className="date">{time.format('LL')}</div>
            <div className="time">{time.format('LT')}</div>
          </Location>
        )}
        <Current style={display('current')}>
          <div>
            <div className="temp">
              <span className="temp-val">{weather?.current.temp.toFixed(0)}</span>
              <span className="fg">&deg;F</span>
            </div>
            <div className="currently">currently</div>
          </div>
        </Current>
        <Forecast className="forecast" style={display('forecast')}>
          {forecastCircle(1)}
          {forecastCircle(2)}
          {forecastCircle(3)}
        </Forecast>
      </Container>
      {!isProduction && (
        <Controls>
          <ControlElement section="location" onChange={changeValue} value={sections} />
          <ControlElement section="current" onChange={changeValue} value={sections} />
          <ControlElement section="forecast" onChange={changeValue} value={sections} />
        </Controls>
      )}
    </>
  )
}

const Container = styled.div`
  --subhead-font: ${({ subheadFont }) => `${subheadFont}`};
  --body-font: ${({ bodyFont }) => `${bodyFont}`};
  --headline-font: ${({ headlineFont }) => `${headlineFont}`};

  background-image: url(${({ bg }) => `${WEATHER_BG_URL}new/${bg}.jpg`}),
    url(${({ bg }) => `${WEATHER_BG_URL}${bg}.png`});
  background-size: cover;

  font-size: 1.5vmax;

  display: grid;
  grid-template-rows: min-content 9fr;
  grid-template-columns: 3fr 2fr;
  height: 100%;

  grid-template-areas:
    'location location'
    'current forecast';

  &.cf {
    grid-template-areas:
      'current forecast'
      'current forecast';
  }

  &.lc {
    grid-template-areas:
      'location location'
      'current current';
  }

  &.lf {
    grid-template-areas:
      'location location'
      'forecast forecast';
  }

  &.c {
    grid-template-areas:
      'current current'
      'current current';
  }

  &.f {
    grid-template-areas:
      'forecast forecast'
      'forecast forecast';
  }

  @media (max-aspect-ratio: 1/1) {
    grid-template-rows: min-content 5fr 5fr;
    grid-template-columns: 1fr;

    grid-template-areas:
      'location'
      'current'
      'forecast';

    &.cf {
      grid-template-rows: 6fr 6fr;
      grid-template-areas:
        'current'
        'forecast';
    }

    &.lc {
      grid-template-rows: min-content 6fr;
      grid-template-areas:
        'location'
        'current';
    }

    &.lf {
      grid-template-rows: min-content 6fr;
      grid-template-areas:
        'location'
        'forecast';
    }

    &.c {
      grid-template-rows: 1fr;
      grid-template-areas: 'current';
    }

    &.f {
      grid-template-rows: 1fr;
      grid-template-areas: 'forecast';
    }
  }

  .forecast {
    --icons-direction: row;
    --texts-direction: column;
    --circle-size: 7em;
  }

  &.ratio-125-1000,
  &.ratio-2-100 {
    .forecast {
      --icons-direction: column;
      --texts-direction: row;
    }
  }

  &.ratio-2-100 {
    .temp {
      font-size: 10em;
    }
  }

  &.ratio-10-10 {
    .forecast {
      --circle-size: 9.5em;
      --icons-direction: column;
      --texts-direction: row;

      .details {
        font-size: 2.2em;
      }
    }

    .temp {
      .currently {
        font-size: 2em;
      }
    }

    &.lf,
    &.f {
      .forecast {
        --icons-direction: row;
        --texts-direction: column;
        --circle-size: 12.5em;
      }
    }

    &.cf,
    &.lcf {
      .forecast {
        align-items: flex-start;
      }
    }
  }

  &.ratio-6-10,
  &.ratio-7-10 {
    .forecast {
      --circle-size: 9.5em;
      --icons-direction: row;
      --texts-direction: column;
    }
  }

  &.ratio-16-10 {
    grid-template-columns: 2fr 3fr;

    &.lf,
    &.f {
      .forecast {
        --circle-size: 10em;
        .details {
          font-size: 2em;
        }
      }
    }
  }

  &.ratio-25-10,
  &.ratio-30-10 {
    font-size: 5vmin;
    grid-template-columns: 2fr 3fr;

    .location {
      font-size: 170%;
      padding: 0.5em 0;
    }

    .temp {
      font-size: 10em;

      .currently {
        font-size: 2em;
      }
    }

    &.lf,
    &.f {
      .forecast {
        --texts-direction: row;
        --circle-size: 9.5em;
      }
    }
  }

  &.ratio-40-10 {
    font-size: 5vmin;
    grid-template-columns: 2fr 3fr;

    .location {
      font-size: 170%;
      padding: 0.5em 0;
    }

    .temp {
      font-size: 10em;

      .currently {
        font-size: 2em;
      }
    }

    .forecast {
      --texts-direction: row;
      --circle-size: 10em;

      .details {
        font-size: 2.2em;
      }
    }
  }

  &.ratio-45-100 {
    &.lcf,
    &.cf {
      .forecast {
        align-items: flex-start;
      }
    }

    &.f,
    &.lf {
      .forecast {
        --icons-direction: column;
        --texts-direction: row;
        --circle-size: 12em;

        .details {
          font-size: 2.5em;
        }
      }
    }
  }
`
const Location = styled.div`
  grid-area: location;
  font-size: 150%;

  display: flex;
  color: white;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1 0 0;
  line-height: 1.2;
  align-items: baseline;
  margin-left: -0.1em;
  padding: 1.5em 0;

  & > div {
    white-space: nowrap;
    margin-left: 1em;
    margin-right: 1em;
    position: relative;
    //font-size: 10vw;

    &:after {
      content: '';
      position: absolute;
      right: 100%;
      top: 53%;
      height: 100%;
      width: 0.1em;
      border-left: 0.1em solid white;
      //background-color: #FFF;
      transform: translateY(-50%);
      margin-right: 1em;
    }
  }
`
const Current = styled.div`
  grid-area: current;

  font-family: var(--headline-font);
  display: flex;
  flex-direction: column;
  justify-content: center;

  opacity: 0.9;
  color: white;
  text-align: center;
  font-weight: bold;

  > div {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    justify-content: space-around;
  }

  .temp {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 15em;
  }

  .temp-val {
    line-height: 1;
  }

  .fg {
    font-size: 40%;
  }

  .currently {
    font-size: 3em;
    color: white;
    margin-top: -0.6em;
  }
`

const Forecast = styled.div`
  grid-area: forecast;

  flex-direction: var(--icons-direction);
  align-items: center;
  justify-content: space-evenly;

  .element {
    //margin: 4vmin;
    display: flex;
    flex-direction: var(--texts-direction);
    align-items: center;
  }

  .circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    > img {
      position: absolute;
      width: 60%;
      height: 60%;
      left: 20%;
      top: 20%;
    }
  }

  .circle {
    border-radius: 10em;
    background: white;
    opacity: 0.4;
    border: 0.12em solid white;
    background: currentColor;
    //min-width: calc(100vmax / 6);
    //max-width: calc(100vmax / 4);
    min-width: var(--circle-size);

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .details {
    color: white;
    opacity: 0.9;
    text-align: center;
    padding: 0.25em;

    font-size: 1.5em;

    .day {
      font-family: var(--subhead-font);
    }

    .temps {
      font-family: var(--body-font);
      display: flex;
      justify-content: center;
      font-size: 80%;
      white-space: nowrap;
      padding-left: 1em;

      .fg {
        font-size: 80%;
        margin-top: -1.1vmin;
        margin-left: 1vmin;
      }
    }
  }
`
const Controls = styled.div`
  position: absolute;
  bottom: 0;
`
