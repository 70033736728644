import React from 'react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'

import { ROLES } from 'util/constants'

const AccountsList = ({ profile }) => {
  const navigate = useNavigate()
  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <h2 className="mb-4">Accounts</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profile.map((row) => (
              <TableRow key={row.account_name}>
                <TableCell>{row.account_name}</TableCell>
                <TableCell>
                  {row.access_level_name} / {row.role_name}
                </TableCell>
                <TableCell>
                  {[ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.CUSTOMER_ADMIN].includes(
                    row.role_name
                  ) && (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      className="text-white m-10"
                      onClick={() =>
                        navigate(`/app/admin/accounts/account-edit/${row.account_id}`)
                      }
                    >
                      Manage
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default AccountsList
