import Api from './api';

export default class ScenesApi extends Api {
  async post(data, accountId) {
    const url = accountId ? `${accountId}/scenes` : 'scenes'
    return this.postResource(url, data);
  }

  // Read
  async get(query, accountId) {
    const url = accountId ? `${accountId}/scenes` : 'scenes'
    return this.getResource(url, query);
  }

  async getById(id, query) {
    const screen = await this.getResource(`scenes/${id}`, query);
    return screen[0];
  }

  // Update
  async update(id, data, force) {
    return this.patchResource(`scenes/${id}` + (force ? '?force' : ''), data);
  }

  // Publish
  async publish(id, data, force) {
    return this.patchResource(`scenes/${id}/publish` + (force ? '?force' : ''), data);
  }

  // Save as
  async saveACopy(data, accountId, force) {
    return this.postResource(`${ accountId }/scenes/save-a-copy` + (force ? "?force" : ""), data);
  }

  // Delete
  async delete(id) {
    return this.deleteResource(`scenes/${id}`)
  }

  async getPreviewUrl(id, accountId) {
    return this.getResource(`${ accountId }/scenes/${id}/public-url`);
  }

}
