import { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import moment from 'moment'
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation'
import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard'
import MediaQuery from 'react-responsive'
import uuid from 'uuid/v4'
import _ from 'lodash'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { CircularProgress, IconButton } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import styles from './sliderStyle'

import TextForm from './textForm'
import ImageUploadForm from './imageUploadForm'
import TimingPickerForm from './timingPickerForm'
import SoundUploadForm from './soundUploadForm'
import ColorPickerForm from './colorPickerForm'
import SwatchPickerForm from './swatchPickerForm'
import MediaSliderForm from './mediaSliderForm'
import MediaPreview from './mediaPreview'

import FileUploadApi from '../../data/api/fileUpload'
import TemplateApi from '../../data/api/templates'
import FORM_TYPES from '../TemplateEditor/formTypes'
import * as templateActions from '../../data/actions/TemplateActions'
import RenderButton from './renderButton'
import RenderPreviewModal from '../TemplatePreviewModal/renderPreviewModal'

import TimerIconInactive from '../../assets/img/timer-icon-normal.png'
import TimerIconActive from '../../assets/img/timer-icon-rollover.png'
import GogglesInactive from '../../assets/img/view-icon-normal.png'
import GogglesActive from '../../assets/img/view-icon-rollover.png'
import RenderPreviewButton from '../../assets/img/FramePreviewIcon.svg'
import {
  TYPE_CLOUD_SOURCES,
  TYPE_FILE_UPLOAD,
  TYPE_MEDIA_LIBRARY,
} from 'components/MediaUploadModal'

const getUrlOfAsset = (asset: AssetObject) => {
  return asset.resolution?.link || asset.url || asset.previewUrl
}

const useStyles = makeStyles(styles)

const allSections = [
  TYPE_FILE_UPLOAD,
  TYPE_CLOUD_SOURCES,
  TYPE_MEDIA_LIBRARY,
  'medialibrary',
  'storyblocks',
]

export type SourceSection =
  | 'files'
  | 'cloud-sources'
  | 'media-library'
  | 'medialibrary'
  | 'storyblocks'

export type AssetObject = {
  id: number
  previewUrl: string
  thumbnail_url: string
  url: string
  title: string
  media_type: string
  mediaObj: any // full media library API response from v1
  resolution?: {
    link: string // in case of cloud sources
  }
}

function getStateSection(source: SourceSection) {
  const map: { [key: string]: string } = {
    [TYPE_FILE_UPLOAD]: TYPE_FILE_UPLOAD,
    [TYPE_MEDIA_LIBRARY]: TYPE_MEDIA_LIBRARY,
    [TYPE_CLOUD_SOURCES]: TYPE_CLOUD_SOURCES,
    medialibrary: TYPE_MEDIA_LIBRARY,
    storyblocks: TYPE_CLOUD_SOURCES,
    pexels: TYPE_CLOUD_SOURCES,
  }
  return map[source]
}

function getUploadPreSignMethod(sessionId) {
  return (accountId, name, fileType) => {
    const fileUploadApi = new FileUploadApi()
    return fileUploadApi.getPresignedRenderAssetUrl(sessionId, name, fileType)
  }
}

const FormSliderV2 = ({
  templateId,
  templateSchema,
  user,
  addRenderRequest,
  addPreviewRenderRequest,
  navigate,
  hasPreviews,
}) => {
  const { masterHidden } = templateSchema
  const [state, setState] = useState({
    sessionId: null,
    uploading: false,
    submitEnabled: false,
    submitting: false,
    imageIdxs: {},
    imageCaptions: {},
    files: {},
    sceneCount: 0,
    currentScene: masterHidden ? 1 : 0,
    currentForm: 0,
    timingPickerOpen: false,
    showRenderPreview: false,
    timingSelections: {},
    mediaTimingChoices: [],
    storyblocks: {},
    medialibrary: {},
    trimCropSettings: {},
    previewModalOpen: false,
    previewRenderUrl: null,
  })
  const [prevRenders, setPrevRenders] = useState([])
  const stateRef = useRef()
  const [uploadProgress, setUploadProgress] = useState()
  const templateApi = useRef(new TemplateApi())
  const sceneFormCount = useRef()
  const sceneSliderRef = useRef()
  const formSliderRef = useRef()
  const sceneSliderInitialized = useRef(false)
  const classes = useStyles()
  const theme = useTheme()
  stateRef.current = state

  useEffect(() => {
    setState({
      ...state,
      ...initializedAEFields(),
      sessionId: state.sessionId || uuid(),
      sceneCount: templateSchema.scenes.length,
    })
    document.addEventListener('keydown', tabHandler, false)

    return () => document.removeEventListener('keydown', tabHandler, false)
  }, [])

  useEffect(() => {
    if (sceneSliderRef.current && masterHidden && !sceneSliderInitialized.current) {
      sceneSliderRef.current.slickGoTo(1)
      sceneSliderInitialized.current = true
    }
  }, [sceneSliderRef])

  useEffect(() => {
    templateApi.current
      .getRenderDetails('', `template_id=${templateId}`, user.activeAccount)
      .then(setPrevRenders)
  }, [user.activeAccount])

  if (!templateSchema.Fields) {
    return <></>
  }

  const selectUploadedFile = (
    asset: AssetObject,
    aeFieldName: string,
    source: SourceSection
  ) => {
    let section = getStateSection(source)

    const url = getUrlOfAsset(asset)

    console.log(
      'source',
      source,
      'section',
      section,
      'asset',
      asset,
      'aeFieldName',
      aeFieldName,
      'url',
      url
    )

    setState((state) => {
      const result = {
        ...state,
        [aeFieldName]: url,
        imageIdxs: {
          ...state.imageIdxs,
          [aeFieldName]: null,
        },
      }

      // update
      if (section) {
        result[section] = {
          ...state[section],
          [aeFieldName]: asset,
        }
      }
      return result
    })
  }

  const tabHandler = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault()
      goToNextSlide()
    }
  }

  // Templater needs every ae_field_name to be passed, even if empty
  const initializedAEFields = () => {
    const { Fields } = templateSchema
    return Fields.reduce((agg, field) => {
      const { ae_field_name, ae_field_names, type, default_time, choices, scene } = field
      if (ae_field_name) {
        if (type === FORM_TYPES.TIMING_PICKER_FORM && default_time) {
          const idx = choices.findIndex((c) => c.label === `${default_time} secs`)
          agg[ae_field_name] = idx >= 0 ? choices[idx].value : ''
          _.set(agg, ['timingSelections', scene], `${default_time} secs`)
        } else if (type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          // handleImgClick(0, ae_field_name);
        } else {
          agg[ae_field_name] = ''
        }
      } else if (ae_field_names) {
        ae_field_names.forEach((item) => {
          agg[item] = ''
        })
      }
      return agg
    }, {})
  }

  const handleChange = (e) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })

  const handleTimingChange = (e) => {
    const { timingSelections } = state
    const { Fields } = templateSchema
    const { scene, choices, custom_zero_track } = Fields.find(
      (field) => field.ae_field_name === e.target.name
    )
    const choiceIdx =
      e.target.value === custom_zero_track
        ? 0
        : choices.findIndex((time) => time.value === e.target.value)

    timingSelections[scene] = choiceIdx >= 0 ? choices[choiceIdx].label : 'Custom (media)'
    setState({
      ...state,
      [e.target.name]: e.target.value,
      timingSelections,
    })
  }

  const handleColorChange = (color, name) => {
    setState({ ...state, [name]: color.hex.replace('#', '') })
  }

  const handleTrimCropChange = (aeFieldName, data) => {
    setState({
      ...state,
      trimCropSettings: {
        ...state.trimCropSettings,
        [aeFieldName]: data,
      },
    })
  }

  const handleImgClick = (idx, galleryIdx) => {
    //set image index in array at index of gallery
    const mediaSlider = templateSchema.Fields.find(
      (item) => item.ae_field_name === galleryIdx
    )
    const newImageIdxs = { ...state.imageIdxs }
    newImageIdxs[galleryIdx] = idx

    const newStoryblocks = { ...state.storyblocks }
    delete newStoryblocks[galleryIdx]
    setState({
      ...state,
      imageIdxs: newImageIdxs,
      [galleryIdx]: mediaSlider.choices[idx].url,
      storyblocks: newStoryblocks,
    })
  }

  const handleSwatchChange = (selectedColor, data) => {
    const swatches = data.choices.map((item) =>
      item.split(',').map((c) => `#${c.toUpperCase()}`)
    )
    const colors = _.zip(...swatches) // transpose array
    const fieldValues = {}
    colors.forEach((swatch) => {
      if (swatch.includes(selectedColor.hex.toUpperCase())) {
        swatch.forEach((color, idx) => {
          fieldValues[data.ae_field_names[idx]] = color.replace('#', '')
        })
      }
    })
    setState({
      ...state,
      ...fieldValues,
    })
  }

  const loadPrevRender = (e) => {
    const renderId = e.target.value
    const prevRenderData =
      prevRenders &&
      prevRenders.find((render) => render.template_hash === Number(renderId))
    const imageIdxs = {}
    const sections = {}
    const timingSelections = []
    const fieldValues = templateSchema.Fields.reduce((acc, field) => {
      const prevValue = prevRenderData[field.ae_field_name]
      const fieldName = field.ae_field_name
      if (fieldName && prevValue) {
        const section =
          prevValue.source === 'storyblocks'
            ? 'storyblocks-legacy'
            : getStateSection(prevValue.source)
        acc[fieldName] = getUrlOfAsset(prevValue)
        if (section) {
          imageIdxs[field.ae_field_name] = null
          sections[section] = {
            ...sections[section],
            [fieldName]: prevValue,
          }
        } else if (field.type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          imageIdxs[field.ae_field_name] = field.choices.findIndex(
            ({ url }) => url === prevValue
          )
        } else if (field.type === FORM_TYPES.TIMING_PICKER_FORM) {
          const prevChoice = field.choices.find((c) => c.value === prevValue)
          acc[fieldName] = prevValue
          timingSelections[field.scene] = prevChoice?.label || 'custom (media)'
          if (!prevChoice) {
            const [aeFieldName] =
              Object.entries(prevRenderData).find(
                ([aeFieldName, val]) =>
                  val === prevValue && aeFieldName !== field.ae_field_name
              ) || []
            if (aeFieldName) {
              addFileDurationToTimeChoices({
                url: prevValue,
                ae_field_name: aeFieldName,
              })
            }
          }
        } else {
          acc[fieldName] = prevValue
        }
      } else if (field.ae_field_names) {
        field.ae_field_names.forEach((ae_field_name) => {
          acc[ae_field_name] = prevRenderData[ae_field_name]
        })
      }
      return acc
    }, {})
    setState({
      ...state,
      ...fieldValues,
      imageIdxs: { ...imageIdxs },
      ...sections,
      timingSelections,
    })
  }

  const onDrop = async (files, ae_field_name, updateProgress) => {
    const _updateProgress = updateProgress || setUploadProgress
    setState({ ...state, uploading: true })
    const fileUploadApi = new FileUploadApi()
    try {
      const { signedPutUrl } = await fileUploadApi.getPresignedRenderAssetUrl(
        state.sessionId,
        files[0].name.replace(/[^0-9a-zA-Z.]/g, '_'),
        files[0].type
      )
      const uploadConfig = {
        onUploadProgress: (progressEvent) => {
          const progressUpload = (progressEvent.loaded * 100) / progressEvent.total
          _updateProgress(progressUpload)
        },
      }
      await fileUploadApi.uploadFileToS3(signedPutUrl, files[0], uploadConfig)
      const res = signedPutUrl.split('?')[0]

      setState({
        ...state,
        files: {
          ...state.files,
          [ae_field_name]: files,
        },
        [ae_field_name]: res,
        uploading: false,
      })
      _updateProgress(null)
      setTimeout(
        () =>
          addFileDurationToTimeChoices({
            file: files[0],
            ae_field_name,
          }),
        500
      )
      return res
    } catch (err) {
      setState({ ...state, uploading: false })
    }
  }

  const addFileDurationToTimeChoices = async ({ file, url, ae_field_name }) => {
    if (!file && !url) {
      return console.log('No asset was provided for adding timing choices')
    }
    if (!ae_field_name) {
      return console.log('No ae_field_name was provided for adding timing choices')
    }
    const { Fields } = templateSchema
    const { scene } = Fields.find((s) => s.ae_field_name === ae_field_name)
    try {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        const { duration } = video
        console.log('duration: ', duration)
        const { mediaTimingChoices } = state
        const newMediaTimingChoices = [
          ...mediaTimingChoices,
          {
            scene,
            ae_field_name,
            duration,
          },
        ]
        setState({
          ...stateRef.current,
          mediaTimingChoices: newMediaTimingChoices,
        })
      }
      if (file) {
        video.src = window.URL.createObjectURL(file)
      } else if (url) {
        video.src = url
      }
    } catch (err) {
      console.log(err)
    }
  }

  const removeFile = (ae_field_name) => {
    const { Fields } = templateSchema
    const { imageIdxs, mediaTimingChoices, storyblocks, medialibrary } = state
    const { scene } = Fields.find((s) => s.ae_field_name === ae_field_name)
    const sceneTimingField = Fields.find(
      (s) => s.scene === scene && s.type === FORM_TYPES.TIMING_PICKER_FORM
    )
    let timingStateChanges = {}

    if (sceneTimingField) {
      // reset timing selection for the scene to default (refactor this to a function)
      const sceneTimingFieldName = sceneTimingField.ae_field_name
      const idx = sceneTimingField.choices.findIndex(
        (c) => c.label === `${sceneTimingField.default_time} secs`
      )

      const timing =
        state[ae_field_name] === state[sceneTimingFieldName]
          ? idx >= 0
            ? sceneTimingField.choices[idx].value
            : ''
          : state[sceneTimingFieldName]
      const newTimingSelections = { ...state.timingSelections }
      newTimingSelections[scene] =
        state[ae_field_name] === state[sceneTimingFieldName]
          ? idx >= 0
            ? `${sceneTimingField.default_time} secs`
            : ''
          : newTimingSelections[scene]
      timingStateChanges = {
        [sceneTimingFieldName]: timing,
        timingSelections: newTimingSelections,
      }
    }

    delete imageIdxs[ae_field_name]
    const newStoryblocks = { ...storyblocks }
    delete newStoryblocks[ae_field_name]

    const newMedialibrary = { ...medialibrary }
    delete newMedialibrary[ae_field_name]

    const newTrimCropSettings = { ...state.trimCropSettings }
    delete newTrimCropSettings[ae_field_name]

    setTimeout(
      () =>
        setState({
          ...state,
          ...timingStateChanges,
          [ae_field_name]: '',
          files: {
            ...state.files,
            [ae_field_name]: null,
          },
          storyblocks: newStoryblocks,
          medialibrary: newMedialibrary,
          trimCropSettings: newTrimCropSettings,
          imageIdxs,
          mediaTimingChoices: mediaTimingChoices.filter(
            (t) => t.ae_field_name !== ae_field_name
          ),
        }),
      500
    )
  }

  const allowSubmit = () => {
    const errors = []
    Object.entries(templateSchema.Fields).forEach(([_, item]) => {
      if (item.ae_field_name && item.required) {
        if (item.type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          if (
            isNaN(state && state.imageIdxs && state.imageIdxs[item.ae_field_name]) &&
            !state[item.ae_field_name]
          ) {
            errors.push(`${item.ae_field_name} not entered.`)
          }
        } else if (!state[item.ae_field_name]) {
          errors.push(`${item.ae_field_name} not entered.`)
        }
      }
      if (item.ae_field_names && item.required) {
        if (!state[item.ae_field_names[0]])
          errors.push(`${item.ae_field_names[0]} not entered.`)
      }
    })
    if (!errors.length) return true
  }

  const getRenderParams = ({ isPreview }) => {
    const parameters = isPreview ? { $scene: state.currentScene } : {}

    Object.entries(templateSchema.Fields).forEach(([_, item]) => {
      const { ae_field_name, ae_field_names, type } = item
      if (ae_field_name) {
        const transcode = state.trimCropSettings[ae_field_name]
        if (type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          const idx = state.imageIdxs[ae_field_name]
          if (idx !== undefined) {
            parameters[ae_field_name] = state[ae_field_name] || item.choices[idx].url
          }
        } else if (type === FORM_TYPES.IMAGE_UPLOAD_FORM && transcode) {
          parameters[ae_field_name] = {
            transcode,
            url: state[ae_field_name],
          }
        } else if (state['storyblocks-legacy']?.[ae_field_name]) {
          parameters[ae_field_name] = state['storyblocks-legacy']?.[ae_field_name].s3Url
        } else {
          parameters[ae_field_name] = state[ae_field_name]
        }

        for (let section of allSections) {
          if (state[section]?.[ae_field_name]) {
            parameters[ae_field_name] = {
              source: state[section]?.[ae_field_name].source || section,
              ...(transcode && { transcode }),
              ...state[section][ae_field_name],
            }
          }
        }
      }
      if (ae_field_names) {
        ae_field_names.forEach(
          (aeFieldName) => (parameters[aeFieldName] = state[aeFieldName])
        )
      }
    })
    console.log(parameters)
    // throw new Error();
    return {
      sessionId: state.sessionId,
      user_id: user.profile[0].id,
      account_id: user.activeAccount,
      template_id: templateId,
      status: 'ready',
      is_preview: Number(isPreview),
      parameters: JSON.stringify(parameters),
    }
  }

  const onRequestPreview = async () => {
    const data = getRenderParams({ isPreview: true })
    setState({ ...state, previewPending: true })
    const renderId = await addPreviewRenderRequest(data)
    const timer = setInterval(async () => {
      const render = await templateApi.current.getRenderDetails(renderId)
      if (render[0]['render-status'] === 'done') {
        clearInterval(timer)
        setState({
          ...state,
          previewPending: false,
          previewRenderUrl: render[0].output_url,
          previewModalOpen: true,
        })
      }
    }, 5000)
  }

  const onSubmit = async () => {
    if (!allowSubmit()) return
    const data = getRenderParams({ isPreview: false })
    setState({ ...state, submitting: true })
    addRenderRequest(data, navigate)
  }

  const getGuideImage = (scene, form) => {
    const { Fields } = templateSchema
    const sceneFields = Fields.filter((field) => field.scene === scene)
    return sceneFields[form] && sceneFields[form].guide_image
  }

  const shouldShowMediaPreview = (scene, form) => {
    if (scene < 0 || form < 0) return false

    const { Fields } = templateSchema
    const sceneFields = Fields.filter((field) => field.scene === scene)

    // console.log('scene: ', scene, 'form', form, 'sceneFields', sceneFields)
    return (
      [FORM_TYPES.MEDIA_SLIDER_FORM, FORM_TYPES.IMAGE_UPLOAD_FORM].includes(
        sceneFields[form].type
      ) && state[sceneFields[form].ae_field_name]
    )
  }

  const getAeFieldName = (sceneNumber, formNumber) => {
    const { Fields } = templateSchema
    const sceneFields = Fields.filter((field) => field.scene === sceneNumber)
    return sceneFields[formNumber].ae_field_name
  }

  const renderScenes = () => {
    const { showRenderPreview, timingPickerOpen, currentForm, currentScene } = state
    const { scenes } = templateSchema
    return scenes.map((data, idx) => (
      <RctCollapsibleCard
        heading={data.label}
        key={idx}
        height="350px"
        collapsible={false}
        customClasses="scene-slider-content"
      >
        {shouldShowMediaPreview(currentScene, currentForm) && !showRenderPreview ? (
          <MediaPreview
            url={state[getAeFieldName(currentScene, currentForm)]}
            controls={true}
            smallPreview={
              state.imageIdxs &&
              state.imageIdxs[getAeFieldName(currentScene, currentForm)] != null
            }
          />
        ) : (
          <img
            src={
              showRenderPreview
                ? timingPickerOpen
                  ? data.layoutGuide
                  : getGuideImage(idx, currentForm)
                : data.renderPreview ||
                  'https://react.semantic-ui.com/images/wireframe/image-text.png'
            }
            alt="img"
            width="100%"
            style={{
              paddingBottom: '30px',
              display: 'block',
              margin: 'auto',
              maxHeight: 250,
              width: 'auto',
            }}
          />
        )}
        <div style={{ position: 'absolute', bottom: -10 }}>
          {idx === 0 ? (
            <span className={classes.masterSceneTitle}>Master Scene</span>
          ) : (
            <span className={classes.normalSceneTitle}>
              Scene {idx} of {scenes.length - 1}
            </span>
          )}
        </div>
      </RctCollapsibleCard>
    ))
  }

  const getTimingPicker = (currentScene) =>
    templateSchema.Fields &&
    templateSchema.Fields.filter((field) => field.scene === currentScene).find(
      (field) => field.type === FORM_TYPES.TIMING_PICKER_FORM
    )

  const renderForms = (currentScene) => {
    // console.log(currentScene)
    const sceneFields = templateSchema.Fields.filter(
      (field) =>
        field.scene === currentScene && field.type !== FORM_TYPES.TIMING_PICKER_FORM
    )
    sceneFormCount.current = sceneFields.length
    return sceneFields.map((data, key) => (
      <RctCollapsibleCard
        key={key}
        minHeight="250px"
        customClasses={
          currentScene === 0 ? classes.masterFormWrapper : classes.sceneFormWrapper
        }
      >
        <FormSceneHeader master={currentScene === 0} theme={theme}>
          <span>
            Field {key + 1} of {sceneFields.length}
          </span>
          <span>{data.required ? 'REQUIRED' : 'OPTIONAL'}</span>
        </FormSceneHeader>
        {data.type === FORM_TYPES.TEXT_FIELD_FORM && (
          <TextForm
            theme={theme}
            formSpec={data}
            parentState={state}
            handleChange={handleChange}
          />
        )}
        {data.type === FORM_TYPES.IMAGE_UPLOAD_FORM && (
          <ImageUploadForm
            imageUrl={state[data.ae_field_name]}
            file={state[TYPE_FILE_UPLOAD][data.ae_field_name]}
            activeAccount={user.activeAccount}
            theme={theme}
            formSpec={data}
            selectUploadedFile={(asset: AssetObject, section: SourceSection) =>
              selectUploadedFile(asset, data.ae_field_name, section)
            }
            handleRemove={removeFile}
            handleTrimCropChange={handleTrimCropChange}
            uploadPreSignMethod={getUploadPreSignMethod(state.sessionId)}
          />
        )}
        {data.type === FORM_TYPES.SOUND_UPLOAD_FORM && (
          <SoundUploadForm
            theme={theme}
            formSpec={data}
            parentState={state}
            handleChange={onDrop}
            handleRemove={removeFile}
            uploadProgress={uploadProgress}
          />
        )}
        {data.type === FORM_TYPES.COLOR_PICKER_FORM && (
          <ColorPickerForm
            theme={theme}
            formSpec={data}
            parentState={state}
            handleChange={(color) => handleColorChange(color, data.ae_field_name)}
          />
        )}
        {data.type === FORM_TYPES.SWATCH_PICKER_FORM && (
          <SwatchPickerForm
            theme={theme}
            formSpec={data}
            parentState={state}
            handleChange={(color) => handleSwatchChange(color, data)}
          />
        )}
        {data.type === FORM_TYPES.MEDIA_SLIDER_FORM && (
          <MediaSliderForm
            activeAccount={user.activeAccount}
            theme={theme}
            formSpec={data}
            imageUrl={state[data.ae_field_name]}
            imageIdxs={state.imageIdxs[data.ae_field_name]}
            handleChange={(imgIdx) => handleImgClick(imgIdx, data.ae_field_name)}
            selectUploadedFile={(asset: AssetObject, section: SourceSection) => {
              selectUploadedFile(asset, data.ae_field_name, section)
            }}
            handleRemove={removeFile}
            uploadPreSignMethod={getUploadPreSignMethod(state.sessionId)}
          />
        )}
      </RctCollapsibleCard>
    ))
  }

  const goToPrevSlide = _.debounce(
    () => {
      const { currentForm, currentScene } = state
      if (currentForm === 0) {
        const newScene = currentScene - 1
        const newForm = 0
        setState({ ...state, currentScene: newScene, currentForm: newForm })
        sceneSliderRef.current.slickGoTo(newScene, true)
        setTimeout(() => formSliderRef.current.slickGoTo(newForm), 700)
      } else {
        formSliderRef.current.slickGoTo(currentForm - 1)
        setState({ ...state, currentForm: currentForm - 1 })
      }
    },
    800,
    { leading: true }
  )

  const goToNextSlide = _.debounce(
    () => {
      const { currentForm, currentScene, sceneCount } = state
      // console.log(currentForm, currentScene, sceneCount)

      if (currentForm === sceneFormCount.current - 1) {
        if (currentScene === sceneCount - 1) {
          return
        }
        sceneSliderRef.current.slickGoTo(currentScene + 1, true)
        setTimeout(() => formSliderRef.current.slickGoTo(0), 700)
        setState({ ...state, currentScene: currentScene + 1, currentForm: 0 })
      } else {
        formSliderRef.current.slickGoTo(currentForm + 1)
        setState({ ...state, currentForm: currentForm + 1 })
      }
    },
    800,
    { leading: true }
  )

  const jumpToScene = (newScene) => {
    setTimeout(
      () =>
        setState({
          ...state,
          currentScene: newScene,
          currentForm: 0,
        }),
      700
    )
    formSliderRef.current.slickGoTo(0)
  }

  const jumpToForm = (newForm) => setState({ ...state, currentForm: newForm })

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipe: true,
    touchMove: true,
    swipeToSlide: true,
  }
  const {
    sceneCount,
    currentScene,
    currentForm,
    timingPickerOpen,
    showRenderPreview,
    submitting,
    timingSelections,
  } = state
  const timingForm = getTimingPicker(currentScene)

  return (
    <div className="session-slider" style={{ paddingBottom: '50px' }}>
      <RenderPreviewModal
        open={state.previewModalOpen}
        close={() => setState({ ...state, previewModalOpen: false })}
        previewUrl={state.previewRenderUrl}
      />
      {prevRenders && !!prevRenders.length && (
        <div style={{ marginTop: 10 }}>
          You have previously rendered this template.{' '}
          <AvForm>
            <AvGroup>
              <AvField type="select" name="prevRenders" onChange={loadPrevRender}>
                <option value="" disabled>
                  -- select prev values --
                </option>
                {prevRenders &&
                  prevRenders.map((render) => (
                    <option key={render.created_date} value={render.template_hash}>
                      {moment(render.created_date).format('MM/DD/YYYY h:mm a')}
                      {render.name && ` - ${render.name}`}
                    </option>
                  ))}
              </AvField>
            </AvGroup>
          </AvForm>
        </div>
      )}
      <AvForm onSubmit={onSubmit} style={{ paddingTop: '10px' }}>
        <MediaQuery maxDeviceWidth={991}>
          {(matches) => (
            <Fragment>
              <div style={{ position: 'relative' }}>
                <GogglesWrapper>
                  <img
                    src={showRenderPreview ? GogglesActive : GogglesInactive}
                    alt=""
                    style={{ maxWidth: 75 }}
                    onClick={() =>
                      setState({
                        ...state,
                        showRenderPreview: !showRenderPreview,
                      })
                    }
                    onMouseOver={(e) => (e.currentTarget.src = GogglesActive)}
                    onMouseOut={
                      !showRenderPreview
                        ? (e) => (e.currentTarget.src = GogglesInactive)
                        : () => {}
                    }
                  />
                </GogglesWrapper>

                <RenderButton active={allowSubmit()} submitting={submitting} />

                <SceneSlider
                  beforeChange={(a, b) => jumpToScene(b)}
                  ref={sceneSliderRef}
                  theme={theme}
                  {...{
                    ...settings,
                    draggable: false,
                    masterHidden,
                  }}
                >
                  {renderScenes()}
                </SceneSlider>

                {!(currentScene === 0 && currentForm === 0) &&
                !(currentScene === 1 && currentForm === 0 && masterHidden) ? (
                  <IconButton
                    className={classes.leftIconButton}
                    onClick={goToPrevSlide}
                    size="large"
                  >
                    <StyledChevronLeft theme={theme} master={currentScene === 0} />
                  </IconButton>
                ) : (
                  <></>
                )}
                {!(
                  currentScene === sceneCount - 1 &&
                  currentForm === sceneFormCount.current - 1
                ) ? (
                  <IconButton
                    className={classes.rightIconButton}
                    onClick={goToNextSlide}
                    size="large"
                  >
                    <StyledChevronRight theme={theme} master={currentScene === 0} />
                  </IconButton>
                ) : (
                  <></>
                )}

                {hasPreviews && currentScene !== 0 ? (
                  <RenderPreviewWrapper>
                    {state.previewPending ? (
                      <CircularProgress />
                    ) : (
                      <img
                        onClick={onRequestPreview}
                        src={RenderPreviewButton}
                        alt="preview"
                        style={{ width: 40 }}
                      />
                    )}
                  </RenderPreviewWrapper>
                ) : (
                  <></>
                )}

                {timingForm && (
                  <Fragment>
                    <TimerWrapper>
                      <img
                        alt=""
                        src={timingPickerOpen ? TimerIconActive : TimerIconInactive}
                        style={{ maxWidth: 65 }}
                        onClick={() =>
                          setState({
                            ...state,
                            timingPickerOpen: !timingPickerOpen,
                          })
                        }
                        onMouseOver={(e) => (e.currentTarget.src = TimerIconActive)}
                        onMouseOut={
                          !timingPickerOpen
                            ? (e) => (e.currentTarget.src = TimerIconInactive)
                            : () => {}
                        }
                      />
                    </TimerWrapper>
                    <TimeSelectionWrapper>
                      {timingSelections[currentScene]}
                    </TimeSelectionWrapper>
                    <TimingPickerWrapper theme={theme} visible={timingPickerOpen}>
                      <TimingPickerForm
                        theme={theme}
                        formSpec={timingForm}
                        parentState={state}
                        handleChange={handleTimingChange}
                      />
                    </TimingPickerWrapper>
                  </Fragment>
                )}
              </div>
              <FormSlider
                beforeChange={(a, b) => jumpToForm(b)}
                initialSlide={currentForm}
                ref={formSliderRef}
                {...{
                  ...settings,
                  draggable: matches,
                }}
                master={currentScene === 0}
                theme={theme}
              >
                {renderForms(currentScene)}
              </FormSlider>
            </Fragment>
          )}
        </MediaQuery>
      </AvForm>
    </div>
  )
}

FormSliderV2.propTypes = {
  sidePanelParams: PropTypes.object,
  storyblocksAsset: PropTypes.object,
  setStoryblocksAsset: PropTypes.func.isRequired,
  addRenderRequest: PropTypes.func.isRequired,
  addPreviewRenderRequest: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  templateId: PropTypes.number.isRequired,
  templateSchema: PropTypes.objectOf(PropTypes.any),
  theme: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.object.isRequired,
  hasPreviews: PropTypes.number,
}

FormSliderV2.defaultProps = {
  templateSchema: {},
}

const SceneSlider = styled(Slider)`
  & ul.slick-dots {
    bottom: 35px;
    top: auto;
    text-align: center;

    & li:first-child {
      display: ${(props) => (props.masterHidden ? 'none' : 'inline-block')};
    }

    & li:first-child button:before {
      color: ${({ theme }) => theme.palette.aesendPurple.main};
      opacity: 1;
    }
  }
`

const StyledChevronLeft = styled(ChevronLeft)`
  background: ${({ master, theme }) =>
    master ? theme.palette.aesendPurple.main : theme.palette.aesendBlue.main};
  border-radius: 50%;
  font-size: 28px;
}
`

const StyledChevronRight = styled(ChevronRight)`
  background: ${({ master, theme }) =>
    master ? theme.palette.aesendPurple.main : theme.palette.aesendBlue.main};
  border-radius: 50%;
  font-size: 28px;
}
`

const FormSlider = styled(Slider)`
  & ul.slick-dots {
    text-align: center;

    & li button:before {
      color: ${({ master, theme }) =>
        master ? theme.palette.aesendPurple.main : theme.palette.aesendBlue.main};
    }
  }

  & .slick-next:before {
    color: ${({ master, theme }) =>
      master ? theme.palette.aesendPurple.main : theme.palette.aesendBlue.main};
  }

  & .slick-prev:before {
    color: ${({ master, theme }) =>
      master ? theme.palette.aesendPurple.main : theme.palette.aesendBlue.main};
  }
`

const FormSceneHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: bold;
    color: ${({ master, theme }) =>
      master ? theme.palette.aesendPurple.main : theme.palette.grey[400]};
  }
`

const TimerWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 15px;
  z-index: 1;

  :hover {
    cursor: pointer;
  }
`

const TimeSelectionWrapper = styled.div`
  position: absolute;
  bottom: 22px;
  right: 25px;
  z-index: 1;
  font-size: 12px;

  :hover {
    cursor: pointer;
  }
`

const TimingPickerWrapper = styled.div`
  position: absolute;
  left: 25px;
  bottom: -240px;
  height: 250px;
  width: 510px;
  padding: 10px;
  z-index: 1;
  background: ${({ theme }) => theme.palette.aesendPurple.main};
  color: white;
  display: ${({ visible }) => (visible ? 'inherit' : 'none')};

  :after,
  :before {
    bottom: 100%;
    left: 93%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${({ theme }) => theme.palette.aesendPurple.main};
    border-width: 10px;
    margin-left: -10px;
  }

  :before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: ${({ theme }) => theme.palette.aesendPurple.main};
    border-width: 13px;
    margin-left: -13px;
  }
`

const GogglesWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;

  :hover {
    cursor: pointer;
  }
`

const RenderPreviewWrapper = styled.div`
  position: absolute;
  bottom: 47px;
  right: 47%;
  z-index: 1;
  filter: grayscale(1) brightness(1.5);

  :hover {
    cursor: pointer;
    filter: none;
  }
`

export default connect(null, {
  addRenderRequest: templateActions.addRenderRequest,
  addPreviewRenderRequest: templateActions.addPreviewRenderRequest,
})(FormSliderV2)
