import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import { useSelector } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Link } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'
import QueueAnim from 'rc-queue-anim'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

// app config
import AppConfig from 'constants/AppConfig'

// redux action
import { useAuthActions } from 'hooks/useActions'
import SpotDirectLogo from 'assets/img/SpotDirectLogo.svg'

const Signup = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [state, setState] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
    password2: '',
    errors: null,
  })
  const { fname, lname, email, password, password2 } = state
  const { loading } = useSelector((state) => state.authUser)
  const authActions = useAuthActions()
  const account = location.search.split('=')[1]

  /**
   * On User Signup
   */
  const onUserSignUp = (event, errors, values) => {
    if (!errors.length) authActions.signupUser({ email, password, fname, lname }, navigate)
  }

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}

        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container" stlye={{ position: 'relative' }}>
              <div style={{ position: 'relative', top: '30px', left: '80%' }}>
                <a className="mr-15 text-black" onClick={onUserSignUp}>
                  Already have an account?
                </a>
                <Button
                  component={Link}
                  to={`/signin${account ? `?account=${account}` : ''}`}
                  variant="contained"
                  className="btn-light"
                >
                  Sign In
                </Button>
              </div>
              <div className="session-logo" style={{ marginBottom: '10px' }}>
                <Link to="/">
                  <img
                    src={account === 'spotDirect' ? SpotDirectLogo : AppConfig.appLogo}
                    alt="session-logo"
                    className="img-fluid"
                    width="200"
                    style={{ paddingBottom: 10 }}
                  />
                </Link>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-2 col-md-2 col-lg-2" />
              <div className="col-sm-7 col-md-7 col-lg-8">
                <div className="session-body text-center">
                  <div className="session-head mb-15">
                    <h2>
                      get started with{' '}
                      {account === 'spotDirect' ? 'Spot Direct' : AppConfig.brandName}
                    </h2>
                  </div>
                  <AvForm onSubmit={onUserSignUp}>
                    <AvGroup className="has-wrapper">
                      <AvInput
                        type="text"
                        value={fname}
                        name="user-fname"
                        id="user-fname"
                        className="has-input input-lg"
                        placeholder="Enter Your First Name"
                        required
                        onChange={(e) => setState({ ...state, fname: e.target.value })}
                      />
                      <span className="has-icon">
                        <i className="ti-user"></i>
                      </span>
                      <AvFeedback style={{ textAlign: 'left' }}>
                        Please make sure to enter your first name
                      </AvFeedback>
                    </AvGroup>
                    <AvGroup className="has-wrapper">
                      <AvInput
                        type="text"
                        value={lname}
                        name="user-lname"
                        id="user-lname"
                        className="has-input input-lg"
                        placeholder="Enter Your Last Name"
                        required
                        onChange={(e) => setState({ ...state, lname: e.target.value })}
                      />
                      <span className="has-icon">
                        <i className="ti-user"></i>
                      </span>
                      <AvFeedback style={{ textAlign: 'left' }}>
                        Please make sure to enter your last name
                      </AvFeedback>
                    </AvGroup>
                    <AvGroup className="has-wrapper">
                      <AvInput
                        type="email"
                        value={email}
                        name="user-mail"
                        id="user-mail"
                        className="has-input input-lg"
                        placeholder="Enter Email Address"
                        required
                        validate={{ email: true }}
                        onChange={(e) => setState({ ...state, email: e.target.value })}
                      />
                      <AvFeedback style={{ textAlign: 'left' }}>Invalid email</AvFeedback>
                      <span className="has-icon">
                        <i className="ti-email"></i>
                      </span>
                    </AvGroup>
                    <AvGroup className="has-wrapper">
                      <AvInput
                        value={password}
                        type="Password"
                        name="user-pwd"
                        id="pwd"
                        className="has-input input-lg"
                        placeholder="Password..."
                        required
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                        onChange={(e) => setState({ ...state, password: e.target.value })}
                      />
                      <AvFeedback style={{ textAlign: 'left' }}>
                        Minimum eight characters, one uppercase and lowercase letter and
                        one number{' '}
                      </AvFeedback>
                      <span className="has-icon">
                        <i className="ti-lock"></i>
                      </span>
                    </AvGroup>
                    <AvGroup className="has-wrapper">
                      <AvInput
                        value={password2}
                        type="Password"
                        name="user-pwd2"
                        id="pwd2"
                        className="has-input input-lg"
                        placeholder="Re-enter Password..."
                        required
                        validate={{ match: { value: 'user-pwd' } }}
                        onChange={(e) =>
                          setState({ ...state, password2: e.target.value })
                        }
                      />
                      <AvFeedback style={{ textAlign: 'left' }}>
                        Passwords don't match
                      </AvFeedback>

                      <span className="has-icon">
                        <i className="ti-lock"></i>
                      </span>
                    </AvGroup>
                    <AvGroup className="mb-15">
                      <Button
                        className="btn-info text-white btn-block w-100"
                        variant="contained"
                        size="large"
                        disabled={loading}
                        type="submit"
                      >
                        {loading ? (
                          <CircularProgress size={16} /> // Size 14 works pretty well
                        ) : (
                          <div>Sign Up</div>
                        )}
                      </Button>
                    </AvGroup>
                  </AvForm>
                  <Link to="/contact?signup" style={{ float: 'left' }}>
                    Need help?
                  </Link>
                  <p className="mb-20" style={{ marginRight: 85 }}>
                    or sign in with
                  </p>
                  <Fab
                    style={{ height: 20, width: 35 }}
                    className="btn-facebook mr-15 mb-20 text-white"
                    onClick={() => authActions.signinUserWithFacebook(navigate)}
                  >
                    <i className="zmdi zmdi-facebook"></i>
                  </Fab>
                  <Fab
                    style={{ height: 20, width: 35 }}
                    className="btn-google mr-15 mb-20 text-white"
                    onClick={() => authActions.signinUserWithGoogle(navigate)}
                  >
                    <i className="zmdi zmdi-google"></i>
                  </Fab>
                  <p className="text-muted">
                    By signing up you agree to{' '}
                    {account === 'spotDirect' ? 'Spot Direct' : AppConfig.brandName}
                  </p>
                  <p>
                    <Link to="/terms-conditions" className="text-muted" target="_blank">
                      Terms of Service
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  )
}

export default Signup
