import Api from './api'

export default class StocksApi extends Api {

  // Read
  async get(symbols) {
    const url = `stocks/quote`
    return this.getResource(url, `symbols=${symbols.map((s) => encodeURI(s)).join(',')}`);
  }

  // Read
  async getAutocomplete(input) {
    const url = `stocks/autocomplete`
    return this.getResource(url, `input=${input}`);
  }
}
