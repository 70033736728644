import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'

import { useMediaActions } from 'hooks/useActions'
import FileListing from './fileListing'
import FolderListing from './folderListing'
import FolderBreadcrumb from './folderBreadcrumb'
import TagSelect from './tagSelect'
import { sortMap } from 'components/MediaLibraryModal/sortUtils'

const MediaLibrarySearch = ({ user, open, onSelect, filter }) => {
  const [search, setSearch] = useState('')
  const [currentFolderId, setCurrentFolderId] = useState()
  const [sort, setSort] = useState('')
  const [tagFilter, setTagFilter] = useState('')
  const mediaActions = useMediaActions([])
  const media = useSelector((state) => state.media.list) || []
  const tags = useSelector((state) => state.media.tags) || []
  const folders = media.filter((m) => m.is_folder).sort(sortMap[sort])
  const userFilter = filter || ((media) => media)
  const files = userFilter(
    media.filter(
      (m) =>
        !m.is_folder &&
        ((!currentFolderId && !m.parent) || m.parent === currentFolderId) &&
        (m.media_type?.indexOf('video') !== -1 || m.media_type?.indexOf('image') !== -1)
    )
  )

  useEffect(() => {
    mediaActions.getMedia(user.activeAccount)
    mediaActions.getTags()
  }, [user.activeAccount])

  const pickAsset = (mediaId) => {
    const mediaObj = files.find((f) => f.id === mediaId)
    onSelect({
      id: mediaId,
      previewUrl: mediaObj.preview_url,
      thumbnail_url: mediaObj.thumbnail_url,
      url: mediaObj.url,
      title: mediaObj.name,
      media_type: mediaObj.media_type,
      mediaObj,
    })
  }

  if (!open) {
    return null
  }

  return (
    <Container>
      <SearchForm>
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          type="text"
          label="Keyword"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginRight: 15 }}
        />
        <TagSelect value={tagFilter} onSelect={setTagFilter} tags={tags} />
      </SearchForm>
      <FolderBreadcrumb
        currentFolderId={currentFolderId}
        folders={folders}
        onClick={setCurrentFolderId}
      />
      <MainPanel>
        <ResultsArea>
          <FolderListing
            currentFolderId={currentFolderId}
            folders={folders}
            onClick={setCurrentFolderId}
            search={search}
          />
          <VideoListingContainer>
            <div style={{ color: '#5f6368', fontWeight: '500', marginTop: 20 }}>
              Files and Media
            </div>
            <FileListing
              files={files}
              search={search}
              sortFunc={sortMap[sort]}
              tagFilter={Number(tagFilter)}
              pickAsset={pickAsset}
            />
          </VideoListingContainer>
        </ResultsArea>
      </MainPanel>
    </Container>
  )
}

MediaLibrarySearch.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.func,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  #logo {
    margin: 15px 0 0 15px;
  }
`

// const Loading = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   display: flex;
//   flex-direction: column;
//   color: #AAA;
//   justify-content: center;
//   align-items: center;
//   opacity: 0.7;
//   background: black;
//   z-index: 20;
// `;

const SearchForm = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`

const MainPanel = styled.div`
  display: flex;
  align-items: flex-start;
`

const ResultsArea = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`

const VideoListingContainer = styled.div`
  height: calc(100vh - 274px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;

  @media screen and (max-width: 1230px) {
    .my-col-sm-6 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }

  .nocontent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #aaa;
  }
`

export default MediaLibrarySearch
