import { Form } from 'react-bootstrap'
import React from 'react'

const AppsList = ({ routes, selection, onChange, onFocus, disabled }) => {
  return (
    <Form.Control
      as="select"
      value={selection}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
    >
      {routes.map((route) => {
        return (
          <option key={route.path} value={route.path}>
            {route.title}
          </option>
        )
      })}
    </Form.Control>
  )
}

export { AppsList }
