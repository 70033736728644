import { Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material'
import Authorization from '../../Auth/AuthHoc'
import UserRoles from '../../constants/UserRoles'
import {
  AsyncCmsAesendPlaylistComponent,
  AsyncCmsAddPlaylistComponent,
  AsyncCmsMediaListingComponent,
  AsyncCmsScreenListingComponent,
  AsyncCmsAddScreenComponent,
  AsyncCmsEditScheduleComponent,
  AsyncCmsSceneListingComponent,
  AsyncCmsSceneTypeListComponent,
  AsyncCmsSceneAddEditComponent,
  AsyncCmsStyleAddEditComponent,
  AsyncCmsLibraryComponent,
} from 'components/AsyncComponent/AsyncComponent'
import { LibraryProvider } from 'contexts/library-context'

const { USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN } = UserRoles

const CmsAddPlaylistComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsAddPlaylistComponent)

const CmsAesendPlaylistComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsAesendPlaylistComponent)
const CmsMediaListingComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsMediaListingComponent)
const CmsAddScreenComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsAddScreenComponent)
const CmsEditScheduleComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsEditScheduleComponent)
const CmsScreenListingComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsScreenListingComponent)
const CmsSceneTypeListComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsSceneTypeListComponent)
const CmsSceneAddEditComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsSceneAddEditComponent)
const CmsSceneListingComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsSceneListingComponent)

const CmsStyleAddEditComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsStyleAddEditComponent)

const CmsLibraryComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncCmsLibraryComponent)

const Cms = ({ scroll }) => (
  <Box
    className="content-wrapper d-flex"
    sx={{ minHeight: 'calc(100vh - 180px) !important' }}
  >
    <Routes>
      <Route path="playlists/add" element={<CmsAddPlaylistComponent />} />
      <Route path="playlists/aesend/:id" element={<CmsAddPlaylistComponent />} />
      <Route path="playlists/aesend" element={<CmsAesendPlaylistComponent />} />
      <Route path="media-library" element={<CmsMediaListingComponent />} />
      <Route path="screens/add" element={<CmsAddScreenComponent />} />
      <Route path="screens/groups/:id/schedule" element={<CmsEditScheduleComponent />} />
      <Route path="screens/:id/schedule" element={<CmsEditScheduleComponent />} />
      <Route path="screens/groups/:id" element={<CmsAddScreenComponent />} />
      <Route path="screens/:id" element={<CmsAddScreenComponent />} />
      <Route path="screens" element={<CmsScreenListingComponent />} />
      <Route path="scenes/select-type" element={<CmsSceneTypeListComponent />} />
      <Route path="scenes/add/:type" element={<CmsSceneAddEditComponent />} />
      <Route path="scenes/edit/:id" element={<CmsSceneAddEditComponent />} />
      <Route path="scenes" element={<CmsSceneListingComponent />} />
      <Route path="styles/add" element={<CmsStyleAddEditComponent />} />
      <Route path="styles/edit/:id" element={<CmsStyleAddEditComponent />} />
      <Route
        path="library/*"
        element={
          <LibraryProvider>
            <CmsLibraryComponent scroll={scroll} />
          </LibraryProvider>
        }
      />
    </Routes>
  </Box>
)

export default Cms
