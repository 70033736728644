import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
  useState,
  useMemo,
} from 'react'
import { useLocation } from 'react-router-dom'

import { Grouping, ViewMode } from 'components/Library/types'
import { Asset, CurrentGrouping, SORT_OPTIONS } from 'routes/cms/library/types'
import { TagNamespace } from 'store/types'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'

export enum FilterSections {
  SORT = 'SORT',
  ASPECT_RATIO = 'ASPECT_RATIO',
  COLOR = 'COLOR',
  COLLECTION = 'COLLECTION',
  CATEGORY = 'CATEGORY',
  TYPE = 'TYPE',
  TAG = 'TAG',
}

export type Dispatch = (action: Action) => void
type ActionTypes =
  | 'setGrouping'
  | 'setViewMode'
  | 'setZoom'
  | 'setTypeFilter'
  | 'setTags'
  | 'setTagJoin'
  | 'setSort'
  | 'setSearch'
  | 'setPinnedOpen'
  | 'setAssetPreview'
  | 'setOpenedModal'
  | 'setGroupingListPanelOpen'
  | 'setShowAddNewGroup'
  | 'setSelectedItems'
  | 'setSelectedGroups'
  | 'clearSelectedItems'
  | 'toggleFilter'
  | 'clearFilters'
  | 'setCurrentGrouping'
  | 'clearCurrentGrouping'
  | 'setManageGroupingsType'
  | 'setFocusedItems'
  | 'clearFocusedItems'
  | 'setEditingTileTitle'
  | 'setLoadingTiles'
  | 'clearLoadingTiles'
  | 'setEditingGroupTitle'

type Action = {
  type: ActionTypes
  payload?: any
}

export type GroupingItem = {
  value: number
  label: string
  accountId: number
  count: number
  thumbnailUrl?: string
  representativeAssetId: number
  representativeAssetType: number
  createdAt: string
  shared?: boolean
}

export type GroupingItemArray = GroupingItem[]

export type Filters = {
  [TagNamespace.COLLECTION]: number[]
  [TagNamespace.CATEGORY]: number[]
  [TagNamespace.TAG]: number[]
  ASPECT_RATIO: string[]
  COLOR: string[]
  TYPE: string[]
  PINNED: number[]
}

export type SetTagsPayload = {
  categories: GroupingItemArray
  collections: GroupingItemArray
  tags: GroupingItemArray
  types: GroupingItemArray
  aspectRatios: GroupingItemArray
}

export type State = {
  grouping: Grouping
  viewMode: ViewMode
  zoom: number
  typeFilter: string
  tagJoin: 'and' | 'or'
  search: string
  currentGrouping?: CurrentGrouping
  isInTypeGrouping?: boolean
  assetPreview?: Asset
  selectedItems: AssetIdentifier[]
  selectedGroups: number[]
  editingTileTitle?: AssetIdentifier | undefined
  loadingTiles?: AssetIdentifier[] | undefined
  editingGroupTitle?: number | undefined
  pinnedOpen: boolean
  collections: GroupingItemArray
  categories: GroupingItemArray
  tags: GroupingItemArray
  types: GroupingItemArray
  aspectRatios: GroupingItemArray
  openedModal?:
    | 'AssetPreview'
    | 'ManageGroupings'
    | 'Download'
    | 'TrimCrop'
    | 'Media'
    | 'Share'
    | 'AddGrouping'
  groupingListPanelOpen: boolean
  showAddNewGroup?: boolean
  filters: Filters
  sort: SORT_OPTIONS
  manageGroupingsType?: TagNamespace.CATEGORY | TagNamespace.COLLECTION | TagNamespace.TAG
  focusedItems: AssetIdentifier[]
}

type LibraryProviderProps = {
  children: ReactNode
}
type LibraryContextType =
  | {
      state: State
      dispatch: Dispatch
      switchSelectedItem: (a: AssetIdentifier[] | AssetIdentifier) => void
      switchSelectedGroup: (a: number[] | number) => void
    }
  | undefined

const LibraryContext = createContext<LibraryContextType>(undefined)

export const libraryReducer = (state: State, action: Action) => {
  const { type, payload } = action
  switch (type) {
    case 'setGrouping': {
      return { ...state, grouping: payload }
    }
    case 'setViewMode': {
      return { ...state, viewMode: payload }
    }
    case 'setZoom': {
      return { ...state, zoom: payload }
    }
    case 'setTypeFilter': {
      return { ...state, typeFilter: payload }
    }
    case 'setSort': {
      return { ...state, sort: payload }
    }
    case 'setSearch': {
      return {
        ...state,
        search: payload,
        filters: {
          ...state.filters,
          PINNED: payload === '' ? [] : state.filters.PINNED,
        },
      }
    }
    case 'setPinnedOpen': {
      return { ...state, pinnedOpen: payload }
    }
    case 'setOpenedModal': {
      return { ...state, openedModal: payload }
    }
    case 'setAssetPreview': {
      return { ...state, assetPreview: payload }
    }
    case 'setTags': {
      const { categories, collections, tags, types, aspectRatios } = payload
      return { ...state, categories, collections, tags, types, aspectRatios }
    }
    case 'setTagJoin': {
      return { ...state, tagJoin: payload }
    }
    case 'setGroupingListPanelOpen': {
      return { ...state, groupingListPanelOpen: payload }
    }
    case 'setShowAddNewGroup': {
      return { ...state, showAddNewGroup: payload }
    }
    case 'setSelectedItems': {
      return { ...state, selectedItems: payload }
    }
    case 'setSelectedGroups': {
      return { ...state, selectedGroups: payload }
    }
    case 'setFocusedItems': {
      return { ...state, focusedItems: payload }
    }
    case 'clearFocusedItems': {
      return { ...state, focusedItems: [] }
    }
    case 'clearSelectedItems': {
      return { ...state, selectedItems: [] }
    }
    case 'toggleFilter': {
      const { section, value } = payload
      return {
        ...state,
        filters: {
          ...state.filters,
          [section]: state.filters[section].includes(value)
            ? state.filters[section].filter((i) => i !== value)
            : [...state.filters[section], value],
        },
      }
    }
    case 'setCurrentGrouping': {
      return {
        ...state,
        currentGrouping: payload,
        isInTypeGrouping: state.types?.some((i) => i.value === payload?.value),
      }
    }
    case 'clearCurrentGrouping': {
      return {
        ...state,
        currentGrouping: undefined,
        isInTypeGrouping: false,
      }
    }
    case 'clearFilters': {
      return { ...state, filters: emptyFilters }
    }
    case 'setManageGroupingsType': {
      return { ...state, manageGroupingsType: payload }
    }
    case 'setEditingTileTitle': {
      return { ...state, editingTileTitle: payload }
    }
    case 'setLoadingTiles': {
      return { ...state, loadingTiles: Array.isArray(payload) ? payload : [payload] }
    }
    case 'clearLoadingTiles': {
      return { ...state, loadingTiles: undefined }
    }
    case 'setEditingGroupTitle': {
      return { ...state, editingGroupTitle: payload }
    }
    default: {
      throw new Error(`Unhandled action type ${type}`)
    }
  }
}

export const emptyFilters = {
  [TagNamespace.COLLECTION]: [],
  [TagNamespace.CATEGORY]: [],
  [TagNamespace.TAG]: [],
  ASPECT_RATIO: [],
  COLOR: [],
  TYPE: [],
  PINNED: [],
}

const initialState = {
  grouping: Grouping.all,
  viewMode: 'grid',
  zoom: 3,
  typeFilter: '',
  tagJoin: 'and',
  sort: '',
  search: '',
  groupingListPanelOpen: false,
  currentGrouping: undefined,
  isInTypeGrouping: false,
  selectedItems: [],
  selectedGroups: [],
  focusedItems: [],
  pinnedOpen: false,
  collections: [],
  categories: [],
  tags: [],
  types: [],
  aspectRatios: [],
  showAddNewGroup: false,
  filters: emptyFilters,
  manageGroupingsType: undefined,
  loadingTiles: [],
}

const LibraryProvider = ({ children }: LibraryProviderProps) => {
  const [state, dispatch] = useReducer(libraryReducer, initialState, (v) => v)

  const switchSelectedItem = (items: AssetIdentifier[] | AssetIdentifier) => {
    if (!Array.isArray(items)) {
      items = [items]
    }
    let newList = [...state.selectedItems]
    items.forEach((item) => {
      if (
        state.selectedItems.find(
          (i: AssetIdentifier) =>
            i.assetId === item.assetId && i.assetType === item.assetType
        )
      ) {
        newList = newList.filter(
          (i) => !(i.assetId === item.assetId && i.assetType === item.assetType)
        )
      } else {
        newList.push(item)
      }
    })
    dispatch({ type: 'setSelectedItems', payload: newList })
  }

  const switchSelectedGroup = (groups: number[] | number) => {
    if (!Array.isArray(groups)) {
      groups = [groups]
    }
    let newList = [...state.selectedGroups]
    groups.forEach((item: number) => {
      if (state.selectedGroups.includes(item)) {
        newList = newList.filter((i) => i !== item)
      } else {
        newList.push(item)
      }
    })
    dispatch({ type: 'setSelectedGroups', payload: newList })
  }

  const value = useMemo(
    () => ({
      state,
      dispatch,
      switchSelectedItem,
      switchSelectedGroup,
    }),
    [state]
  )
  return <LibraryContext.Provider value={value}>{children}</LibraryContext.Provider>
}

const useLibrary = () => {
  const { pathname } = useLocation()
  const [prevPath, setPrevPath] = useState(pathname)
  const context = useContext(LibraryContext)
  if (context === undefined) {
    throw new Error('useLibrary must be used within a LibraryProvider')
  }

  useEffect(() => {
    if (prevPath !== pathname) {
      context.dispatch({ type: 'setSelectedItems', payload: [] })
      setPrevPath(pathname)
    }
  }, [pathname])

  return context
}

export { LibraryProvider, useLibrary }
