/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import Auth from '../../Auth/Auth'
import { NotificationManager } from 'react-notifications'
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  SET_USER_PROFILE,
  UPDATE_USER_SUCCESS,
  SWITCH_ACTIVE_ACCOUNT,
} from 'data/actions/types'
import { getTemplates, getTemplateCategories } from './TemplateActions'

import UserApi from '../api/users'
const userApi = new UserApi()
const auth = new Auth()

/**
 * Redux Action To Signin User
 */
export const signinUser = (user) => async (dispatch) => {
  dispatch({ type: LOGIN_USER })
  try {
    const result = await auth.login(user.email, user.password)
  } catch (err) {
    dispatch({ type: LOGIN_USER_FAILURE })
    NotificationManager.error(err)
  }
}

export const getUserInfo = () => async (dispatch) => {
  // dispatch({ type: LOGIN_USER });
  try {
    const userInfo = await userApi.getUserInfo()
    const permissions = await userApi.getUserPermissions()
    if (userInfo) {
      dispatch({
        type: SET_USER_PROFILE,
        payload: {
          userInfo,
          permissions,
        },
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export const updateUserInfo = (data) => async (dispatch) => {
  try {
    const userInfo = await userApi.updateUser(data)
    dispatch({ type: UPDATE_USER_SUCCESS, payload: userInfo })
  } catch (err) {
    console.log(err)
  }
}

/**
 * Redux Action To Signout User
 */
export const logoutUser = (navigate) => (dispatch) => {
  dispatch({ type: LOGOUT_USER })
  auth.logout()
  if (navigate) navigate('/')
  // NotificationManager.success('User Logout Successful');
}

/**
 * Redux Action To Signup User
 */
export const signupUser = (user, navigate) => (dispatch) => {
  dispatch({ type: SIGNUP_USER })
  userApi
    .signup(user)
    .then((result) => {
      navigate('/signin')
      NotificationManager.success(
        `Please check your email and click ` +
          `the verification link to complete your sign-up and login.`,
        `Your registration was successful. `,
        3000
      )
      dispatch({ type: SIGNUP_USER_SUCCESS })
    })
    .catch((err) => {
      dispatch({ type: SIGNUP_USER_FAILURE })
      const apiMessage = JSON.parse(err.request.response).data.message
      console.log(apiMessage)
      NotificationManager.error(apiMessage, 'There was a problem', 3000)
    })
}

export const resetPwd = (email: string, navigate) => async (dispatch) => {
  return auth
    .resetPwd(email)
    .then((result) => {
      navigate('/signin')
      NotificationManager.success(
        `Please check your email and follow the reset procedure ` +
          `to complete setting up a new password.`,
        `Your password reset was successful. `,
        3000
      )
    })
    .catch((err) => {
      console.log('action err ' + err.message)
    })
}

export const changePwd = (data) => async (dispatch) => {
  try {
    const result = await userApi.changePassword(data)
    window.open(result, '_blank')
    NotificationManager.success('Password change request successful')
  } catch (err) {
    const apiMessage = JSON.parse(err.request.response).data.message
    console.log(apiMessage)
    NotificationManager.error(apiMessage, 'There was a problem', 3000)
  }
}

/**
 * Redux Action To Signin User In Firebase With Google
 */
export const signinUserWithGoogle = () => (dispatch) => {
  dispatch({ type: LOGIN_USER })
  auth.loginWithGoogle()
}

/**
 * Redux Action To Signin User In Firebase With Facebook
 */
export const signinUserWithFacebook = () => (dispatch) => {
  dispatch({ type: LOGIN_USER })
  auth.loginWithFacebook()
}

export const switchActiveAccount = (accountId) => (dispatch) => {
  dispatch({
    type: SWITCH_ACTIVE_ACCOUNT,
    payload: accountId,
  })
  dispatch(getTemplates({ ae_version: '2024' }))
  dispatch(getTemplateCategories())
}
