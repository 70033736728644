import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import {
  AssetUploadState,
  useAssetUploadData,
  useAssetUploadApi,
} from 'contexts/asset-upload-context'
import { useState } from 'react'
import { getFileTypeFromMediaType } from 'routes/cms/library/types'
import FileIcon from '../Tile/FileIcon'
import Preview from 'components/MediaUploadModal2/assetPreview'

type AssetsByState = {
  [state: string]: AssetUploadState[]
}

type AssetPreviewProps = {
  asset: AssetUploadState
}

const AssetImage = ({ asset }: AssetPreviewProps) => {
  const fileType = getFileTypeFromMediaType(asset.type)
  if (['Image', 'Video']) {
    return (
      <Preview url={asset.preview} mediaType={asset.type} maxWidth={60} maxHeight={30} />
    )
  }
  return <FileIcon type={fileType} width="25px" height="25px" />
}

const AssetPreview = ({ asset }: AssetPreviewProps) => {
  return (
    <Grid
      container
      item
      xs={10}
      columnGap="10px"
      justifyContent="flex-start"
      alignItems="center"
      flexWrap="nowrap"
    >
      <AssetImage asset={asset} />

      <Typography
        sx={{
          font: 'normal 700 14px/16px DMSans',
          color: 'black',
          width: '100%',
          whiteSpace: 'pre',
        }}
        className="ellipsis"
      >
        {asset.name}
      </Typography>
    </Grid>
  )
}

export const UploadHandler = () => {
  const [opened, setOpened] = useState(true)
  const { uploadState } = useAssetUploadData()
  const { removeAsset } = useAssetUploadApi()

  const { complete, pending, uploading }: AssetsByState = uploadState.assets.reduce(
    (agg: AssetsByState, curr) => {
      agg[curr.state].push(curr)
      return agg
    },
    { complete: [], pending: [], uploading: [], error: [] }
  )

  const getTitleText = () => {
    if (uploading.length > 0) {
      return `Uploading ${uploading.length} File${uploading.length > 1 ? 's' : ''}`
    } else if (pending.length > 0) {
      return `Waiting (${pending.length})`
    } else if (complete.length > 0) {
      return `Done (${complete.length})`
    }
    return 'Upload'
  }
  return uploadState.assets.length ? (
    <Collapse
      in={opened}
      collapsedSize={44}
      sx={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        width: '30vw',
        maxWidth: '450px',
        zIndex: 1001,
        borderRadius: '15px',
        p: 0,
        boxShadow: 1,
        background: 'white',
      }}
    >
      <Box
        sx={{
          background: '#171717',
          height: '44px',
          width: '100%',
          borderRadius: '15px 15px 0 0',
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={() => {
          setOpened((prev) => !prev)
        }}
      >
        <Typography sx={{ font: 'normal 700 16px/18px DMSans', color: 'white' }}>
          {getTitleText()}
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            position: 'absolute',
            right: '10px',
            fill: 'white',
            transform: !opened ? 'rotate(180deg)' : '',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >
        {uploading.length ? (
          <>
            {uploading.map((asset) => (
              <Box
                key={asset.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 0',
                }}
              >
                <AssetPreview asset={asset} />
                <Grid
                  item
                  xs={2}
                  container
                  columnGap="10px"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="auto"
                >
                  <CircularProgress
                    variant="determinate"
                    value={Math.round(asset.progress)}
                    size={20}
                  />
                  <Typography
                    sx={{
                      font: 'normal 400 14px/16px DMSans',
                      color: 'black',
                      textAlign: 'center',
                    }}
                  >
                    Uploading {Math.round(asset.progress)}%
                  </Typography>
                </Grid>
              </Box>
            ))}
          </>
        ) : (
          <></>
        )}
        {pending.length ? (
          <>
            {pending.map((asset) => (
              <Box
                key={asset.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 0',
                }}
              >
                <AssetPreview asset={asset} />
                <Grid item xs={1}>
                  <Typography
                    sx={{
                      font: 'normal 400 14px/16px DMSans',
                      color: 'black',
                      opacity: '.4',
                    }}
                  >
                    Waiting...
                  </Typography>
                </Grid>
              </Box>
            ))}
          </>
        ) : (
          <></>
        )}
        {complete.length ? (
          <>
            {complete.map((asset) => (
              <Grid
                container
                key={asset.path}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '5px 0',
                  flexWrap: 'nowrap',
                }}
              >
                <AssetPreview asset={asset} />
                <Grid item xs={1}>
                  <IconButton
                    disableRipple
                    onClick={() => removeAsset({ uuid: asset.uuid! })}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <></>
        )}
      </Box>
    </Collapse>
  ) : (
    <></>
  )
}
