import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TitleLabel } from './styledComponents'
import MediaPickStrip from './mediaPickStrip'
import AssetPreview from './assetPreview'
import 'react-image-gallery/styles/css/image-gallery.css'
import { getFileType } from 'util/pathUtils'
import MediaUploadModal, { Sources } from 'components/MediaUploadModal2'
import UploadIcon from '@mui/icons-material/CloudUpload'
import Button from '@mui/material/Button'
import useUpdatableState from '@landisdesign/use-updatable-state'

const MediaSliderForm = ({
  activeAccount,
  formSpec,
  imageUrl,
  imageIdxs,
  handleChange,
  handleRemove,
  theme,
  selectUploadedFile,
  uploadPreSignMethod,
}) => {
  const { ae_field_name } = formSpec
  const [uploadedFileUrl, setUploadedFileUrl] = useUpdatableState(imageUrl)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const { type } = getFileType(uploadedFileUrl)

  return (
    <div>
      <MediaUploadModal
        pickerMode
        onSelect={(assets, type) => {
          selectUploadedFile(assets[0], type)
          setUploadModalOpen(false)
        }}
        accountId={activeAccount}
        multiple={false}
        allowedSources={{ [Sources.MyDevice]: true, [Sources.Library]: true, [Sources.CloudSources]: true }}
        isOpen={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}

        // mediaLibraryFilter={(items) =>
        //   items.filter((item) =>
        //     item.media_type?.startsWith('video/') || item.media_type?.startsWith('image/')
        //       ? item
        //       : false
        //   )
        // }
        uploadPreSignMethod={uploadPreSignMethod}
      />
      <TitleLabel color={formSpec.scene === 0 ? theme.palette.aesendPurple.main : '#000'}>
        {formSpec.label}
      </TitleLabel>
      <div style={{ maxWidth: 385, marginBottom: 10 }}>{formSpec.user_prompt}</div>
      <Container>
        <MediaPickStrip
          choices={formSpec.choices}
          onSelect={handleChange}
          selected={imageIdxs}
        />
        {formSpec.allow_upload &&
          (!uploadedFileUrl ? (
            <Button
              variant="contained"
              disableElevation
              style={{ minWidth: 120 }}
              startIcon={<UploadIcon />}
              onClick={() => setUploadModalOpen(true)}
            >
              Upload
            </Button>
          ) : (
            <SelectionWrapper
              selected={imageUrl === uploadedFileUrl}
              onClick={() => selectUploadedFile(uploadedFileUrl)}
            >
              <AssetPreview
                url={uploadedFileUrl}
                type={type}
                handleRemove={() => {
                  if (imageUrl === uploadedFileUrl) {
                    handleRemove(ae_field_name)
                  }
                  setUploadedFileUrl(undefined)
                }}
                maxHeight={100}
                maxWidth={100}
              />
              {imageUrl === uploadedFileUrl ? 'Selected ' : 'Select'}
            </SelectionWrapper>
          ))}
      </Container>
      {formSpec.file_specs && formSpec.allow_upload && (
        <FileSpecsContainer>
          Recommended file specs for upload:
          {formSpec.file_specs}
        </FileSpecsContainer>
      )}
    </div>
  )
}

MediaSliderForm.propTypes = {
  imageUrl: PropTypes.string,
  imageIdxs: PropTypes.number,
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  parentState: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  selectUploadedFile: PropTypes.func.isRequired,
  uploadPreSignMethod: PropTypes.func,
}

const Container = styled.div`
  display: flex;
  height: 120px;
`

const FileSpecsContainer = styled.div`
  font-weight: bold;
  color: black;
`

const SelectionWrapper = styled.div`
  cursor: pointer;
  border: ${({ selected }) => selected && '2px solid cornflowerblue'};
  border-radius: 5px;
  padding: 5px;
  color: ${({ selected }) => (selected ? '#888' : '#00000000')};

  :hover {
    color: #888;
  }

  text-align: center;
  font-size: 0.8em;
  font-weight: bold;

  > div {
    margin-bottom: 10px !important;
  }
`

export default MediaSliderForm
