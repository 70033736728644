import { ChevronRight, ChevronLeft } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import { MediaListEntry } from 'backend-v2/src/modules/library/dtos/library.dto'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { TileList } from 'components/Library/Tile/TileList'
import { useRef, useState, useEffect } from 'react'

interface IProps {
  pinnedAssets: MediaListEntry<any>[]
  pin: (assetIds: AssetIdentifier[]) => void
  unPin: (assetIds: AssetIdentifier[]) => void
  onAssetClick: (asset: number) => any
  openDownloadModal?: ({ asset }: { asset: AssetIdentifier }) => void
  openShareModal?: (assets: AssetIdentifier[] | number[]) => void
  openTrimCropModal?: ({ asset }: { asset: AssetIdentifier }) => void
  cloneAsset?: ({ asset }: { asset: AssetIdentifier }) => void
  updateAsset?: ({ asset }: { asset: AssetIdentifier }) => void
  deleteAsset?: ({ asset }: { asset: AssetIdentifier }) => void
}

export const PinnedAssets = ({
  pinnedAssets,
  pin,
  unPin,
  onAssetClick,
  openDownloadModal,
  openShareModal,
  openTrimCropModal,
  cloneAsset,
  updateAsset,
  deleteAsset,
}: IProps) => {
  const [hover, setHover] = useState(false)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  const hoverRef = useRef(hover)
  const containerRef = useRef<HTMLDivElement>(null)
  hoverRef.current = hover

  const checkScroll = () => {
    const container = containerRef.current
    if (!container) return
    setCanScrollLeft(container.offsetWidth + container.scrollLeft < container.scrollWidth)
    setCanScrollRight(container.scrollLeft > 0)
  }

  useEffect(() => {
    const container = containerRef.current
    if (!container) return
    container.addEventListener('scroll', checkScroll)
    return () => container.removeEventListener('scroll', checkScroll)
  }, [checkScroll])

  useEffect(() => {
    checkScroll()
  }, [pinnedAssets])

  return (
    <Box
      sx={{
        position: 'relative',
        '& div::-webkit-scrollbar': { display: 'none', width: 0, height: 0 },
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          minHeight: 150,
          width: '100%',
          borderRadius: '5px',
          pl: 1,
          pb: 1,
          pr: 1,
          transition: 'height 280ms cubic-bezier(0.4, 0, 0.2, 1)',
          overflowY: 'visible',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          background: 'transparent',
          scrollBehavior: 'smooth',
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          ...(hover && {
            marginBottom: '-150px',
            paddingBottom: '158px',
          }),
        }}
        id="container"
        onMouseMove={(e) => {
          var rect = e.target.getBoundingClientRect()
          var y = e.clientY - rect.top
          if (y > 200 && hoverRef.current && !!hover) {
            setHover(false)
          } else if (y < 200 && !hoverRef.current && !hover) {
            setHover(true)
          }
        }}
      >
        <TileList
          assets={pinnedAssets}
          checkedItems={[]}
          pin={pin}
          unPin={unPin}
          onAssetClick={onAssetClick}
          sx={{ marginRight: 2, maxWidth: 250 }}
          openDownloadModal={openDownloadModal}
          openTrimCropModal={openTrimCropModal}
          cloneAsset={cloneAsset}
          deleteAsset={deleteAsset}
          updateAsset={updateAsset}
          openShareModal={openShareModal}
        />
      </Box>
      {canScrollRight && (
        <IconButton
          sx={{
            position: 'absolute',
            left: 2,
            top: 50,
            background: 'white',
            border: '1px solid #D9D9D9',
            '&:hover': { background: 'white' },
            zIndex: 1000,
          }}
          onClick={() => {
            document.getElementById('container').scrollLeft -= 300
          }}
        >
          <ChevronLeft color="primary" />
        </IconButton>
      )}
      {canScrollLeft && (
        <IconButton
          sx={{
            position: 'absolute',
            right: -2,
            top: 50,
            background: 'white',
            border: '1px solid #D9D9D9',
            '&:hover': { background: 'white' },
            zIndex: 1000,
          }}
          onClick={() => {
            document.getElementById('container').scrollLeft += 300
          }}
        >
          <ChevronRight color="primary" />
        </IconButton>
      )}
    </Box>
  )
}
