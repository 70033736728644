import auth0 from 'auth0-js'
import { AUTH_CONFIG } from './auth-variables'
import { LOGIN_USER, LOGIN_USER_FAILURE } from '../data/actions/types'
import UserApi from '../data/api/users'

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.customDomain || AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid profile email user_metadata',
  })

  constructor() {
    this.login = this.login.bind(this)
    this.signup = this.signup.bind(this)
    this.loginWithGoogle = this.loginWithGoogle.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        { realm: AUTH_CONFIG.dbConnectionName, username, password },
        (err, authResult) => {
          if (err) {
            reject(`Error: ${err.description}.`)
          }
          resolve(authResult)
        }
      )
    })
  }

  loginWithGoogle() {
    this.auth0.authorize({ connection: 'google-oauth2' })
  }

  loginWithFacebook() {
    this.auth0.authorize({ connection: 'facebook' })
  }

  signup({ email, password, fname, lname }) {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: AUTH_CONFIG.dbConnectionName,
          email,
          password,
          userMetadata: {
            fname,
            lname,
          },
        },
        (err, res) => {
          if (err) {
            console.log(err)
            reject(err)
          }
          console.log(res)
          resolve(res)
        }
      )
    })
  }

  resetPwd = (email) => {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: AUTH_CONFIG.dbConnectionName,
          email,
        },
        (err, res) => {
          if (err) {
            console.log(err)
            reject(err)
          }
          console.log(res)
          resolve(res)
        }
      )
    })
  }

  handleAuthentication(dispatch, navigate) {
    return new Promise((resolve, reject) => {
      console.log(window.location.hash)
      this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
        console.log(authResult)
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
            if (!profile?.email_verified) {
              confirm('You cannot login with an unverified email.')
              navigate('/signin')
              return dispatch({ type: LOGIN_USER_FAILURE })
            }
            this.setSession(authResult)
            dispatch({ type: 'LOGIN_USER_SUCCESS', payload: authResult })
            // ensure user is in our db if login is from Oauth source (Google/facebook)
            if (authResult?.idTokenPayload?.sub?.split('|')?.[0] !== 'auth0') {
              const userApi = new UserApi(authResult.idToken)
              userApi.syncUser().then(console.log)
            }
            navigate('/app/cms/library')
            resolve()
          })
        } else if (err) {
          alert(`Access Denied: ${err}.`)
          dispatch({ type: LOGIN_USER_FAILURE })
          navigate('/signin')
          reject()
        }
      })
    })
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('redux')
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }
}
