import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

import { AppType, FileType } from 'routes/cms/library/types'
import AudioIcon from 'assets/img/LibraryTypes/audio.svg'
import VideoIcon from 'assets/img/LibraryTypes/film.svg'
import SpreadsheetIcon from 'assets/img/LibraryTypes/file-spreadsheet.svg'
import ImageIcon from '../../../assets/img/image.svg'
import NewsIcon from 'assets/img/news-icon.svg'
import fileIcon from 'assets/img/file.svg'
import pdfIcon from 'assets/img/pdf.svg'
import LinkTextIcon from 'assets/img/link-text.svg'
import WeatherIcon from 'assets/img/weather.svg'
import StockIcon from 'assets/img/stock.svg'
import PlaylistIcon from 'assets/img/LibraryTypes/playlist.svg'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

type FileIconProps = {
  type: FileType | AppType
  height?: number | string
  width?: number | string
}

const SVG = styled(ReactSVG)`
  & div svg path {
    fill: white;
  }
`

const FileIcon = ({ type, height, width }: FileIconProps) => {
  const fileIconMap = new Map<FileType | AppType, JSX.Element>()
  fileIconMap.set('Audio', <img src={AudioIcon} style={{ height, width }} />)
  fileIconMap.set('Video', <img src={VideoIcon} style={{ height, width }} />)
  fileIconMap.set('Image', <img src={ImageIcon} style={{ height, width }} />)
  fileIconMap.set('File', <img src={fileIcon} style={{ height, width }} />)
  fileIconMap.set('PDF', <img src={pdfIcon} style={{ height, width }} />)
  fileIconMap.set('Playlist', <img src={PlaylistIcon} style={{ height, width }} />)
  fileIconMap.set('Spreadsheet', <img src={SpreadsheetIcon} style={{ height, width }} />)
  fileIconMap.set(
    'WEATHER',
    <SVG src={WeatherIcon} style={{ '& svg': { height, width } }} />
  )
  fileIconMap.set(
    'WEB_PAGE',
    <SVG src={LinkTextIcon} style={{ '& svg': { height, width } }} />
  )
  fileIconMap.set(
    'STOCKS',
    <SVG src={StockIcon} style={{ '& svg': { height, width } }} />
  )
  fileIconMap.set('NEWS', <SVG src={NewsIcon} style={{ '& svg': { height, width } }} />)
  return (
    fileIconMap.get(type) || (
      <InsertDriveFileOutlinedIcon
        htmlColor="white"
        sx={{ '& svg': { height, width } }}
      />
    )
  )
}

export default FileIcon
