import React, { useState } from 'react'
import { uniqId } from 'util/helpers'

export const ConfigCheckbox = ({ config, onFocus, onBlur, onChange, className }) => {
  const [value, setValue] = useState(config.value)

  const _onChange = (event) => {
    setValue(event.target.checked)

    typeof onChange === 'function' && onChange(event.target.checked)
  }

  const id = uniqId('checkbox')

  return (
    <div className={className}>
      <label htmlFor={id}>{config.name}</label>
      <input
        id={id}
        type="checkbox"
        value={value}
        onFocus={() => onFocus()}
        onBlur={onBlur}
        onChange={_onChange}
      />
    </div>
  )
}
