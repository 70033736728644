import React from 'react';

export const WebPageSceneRenderer = ({ url }) => {
  return (
    <div style={{ flex: 1 }}>
      <iframe
        title="Preview"
        src={url || ''}
        style={{ width: '100vw', height: '100vh', borderWidth: 0 }}
      />
    </div>
  )
}
