import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

const ColorPickerForm = ({ theme, formSpec, parentState, handleChange }) => {
  const { ae_field_name, scene, label, user_prompt } = formSpec;
  return (
    <div style={{ textAlign: 'left', marginTop: 10 }}>
      <span style={{
        color: scene === 0
          ? theme.palette.aesendPurple.main
          : 'black',
        fontWeight: 'bold' }}>
        {label}
      </span>
      <p>{user_prompt}</p>
      <ChromePicker
        style={{ margin: '20px' }} 
        width='100%' color={parentState[ae_field_name]}
        onChangeComplete={handleChange}
      />
    </div>
  );
}

ColorPickerForm.propTypes = {
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  parentState: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
}

export default ColorPickerForm;