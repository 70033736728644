import { useState } from 'react';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Row, Col, Label  } from 'reactstrap';
import styled from 'styled-components';
import { types } from '../index'
import Preview from '../common/Preview'

const styles = {
  labelStyle: { color: '#464D69CC', marginBottom: '0px', minWidth: 80 },
}

export const WebPageSceneEditor = ({ name, config, onSave, saving, sceneUrl }) => {
  const [sceneName, setSceneName] = useState(name)
  const [url, setUrl] = useState(config?.url || '')

  const regex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
  const validatedUrl = url.match(regex) ? url : ''

  const save = () => onSave({ name: sceneName, type: types.WEB_PAGE, config: { url } })

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col sm="7" style={{ display: 'block' }}>
          <h2>{name ? 'Edit' : 'New'} Web Page Scene</h2><br/>
          <AvForm>
            <StyledGroup style={styles.groupStyle}>
              <Label for="name" style={styles.labelStyle}>Name</Label>
              <AvInput
                type="text"
                value={sceneName}
                name="name"
                id="name"
                required
                onChange={e => setSceneName(e.target.value)}
              />
              <AvFeedback style={{ textAlign: 'left' }}>
                Please enter a name for the scene
              </AvFeedback>
            </StyledGroup>


            <StyledGroup style={styles.groupStyle}>
              <Label for="url" style={styles.labelStyle}>URL</Label>
              <AvInput
                type="text"
                value={url}
                name="url"
                id="url"
                required
                onChange={e => setUrl(e.target.value)}
              />
              <AvFeedback style={{ textAlign: 'left' }}>
                Please enter the URL
              </AvFeedback>
            </StyledGroup>
            <Button 
              type="submit"
              variant="contained" 
              color="primary" 
              fullWidth
              onClick={save}
              style={{ marginTop: '20px' }}
              disabled={!validatedUrl || !sceneName}
            >
              {saving ?
                <CircularProgress color="secondary" size={22} /> :
                <div>SAVE</div>
              }
            </Button>
          </AvForm>          
        </Col>
        <Col sm="5" style={{ paddingTop: 35 }}>
          <Preview saving={saving} sceneUrl={sceneUrl} />
        </Col>
        <Col sm="2"/>
      </Row>
    </div>
  )
}

const StyledGroup = styled(AvGroup)`
  margin-bottom: 5px;
  // display: flex;
  align-items: center;
  min-width: 400px;
`