import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getFileType } from 'util/pathUtils'
import pdfIcon from 'assets/img/pdf-icon.png'
import wordIcon from 'assets/img/word.png'
import excelIcon from 'assets/img/Excel.png'
import pptIcon from 'assets/img/powerpoint.png'
import audioIcon from 'assets/img/audio-icon.png'
import postscriptFile from 'assets/img/ps-file-symbol.png'
import ReactPlayer from 'react-player'

const AssetPreview = memo(({ url, mediaType, maxHeight, maxWidth }) => {
  const { filename, type } = mediaType ? { type: mediaType } : getFileType(url)

  return (
    <div style={{ position: 'relative' }}>
      {type.indexOf('image') !== -1 && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={url}
          alt="preview"
          width="80%"
        />
      )}
      {type.indexOf('audio') !== -1 && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={audioIcon}
          alt="preview"
          width="80%"
        />
      )}
      {type.indexOf('video') !== -1 && (
        <ReactPlayer
          width={maxWidth}
          height="100%"
          autoPlay
          playing={true}
          muted
          loop
          volume={0}
          controls={false}
          playsInline
          url={url + '#t=0.5'}
        />
      )}
      {type.indexOf('postscript') !== -1 && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={postscriptFile}
          alt="pdf"
          width="80%"
        />
      )}
      {type.indexOf('pdf') !== -1 && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={pdfIcon}
          alt="pdf"
          width="80%"
        />
      )}
      {(type.indexOf('msword') !== -1 || type.indexOf('wordprocessingml') !== -1) && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={wordIcon}
          alt="pdf"
          width="80%"
        />
      )}
      {(type.indexOf('ms-excel') !== -1 || type.indexOf('spreadsheetml') !== -1) && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={excelIcon}
          alt="pdf"
          width="80%"
        />
      )}
      {(type.indexOf('powerpoint') !== -1 || type.indexOf('presentationml') !== -1) && (
        <img
          style={{ margin: '0 auto', maxHeight, maxWidth, width: 'auto' }}
          src={pptIcon}
          alt="pdf"
          width="80%"
        />
      )}
      {type.indexOf('other') !== -1 && (
        <div
          style={{
            border: '1px dotted #ccc',
            background: '#eee',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            minHeight: 100,
          }}
        >
          {type.split('/')[1]} file
        </div>
      )}
    </div>
  )
})

AssetPreview.propTypes = {
  url: PropTypes.string,
  mediaType: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
}

export default AssetPreview
