import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import withStyles from '@mui/styles/withStyles'

const defaultToolbarStyles = {
  iconButton: {},
}

interface IProps {
  classes: any
  navigate: () => void
}

const CustomToolbar = ({ classes, navigate }: IProps) => (
  <Tooltip title={'Add New Software Release'}>
    <IconButton
      className={classes.iconButton}
      onClick={() => navigate(`/app/admin/software-releases/add-edit`)}
      size="large"
    >
      <AddIcon className={classes.deleteIcon} />
    </IconButton>
  </Tooltip>
)

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(CustomToolbar)
