/**
 * Sign Up With Firebase
 */
import { useState } from 'react'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import QueueAnim from 'rc-queue-anim'

import AppConfig from 'constants/AppConfig'
import ContactForm from 'components/ContactForm'
import ContactApi from 'data/api/contact'
import SpotDirectLogo from 'assets/img/SpotDirectLogo.svg'

const contactApi = new ContactApi()

const Contact = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const account = location.search.split('=')[1]

  const onSubmit = async (event, errors, values) => {
    const source = location.search.replace('?', '')
    try {
      if (!errors.length) {
        setLoading(true)
        await contactApi.post({ ...values, sourcePage: source })
        navigate('/signin')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}

        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container" style={{ position: 'relative' }}>
              <div style={{ position: 'relative', top: '30px', left: '80%' }}>
                <a className="mr-15 text-black">Return to</a>
                <Button
                  component={Link}
                  to={`/signin`}
                  variant="contained"
                  className="btn-light"
                >
                  Sign In
                </Button>
              </div>
              <div className="session-logo" style={{ marginBottom: '10px' }}>
                <Link to="/">
                  <img
                    src={account === 'spotDirect' ? SpotDirectLogo : AppConfig.appLogo}
                    alt="session-logo"
                    className="img-fluid"
                    width="200"
                    style={{ paddingBottom: 10 }}
                  />
                </Link>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-2 col-md-2 col-lg-2" />
              <div className="col-sm-7 col-md-7 col-lg-8">
                <div className="session-body text-center">
                  <div className="session-head mb-15">
                    <h2>
                      submit message to{' '}
                      {account === 'spotDirect' ? 'Spot Direct' : AppConfig.brandName}
                    </h2>
                  </div>

                  <ContactForm onSubmit={onSubmit} loading={loading} />
                  <p className="text-muted">
                    You agree to{' '}
                    {account === 'spotDirect' ? 'Spot Direct' : AppConfig.brandName}
                  </p>
                  <p>
                    <Link to="/terms-conditions" className="text-muted" target="_blank">
                      Terms of Service
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  )
}

export default Contact
