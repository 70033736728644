import Api from './api'

export default class ScreensApi extends Api {

  post(data, accountId) {
    const url = accountId ? `${accountId}/screens` : 'screens'
    return this.postResource(url, data)
  }

  // Read
  get(query, accountId) {
    const url = accountId ? `${accountId}/screens` : 'screens'
    return this.getResource(url, query)
  }

  async getById(id, query = '') {
    const screen = await this.getResource(`screens/${id}`, query) 
    return screen[0]
  }

  // Update
  update(id, data) {
    return this.patchResource(`screens/${id}`, data)
  }
  
  updateStatus(id, data) {
    return this.putResource(`screens/${id}/status`, data)
  }

  // Delete
  delete(id) {
    return this.deleteResource(`screens/${id}`)
  }

  postGroup(data, accountId) {
    const url = accountId ? `${accountId}/screens/groups` : 'screens/groups'
    return this.postResource(url, data)
  }

  async getGroupById(id, query) {
    const group = await this.getResource(`screens/groups/${id}`, query) 
    return group[0]
  }

  updateGroup(id, data) {
    return this.patchResource(`screens/groups/${id}`, data)
  }

  deleteGroup(id) {
    return this.deleteResource(`screens/groups/${id}`)
  }

}
