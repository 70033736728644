import { Box, SxProps } from '@mui/material'

interface IProps {
  sx: SxProps
}

export const TableListing = ({ sx }: IProps) => {
  return (
    <Box sx={{ minHeight: 300, background: '#ccc', p: 2, borderRadius: '5px', ...sx }}>
      Table Listing
    </Box>
  )
}
