import { useState, useMemo } from 'react'
import { GroupingArray } from 'contexts/library-context'

export const useFilteredItems = (items: GroupingArray) => {
  const [search, setSearch] = useState('')

  const filteredItems = useMemo(
    () =>
      items?.filter(
        (i) => !search || i.label.toLowerCase().includes(search.toLowerCase())
      ),
    [items, search]
  )

  return { search, setSearch, filteredItems }
}
