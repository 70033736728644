import { Route, Routes } from 'react-router-dom'
import Authorization from '../../../Auth/AuthHoc'
import UserRoles from '../../../constants/UserRoles'
import AddEditSoftware from './addEditSoftware'
import SoftwareList from './listing'

const { SUPER_ADMIN } = UserRoles

const SoftwareListComponent = Authorization([SUPER_ADMIN])(SoftwareList)
const AddEditSoftwareComponent = Authorization([SUPER_ADMIN])(AddEditSoftware)

const SoftwareReleases = () => (
  <div className="content-wrapper">
    <Routes>
      <Route path="list" element={<SoftwareListComponent />} />
      <Route path="add-edit" element={<AddEditSoftwareComponent />} />
      <Route path="add-edit/:id" element={<AddEditSoftwareComponent />} />
    </Routes>
  </div>
)

export default SoftwareReleases
