
const dropzoneStyle = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  padding: '20px',
  margin: '15px',
  backgroundColor: '#E1E1E1',
  borderRadius: '5px',
  minHeight: '200px',
  border: '2px dashed #C7C7C7',
};

export default dropzoneStyle;