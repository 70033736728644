import React from 'react'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import { Divider, FormControlLabel, Menu, MenuItem, RadioGroup } from '@mui/material'
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const getValue = (variants, variant) => {
  if (typeof variants[variant] === 'string') {
    return variant.toString()
  }

  return variants[variant].value || variant
}

const getCaption = (variants, variant) => {
  if (typeof variants[variant] === 'string') {
    return variants[variant]
  }

  return variants[variant].caption
}

export const getDefaultValue = (filter) => {
  if ('default' in filter) {
    return filter['default']
  }

  const firstVariantName = Object.keys(filter.variants)[0]

  return getValue(filter.variants, firstVariantName)
}

/**
 * Removes default values from object with filters
 *
 * @param filters
 * @param defaultFilters
 * @return {{}}
 */
export const removeDefaultFilters = (filters, defaultFilters) => {
  const result = {}

  Object.keys(filters).map((name) => {
    if (filters[name] !== defaultFilters[name]) {
      result[name] = filters[name]
    }
  })

  return result
}

/**
 * Returns default values of filters
 *
 * @param filtersConfigurationObject
 * @return {{}}
 */
export const getDefaultFilters = (filtersConfigurationObject) => {
  const result = {}

  Object.keys(filtersConfigurationObject).forEach((filterName) => {
    result[filterName] = getDefaultValue(filtersConfigurationObject[filterName])
  })

  return result
}

const FilterRadio = ({ name, caption, variants, onChange, value }) => {
  return (
    <RadioGroup aria-label={caption} name={name} value={value} onChange={onChange}>
      {Object.keys(variants).map((variant, key) => (
        <FormControlLabel
          key={key}
          value={getValue(variants, variant)}
          label={getCaption(variants, variant)}
          control={<Radio />}
          sx={{ font: 'normal 400 14px/18px DMSans' }}
        />
      ))}
    </RadioGroup>
  )
}

const Filter = ({ name, config, onChange, value }) => {
  const changeValue = (value) => {
    onChange(value.target.value)
  }

  return (
    <div className="filter-panel">
      <FormControl variant="standard" component="fieldset">
        <FormLabel sx={{ font: 'normal 400 14px/18px DMSans' }} component="legend">
          {config.caption}
        </FormLabel>
        <FilterRadio
          name={name}
          caption={config.caption}
          variants={config.variants}
          onChange={(e) => changeValue(e)}
          value={value}
        />
      </FormControl>
    </div>
  )
}

export const Filters = ({ config, onChange, values, clearFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const changeValue = (filter, value) => {
    typeof onChange === 'function' && onChange(filter, value)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Filters
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={clearFilters} sx={{ font: 'normal 400 14px/18px DMSans' }}>
          Clear Filters
        </MenuItem>
        {Object.keys(config).map((filter, key) => {
          return (
            <div key={key}>
              <Divider />
              <MenuItem disableRipple>
                <Filter
                  key={key}
                  name={filter}
                  config={config[filter]}
                  value={values[filter]}
                  onChange={(e) => {
                    changeValue(filter, e)
                  }}
                />
              </MenuItem>
            </div>
          )
        })}
      </Menu>
    </>
  )
}
