import { Route, Routes } from 'react-router-dom'
import Authorization from '../../Auth/AuthHoc'
import UserRoles from '../../constants/UserRoles'
import {
  AsyncTemplatesListComponent,
  AsyncTemplateCustomizationComponent,
  AsyncTemplateRenderingComponent,
  AsyncRenderingListComponent,
} from 'components/AsyncComponent/AsyncComponent'
import { LibraryProvider } from 'contexts/library-context'

const { USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN } = UserRoles

const TemplatesListComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncTemplatesListComponent)

const TemplateCustomizationComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncTemplateCustomizationComponent)

const TemplateRenderingComponent = Authorization([
  USER,
  CUSTOMER_ADMIN,
  AGENCY_ADMIN,
  SUPER_ADMIN,
])(AsyncTemplateRenderingComponent)

const Forms = () => (
  <div className="content-wrapper">
    <Routes>
      <Route path="templates-list" element={<TemplatesListComponent />} />
      <Route
        path="template-customization/rendering/:id"
        element={<TemplateRenderingComponent />}
      />
      <Route
        path="template-customization/:id"
        element={
          <LibraryProvider>
            <TemplateCustomizationComponent />
          </LibraryProvider>
        }
      />
    </Routes>
  </div>
)

export default Forms
