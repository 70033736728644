import React from "react"
import { IconButton, Tooltip } from "@mui/material"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface DeleteButtonProps {
  onClick: () => void
}
export const DeleteButton = ({onClick}:DeleteButtonProps) => {
  return <Tooltip title='Delete asset'><IconButton 
            sx={{
              zIndex: 100,
              position: 'absolute',
              right: '-5px',
              top: '0',
            }} 
            onClick={onClick}
            disableFocusRipple
            disableRipple
            >
            <DeleteOutlineIcon htmlColor='red'/>
          </IconButton></Tooltip>
}