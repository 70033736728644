import React, { useState } from 'react';
import { AppsList } from "./components/appsList";
import cls from 'classnames';


export const ConfigRoute = ({ config, onFocus, onChange, appSettings, className }) => {
    const [current, setCurrent] = useState(config.value);

    const changeValue = (event) => {
        setCurrent(event.target.value);
        typeof onChange === "function" && onChange(event.target.value);
    }

    return (
        <div className={ cls("routes-list", className) }>
            <label className="field">{ config.name }</label>

            <AppsList
                disabled={config.__editable === false}
                routes={ appSettings.routes }
                selection={ current }
                onChange={ (event) => {
                    changeValue(event)
                } }
                onFocus={ () => onFocus() }
            />
        </div>

    );
}
