/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react'
import Loadable from 'react-loadable'

// rct page loader
import RctPageLoader from 'components/RctPageLoader/RctPageLoader'
import AsyncTemplateCustomizationComponent from 'routes/templates/template-customization'

// Terms and Conditions
const AsyncTermsConditionComponent = Loadable({
  loader: () => import('routes/misc/terms-and-conditions'),
  loading: () => <RctPageLoader />,
})

// Privacy
const AsyncPrivacyComponent = Loadable({
  loader: () => import('routes/misc/privacy'),
  loading: () => <RctPageLoader />,
})

// Template List
const AsyncTemplatesListComponent = Loadable({
  loader: () => import('routes/templates/templates-list'),
  loading: () => <RctPageLoader />,
})

// Template Customization
// const AsyncTemplateCustomizationComponent = Loadable({
// 	loader: () => import("routes/templates/template-customization"),
// 	loading: () => <RctPageLoader />,
// })

// Template Rendering
const AsyncTemplateRenderingComponent = Loadable({
  loader: () => import('routes/templates/template-customization/rendering'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsAesendPlaylistComponent = Loadable({
  loader: () => import('routes/cms/playlists/listing-aesend'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsAddPlaylistComponent = Loadable({
  loader: () => import('routes/cms/playlists/addPlaylist'),
  loading: () => <RctPageLoader />,
})

// Template Rendering list
const AsyncRenderingListComponent = Loadable({
  loader: () => import('routes/templates/renderings'),
  loading: () => <RctPageLoader />,
})

// Admin Template List
const AsyncAdminTemplatesListComponent = Loadable({
  loader: () => import('routes/admin/templates/templates-list'),
  loading: () => <RctPageLoader />,
})

// Admin Template Edit
const AsyncAdminTemplateEditComponent = Loadable({
  loader: () => import('routes/admin/templates/template-edit'),
  loading: () => <RctPageLoader />,
})

// Admin Account List
const AsyncAdminAccountListComponent = Loadable({
  loader: () => import('routes/admin/accounts/account-list'),
  loading: () => <RctPageLoader />,
})

// Admin User List
const AsyncAdminUserListComponent = Loadable({
  loader: () => import('routes/admin/users/user-list'),
  loading: () => <RctPageLoader />,
})

// Admin User Company Setup
const AsyncAdminUserCompaniesComponent = Loadable({
  loader: () => import('routes/admin/users/userCompanies'),
  loading: () => <RctPageLoader />,
})

// Admin Account Edit
const AsyncAdminAccountEditComponent = Loadable({
  loader: () => import('routes/admin/accounts/account-edit'),
  loading: () => <RctPageLoader />,
})

// Media Listing Component
const AsyncCmsMediaListingComponent = Loadable({
  loader: () => import('routes/cms/mediaLibrary'),
  loading: () => <RctPageLoader />,
})

// Screen Listing Component
const AsyncCmsScreenListingComponent = Loadable({
  loader: () => import('routes/cms/screens/listing'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsAddScreenComponent = Loadable({
  loader: () => import('routes/cms/screens/add'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsEditScheduleComponent = Loadable({
  loader: () => import('routes/cms/screens/schedule'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsSceneListingComponent = Loadable({
  loader: () => import('routes/cms/scenes/listing'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsSceneTypeListComponent = Loadable({
  loader: () => import('routes/cms/scenes/typeListing'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsSceneAddEditComponent = Loadable({
  loader: () => import('routes/cms/scenes/addEditScene'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsStyleAddEditComponent = Loadable({
  loader: () => import('routes/cms/scenes/addEditStyle'),
  loading: () => <RctPageLoader />,
})

const AsyncCmsLibraryComponent = Loadable({
  loader: () => import('routes/cms/library'),
  loading: () => <RctPageLoader />,
})

export {
  AsyncTermsConditionComponent,
  AsyncTemplatesListComponent,
  AsyncTemplateCustomizationComponent,
  AsyncPrivacyComponent,
  AsyncTemplateRenderingComponent,
  AsyncRenderingListComponent,
  AsyncAdminTemplatesListComponent,
  AsyncAdminTemplateEditComponent,
  AsyncAdminAccountListComponent,
  AsyncAdminUserListComponent,
  AsyncAdminUserCompaniesComponent,
  AsyncAdminAccountEditComponent,
  AsyncCmsAesendPlaylistComponent,
  AsyncCmsAddPlaylistComponent,
  AsyncCmsMediaListingComponent,
  AsyncCmsScreenListingComponent,
  AsyncCmsAddScreenComponent,
  AsyncCmsEditScheduleComponent,
  AsyncCmsSceneListingComponent,
  AsyncCmsSceneTypeListComponent,
  AsyncCmsSceneAddEditComponent,
  AsyncCmsStyleAddEditComponent,
  AsyncCmsLibraryComponent,
}
