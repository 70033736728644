import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { renderScene } from 'components/SceneComponents'
import ScenesApi from 'data/api/scenes'
import StylesApi from 'data/api/styles'

const RenderScene = () => {
  const { id } = useParams()
  const [sceneData, setSceneData] = useState({})
  const [style, setStyle] = useState({})
  const location = useLocation()
  const token = location?.search?.split('=')[1]

  useEffect(() => {
    const getSceneData = async () => {
      if (token) {
        await localStorage.setItem('id_token', token)
      }
      const scenesApi = new ScenesApi()
      const stylesApi = new StylesApi()
      if (id) {
        scenesApi
          .getById(id)
          .then((scene) => {
            setSceneData(scene)
            if (scene?.config?.styleId) {
              stylesApi.getById(scene?.config?.styleId).then(setStyle)
            }
          })
          .catch((err) => console.log(err))
        // .then(() => token && localStorage.removeItem('id_token'))
      }
    }

    getSceneData()
  }, [id])

  if (sceneData) {
    return renderScene({
      type: sceneData.type,
      name: sceneData.name,
      config: { ...sceneData.config, style: style?.config },
      id,
    })
  } else {
    return <div />
  }
}

export default RenderScene
