import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import ButtonBase from '@mui/material/ButtonBase';
import previewButton from '../../assets/img/Buttons-Custom-Preview.svg';
import selectButton from '../../assets/img/Buttons-Custom-Select.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      //height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.0,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 55,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.0,
    //transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} calc(${theme.spacing(1)} + 6px)`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    // transition: theme.transitions.create('opacity'),
  },
});

const TileButton = ({ classes, onPreview, selectUrl, active }) =>
  <div>
    <span className={classes.imageButton}>
      {active &&
        <div>
          <Link to={selectUrl}>
              <ButtonBase
                focusRipple
                focusVisibleClassName={classes.focusVisible}
                style={{
                  position: 'absolute', 
                  right: '110px', 
                  bottom: '45px',
                }}
              >
                {/* <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${selectButton})`,
                    width: 100, height: 35, backgroundRepeat: 'no-repeat',
                  }} 
                /> */}
              </ButtonBase>
            </Link>
          <span className={classes.imageSrc}
            style={{
              backgroundImage: `url(${previewButton})`,
              width: 100, height: 35, backgroundRepeat: 'no-repeat',
              position: 'absolute', left: '15px', top: '25px'
            }} />
        </div>
      }
    </span>
  </div>

TileButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onPreview: PropTypes.func.isRequired,
  selectUrl: PropTypes.string.isRequired,
};

export default withStyles(styles)(TileButton);