import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import ReactPlayer from 'react-player';
import useUpdatableState from '@landisdesign/use-updatable-state';
import Preloader from "./preloader";

const AssetPreview = ({url, type, handleRemove, maxHeight, maxWidth, loading}) => {
    /** https://betterprogramming.pub/updating-state-from-properties-with-react-hooks-5d48693a4af8 */
    const [isLoading, setIsLoading] = useUpdatableState(loading, (newValue, oldValue) => {
        // If predicate returns false, the value is changed
        if (newValue !== oldValue && newValue ) {
            return false;
        }
        return true;
    }); //only change towards "True"

    return (
        <div style={{position: 'relative', marginBottom: '20px'}}>
            <Preloader loading={isLoading} maxHeight={maxHeight} maxWidth={maxWidth}/>
            {handleRemove &&
                <div style={{position: 'absolute', top: -10, left: -10, zIndex: 100}}>
                    <IconButton
                        style={{
                            background: 'linear-gradient(45deg, #2196f399 30%, #21cbf399 90%)',
                            height: '30px',
                            width: '30px',
                            padding: '4px',
                        }}
                        size="large">
                        <Close style={{color: 'white'}} onClick={handleRemove}/>
                    </IconButton>
                </div>
            }
            {type.indexOf('image') !== -1 &&
                <img style={{margin: '0 auto', maxHeight, maxWidth, width: 'auto'}}
                     src={url} alt='preview'
                     onLoad={() => {
                         setIsLoading(false)
                     }}/>
            }
            {type.indexOf('video') !== -1 &&
                <ReactPlayer
                    width={maxWidth}
                    height='100%'
                    autoPlay
                    playing={true}
                    muted
                    loop
                    volume={0}
                    controls={false}
                    playsInline
                    url={url + '#t=0.5'}
                />
                // <video key={url} width={maxWidth} controls={false} muted autoPlay playsInline loop>
                //   <source src={url + '#t=0.5'} type={type}/>
                // </video>
            }
            {type.indexOf('other') !== -1 &&
                <div style={{
                    border: '1px dotted #ccc', background: '#eee', display: 'flex',
                    justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', minHeight: 100
                }}>
                    {type.split('/')[1]} file
                </div>
            }
        </div>
    );
}


AssetPreview.propTypes = {
    url: PropTypes.string,
    type: PropTypes.string,
    handleRemove: PropTypes.func,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    loading: PropTypes.bool
};

export default AssetPreview;
