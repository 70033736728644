import Api from '/src/data/api/api'

const apiUrl = 'media-content/search'

export default class MediaContentAPI extends Api {
    search(query) {
        // this.abortPrevious()
        return this.getResource(apiUrl, this.stringifyParams(query), true)
    }
}
