import * as actions from '../actions/types';

// initial state
const INIT_STATE = {
  list: []
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // get todos
    case actions.FETCH_STYLES_SUCCESS:
      return { ...state, list: payload };

    case actions.DELETE_STYLES_SUCCESS:
      return { ...state, list: state.list.filter(i => i.id !== payload) };

    default: return state;
  }
}
