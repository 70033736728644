import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import loading from './loading.svg'

//Auth0
import Auth from '../../Auth/Auth'

const auth = new Auth()

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
}

const Callback = ({ initialize }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      auth
        .handleAuthentication(dispatch, navigate)
        .then(() => setTimeout(() => initialize(), 500))
        .catch((err) => console.log('Error in Authorization. ', err, err.message))
    }
  }, [])

  return (
    <div style={style}>
      <img src={loading} alt="loading" />
    </div>
  )
}

export default Callback
