import React from 'react'
import { get } from 'lodash'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { TextField } from 'formik-mui'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useAuthActions } from 'hooks/useActions'

const profileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('This must be a valid email address.').required('Required'),
})

const BasicProfile = ({ profile, user }) => {
  const authActions = useAuthActions([])
  const sub = get(user, 'idTokenPayload.sub')
  const isAuth0User = sub.startsWith('auth0') // vs. Google, etc
  const userProvider = sub.split('|')[0]

  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <h2 className="mb-4">User Profile</h2>
      <Formik
        enableReinitialize
        initialValues={{
          email: get(profile, '0.user_email_main'),
          firstName: get(profile, '0.first_name'),
          lastName: get(profile, '0.last_name'),
        }}
        validationSchema={profileSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await authActions.updateUserInfo(values)
          setSubmitting(false)
        }}
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form>
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={3} className="text-right">
                Username/Email
              </Grid>
              <Grid item sm={9}>
                <Field
                  component={TextField}
                  name="email"
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Username/Email"
                  disabled={!isAuth0User}
                />
                {!isAuth0User && (
                  <div className="text-right font-sm text-muted">
                    Cannot change {userProvider} email
                  </div>
                )}
              </Grid>
              <Grid item sm={3} className="text-right">
                First Name
              </Grid>
              <Grid item sm={9}>
                <Field
                  component={TextField}
                  name="firstName"
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="First Name"
                />
              </Grid>
              <Grid item sm={3} className="text-right">
                Last Name
              </Grid>
              <Grid item sm={9}>
                <Field
                  component={TextField}
                  name="lastName"
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Last Name"
                />
              </Grid>
              <Grid item sm={3} />
              <Grid item sm={9}>
                <Button
                  variant="contained"
                  color="primary"
                  className="text-white m-10"
                  disabled={!isValid}
                  type="submit"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default BasicProfile
