import * as actions from '../actions/types';

// initial state
const INIT_STATE = {
  list: [],
  tags: [],
  mediaSort: '1',
};

export default (state = INIT_STATE, { type, payload, auth }) => {
  switch (type) {
      case actions.ADD_MEDIA_SUCCESS:
        const {activeAccount} = auth;
        if (auth && activeAccount) {
          if (activeAccount !== payload.data.account_id) {
            return state;
          }
        }
        const {list} = state;
        const newList = [...list];
        //do not add it twice
        const there = list.find((item) => item.id === payload.id)
        if (there) {
          return state;
        }

        newList.push(payload.data);
        return { ...state, list: newList };

      case actions.FETCH_MEDIA_SUCCESS:
        return { ...state, list: payload };

      case actions.DELETE_MEDIA_SUCCESS:
        const payloadNumeric = parseInt(payload)
        return { ...state, list: state.list.filter(i => i.id !== payloadNumeric) };

      case actions.UPDATE_MEDIA_START:
      case actions.DELETE_MEDIA_START:
        return { ...state, list: state.list.map(i => i.id === payload ? {...i, updating: true} : i)}
      case actions.UPDATE_MEDIA_SUCCESS:
        return {
          ...state,
          list: state.list.map(i => i.id === payload.id
            ? {
              ...i,
              ...payload.data,
              updating: false,
            }
            : i
          )
        }

      case actions.FETCH_TAGS_SUCCESS:
        return {
          ...state,
          tags: payload,
        }
      case actions.INSERT_TAG_SUCCESS:
        return {
          ...state,
          tags: [...state.tags, payload]
        }
      case actions.DELETE_TAG_SUCCESS:
        return {
          ...state,
          tags: state.tags.filter(t => t.id !== payload)
        }

      case actions.ADD_TAGS_SUCCESS:
        return {
          ...state,
          list: state.list.map(file => file.id === payload.id
            ? { ...file, tags: payload.tags }
            : file
          )
        }

      default: return state;
  }
}
