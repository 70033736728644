import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  ButtonGroup,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
} from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import gridIcon from 'assets/img/grid-view.svg'
import tableIcon from 'assets/img/table-view.svg'
import filtersIcon from 'assets/img/filters.svg'
import Collection from '@mui/icons-material/CollectionsOutlined'
import Pin from '@mui/icons-material/PushPinOutlined'
import Tag from '@mui/icons-material/SellOutlined'
import Close from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

import { CustomSlider } from './CustomSlider'
import { Grouping, ViewMode } from './types'
import { CurrentGrouping } from 'routes/cms/library/types'
import { AddMediaButton } from './AddMediaButton'
import { FilterDropdown } from './Filter/FilterDropdown'
import {
  Dispatch,
  Filters,
  FilterSections,
  GroupingItemArray,
} from 'contexts/library-context'
import downloadLogo from 'assets/img/download.svg'
import shareLogo from 'assets/img/share.svg'
import deleteLogo from 'assets/img/delete.svg'
import categoryLogo from 'assets/img/union.svg'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import OrderDropdown from './Order/OrderDropdown'
import { TagNamespace } from 'store/types'
import { ReactSVG } from 'react-svg'

const GrayButton = styled(Button)`
  border-color: rgba(0, 0, 0, 0.25);
  font-weight: bold !important;
`
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    padding: '5px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}))

interface IProps {
  viewMode: ViewMode
  zoom: number
  grouping?: Grouping // view in top listing (all, collections, categories, types)
  typeFilter?: string
  sort: string
  search?: string
  currentGrouping?: CurrentGrouping
  groupingType?: TagNamespace // view in grouping listing
  inPinned?: boolean
  selectMode?: boolean
  selectedItems?: AssetIdentifier[]
  selectedGroups?: number[]
  leftSlot?: ReactNode
  categories: GroupingItemArray
  collections: GroupingItemArray
  tags: GroupingItemArray
  types: GroupingItemArray
  aspectRatios: GroupingItemArray
  tagJoin: 'and' | 'or'
  filters: Filters
  skipFilters?: FilterSections[]
  dispatch: Dispatch
  onManageCollections?: () => void
  onManageCategories?: () => void
  onManageTags?: () => void
  onPin?: (items: AssetIdentifier[]) => void
  onDownload?: (item: AssetIdentifier) => void
  onShare?: (items: AssetIdentifier[]) => void
  onDeleteAsset?: (items: AssetIdentifier[]) => Promise<void>
  onDeleteGroup?: (items: number[]) => void
  onCancelSelection?: () => void
  onAddMediaButtonClick?: () => void
  sx?: SxProps
}

export const FilterControl = ({
  viewMode = 'grid',
  zoom,
  grouping = Grouping.all,
  categories,
  collections,
  filters,
  skipFilters,
  tags,
  types,
  aspectRatios,
  tagJoin,
  sort,
  search,
  currentGrouping,
  groupingType,
  inPinned,
  selectMode,
  selectedItems = [],
  selectedGroups = [],
  leftSlot,
  dispatch,
  onManageCollections,
  onManageCategories,
  onManageTags,
  onPin,
  onDeleteAsset,
  onDeleteGroup,
  onDownload,
  onShare,
  onAddMediaButtonClick,
  sx,
}: IProps) => {
  const navigate = useNavigate()
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
  const cancelAssetSelection = () => dispatch({ type: 'setSelectedItems', payload: [] })
  const cancelGroupSelection = () => dispatch({ type: 'setSelectedGroups', payload: [] })
  const showingGroupings = grouping !== Grouping.all
  const filtersSelected = Object.values(filters).some((i) => i?.length)

  const renderGroupingButton = (type: Grouping) => (
    <Button
      variant={grouping === type ? 'contained' : undefined}
      onClick={() => {
        dispatch({ type: 'setGrouping', payload: type })
      }}
      sx={{
        borderRadius: '5px !important',
        padding: '8px 12px',
        '&.MuiButton-contained': {
          backgroundColor: 'toggleButtonBackground.main',
          color: 'primary.main',
        },
      }}
    >
      <Typography sx={{ font: 'normal 400 16px/18px DMSans' }}>
        {type.toLowerCase() !== Grouping.all ? 'By' : ''} {type}
      </Typography>
    </Button>
  )

  const handleFilterToggle = (type: FilterSections) => (value: number | string) => () => {
    if (type === FilterSections.SORT) {
      dispatch({ type: 'setSort', payload: value })
    } else {
      dispatch({ type: 'toggleFilter', payload: { section: type, value } })
    }
  }

  const changeTagJoin = (v: 'and' | 'or') => dispatch({ type: 'setTagJoin', payload: v })

  const clearFilters = () => dispatch({ type: 'clearFilters' })

  const handleNewGroupingClick = () => {
    dispatch({ type: 'setOpenedModal', payload: 'AddGrouping' })
  }

  return (
    <Box
      sx={{
        borderRadius: '15px',
        backgroundColor: 'white',
        p: 2,
        gridArea: 'filter',
        display: 'flex',
        boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.12)',
        ...sx,
      }}
    >
      {selectedGroups.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            {selectedGroups.length} selected
          </Typography>
          <div>
            <Button
              sx={{
                color: '#000',
                mr: 2,
              }}
              onClick={() => {
                onDeleteGroup && onDeleteGroup(selectedGroups)
                cancelGroupSelection()
              }}
            >
              <img style={{ padding: '2px 10px 2px 2px' }} src={deleteLogo} /> Delete
            </Button>
            <GrayButton variant="outlined" onClick={cancelGroupSelection} color="primary">
              Cancel
            </GrayButton>
          </div>
        </Box>
      ) : (
        <>
          {!selectedItems?.length ? (
            <>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {!inPinned &&
                  !leftSlot &&
                  !selectMode &&
                  (!search || showingGroupings) && (
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <ButtonGroup
                        disableElevation
                        variant="standard"
                        sx={{ display: 'flex', columnGap: '5px' }}
                      >
                        {renderGroupingButton(Grouping.all)}
                        {renderGroupingButton(Grouping.category)}
                        {renderGroupingButton(Grouping.collection)}
                        {renderGroupingButton(Grouping.type)}
                      </ButtonGroup>
                    </Box>
                  )}
                {search && !selectMode && !showingGroupings && (
                  <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <Typography variant="h5">Search Results</Typography>
                    <ButtonGroup disableElevation variant="standard" sx={{ ml: 2 }}>
                      <Button
                        variant={filters.PINNED.includes(0) ? undefined : 'contained'}
                        onClick={() =>
                          dispatch({
                            type: 'toggleFilter',
                            payload: { section: 'PINNED', value: 0 },
                          })
                        }
                        sx={{
                          borderRadius: '5px !important',
                          '&.MuiButton-contained': {
                            backgroundColor: 'toggleButtonBackground.main',
                            color: 'primary.main',
                          },
                        }}
                      >
                        All
                      </Button>
                      <Button
                        variant={filters.PINNED.includes(0) ? 'contained' : undefined}
                        onClick={() => {
                          dispatch({
                            type: 'toggleFilter',
                            payload: { section: 'PINNED', value: 0 },
                          })
                          dispatch({
                            type: 'setTagJoin',
                            payload: 'and',
                          })
                        }}
                        sx={{
                          borderRadius: '5px !important',
                          '&.MuiButton-contained': {
                            backgroundColor: 'toggleButtonBackground.main',
                            color: 'primary.main',
                          },
                        }}
                      >
                        Pinned
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                {inPinned && !search && <Typography variant="h5">Pinned</Typography>}
                {leftSlot && !search && <>{leftSlot}</>}
                <Box
                  sx={{ display: 'flex', alignItems: 'center', flex: selectMode ? 1 : 0 }}
                >
                  {viewMode === 'grid' && (
                    <Box
                      sx={{
                        width: 150,
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 2,
                      }}
                    >
                      <CustomSlider
                        defaultValue={zoom}
                        valueLabelDisplay="off"
                        step={1}
                        min={2}
                        max={8}
                        onChange={(e, v) => dispatch({ type: 'setZoom', payload: v })}
                      />
                    </Box>
                  )}
                  <StyledToggleButtonGroup
                    color="primary"
                    value={viewMode}
                    exclusive
                    onChange={(e, v) => dispatch({ type: 'setViewMode', payload: v })}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      disableRipple
                      value="list"
                      sx={{
                        height: '34px',
                        width: '34px',
                        '& svg': {
                          fill: viewMode === 'list' ? 'white' : '#666666',
                          display: 'flex',
                        },
                      }}
                    >
                      <ReactSVG src={tableIcon} />
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      value="grid"
                      sx={{
                        height: '34px',
                        width: '34px',
                        '& svg': {
                          fill: viewMode === 'grid' ? 'white' : '#666666',
                          display: 'flex',
                        },
                      }}
                    >
                      <ReactSVG src={gridIcon} />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  {!showingGroupings && (
                    <ToggleButton
                      onClick={(e) => setFilterAnchor(e.currentTarget)}
                      sx={{
                        height: '34px',
                        width: '34px',
                        padding: '5px',
                        marginLeft: 2,
                        '&.Mui-selected': {
                          backgroundColor: 'primary.main',
                          color: 'white',
                        },
                        '& svg': {
                          fill: filtersSelected ? 'white' : '#666666',
                          display: 'flex',
                        },
                      }}
                      value={''}
                      selected={filtersSelected}
                    >
                      <ReactSVG src={filtersIcon} />
                    </ToggleButton>
                  )}
                  <FilterDropdown
                    anchorEl={filterAnchor}
                    handleClose={() => setFilterAnchor(null)}
                    handleToggle={handleFilterToggle}
                    clearFilters={clearFilters}
                    categories={categories}
                    collections={collections}
                    tags={tags}
                    types={types}
                    aspectRatios={aspectRatios}
                    changeTagJoin={changeTagJoin}
                    tagJoin={tagJoin}
                    filters={filters}
                    skip={skipFilters}
                    sort={sort}
                  />
                  {(grouping === Grouping.category ||
                    grouping === Grouping.collection) && (
                    <OrderDropdown
                      value={sort}
                      onChange={(newValue) =>
                        dispatch({
                          type: 'setSort',
                          payload: newValue,
                        })
                      }
                    />
                  )}
                  {grouping === Grouping.category && (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ marginLeft: 2, whiteSpace: 'nowrap' }}
                      onClick={handleNewGroupingClick}
                    >
                      New Category
                    </Button>
                  )}
                  {grouping === Grouping.collection && (
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ marginLeft: 2, whiteSpace: 'nowrap' }}
                      onClick={handleNewGroupingClick}
                    >
                      New Collection
                    </Button>
                  )}
                  {(inPinned || currentGrouping || selectMode) && (
                    <OutlinedInput
                      fullWidth={selectMode}
                      value={search}
                      notched={false}
                      margin="dense"
                      placeholder="Search Media"
                      onChange={(e) =>
                        dispatch({ type: 'setSearch', payload: e.target.value })
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                      endAdornment={
                        search && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => dispatch({ type: 'setSearch', payload: '' })}
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      sx={{
                        ml: 2,
                        width: selectMode ? undefined : 200,
                        background: 'white',
                        '& input': { paddingTop: 1, paddingBottom: 1 },
                      }}
                    />
                  )}
                  {(inPinned || currentGrouping) && !currentGrouping?.shared && (
                    <>
                      {groupingType &&
                        [TagNamespace.CATEGORY, TagNamespace.COLLECTION].includes(
                          groupingType
                        ) && (
                          <GrayButton
                            variant="outlined"
                            onClick={() => navigate(`add`)}
                            sx={{ ml: 2, whiteSpace: 'nowrap' }}
                          >
                            Select From Library
                          </GrayButton>
                        )}
                      <AddMediaButton
                        onOptionClick={onAddMediaButtonClick}
                        sx={{ ml: 2, font: 'normal normal 700 14px/18px DMSans' }}
                      />
                    </>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                {selectedItems.length} selected
              </Typography>
              <Box
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                <Button sx={{ color: '#000', mr: 2 }} onClick={onManageCollections}>
                  <Collection sx={{ mr: 1 }} color="primary" />
                  Manage Collection
                </Button>
                <Button sx={{ color: '#000', mr: 2 }} onClick={onManageCategories}>
                  <img style={{ padding: '2px 10px 2px 2px' }} src={categoryLogo} />
                  Manage Category
                </Button>
                <Button sx={{ color: '#000', mr: 2 }} onClick={onManageTags}>
                  <Tag sx={{ mr: 1 }} color="primary" /> Manage Tags
                </Button>
                <Button
                  sx={{ color: '#000', mr: 2 }}
                  onClick={async () => {
                    onPin && (await onPin(selectedItems))
                    cancelAssetSelection()
                  }}
                >
                  <Pin sx={{ mr: 1 }} color="primary" /> Pin
                </Button>
                <Button
                  sx={{
                    color: '#000',
                    mr: 2,
                  }}
                  onClick={() => onShare(selectedItems)}
                >
                  <img style={{ padding: '2px 10px 2px 2px' }} src={shareLogo} /> Share
                </Button>
                <Button
                  sx={{
                    color: '#000',
                    mr: 2,
                    opacity: selectedItems.length > 1 ? '.4' : '1',
                  }}
                  onClick={() => {
                    onDownload && onDownload(selectedItems[0])
                  }}
                  disabled={selectedItems.length > 1}
                >
                  <img style={{ padding: '2px 10px 2px 2px' }} src={downloadLogo} />{' '}
                  Download
                </Button>
                <Button
                  sx={{
                    color: '#000',
                    mr: 2,
                  }}
                  onClick={async () => {
                    onDeleteAsset && (await onDeleteAsset({ asset: selectedItems }))
                    cancelAssetSelection()
                  }}
                >
                  <img style={{ padding: '2px 10px 2px 2px' }} src={deleteLogo} /> Delete
                </Button>
                <GrayButton
                  variant="outlined"
                  onClick={cancelAssetSelection}
                  color="primary"
                >
                  Cancel
                </GrayButton>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
