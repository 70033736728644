import axios from 'axios';
import Api from './api';

export default class FileUploadApi extends Api {

  async post(uploadType, requestId, file, setProgress) {
    const formData = new FormData();

    formData.append('file', file, file.name.replace(/[^0-9a-zA-Z.]/g, '_'));
    return this.postResource(
      `upload/${uploadType}/${requestId}`,
      formData,
      {
        onUploadProgress: progressEvent =>
          setProgress((progressEvent.loaded * 100) / progressEvent.total),
      },
    );
  }

  async getPresignedOtherAssetUrl(type, path, filename, mediaType) {
    return this.getResource(
      `upload/${type}/${path}`,
      `mediaType=${encodeURIComponent(mediaType)}&` +
      `filename=${encodeURIComponent(filename)}`,
    )
  }

  async getPresignedTemplateAssetUrl(sessionId, filename, mediaType) {
    return this.getResource(
      `upload/template-assets/${sessionId}`,
      `mediaType=${encodeURIComponent(mediaType)}&` +
      `filename=${encodeURIComponent(filename)}`,
    )
  }

  async getPresignedRenderAssetUrl(sessionId, filename, mediaType) {
    return this.getResource(
      `upload/render-assets/${sessionId}`,
      `mediaType=${encodeURIComponent(mediaType)}&` +
      `filename=${encodeURIComponent(filename)}`,
    )
  }

  async fetchStoryblocksFile(requestId, assetId) {
    return this.postResource(
      `upload/render-assets/${requestId}`,
      {
        downloadSource: {
          service: 'storyblocks',
          sourceAssetId: assetId,
        }
      }
    )
  }

  async head(url) {
    try {
      return await axios.head(url);
    } catch (err) {
      console.log(err);
    }
  }

  async delete(uploadType, requestId, filename) {
    const encodedFilename = encodeURIComponent(filename);
    return this.deleteResource(`upload/${uploadType}/${requestId}`,`filename=${encodedFilename}`)
  }

  async deleteFile(bucket, path, filename) {
    const encodedFilename = encodeURIComponent(filename);
    const encodedPath = encodeURIComponent(path)
    return this.deleteResource(
      `upload/${bucket}`,`path=${encodedPath}&filename=${encodedFilename}`
    )
  }

  getPresignedUploadUrl (accountId, filename, mediaType) {
    return this.getResource(
      'upload/signed-media-library-put-object',
      `accountId=${accountId}&` +
      `contentType=${encodeURIComponent(mediaType)}&` +
      `filename=${encodeURIComponent(filename)}`,
    )
  }

  getPresignedSoftwareReleaseUploadUrl (version, platform, filename) {
    return this.getResource(
      'upload/signed-software-release-put-object',
      `version=${version}&` +
      `platform=${encodeURIComponent(platform)}&` +
      `filename=${encodeURIComponent(filename)}`,
    )
  }

  uploadFileToS3 (presignedUrl, file, uploadConfig = {}) {
    const options = {
      ...uploadConfig,
    };
    return axios.put(presignedUrl, file, options)
  }

}
