import React, {useState} from 'react';
import {Icon} from "@mui/material";
import {Button, Form} from "react-bootstrap";
import {AppsList} from "./components/appsList";
import cls from 'classnames';


export const ConfigRoutesList = ({config, onFocus, onChange, appSettings, className}) => {
    const [current, setCurrent] = useState(config.value);
    const changeValue = (element, event, key) => {
        const newCurrent = [...current];
        newCurrent[key][element] = event.target.value;
        setCurrent(newCurrent);

        typeof onChange === "function" && onChange({key, element, value: event.target.value});
    }

    const _onFocus = (element, key) => {
        typeof onFocus === "function" && onFocus({key, element})
    }

    const moveUp = (key) => {
        typeof onChange === "function" && onChange({key, move: 'up'});
    }

    const moveDown = (key) => {
        typeof onChange === "function" && onChange({key, move: 'down'});
    }

    const add = () => {
        const newCurrent = [...current];
        newCurrent.push({path: "undefined", title: "New"});
        setCurrent(newCurrent);

        const key = newCurrent.length - 1;

        if (typeof onChange === "function") {
            onChange({key, element: "title", value: "New"});
            onChange({key, element: "path", value: "undefined"});
        }
    }

    const remove = (key) => {
        typeof onChange === "function" && onChange({key, delete: key});
    }

    return (
        <div className={cls("routes-list", className)}>
            <label className="field">{config.name}</label>
            {Object.keys(current).map((key) => {
                return (
                    <div className="field" key={key}>
                        <div className="move-buttons">
                            <button className="move up" onClick={() => {
                                moveUp(key)
                            }}><Icon classes={{root: 'up'}}>arrow_drop_up</Icon>
                            </button>
                            <button className="move down" onClick={() => {
                                moveDown(key)
                            }}><Icon classes={{root: 'down'}}>arrow_drop_down</Icon>
                            </button>
                        </div>
                        <div className="input">
                            <label className="label">Title</label>
                            <Form.Control name={current[key].title} value={current[key].title}
                                          onChange={(event) => {
                                              changeValue('title', event, key)
                                          }}
                                          onFocus={() => _onFocus('title', key)}
                                          placeholder={current[key].title ? "" : "Title"}/>
                        </div>
                        <div className="input">
                            <label className="label">Path</label>
                            <AppsList
                                routes={appSettings.routes}
                                selection={current[key].path}
                                onChange={(event) => {
                                    changeValue('path', event, key)
                                }}
                                onFocus={() => _onFocus('path', key)}
                            />
                        </div>
                        <button className="delete" onClick={() => {
                            remove(key)
                        }}><Icon>remove_circle</Icon>
                        </button>
                    </div>
                )
            })}
            <div key="add-button-key">
                <Button className="add" variant="outline-primary" onClick={add}>Add Button</Button>
            </div>
        </div>
    );
}
