import React from 'react';
import Dropzone from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Close from '@mui/icons-material/Close';
import { FormText } from 'reactstrap';
import { AvGroup } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';

import dropzoneStyle from './dropzoneStyle';

const AssetPreview = ({ url, type, handleRemove }) =>
  <div style={{ position: 'relative', marginBottom: '20px' }} >
    <span style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 100 }}>
      <IconButton
        tooltip='remove'
        hoveredstyle={{ cursor: 'pointer' }}
        style={{ background: 'linear-gradient(45deg, #2196f399 30%, #21cbf399 90%)',
        height: '30px', width: '30px', padding: '4px' }}
        size="large">
        <Close style={{ color: 'white' }} onClick={handleRemove}/>
      </IconButton>
    </span>
    { type.indexOf('image') !== -1 &&
      <img style={{ margin: '0 auto' }}
        src={url} alt='preview' width='80%'/>
    }
    { type.indexOf('video') !== -1 &&
      <video width='100%' controls>
        <source src={url + '#t=0.5'} type={type}/>
      </video>
    }
    { type.indexOf('other') !== -1 &&
      <div style={{ border: '1px dotted #ccc', background: '#eee', display: 'flex',
        justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', minHeight: 200 }}>
        {type.split('/')[1]} file
      </div>
    }
  </div>

const ImageUploadForm = ({ formSpec, parentState, handleChange, handleRemove }) => {
  const { ae_field_name, max_width, max_height } = formSpec;
  const imageUrl = parentState[ae_field_name];
  const filename = imageUrl && decodeURIComponent(imageUrl.substr(imageUrl.lastIndexOf('/') + 1))
  const extension = filename && filename.split('.').pop().toLowerCase();
  let type;
  if (['png','jpg','gif'].includes(extension)) {
    type = `image/${extension}`;
  } else if (['mp4','mov'].includes(extension)) {
    type = `video/${extension}`;
  } else {
    type = `other/${extension}`;
  }


  return (
    <div style={{ textAlign: 'left' }}>
      <img src={formSpec.guide_image || 'https://react.semantic-ui.com/images/wireframe/image-text.png'}
        alt='img' width='100%'
        style={{ paddingBottom: '20px', display: 'block', margin: 'auto' }} />

      <Row>
        <Col sm='6'>
          <AvGroup style={{ paddingTop: '30px'}}>
            <FormText color="muted">
              Please drag the image file to render into this portion of the video into the dropzone. It
              should be { max_width } x { max_height } px or smaller.
            </FormText>
          </AvGroup>
        </Col>
        <Col sm='6'>
          { parentState.uploading && <LinearProgress /> }
          { !parentState.files[ae_field_name] && !imageUrl &&
            <Dropzone
              multiple={false}
              accept={['image/*', 'video/*', 'application/pdf', 'application/illustrator']}
              onDrop={files => handleChange(files, ae_field_name)}
            >
              { ({ getRootProps, getInputProps }) =>
                <div
                  id='dropzone'
                  {...getRootProps()}
                  style={dropzoneStyle}>
                  Drop your image or video file here...
                </div> }
            </Dropzone>
          }
          { !parentState[ae_field_name] ?
              parentState.files[ae_field_name] &&
              parentState.files[ae_field_name].map((file, idx) =>
              <AssetPreview
                key={idx}
                url={file.preview}
                type={file.type}
                handleRemove={() => handleRemove(ae_field_name)}/>)
            :
              <AssetPreview
                url={imageUrl}
                type={type}
                handleRemove={() => handleRemove(ae_field_name)}
              />
          }

          <p>Selected File: { !parentState[ae_field_name] ?
            parentState.files[ae_field_name] &&
            parentState.files[ae_field_name].map((f,i) =>
              <li key={i}>{f.name} - {Math.round(f.size/10000)/100} MB</li>)
            :
            filename
          }
          </p>
        </Col>
      </Row>
    </div>
  );
}
export default ImageUploadForm;
