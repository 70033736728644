import Box from '@mui/material/Box'
import { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  groupingListPanelOpen: boolean
}

export const GroupListingLayout = ({ children, groupingListPanelOpen }: IProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        flex: 1,
        gridTemplateColumns: `${
          groupingListPanelOpen ? 'min(25vw, 350px)' : '50px'
        } auto`,
        gridTemplateRows: '100px auto',
        gap: 2,
        gridTemplateAreas: `"sidebar filter filter"
          "${groupingListPanelOpen ? 'sidebar' : 'content'} content content"`,
      }}
    >
      {children}
    </Box>
  )
}
