import Api from './api';

export default class GeographyApi extends Api {

  // Read
  async getZip(zip) {
    const url = `geo/zip/${zip}`
    return this.getResource(url);
  }


}
