import { useState } from 'react'
import FileUploadApi from 'data/api/fileUpload'

const fileUploadApi = new FileUploadApi()

/**
 * @param sessionId
 * @param callback
 * @param errorCallback
 * @returns {{setUploading: (value: (((prevState: boolean) => boolean) | boolean)) => void, onDrop: ((function(*): Promise<*|undefined>)|*), uploadProgress: unknown, setUploadProgress: (value: unknown) => void, uploading: boolean}}
 */
const useDrop2S3 = (sessionId, callback = undefined, errorCallback = undefined) => {
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(null)

  const onDrop = async (files) => {
    setUploading(true)

    try {
      const { signedPutUrl } = await fileUploadApi.getPresignedRenderAssetUrl(
        sessionId,
        files[0].name.replace(/[^0-9a-zA-Z.]/g, '_'),
        files[0].type
      )

      const uploadConfig = {
        onUploadProgress: (progressEvent) => {
          const progressUpload = (progressEvent.loaded * 100) / progressEvent.total
          setUploadProgress(progressUpload)
        },
      }

      await fileUploadApi.uploadFileToS3(signedPutUrl, files[0], uploadConfig)
      const res = signedPutUrl.split('?')[0]

      setUploadProgress(null)
      setUploading(false)
      callback && typeof callback === 'function' && callback.call(this, res, files)

      /*
            setState({
                ...state,
                files: {
                    ...state.files,
                    [ae_field_name]: files,
                },
                [ae_field_name]: res,
                uploading: false,
            });
            setUploadProgress(null);
            setTimeout(() => addFileDurationToTimeChoices({
                file: files[0],
                ae_field_name,
            }), 500);
                         */
      return res
    } catch (err) {
      // setState({ ...state, uploading: false });
      errorCallback && typeof errorCallback === 'function' && errorCallback.call()
    }
  }

  return { onDrop, uploading, uploadProgress, setUploading, setUploadProgress }
}

export { useDrop2S3 }
