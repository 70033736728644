import { useMemo, useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Search from '@mui/icons-material/Search'
import { Typography } from '@mui/material'

interface IProps {
  onBack: () => void
  onSelect: (acctId: number) => void
  user: any
}

export const AccountSelectSubmenu = ({ onBack, onSelect, user }: IProps) => {
  const [search, setSearch] = useState('')

  const accounts = useMemo(
    () =>
      user.profile
        .map((p) => ({
          label: p.account_name,
          value: p.account_id,
        }))
        .filter((a) => a.label.includes(search))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [user, search]
  )

  return (
    <Box>
      <MenuItem onClick={onBack} disableRipple sx={{ justifyContent: 'center' }}>
        <ChevronLeft style={{ left: 0, color: 'GrayText' }} />
        <Typography sx={{ fontWeight: 'bold' }}>Select Account</Typography>
      </MenuItem>
      <TextField
        label=""
        size="small"
        fullWidth
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ marginTop: 1, marginBottom: 1 }}
        variant="outlined"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      {accounts.map((account) => (
        <MenuItem onClick={() => onSelect(account.value)} disableRipple>
          <Typography>{account.label}</Typography>
          <ChevronRight />
        </MenuItem>
      ))}
    </Box>
  )
}
