import { 
  FETCH_SCREENS_START,
  FETCH_SCREENS_SUCCESS,
  FETCH_SCREENS_ERROR,
} from './types';
import ScreensApi from '../api/screens';

const screensApi = new ScreensApi()

/**
 * Redux Action Get Screens
 */
export const getScreens = () => async (dispatch, getState) => {
  const { authUser: { activeAccount } } = getState();
  dispatch({ type: FETCH_SCREENS_START });
  try {
    const response = await screensApi.get(null, activeAccount);
    dispatch({ type: FETCH_SCREENS_SUCCESS, payload: response });
  } catch(error) {
    console.log(error);
    dispatch({ type: FETCH_SCREENS_ERROR, payload: error });
  }
}
