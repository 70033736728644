import Api from './api';

export default class UserApi extends Api {

  getUserInfo() {
    return this.getResource('users');
  }

  getUserPermissions () {
    return this.getResource('users/permissions');
  }

  // this is for explicit user registrations from our site
  async signup(user) {
    try {
      const result = await this.postResource('register', user);
      if (result.error) throw new Error(result.message);
      return result;
    } catch (err) {
      throw err;
    }
  }

  // this API call is made to sync our db with info from OAuth logins
  async syncUser() {
    try {
      const result = await this.postResource('users', {});
      return result;
    } catch (err) {
      throw err;
    }
  }

  async updateUser(data) {
    return this.putResource('users', data);
  }

  async changePassword(data) {
    return this.postResource('users/change-password', data)
  }

}