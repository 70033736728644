import React from 'react';
import PropTypes from 'prop-types';
import { Label, FormText } from 'reactstrap';
import { TitleLabel } from './styledComponents';
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

const TextForm = ({ formSpec, parentState, handleChange, theme }) =>
  <div>
    <AvGroup>
      <TitleLabel
        color={formSpec.scene === 0 ? theme.palette.aesendPurple.main : '#000' }>
        {formSpec.label}
      </TitleLabel>
      <Label for='text'>{formSpec.user_prompt}</Label>
      <AvInput
        type="textarea"
        rows={5}
        name={formSpec.ae_field_name}
        id={formSpec.ae_field_name}
        value={parentState[formSpec.ae_field_name] || ''}
        placeholder={formSpec.placeholder}
        required={formSpec.required}
        maxLength={formSpec.length}
        onChange={handleChange}
      />
      <AvFeedback>This field is required.</AvFeedback>
      <FormText>
        Line breaks will be rendered
        the same as it appears in the text field.
        { formSpec.length && <span> ({(parentState[formSpec.ae_field_name] && 
            parentState[formSpec.ae_field_name].length) || 0 }/{formSpec.length} 
          {' '}characters limit)</span>
        }
      </FormText><br/>
    </AvGroup>
  </div>

TextForm.propTypes = {
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  parentState: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
}

export default TextForm;