import { useEffect, useReducer } from 'react'

import { useLibraryData } from 'hooks/useLibraryData'
import {
  libraryReducer,
  useLibrary,
  emptyFilters,
  Filters,
} from 'contexts/library-context'
import { ViewMode, Grouping } from 'components/Library/types'
import { Asset } from './types'
import { createLibraryGroupingListings } from 'lib/library/utils'

type State = {
  grouping: Grouping
  viewMode: ViewMode
  zoom: number
  tagJoin: 'and' | 'or'
  search: string
  assetPreview?: Asset
  filters: Filters
  sort: string
}

const initialState: State = {
  grouping: Grouping.all,
  viewMode: 'grid',
  zoom: 3,
  tagJoin: 'or',
  search: '',
  assetPreview: undefined,
  filters: emptyFilters,
  sort: '',
}

interface IProps {
  scroll: { scrolledToBottom: boolean; setScrolledToBottom: (v: boolean) => void }
  user: any
  fetchTags?: boolean
}

export const useLocalLibrarySearch = ({ scroll, user, fetchTags = false }: IProps) => {
  const [state, dispatch] = useReducer(libraryReducer, initialState)
  const { state: libraryState } = useLibrary()
  const {
    assets: libAssets,
    fetching,
    tagsList,
  } = useLibraryData({
    scroll,
    libraryState: state,
    user,
  })

  useEffect(() => {
    if (!tagsList || !fetchTags) return
    const tags = createLibraryGroupingListings(tagsList, user.activeAccount)
    dispatch({
      type: 'setTags',
      payload: tags,
    })
  }, [tagsList])

  return { libraryState, libAssets, fetching, dispatch, state }
}
