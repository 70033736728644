/** @jsxImportSource @emotion/react */
import { CSSProperties } from 'react'
import { Tooltip, Box } from '@mui/material'

import SharedWithIcon from 'assets/img/shared.svg'

export const SharedIcon = ({
  sx = {},
  whiteBackground = false,
}: {
  sx?: CSSProperties
  whiteBackground?: boolean
}) => (
  <Tooltip title="Shared">
    {whiteBackground ? (
      <Box
        sx={{
          zIndex: 1,
          ...sx,
          ...(whiteBackground && {
            borderRadius: '50%',
            display: 'flex',
            width: '29px',
            height: '29px',
            background: 'white',
            padding: 0.5,
          }),
        }}
      >
        <img src={SharedWithIcon} />
      </Box>
    ) : (
      <img src={SharedWithIcon} css={{ ...sx }} />
    )}
  </Tooltip>
)
