import React from 'react'
import styled from 'styled-components'

const colorRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

const sizeMap = {
  xs: 24,
  sm: 32,
  md: 48,
  lg: 60,
}

const ColorSwatch = ({
  colors = [],
  style,
  onClick,
  selected,
  size = 'lg',
  separator,
}) =>
  <Container style={style}>
    {colors.map((color, idx) =>
      <>
        <ColorCircle
          style={{
            background: colorRegex.test(color) ? color : 'black',
            cursor: onClick ? 'pointer' : 'default',
            border: (!!color && selected === color)
              ? '3px solid limegreen'
              : '1px solid #444',
            padding: 3,
            ...(size !== 'lg' && { width: sizeMap[size], height: sizeMap[size] }),
          }}
          onClick={() => onClick ? onClick(color) : {}}
        />
        {idx === separator - 1 && <Separator />}
      </>
    )}
  </Container>


const Container = styled.div`
  background: white;
  display: flex;
  padding: 10px 0 10px 10px;
`

const ColorCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
`

const Separator = styled.div`
  border-right: 1px solid #888;
  margin-right: 10px;
`

export default ColorSwatch
