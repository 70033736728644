import { SetTagsPayload, GroupingItem } from 'contexts/library-context'
import { Tag, TagNamespace } from 'store/types'

export const createLibraryGroupingListings = (tagsList: Tag[], activeAccount: number) =>
  tagsList?.reduce<SetTagsPayload>(
    (agg, curr) => {
      const item: GroupingItem = {
        value: curr.id,
        label: curr.name,
        accountId: curr.accountId,
        count: curr.assetCount ?? 0,
        thumbnailUrl: curr.thumbnailUrl,
        representativeAssetId: curr.representativeAssetId,
        representativeAssetType: curr.representativeAssetType,
        createdAt: curr.createdDate,
        shared: ![0, activeAccount].includes(curr.accountId),
      }
      if (curr.namespace === TagNamespace.COLLECTION) {
        agg.collections.push(item)
      } else if (curr.namespace === TagNamespace.CATEGORY) {
        agg.categories.push(item)
      } else if (curr.namespace === TagNamespace.TAG) {
        agg.tags.push({ ...item, label: `#${item.label}` })
      } else if (curr.namespace.toUpperCase() === TagNamespace.TYPE) {
        agg.types.push(item)
      } else if (curr.namespace.toUpperCase() === TagNamespace.ASPECT_RATIO) {
        agg.aspectRatios.push(item)
      }
      return agg
    },
    { collections: [], categories: [], tags: [], types: [], aspectRatios: [] }
  )
