import Api from './api'

type MediaPayload = {
  mediaId: number
  title: string
  description: string
  privacyStatus: 'private' | 'public' | 'unlisted'
}[]

export default class MediaApi extends Api {
  async post(data, accountId) {
    const url = accountId ? `${accountId}/media` : 'media'
    return this.postResource(url, data)
  }

  // Read
  async get(query, accountId) {
    const url = accountId ? `${accountId}/media` : 'media'
    return this.getResource(url, query)
  }

  async getById(id, query) {
    const media = await this.getResource(`media/${id}`, query)
    return media[0]
  }

  // Update
  async update(id, data) {
    return this.patchResource(`media/${id}`, data)
  }

  // Delete
  async delete(id) {
    return this.deleteResource(`media/${id}`)
  }

  async import(data) {
    return this.postResource('media/import', data)
  }

  async move(id, destination) {
    return this.postResource(`media/${id}/move`, { id, destination })
  }

  async alter(id, params) {
    return this.postResource(`media/${id}/alter`, { ...params })
  }

  async clone(id, params, accountId) {
    return this.postResource(`${accountId}/media/${id}/clone`, { ...params })
  }

  addTags(id, tags) {
    return this.postResource(`media/${id}/tags`, { tags })
  }

  getTags(id) {
    return this.getResource(`media/${id}/tags`)
  }

  getPublicUrl(id) {
    return this.postResource(`media/${id}/make-public`)
  }

  getPresignedUrl(id, version) {
    return this.postResource(`media/${id}${version ? `/${version}` : ''}/signed-url`)
  }

  uploadToYoutube(media: MediaPayload, credentials) {
    return this.postResource(`media/youtube`, { media, credentials })
  }

  getSharedMedia(shareKey: string) {
    return this.getResource(`shared-media/${shareKey}`)
  }
}
