/*
 *  https://hackernoon.com/role-based-authorization-in-react-c70bb7641db4
 */

import {usePermitted} from "./usePermitted";

const Authorization = allowedRoles => WrappedComponent => props => {
  const {permitted, profile, loading, user} = usePermitted(allowedRoles);

  if (permitted) {
    return <WrappedComponent user={user} {...props} />
  } else {
    if (!loading && !!profile && profile.length) {
      return (
        <h1 style={{ height: '60vh' }}>
          Your login has not yet been granted permission for this action.
        </h1>
      )
    }
    return <h1>Loading user account profile...</h1>
  }
}

export default Authorization
