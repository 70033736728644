import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Row, Col } from 'reactstrap'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { TextField, Select } from 'formik-mui'
import { Formik, Form, Field } from 'formik'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import * as Yup from 'yup'
import { NotificationManager } from 'react-notifications'

import { RoleIds } from 'constants/UserRoles'
import AdminApi from '../../../../data/api/admin'
import { confirm } from 'components/confirmation'
const adminApi = new AdminApi()

const profileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  lastName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  email: Yup.string().email('This must be a valid email address.').required('Required'),
  companyId: Yup.string().required(),
  role: Yup.string().required(),
})

const EditUser = ({ user }) => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const theme = useTheme()
  const [error, setError] = useState('')
  const [companies, setCompanies] = useState([])
  const [editedUser, setEditedUser] = useState()
  const roleId = RoleIds[get(editedUser, '0.role_name')] || ''
  const userProvider =
    editedUser && editedUser.length && editedUser[0].auth0_id.split('|')[0]
  const isAuth0User = userProvider === 'auth0'

  useEffect(() => {
    adminApi.getCompanies().then(setCompanies)
    adminApi.getUser(userId).then(setEditedUser)
  }, [])

  const removeUserRights = () => {
    confirm('', {
      title: 'Please Confirm',
      description:
        "Are you sure you want to remove this user's access to your " + 'account?',
      noAction: 'Cancel',
      yesAction: 'Continue',
    }).then(() =>
      adminApi
        .revokeAccess(userId)
        .then(() => {
          NotificationManager.success('User Privileges Revoked')
          navigate('/app/admin/users/user-list')
        })
        .catch((err) => NotificationManager.error(err.response.data.data.message))
    )
  }

  return (
    <div className="p-20">
      <Row>
        <Col xs="1" sm="3" />
        <Col xs="10" sm="6">
          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <h2 className="mb-4">Edit User</h2>
            <Formik
              enableReinitialize
              initialValues={{
                email: get(editedUser, '0.user_email_main', ''),
                firstName: get(editedUser, '0.first_name', ''),
                lastName: get(editedUser, '0.last_name', ''),
                role: roleId,
                companyId: get(editedUser, '0.company_id', 0) || 0,
              }}
              validationSchema={profileSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  setError('')
                  await adminApi.editUser(userId, values)
                  setSubmitting(false)
                  navigate('/app/admin/users/user-list')
                } catch (err) {
                  console.log(err.response)
                  setError(err.response.data.data.message)
                }
              }}
            >
              {({ isValid, isSubmitting }) => (
                <Form>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item sm={3} className="text-right">
                      Username/Email
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="email"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Username/Email"
                        disabled={!isAuth0User}
                      />
                      {!isAuth0User && (
                        <div className="text-right font-sm text-muted">
                          Cannot change {userProvider} email
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      First Name
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="firstName"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="First Name"
                      />
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      Last Name
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="lastName"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      Company
                    </Grid>
                    <Grid item sm={9}>
                      <FormControl fullWidth variant="outlined">
                        <Field component={Select} name="companyId" native>
                          <option value={0}>None Selected</option>
                          {companies.map((c) => (
                            <option key={c.name} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      User Role
                    </Grid>
                    <Grid item sm={9}>
                      <FormControl fullWidth variant="outlined">
                        <Field component={Select} name="role" native>
                          <option value="" />
                          <option value={RoleIds.user}>User</option>
                          <option value={RoleIds.customer_admin}>Customer Admin</option>
                          <option
                            value={RoleIds.agency_admin}
                            disabled={!user.isSuperAdmin && !user.isAgencyAdmin}
                          >
                            Agency Admin
                          </option>
                          <option
                            value={RoleIds.super_admin}
                            disabled={!user.isSuperAdmin}
                          >
                            Super Admin
                          </option>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} />
                    <Grid item sm={9} className="d-flex align-items-center">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white m-10"
                        disabled={!isValid || isSubmitting}
                        type="submit"
                      >
                        Update
                      </Button>
                      {isSubmitting && <CircularProgress />}
                      <div className="text-danger font-sm">{error}</div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <h2 className="mb-4">Remove Privileges</h2>
            <Button
              variant="outlined"
              type="submit"
              style={{
                color: theme.palette.aesendPurple.main,
                borderColor: theme.palette.aesendPurple.main,
              }}
              onClick={removeUserRights}
            >
              Remove User Rights
            </Button>
          </Paper>
        </Col>
        <Col xs="1" sm="3" />
      </Row>
    </div>
  )
}

export default EditUser
