import { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import { selectDefaultVideoFile } from './utils'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const itemHash = (a) => {
  return `${a.width}x${a.height}x${a.quality}`
}

const InfoTable = ({ info }) => {
  return (
    <Grid container spacing={3} style={{ width: '100%' }}>
      {info.map((item) => {
        return (
          <Grid
            sx={{ font: 'normal 400 14px/18px DMSans' }}
            key={item.caption}
            className="item-value"
            item
            xs={4}
          >
            <span className="caption">{item.caption}</span>{' '}
            <strong>{item.display}</strong>
          </Grid>
        )
      })}
    </Grid>
  )
}

export const DetailsPane = ({
  item,
  onClose,
  selectCB,
  renderResolutionLabel,
  infoProperties,
}) => {
  const [resolution, setResolution] = useState(undefined)

  let seen = new Set()
  item.video_files = item.video_files
    .sort((a, b) => {
      return a.width < b.width ? -1 : 1
    })
    .filter((a) => a.width)
    .filter((a) => {
      const hash = itemHash(a)
      if (seen.has(hash)) {
        return false
      }

      seen.add(hash)
      return true
    })

  const selectedVideoFile = selectDefaultVideoFile(item.video_files)
  const firstPicture = item.image

  const width = item.width
  const height = item.height
  const ratio = width / height

  const maskValue = (value) => `_${value}`
  const unMaskValue = (value) => value.substr(1)

  if (resolution === undefined) {
    setResolution(maskValue(selectedVideoFile.id))
  }

  const changeResolution = (event) => {
    setResolution(event.target.value)
  }

  const renderResolutionLabelFallback = (videoFile) => {
    return (
      <span>
        {videoFile.width}p {videoFile.quality} {videoFile.file_type}
      </span>
    )
  }

  const _onSelect = () => {
    const unmasked = parseInt(unMaskValue(resolution))
    const resolutionResolved = item.video_files.find((item) => item.id === unmasked)
    selectCB(item, resolutionResolved)
  }

  const renderLabel = renderResolutionLabel || renderResolutionLabelFallback
  const infoInstance = new infoProperties(item)
  const info = infoInstance.getInfo()
  return (
    <Container>
      <div className="details-pane">
        <div id="back-button">
          <Button onClick={onClose}>
            <ArrowBackIosNewIcon sx={{ fontSize: '10px' }} />{' '}
            <span id="close-label">Back</span>
          </Button>
        </div>
        <div id="video">
          <video
            id={selectedVideoFile.id}
            width="100%"
            preload="auto"
            loop={true}
            autoPlay={true}
            controls={true}
            poster={firstPicture}
          >
            <source src={selectedVideoFile.link} type={selectedVideoFile.file_type} />
          </video>
        </div>
        <div id="resolutions">
          <Typography sx={{ font: 'normal 400 14px/18px DMSans' }}>Resolution</Typography>
          <FormControl sx={{ minWidth: 100 }}>
            <Select
              value={resolution}
              onChange={changeResolution}
              autoWidth
              sx={{ font: 'normal 400 14px/18px DMSans' }}
            >
              {item.video_files.map((videoFile) => {
                return (
                  <MenuItem key={videoFile.id} value={`_${videoFile.id}`}>
                    {renderLabel(videoFile)}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
        <div id="info">
          <InfoTable info={info} />
        </div>
        <div id="select-button">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: 10, maxHeight: 34 }}
            onClick={() => {
              _onSelect()
            }}
          >
            Select
          </Button>
        </div>
        <div id="similar-tags" />
        <div id="similar-videos" />
      </div>
    </Container>
  )
}

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 0;

  .MuiFormControlLabel-label {
    font-size: 0.8rem;
  }

  .MuiRadio-root {
    padding: 5px;
  }
`

const Container = styled.div`
  .details-pane {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 20px;
    background: #fff;
    z-index: 3;

    div#back-button {
      padding: 10px 0;
      flex: 0 0 auto;

      span#close-label {
        margin-left: 5px;
      }
    }

    #video {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      flex: 1 1 auto;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    #resolutions {
      margin: 10px 0;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
    }

    #info {
      padding: 16px 0;
      flex: 0 0 auto;

      .item-value {
        div {
          display: inline-block;
        }
        .caption: {
          font: 'normal 400 14px/18px DMSans';
        }
      }
    }
  }
`
