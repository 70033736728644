import { Route, Routes } from 'react-router-dom'

import Templates from './templates'
import Accounts from './accounts'
import Users from './users'
import SoftwareReleases from './softwareReleases'

const AdminApp = () => (
  <Routes>
    <Route path="templates/*" element={<Templates />} />
    <Route path="accounts/*" element={<Accounts />} />
    <Route path="users/*" element={<Users />} />
    <Route path="software-releases/*" element={<SoftwareReleases />} />
  </Routes>
)

export default AdminApp
