import { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  zIndex?: number
  top?: number
  sx?: SxProps
}

export const StickyWrapper = ({ children, zIndex, top, sx }: IProps) => (
  <Box
    sx={{
      paddingTop: 1,
      paddingBottom: 1,
      position: 'sticky',
      top: top ? `${top}px` : 0,
      zIndex: 1000 ?? zIndex,
      background: '#F2F5F8',
      ...sx,
    }}
  >
    {children}
  </Box>
)
