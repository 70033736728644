import useUpdatableState from "@landisdesign/use-updatable-state";
import { Button, Form, Modal } from "react-bootstrap";
import cls from "classnames";
import React from "react";

export const SaveAsModal = ({ visible, onClose, name, text, action }) => {

    const [inputValue, setInputValue] = useUpdatableState(name || "");

    // SQL collation is case-insensitive
    const isOverwrite = name?.trim().toLowerCase() === inputValue?.trim().toLowerCase();

    const _onSaveClick = () => {
        const subject = {
            action,
            newName: inputValue,
            force: isOverwrite
        };

        console.log(subject);

        onClose(subject);
    }

    const _onCloseClick = () => {
        onClose({});
    }

    return (
        <Modal show={ visible } onHide={ _onCloseClick } dialogClassName="save-as-dialog">
            <Modal.Header closeButton>Warning - duplicate name</Modal.Header>
            <Modal.Body>
                <div>{ text }</div>
                <div>
                    <Form.Control as="input" className={ cls({ overwrite: isOverwrite }) } value={ inputValue }
                                  onChange={ (e) => setInputValue(e.target.value) }/>
                </div>
                <div className="buttons">
                    <Button variant={ isOverwrite ? "danger" : "primary" } className={ cls({ overwrite: isOverwrite }) }
                            onClick={ _onSaveClick }>
                        { isOverwrite ? "Duplicate" : "Save" }
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
