import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Button, Checkbox, IconButton } from '@mui/material'
import styled from 'styled-components'
import { bottomGutter } from 'components/MediaContentModal/constants'
import cls from 'classnames'
import { Preloader } from 'components/Preloader'
import checkboxIcon from '../../assets/img/checkbox.svg'
import checkboxSelectedIcon from '../../assets/img/checkbox-selected.svg'

const videoThumbnailWidth = 250

const Video = memo(({ item, onMore, onSelect, onCheckbox, multiSelect = true }) => {
  const hoverData = useRef([])
  const firstVideoFile = item.video_files?.[0]
  const firstPicture = item.image //.toString().substr(0, item.image.toString().indexOf('?'));
  const width = item.width
  const height = item.height
  const ratio = height / width

  const play = (e) => {
    const videoItem = e.currentTarget
    const video = videoItem.querySelector('video')

    const timeout = setTimeout(() => {
      videoItem.classList.add('played', 'playing')
      try {
        video?.play()
      } catch (e) {
        // https://goo.gl/LdLk22
      }
    }, 1000)

    hoverData.current.push({ videoItem, video, timeout })
  }

  const pause = () => {
    hoverData.current.forEach(({ videoItem, video, timeout }) => {
      if (timeout) {
        videoItem.classList.remove('playing')
        clearTimeout(timeout)

        video.pause()
      }
    })
    hoverData.current = []
  }

  const _onMore = () => {
    typeof onMore === 'function' && onMore(item)
  }

  const _onSelect = () => {
    typeof onSelect === 'function' && onSelect(item)
  }

  const _onCheckbox = (event) => {
    typeof onCheckbox === 'function' && onCheckbox(item, event.target.checked)
  }

  return (
    <div
      key={firstVideoFile?.id}
      className="video-item"
      onMouseEnter={play}
      onMouseLeave={pause}
      style={{ paddingTop: `${ratio * 100}%` }}
    >
      {multiSelect && (
        <Checkbox
          sx={{ position: 'absolute', zIndex: '100', top: '10px', left: '10px' }}
          onChange={_onCheckbox}
          icon={<img src={checkboxIcon} />}
          checkedIcon={<img src={checkboxSelectedIcon} />}
        />
      )}
      <img
        className="thumbnail"
        src={firstPicture}
        data-video-id={firstVideoFile?.id}
        alt="Video"
      />
      <video
        id={firstVideoFile?.id}
        muted
        width="100%"
        preload="none"
        loop={true}
        poster={firstPicture}
        onClick={_onMore}
        controls={true}
      >
        <source src={firstVideoFile.link} type={firstVideoFile.file_type} />
      </video>
    </div>
  )
})

const Photo = memo(({ item, onSelect, onCheckbox, multiSelect = true }) => {
  const width = item.width
  const height = item.height
  const ratio = height / width

  const _onSelect = () => {
    const send = { ...item, type: 'photo' }
    typeof onSelect === 'function' && onSelect(send)
  }

  const _onCheckbox = (event) => {
    const send = { ...item, type: 'photo' }
    typeof onCheckbox === 'function' && onCheckbox(send, event.target.checked)
  }

  return (
    <div className="image-item" style={{ paddingTop: `${ratio * 100}%` }}>
      {multiSelect && <Checkbox onChange={_onCheckbox} />}
      <img src={item?.src?.medium} alt={item?.alt} onClick={_onSelect} />
      <div className="overlay">
        <IconButton onClick={_onSelect} size="large">
          <ChevronLeftIcon />
        </IconButton>
        <div className="photographer">
          <a href={item?.photographer_url} target="_blank">
            {item?.photographer}
          </a>
        </div>
      </div>
    </div>
  )
})

export const ResultsGrid = ({
  content = [],
  loadMoreCB,
  showMoreCB,
  selectCB,
  type,
  active,
  hasMore,
  multiple = true,
}) => {
  const blockRef = useRef()
  const [columnsCount, setColumnsCount] = useState(0)
  const [selected, setSelected] = useState(new Map())

  const Component = type === 'videos' ? Video : Photo

  const calculateColumns = () => {
    const availableWidth = blockRef.current?.clientWidth
    if (availableWidth) {
      const columns = Math.min(Math.trunc(availableWidth / videoThumbnailWidth), 3)
      setColumnsCount(columns)
    }
  }

  const loadMore = (entries, observer, loadMoreCB) => {
    loadMoreCB()
  }

  const setupObserver = () => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        let isIntersecting = false
        entries.forEach((_entry) => {
          isIntersecting = isIntersecting || _entry.isIntersecting
        })
        if (isIntersecting) {
          loadMore(entries, observer, loadMoreCB)
        }
      },
      {
        root: blockRef.current,
        rootMargin: '0px',
        threshold: 0.5,
      }
    )

    const loadMoreElements = blockRef.current?.querySelectorAll('.load-more-anchor') || []

    loadMoreElements.forEach((elem) => {
      observer.observe(elem)
    })

    return () => {
      observer.disconnect()
    }
  }

  // const lastScroll = useScrollPosition(blockRef)
  //
  // useEffect(() => {
  //     setScrollPosition(blockRef.current, lastScroll)
  // }, [lastScroll])

  useLayoutEffect(() => {
    calculateColumns()
  }, [columnsCount])

  useEffect(() => {
    const listener = () => {
      calculateColumns()
    }
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [columnsCount])

  useEffect(() => {
    return setupObserver()
  }, [content, blockRef])

  const onCheckbox = (item, checked) => {
    setSelected((current) => {
      const result = new Map(current)

      if (checked) {
        result.set(item.id, item)
      } else {
        result.delete(item.id)
      }

      return result
    })
  }

  const onMultiSelect = () => {
    for (let item of selected.values()) {
      selectCB(item)
    }
  }

  const columns = []
  const keys = []
  const count = columnsCount
  let current = 0

  content.forEach((item) => {
    if (!columns[current]) {
      columns[current] = []
      keys[current] = []
    }
    columns[current].push(
      <Component
        item={item}
        key={item.id}
        onMore={showMoreCB}
        onSelect={selectCB}
        onCheckbox={onCheckbox}
        multiSelect={multiple}
      />
    )
    keys[current].push(item.id)
    current++
    if (current >= count) {
      current = 0
    }
  })

  if (hasMore) {
    const back = 1

    for (let i = 0; i < columns.length; i++) {
      const items = columns[i].length
      const part1 = columns[i].slice(0, items - back)
      const part2 = columns[i].slice(-back)

      const loadMore = (
        <div key={`ref-${i}`} onClick={loadMoreCB} className="load-more-anchor">
          more...
        </div>
      )
      const preloader = (
        <Preloader
          key={`pre-${i}`}
          className="loading-indicator"
          color="#dfc0ce"
          size={12}
          active={active}
        />
      )
      columns[i] = [...part1, loadMore, ...part2, preloader]
    }
  }

  return (
    <>
      <Container ref={blockRef} className={cls({ active: active })}>
        {columns.map((column, index) => {
          return (
            <div className="column" key={index}>
              {column.map((item) => {
                return item
              })}
            </div>
          )
        })}
      </Container>
      <Selected className={cls({ open: selected.size > 0 })}>
        <Button color="primary" variant="contained" onClick={onMultiSelect}>
          Select {selected.size}
        </Button>
      </Selected>
    </>
  )
}

const iconSize = '3rem'

const Container = styled.div`
   {
    display: flex;
    overflow: scroll;
    position: relative;
    height: calc(70vh - ${bottomGutter});

    .loading-indicator {
      padding-bottom: 12px;
    }

    .column {
      margin-right: 1.5rem;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }

    .load-more-anchor {
      visibility: hidden;
      font-size: 1px;
      line-height: 1px;
    }

    .video-item,
    .image-item {
      z-index: 1;
      position: relative;
      min-height: 100px;
      margin: 0 0 14px 0;
      background: #000;
      cursor: pointer;

      .MuiCheckbox-colorSecondary {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        .MuiIconButton-label {
          background: #8f2b5f;
          padding: 0;
          color: white;
          border-radius: 3px;
        }
      }

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .overlay {
        opacity: 0;
        transition: opacity 0.2s;
        position: absolute;
        bottom: 0;
        width: 100%;

        background: rgb(2, 0, 36);
        background: linear-gradient(
          0deg,
          rgba(2, 0, 36, 0.7) 0%,
          rgba(203, 205, 202, 0) 100%
        );

        color: white;

        z-index: 6;
        //padding: 8px;

        display: flex;
        justify-content: space-between;

        button {
          color: white;
          padding: 8px;
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }

    .video-item {
      &.played .thumbnail {
        display: none;
      }

      &.playing {
        .play-icon {
          display: none;
        }
      }

      .thumbnail {
        display: none;
        z-index: 3;
      }

      video {
        object-fit: cover;
        z-index: 2;
      }

      .play-icon {
        position: absolute;
        left: calc(50% - ${iconSize} / 2);
        top: calc(50% - ${iconSize} / 2);
        font-size: ${iconSize};
        color: white;
        z-index: 5;

        &.black {
          z-index: 4;

          left: calc(50% - ${iconSize} / 2 + 1px);
          top: calc(50% - ${iconSize} / 2 + 1px);
          color: #a0a0a0;
        }
      }
    }

    .image-item {
      width: 100%;

      .overlay {
        align-items: center;
      }

      .photographer {
        padding-right: 8px;

        a {
          color: white;
        }
      }
    }
  }
`

const Selected = styled.div`
  &.open {
    bottom: 0;
    display: block;
  }

  display: none;
  transition: bottom 0.3s linear;
  background: white;
  position: absolute;
  bottom: -100px;
  width: calc(100% - 64px);
  text-align: right;
  z-index: 11;
  padding: 12px 0;
`
