import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import LinearProgress from '@mui/material/LinearProgress'
import AssetPreview from './assetPreview'
import { getFileType } from 'util/pathUtils'
import { Button } from 'react-bootstrap'
import './imageUploadForm.scss'
import uuid from 'uuid/v4'
import { useDrop2S3 } from 'hooks/useDrop2S3'
import { SIDE_PANEL_TYPES } from 'util/constants'

/**
 * @param handleChange
 * @param handleRemove
 * @param setSidePanelParams
 * @param previewImage
 * @param fileUrl
 * @param [prompt]
 * @param [{string}] [accept]
 * @param [withTest]
 * @param [showMediaContentButton] {bool}
 * @param [showMediaLibraryButton] {bool}
 * @returns {JSX.Element}
 */
const ImageUploadForm = ({
  handleChange,
  handleRemove,
  setSidePanelParams,
  previewImage,
  fileUrl,
  prompt,
  accept,
  withTest,
  showMediaLibraryButton = false,
  showMediaContentButton = false,
}) => {
  const sessionId = useMemo(() => uuid(), [])

  const { onDrop, uploading, uploadProgress, setUploading, setUploadProgress } =
    useDrop2S3(sessionId, (res) => {
      typeof handleChange === 'function' && handleChange(res)
    })

  const { type } = getFileType(fileUrl)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const fakeIt = () => {
    const steps = 10
    const duration = 2000
    let step = 0
    setUploading(true)
    setUploadProgress(0)

    const i = setInterval(() => {
      setUploadProgress((step / steps) * 100)
      step += 1
    }, duration / steps)

    setTimeout(() => {
      setUploading(false)
      setUploadProgress(undefined)
      clearInterval(i)
      handleChange(
        'https://aesend-resources-dev.s3.us-west-2.amazonaws.com/render_assets/ef8a0dd0-a301-4457-a794-084e5c4b636b/e1a3f60d_ed63_4136_8b3b_21ec15353871.jpeg?nocache=' +
          Math.random()
      )

      setTimeout(() => {
        handleChange(
          'https://aesend-media-library-dev.s3.us-west-2.amazonaws.com/1/logo.png?AWSAccessKeyId=AKIAIWXW5R3C5XAPECBA&Expires=1639562516&Signature=9hf%2BoB1JP6Kt5BY8iWHkxuDotQs%3D'
        )
      }, duration * 3)
    }, duration)
  }

  return (
    <div className="image-upload-form">
      {uploading && <LinearProgress variant="determinate" value={uploadProgress} />}

      <DropzoneContainer
        {...getRootProps({
          className: 'dropzone',
          $isDragActive: isDragActive,
          $uploading: uploading,
        })}
      >
        <input
          {...getInputProps({
            multiple: false,
            accept: accept || ['image/*', 'video/*'],
          })}
        />
        {prompt || 'Drag here or click to select'}
      </DropzoneContainer>
      {withTest && <button onClick={fakeIt}>Do it</button>}
      {previewImage && (
        <AssetPreview
          url={previewImage}
          type={type}
          handleRemove={handleRemove}
          maxWidth={150}
          maxHeight={225}
          loading={uploading}
        />
      )}

      <div className="buttons">
        {showMediaContentButton && (
          <Button
            onClick={() =>
              setSidePanelParams({
                open: true,
                type: SIDE_PANEL_TYPES.mediaContent,
              })
            }
            variant="outline-primary"
          >
            Media Content
          </Button>
        )}
        {showMediaLibraryButton && (
          <Button
            onClick={() =>
              setSidePanelParams({
                open: true,
                type: SIDE_PANEL_TYPES.mediaLibrary,
              })
            }
            variant="outline-primary"
          >
            Media Library
          </Button>
        )}
      </div>
    </div>
  )
}

const DropzoneContainer = styled.div`
  background-color: ${({ $isDragActive }) => ($isDragActive ? '#1b75b550' : '#FFF')};
  margin-top: ${({ $uploading }) => ($uploading ? 0 : '4px')};
`

export default ImageUploadForm
