/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import { GroupingItem } from 'contexts/library-context'
import { MediaListEntry } from 'backend-v2/src/modules/library/dtos/library.dto'
import Image from '@mui/icons-material/Image'
import Divider from '@mui/material/Divider'
import DeleteIcon from 'assets/img/delete.svg'
import Arrow from 'assets/img/GroupingContentArrow.svg'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { ASSET_TYPE_IDS } from 'components/Library/constants'
import TileAsset from 'components/Library/Tile/TileAsset'
import { LoadingButton } from '@mui/lab'

interface IProps {
  newAssets: MediaListEntry<any>[]
  setNewAssets: (prev: MediaListEntry<any>[]) => MediaListEntry<any>[]
  currentGrouping: GroupingItem
  onClose?: () => void
  onSave: (representativeAsset: AssetIdentifier | null) => Promise<void>
  isLoading?: boolean
}

export const GroupingContentPanel = ({
  newAssets,
  setNewAssets,
  currentGrouping,
  onClose,
  onSave,
  isLoading,
}: IProps) => {
  const [representativeAsset, setRepresentativeAsset] = useState<AssetIdentifier | null>({
    assetId: currentGrouping?.representativeAssetId,
    assetType: currentGrouping?.representativeAssetType,
  })

  useEffect(() => {
    if (!representativeAsset?.assetId && newAssets.length) {
      setRepresentativeAsset({
        assetId: newAssets[0].id,
        assetType: ASSET_TYPE_IDS[newAssets[0].kind],
      })
    } else if (
      representativeAsset?.assetId &&
      !newAssets.some((asset) => asset.id === representativeAsset?.assetId)
    ) {
      if (newAssets.length) {
        setRepresentativeAsset({
          assetId: newAssets[0].id,
          assetType: ASSET_TYPE_IDS[newAssets[0].kind],
        })
      } else {
        setRepresentativeAsset(null)
      }
    }
  }, [newAssets])

  const onDelete = (assetId: number, assetKind: string) => () => {
    setNewAssets((prev) =>
      prev.filter((asset) => !(asset.id === assetId && asset.kind === assetKind))
    )
  }

  return (
    <Box sx={{ minWidth: '50px', gridArea: 'sidebar', paddingTop: 2 }}>
      <Card
        elevation={0}
        sx={{
          flex: 1,
          height: 'calc(100vh - 165px)',
          borderRadius: 3,
          padding: 1,
          position: 'relative',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 600 }}>{currentGrouping?.label}</Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
            edge="start"
            sx={{ position: 'absolute', top: 2, right: 2 }}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            height: '100%',
            flex: 1,
            ...(newAssets?.length === 0 && {
              justifyContent: 'center',
              alignItems: 'center',
            }),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {newAssets?.length === 0 ? (
            <img src={Arrow} />
          ) : (
            <List dense>
              {newAssets.map((asset) => (
                <>
                  <ListItem
                    sx={{ paddingY: 1, paddingX: 0 }}
                    secondaryAction={
                      <>
                        <Tooltip title="Click to set cover image">
                          <IconButton
                            edge="end"
                            aria-label="Representative Image"
                            onClick={() =>
                              setRepresentativeAsset({
                                assetId: asset.id,
                                assetType: ASSET_TYPE_IDS[asset.kind],
                              })
                            }
                            sx={{ mr: 0.5 }}
                          >
                            <Image
                              sx={{
                                width: '16px',
                                height: '16px',
                                color:
                                  asset.id === representativeAsset?.assetId
                                    ? 'primary.main'
                                    : '#CCCCCC',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={onDelete(asset.id, asset.kind)}
                        >
                          <img src={DeleteIcon} alt="delete" height="18px" width="18px" />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemAvatar>
                      <Box
                        sx={{
                          width: 75,
                          height: 45,
                          mr: 2,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {asset.assetInfo.thumbnailUrl ? (
                          <img
                            src={asset.assetInfo.thumbnailUrl}
                            alt="thumbnail"
                            css={{ maxWidth: 75, maxHeight: 45 }}
                          />
                        ) : (
                          <TileAsset
                            {...asset}
                            {...(asset.assetInfo.appType && {
                              kind: asset.assetInfo.appType,
                            })}
                            cardSx={{ borderRadius: '5px', height: '45px' }}
                          />
                        )}
                      </Box>
                    </ListItemAvatar>
                    <ListItemText
                      primary={asset.title}
                      sx={{
                        maxWidth: '220px',
                        '& span': {
                          display: 'inline-block',
                          overflowWrap: 'break-word',
                          width: '100%',
                        },
                      }}
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          )}
        </Box>
        <Box>
          <LoadingButton
            variant="contained"
            sx={{ width: '100%' }}
            disableElevation
            onClick={() => onSave(representativeAsset)}
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </Box>
  )
}
