import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import moment from 'moment';

const getTimeLocation = locationCode => {
  if (locationCode === 'tl') {
    return { top: '5vmin', left: '5vmin' }
  }
  if (locationCode === 'tr') {
    return { top: '5vmin', right: '10vmin' }
  }
  if (locationCode === 'bl') {
    return { bottom: '5vmin', left: '5vmin' }
  }
  return { bottom: '5vmin', right: '10vmin' }
}

const TimeDisplay = ({ showCurrentTime, style = {} }) => {
  const [time, setTime] = useState(moment())

  useEffect(() => {
    setTimeout(() => setTime(moment()), 60000)
  }, [time])

  return showCurrentTime
    ? (
      <Wrapper style={{ ...getTimeLocation(showCurrentTime), ...style}}>
        {time.format('LL')} | {time.format('LT')}
      </Wrapper>
    ) : (
      <></>
    )
}

const Wrapper = styled.div`
  position: absolute;
  color: white;
  font-size: 3vmin;
  max-width: 30%;
  white-space: nowrap;
  padding: 3vmin;
  z-index: 10;
`

export default TimeDisplay