import { CardMedia, Grid } from '@mui/material'
import { SxProps } from '@mui/system'
import { useState } from 'react'

import { AssetInfo, getFileTypeFromMediaType } from 'routes/cms/library/types'
import backgroundColors from './backgroundColors'
import FileIcon from './FileIcon'
import { LibraryEntryKind } from 'backend-v2/src/modules/library/dtos/library.dto'
import React from 'react'

type TileAssetProps = {
  assetInfo: AssetInfo
  roundBorders?: boolean
  iconSize?: number
  onClick?: () => any
  height?: number
  kind?: LibraryEntryKind
  objectFit?: string
  cardSx?: SxProps
}
const TileAsset = ({
  assetInfo,
  roundBorders,
  iconSize = 36,
  onClick,
  height,
  kind,
  objectFit,
  cardSx = {},
}: TileAssetProps) => {
  const [loading, setLoading] = useState(false)
  const { mediaType, thumbnailUrl, previewUrl, originalUrl, appType, type } = assetInfo
  const fileType = type || getFileTypeFromMediaType(mediaType)
  if (
    (fileType === 'Image' || fileType === 'Video') &&
    (previewUrl || thumbnailUrl) &&
    previewUrl !== '__processing__' &&
    !loading
  ) {
    return (
      <CardMedia
        component="img"
        onError={() => setLoading(true)}
        image={fileType === 'Image' ? previewUrl ?? originalUrl : thumbnailUrl}
        onClick={onClick}
        sx={{
          height: height ? `${height - 36}px` : '145px',
          borderRadius: roundBorders ? '15px 15px 0 0' : '',
          border: '1px solid #0000001a',
          borderBottom: '0',
          objectFit: objectFit || 'cover',
          ...cardSx,
        }}
      />
    )
  } else {
    let Icon = () => (
      <FileIcon
        height={`${iconSize}px`}
        width={`${iconSize}px`}
        type={kind || appType || getFileTypeFromMediaType(mediaType)}
      />
    )
    let background =
      thumbnailUrl && !loading ? undefined : backgroundColors.get(kind || fileType)
    return (
      <Grid
        container
        onClick={onClick}
        justifyContent={'center'}
        sx={{
          background,
          borderRadius: roundBorders ? '15px 15px 0 0' : '',
          border: '1px solid #0000001a',
          borderBottom: '0',
          width: '100%',
          height: height ? `${height - 36}px` : '145px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          ...cardSx,
        }}
        alignItems={'center'}
      >
        {thumbnailUrl && !loading ? (
          <img
            style={{
              width: '100%',
              height: '100%',
              zIndex: '1',
              borderRadius: roundBorders ? '15px 15px 0 0' : '',
            }}
            src={thumbnailUrl}
          />
        ) : (
          <></>
        )}
        <>
          {previewUrl !== '__processing__' && (
            <div
              style={{
                position: 'absolute',
                zIndex: '2',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon />
            </div>
          )}
        </>
      </Grid>
    )
  }
}
export default TileAsset
