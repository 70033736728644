/**
 * Footer
 */
import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

// intl messages
import IntlMessages from 'util/IntlMessages'

// app config
import AppConfig from 'constants/AppConfig'

const Footer = ({ openContactModal }) => (
  <Box className="rct-footer d-flex justify-content-between align-items-center">
    <ul className="list-inline footer-menus mb-0">
      <li className="list-inline-item">
        <Button
          component="a"
          href="https://vimeo.com/showcase/10399924"
          target="_blank"
          color="headerText"
        >
          <IntlMessages id="sidebar.gettingStarted" />
        </Button>
      </li>
      <li className="list-inline-item">
        <Button
          component="a"
          href="https://aesend.com/faqs/"
          color="headerText"
          target="_blank"
        >
          <IntlMessages id="sidebar.faq(s)" />
        </Button>
      </li>
      <li className="list-inline-item">
        <Button component={Link} to="/app/privacy" color="headerText">
          <IntlMessages id="sidebar.privacy" />
        </Button>
      </li>
      <li className="list-inline-item">
        <Button component={Link} to="/app/terms-conditions" color="headerText">
          <IntlMessages id="sidebar.terms&Conditions" />
        </Button>
      </li>
      <li className="list-inline-item">
        <Button component={Link} onClick={openContactModal} color="headerText">
          <IntlMessages id="sidebar.feedback" />
        </Button>
      </li>
    </ul>
    <h5 className="mb-0">{AppConfig.copyRightText}</h5>
  </Box>
)

export default Footer
