/**
 * App Config File
 */
const AppConfig = {
  appLogo: require('assets/img/aesend-logo.svg'), // App Logo
  termsAndConditionLastUpdated: '2023-05-01T00:00:00', // Terms and Conditions and Privacy Policy last updated date
  brandName: 'aesend', // Brand Name
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: '', // Select sidebar image
  isDarkSidenav: false, // Set true to dark sidebar
  enableThemeOptions: false, // Enable Theme Options
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
  enableUserTour: process.env.NODE_ENV === 'production' ? true : false, // Enable / Disable User Tour
  copyRightText: `aesend © ${new Date().getFullYear()} All Rights Reserved.`, // Copy Right Text
  // light theme colors
  themeColors: {
    primary: '#1b75b5',
    secondary: '#677080',
    success: '#00D014',
    aesendPurple: '#8f2c5f',
    aesendBlue: '#1b75b5',
    danger: '#FF3739',
    warning: '#FFB70F',
    info: '#00D0BD',
    dark: '#464D69',
    default: '#FAFAFA',
    greyLighten: '#A5A7B2',
    grey: '#677080',
    white: '#FFFFFF',
    purple: '#896BD6',
    yellow: '#D46B08',
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: '#424242',
  },
}

export default AppConfig
