import UserRoles from 'constants/UserRoles'

const { USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN } = UserRoles

// horizontal nav links
export default {
  category1: [
    {
      menu_title: 'Library',
      path: '/app/cms/library',
      child_routes: null,
      user_roles: [USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN],
    },
    {
      menu_title: 'Templates',
      path: '/app/templates/templates-list',
      child_routes: null,
      user_roles: [USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN],
    },
    {
      menu_title: 'Renders',
      path: '/app/cms/library/renderings',
      child_routes: null,
      user_roles: [USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN],
    },
    {
      menu_title: 'Screens',
      path: '/app/cms/screens',
      child_routes: null,
      user_roles: [USER, CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN],
    },
  ],
  category2: [
    {
      menu_title: 'Accounts',
      menu_icon: 'ti-briefcase',
      path: '/app/admin/accounts/account-list',
      child_routes: null,
      user_roles: [CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN],
    },
    {
      menu_title: 'Users',
      menu_icon: 'ti-user',
      path: '/app/admin/users/user-list',
      child_routes: null,
      user_roles: [CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN],
    },
    {
      menu_title: 'Templates',
      menu_icon: 'ti-view-list-alt',
      path: '/app/admin/templates/templates-list',
      child_routes: null,
      user_roles: [SUPER_ADMIN],
    },
    {
      menu_title: 'Software Releases',
      menu_icon: 'ti-package',
      path: '/app/admin/software-releases/list',
      child_routes: null,
      user_roles: [SUPER_ADMIN],
    },
  ],
}
