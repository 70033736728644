import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { keyBy } from 'lodash'
import { useGetTagsQuery } from 'store/services/tags'
import { useGetLibraryQuery } from 'store/services/library'
import { MediaListEntry } from 'backend-v2/src/modules/library/dtos/library.dto'
import { State } from 'contexts/library-context'
import { TagNamespace } from 'store/types'
import {
  useCreateAssetTagsMutation,
  useRemoveAssetTagsMutation,
  useUpdateAssetMutation,
} from 'store/services/library'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { useWebSocket } from 'hooks/useWebsocket'
import { Grouping } from 'components/Library/types'
import { AssetUser, AssetUsers } from 'routes/cms/library/types'
import { useLazyGetTagSharesQuery } from 'store/services/share'

interface IProps {
  scroll: { scrolledToBottom: boolean; setScrolledToBottom: (v: boolean) => void }
  libraryState: State
  user: { profile: { id: number }[]; activeAccount: number }
}

const sortFieldMap = {
  'A-Z asc': { sortField: 'name', sortOrder: 'asc' as 'asc' | 'desc' | undefined },
  'A-Z desc': { sortField: 'name', sortOrder: 'desc' as 'asc' | 'desc' | undefined },
  'Date asc': { sortField: 'id', sortOrder: 'asc' as 'asc' | 'desc' | undefined },
  'Date desc': { sortField: 'id', sortOrder: 'desc' as 'asc' | 'desc' | undefined },
}

export const useLibraryData = ({ scroll, libraryState, user }: IProps) => {
  const params = useParams()
  const { scrolledToBottom, setScrolledToBottom } = scroll
  const [page, setPage] = useState(0)
  const [collectiveData, setCollectiveData] = useState<MediaListEntry<any>[]>([])
  const [assetUsers, setAssetUsers] = useState<AssetUsers>({})
  const [createAssetTag] = useCreateAssetTagsMutation()
  const [removeAssetTag] = useRemoveAssetTagsMutation()
  const [rtkUpdateAsset] = useUpdateAssetMutation()
  const accountId = user?.activeAccount
  const inPinned = params['*'] === 'pinned'
  const [getTagShares, _data] = useLazyGetTagSharesQuery()

  const {
    tagJoin,
    search,
    sort,
    currentGrouping: currGrouping,
    grouping,
    filters,
    collections,
  } = libraryState
  const currentGrouping = currGrouping?.value

  const filterTags = useMemo(
    () => [
      ...Object.values(filters).flat(),
      ...(currentGrouping ? [currentGrouping] : []),
      ...(inPinned ? [1] : []),
    ],
    [filters, currentGrouping, params]
  )

  const { data: libraryAssets, isFetching: isFetchingLibrary } = useGetLibraryQuery({
    accountId,
    page: page,
    size: 50,
    tags: currentGrouping ? [currentGrouping] : undefined,
    ...(sortFieldMap[sort] ?? { sortField: 'id', sortOrder: 'desc' }),
    ...(filterTags.length && { tags: filterTags as number[], tagsLogic: tagJoin }),
    ...(search ? { name: search } : {}),
  })

  const { data: pinnedAssets, isFetching: fetchingPinned } = useGetLibraryQuery({
    accountId,
    page: 0,
    size: 1000,
    sortField: 'id',
    sortOrder: 'desc',
    tags: [1],
    tagUserId: user.profile?.[0]?.id,
  })

  const { data: tagsList, refetch: refetchTags } = useGetTagsQuery(
    { accountId },
    {
      pollingInterval: 240000,
      refetchOnFocus: true,
    }
  )

  const [collectionShareWith, setCollectionShareWith] = useState({})

  useEffect(() => {
    if (collections?.length > 0) {
      const newValue = { ...collectionShareWith }
      collections.forEach(async ({ value }) => {
        await getTagShares({ tagId: value, accountId: user.activeAccount })
          .unwrap()
          .then((results) => {
            if (results.length > 0) {
              results.forEach((result) => {
                if (!newValue[value]) {
                  newValue[value] = []
                }
                if (!newValue[value].includes(result.shareWithAccountName)) {
                  newValue[value].push(result.shareWithAccountName)
                }
              })
              setCollectionShareWith({ ...newValue })
            }
          })
      })
    }
  }, [collections])

  const updateAsset = ({ asset, data }: { asset: AssetIdentifier; data: any }) => {
    setCollectiveData((p) =>
      p.map((item) =>
        item.id === asset.assetId
          ? { ...item, ...(data.name ? { title: data.name } : data) }
          : item
      )
    )

    return rtkUpdateAsset({ asset, data })
  }

  const addAssetUsers = (assetUsers: AssetUser[]) => {
    if (!assetUsers?.length) return

    setAssetUsers((p) =>
      assetUsers.reduce((agg, curr) => {
        agg[curr.id] = curr
        return agg
      }, p)
    )
  }

  const getAssetSharedWith = (asset: MediaListEntry<any>) => {
    if (!collections?.length) return []
    const assetCollectionsIds = asset.tags.filter((tag: number) =>
      collections.map(({ value }) => value).includes(tag)
    )
    const sharedWithAccountNames: string[] = []
    assetCollectionsIds.forEach((collectionId: number) => {
      if (collectionShareWith[collectionId]) {
        sharedWithAccountNames.push(
          ...collectionShareWith[collectionId].filter(
            (name: string) => !sharedWithAccountNames.includes(name)
          )
        )
      }
    })
    return sharedWithAccountNames
  }

  const mergeTagNames = (assetsList?: MediaListEntry<any>[]) => {
    if (!assetsList) return []

    const hashTagsLookup = keyBy(
      tagsList?.filter((tag) => tag.namespace === TagNamespace.TAG) ?? [],
      'id'
    )
    return assetsList.map((asset) => {
      const assetCopy = JSON.parse(JSON.stringify(asset))
      if (
        !asset.assetInfo?.thumbnailUrl &&
        asset.assetInfo?.mediaType?.includes('image')
      ) {
        assetCopy.assetInfo.thumbnailUrl = asset.assetInfo?.originalUrl
      }
      if (!asset.assetInfo?.previewUrl) {
        assetCopy.assetInfo.previewUrl = asset.assetInfo?.originalUrl
      }

      return {
        ...assetCopy,
        hashTags: asset.tags.reduce<string[]>((agg, curr) => {
          if (hashTagsLookup[curr]) {
            agg.push({ name: hashTagsLookup[curr]?.name, id: hashTagsLookup[curr]?.id })
          }
          return agg
        }, []),
        isPinned: pinnedAssets?.items?.find((i) => i.id === asset.id),
        isShared: asset.assetInfo?.accountId !== user.activeAccount,
        sharedWith: getAssetSharedWith(asset),
        createdBy: assetUsers[asset.assetInfo?.userId],
      }
    })
  }

  const taggedAssets = useMemo(
    () => mergeTagNames(collectiveData),
    [collectiveData, tagsList, collectionShareWith]
  )

  const taggedPinnedAssets = useMemo(
    () => mergeTagNames(pinnedAssets?.items),
    [pinnedAssets, tagsList, collectionShareWith]
  )

  useEffect(() => {
    setCollectiveData([])
    setPage(0)
  }, [libraryState.filters, tagJoin, search, sort, currentGrouping])

  useEffect(() => {
    if (libraryAssets?.items?.length) {
      setCollectiveData(
        page === 0 ? libraryAssets?.items : [...collectiveData, ...libraryAssets?.items]
      )
      addAssetUsers(libraryAssets?.users || [])
    }
    setScrolledToBottom(false)
  }, [libraryAssets])

  useEffect(() => {
    addAssetUsers(pinnedAssets?.users ?? [])
  }, [pinnedAssets])

  useEffect(() => {
    if (
      scrolledToBottom &&
      grouping === Grouping.all &&
      page < libraryAssets?.totalPages - 1
    ) {
      console.log('Fetching more data...')
      const nextPage = page + 1
      setPage(nextPage)
    }
    // scrolledToBottom is set to false only after the new data is added to collective
    // data to avoid race condition
  }, [scrolledToBottom, grouping])

  const pin = (assets: AssetIdentifier[]) =>
    createAssetTag({
      accountId,
      assets,
      tagIdList: [1],
    })
  const unPin = (assets: AssetIdentifier[]) =>
    removeAssetTag({
      accountId,
      assets,
      tagIdList: [1],
    })

  const addWebsocketItem = (item: any) => {
    if (!['ADD_MEDIA_SUCCESS', 'UPDATE_MEDIA_SUCCESS'].includes(item.type)) return
    const asset = {
      id: item.payload.id,
      kind: 'Media',
      tags: [],
      title: item.payload.data.name,
      createdAt: item.payload.data.created_date,
      assetInfo: {
        accountId: item.payload.data.account_id,
        mediaType: item.payload.data.media_type ?? 'image',
        previewUrl: item.payload.data.preview_url ?? item.payload.data.thumbnail_url,
        thumbnailUrl: item.payload.data.thumbnail_url,
        aspectRatio: item.payload.data.aspect_ratio ?? 1.7777,
      },
    }
    console.log('ADDED ASSET', asset)
    if (item.type === 'ADD_MEDIA_SUCCESS') {
      setCollectiveData((p) => [asset, ...p])
      refetchTags()
    }
    if (item.type === 'UPDATE_MEDIA_SUCCESS') {
      setCollectiveData((p) => p.map((i) => (i.id === asset.id ? asset : i)))
    }
  }

  useWebSocket(addWebsocketItem)
  return {
    assets: taggedAssets,
    pinnedAssets: taggedPinnedAssets,
    fetching: isFetchingLibrary || fetchingPinned,
    tagsList,
    updateAsset,
    pin,
    unPin,
  }
}
