import { useState, useEffect } from 'react'
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Row, Col, Label } from 'reactstrap'
import styled from 'styled-components'

import { types } from '../index'
import Preview from '../common/Preview'
import { getStyleColors } from '../common/utils'
import ColorSwatch from 'routes/cms/scenes/addEditStyle/colorSwatch'

const labelStyle = { color: '#464D69CC', marginBottom: '0px', minWidth: 72 }

export const StocksSceneEditor = ({ name, config, onSave, saving, sceneUrl, styles }) => {
  delete config?.sceneName
  const [formData, setFormData] = useState({
    sceneName: name,
    stocks: config?.stocks?.join(',') || '',
    backgroundColorOverlay: '#00664e',
    tileMode: 'dark',
    ...config,
  })

  const style = styles.find((s) => s.id == formData.styleId)?.config
  const styleColors = getStyleColors(style)

  useEffect(() => {
    if (config) {
      setFormData((prev) => ({ ...prev, ...config }))
    }
  }, [config])

  const handleChange =
    (field, stripSpaces = false) =>
    (e) =>
      setFormData((prev) => ({
        ...prev,
        [field]:
          typeof e?.target?.value === 'string'
            ? stripSpaces
              ? e.target.value.replace(' ', '')
              : e.target.value
            : e,
      }))

  const save = () =>
    onSave({
      name: formData.sceneName,
      type: types.STOCKS,
      config: {
        ...formData,
        stocks: getStocks(),
      },
    })

  const getStocks = () => {
    const stocks = formData.stocks?.split
      ? formData.stocks?.split(',')?.filter((i) => i)
      : formData.stocks?.length
      ? formData.stocks
      : []
    if (stocks?.length) {
      return stocks
    }
    return []
  }

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col sm="7" style={{ display: 'block' }}>
          <h2>{name ? 'Edit' : 'New'} Stocks Scene</h2>
          <br />
          <AvForm>
            <Row>
              <Col sm="8">
                <StyledGroup>
                  <Label for="name" style={labelStyle}>
                    Name
                  </Label>
                  <AvInput
                    type="text"
                    value={formData.sceneName}
                    name="name"
                    id="name"
                    required
                    onChange={handleChange('sceneName')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    Please enter a name for the scene
                  </AvFeedback>
                </StyledGroup>
              </Col>
              <Col sm="4">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Style
                  </Label>
                  <AvInput
                    id="styleId"
                    name="styleId"
                    type="select"
                    onChange={handleChange('styleId')}
                    validate={{ min: { value: 1 } }}
                    value={Number(formData.styleId)}
                  >
                    <option value="">None</option>
                    {styles &&
                      styles.map((style) => (
                        <option value={style.id} key={style.id}>
                          {style.name}
                        </option>
                      ))}
                  </AvInput>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <AvGroup>
                  <Label for="url" style={labelStyle}>
                    Stock Symbols (separated by comma)
                  </Label>
                  <AvInput
                    type="text"
                    value={formData.stocks}
                    name="stocks"
                    id="stocks"
                    required
                    onChange={handleChange('stocks', true)}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    Please enter the Stock Symbols
                  </AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>Background Color Overlay:</span>
              <ColorSwatch
                size="sm"
                colors={['#00664e', '#555', ...styleColors]}
                onClick={handleChange('backgroundColorOverlay')}
                selected={formData.backgroundColorOverlay}
                style={{ background: 'transparent' }}
                separator={styleColors?.length && 2}
              />
            </div>
            <StyledAvRadioGroup
              inline
              required
              name="tileMode"
              label="Stock Tile Style"
              value={formData.tileMode}
              onChange={(e) => {
                e.persist()
                const { name, value } = e.target
                setFormData((prev) => ({
                  ...prev,
                  [name]: value,
                }))
              }}
            >
              <AvRadio label="Dark" value="dark" />
              <AvRadio label="Light" value="light" />
            </StyledAvRadioGroup>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={save}
              style={{ marginTop: '20px' }}
              // disabled={!validatedUrl || !sceneName}
            >
              {saving ? (
                <CircularProgress color="secondary" size={22} />
              ) : (
                <div>SAVE</div>
              )}
            </Button>
          </AvForm>
        </Col>
        <Col sm="5" style={{ paddingTop: 35, minHeight: 500 }}>
          <Preview saving={saving} sceneUrl={sceneUrl} />
        </Col>
      </Row>
    </div>
  )
}

const StyledGroup = styled(AvGroup)`
  margin-bottom: 5px
  align-items: center
`

const StyledAvRadioGroup = styled(AvRadioGroup)`
  legend {
    margin-bottom: 0.2rem;
    font-size: 1rem;
    color: rgba(70, 77, 105, 0.8);
  }

  > div {
    padding-top: 5px !important;
    box-shadow: none !important;
    background: transparent;
  }
`
