import { useEffect, useState } from 'react'

class ProviderConfig {
  constructor() {
    this.config = {}
  }

  async loadConfig(provider) {
    this.config[provider] = await import(`./${provider}`)
  }

  async getConfig(provider) {
    if (!(provider in this.config)) {
      await this.loadConfig(provider)
    }

    return this.config[provider]
  }
}

const providerConfigInstance = new ProviderConfig()

export const useProvider = (provider, onLoad) => {
  const [providerConfig, setProviderConfig] = useState(false)

  useEffect(() => {
    const loadConfig = async () => {
      const { configuration } = await providerConfigInstance.getConfig(provider)
      setProviderConfig(configuration)
      typeof onLoad === 'function' && onLoad(configuration)
    }

    loadConfig()
  }, [provider])

  return [providerConfig, setProviderConfig]
}
