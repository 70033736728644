/**
 ** Session Slider
 **/
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation'
import MediaQuery from 'react-responsive'
import uuid from 'uuid/v4'
import _ from 'lodash'

import RctCollapsibleCard from 'components/RctCollapsibleCard/RctCollapsibleCard'
import TextForm from './textForm'
import ImageUploadForm from './imageUploadForm'
import TimingPickerForm from './timingPickerForm'
import SoundUploadForm from './soundUploadForm'
import ColorPickerForm from './colorPickerForm'
import SwatchPickerForm from './swatchPickerForm'
import MediaSliderForm from './mediaSliderForm'

import FileUploadApi from '../../data/api/fileUpload'
import TemplateApi from '../../data/api/templates'
import FORM_TYPES from '../TemplateEditor/formTypes'
import { addRenderRequest } from '../../data/actions/TemplateActions'

const FormSlider = ({ templateSchema, templateId, user, addRenderRequest }) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    sessionId: null,
    uploading: false,
    submitEnabled: false,
    submitting: false,
    imageIdxs: {},
    imageCaptions: {},
    files: {},
  })
  const [prevRenders, setPrevRenders] = useState([])

  useEffect(() => {
    if (!state.sessionId) {
      setState({ ...state, sessionId: uuid() })
    }
    initializeAEFields()
  }, [])

  useEffect(() => {
    const templateApi = new TemplateApi()
    templateApi
      .getRenderDetails('', `template_id=${templateId}`, user.activeAccount)
      .then(setPrevRenders)
  }, [user.activeAccount])

  if (!templateSchema.Fields) {
    return <></>
  }

  // Templater needs every ae_field_name to be passed, even if empty
  const initializeAEFields = () => {
    const { Fields } = templateSchema
    const data = Fields.reduce((agg, { ae_field_name, ae_field_names }) => {
      if (ae_field_name) {
        agg[ae_field_name] = ''
      } else if (ae_field_names) {
        ae_field_names.forEach((item) => {
          agg[item] = ''
        })
      }
      return agg
    }, {})
    setState({ ...state, ...data })
  }

  const handleChange = (e) => setState({ ...state, [e.target.name]: e.target.value })

  const handleColorChange = (color, name) =>
    setState({ ...state, [name]: color.hex.replace('#', '') })

  const handleFile = (e) => setState({ ...state, [e.target.name]: e.target.value })

  const handleImgClick = (idx, galleryIdx, e) => {
    //set image index in array at index of gallery
    const mediaSlider = templateSchema.Fields.find(
      (item) => item.ae_field_name === galleryIdx
    )
    const newImageIdxs = { ...state.imageIdxs }
    newImageIdxs[galleryIdx] = idx
    setState({
      ...state,
      imageIdxs: newImageIdxs,
      [galleryIdx]: mediaSlider.choices[idx].url,
    })
  }

  const handleSwatchChange = (selectedColor, data) => {
    const swatches = data.choices.map((item) => item.split(',').map((c) => `#${c}`))
    const colors = _.zip(...swatches) // transpose array
    const fieldValues = {}
    colors.forEach((swatch) => {
      if (swatch.includes(selectedColor.hex))
        swatch.forEach((color, idx) => {
          fieldValues[data.ae_field_names[idx]] = color.replace('#', '')
        })
    })
    setState({
      ...state,
      ...fieldValues,
    })
  }

  const loadPrevRender = (e) => {
    const renderId = e.target.value
    const prevRenderData =
      prevRenders &&
      prevRenders.find((render) => render.template_hash === Number(renderId))
    const imageIdxs = {}
    const fieldValues = templateSchema.Fields.reduce((acc, field) => {
      const prevValue = prevRenderData[field.ae_field_name]
      if (field.ae_field_name) {
        acc[field.ae_field_name] = prevValue
        if (field.type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          const idx = field.choices.findIndex(({ url }) => url === prevValue)
          imageIdxs[field.ae_field_name] = idx
        }
      } else if (field.ae_field_names) {
        field.ae_field_names.forEach((ae_field_name) => {
          acc[ae_field_name] = prevRenderData[ae_field_name]
        })
      }
      return acc
    }, {})
    setState({ ...state, ...fieldValues, imageIdx: { ...imageIdxs } })
  }

  const onDrop = async (files, ae_field_name) => {
    setState({ ...state, uploading: true })
    const fileUploadApi = new FileUploadApi()
    try {
      const res = await fileUploadApi.post('render-assets', state.sessionId, files[0])
      setState({
        ...state,
        files: {
          ...state.files,
          [ae_field_name]: files,
        },
        [ae_field_name]: res,
        uploading: false,
      })
    } catch (err) {
      setState({ ...state, uploading: false })
    }
  }

  const onSoundDrop = (soundFiles) => {
    const fileUploadApi = new FileUploadApi()
    fileUploadApi.post('render-assets', state.sessionId, soundFiles[0])
    setState({
      ...state,
      soundFiles,
    })
  }

  const removeFile = (ae_field_name) => {
    setState({
      ...state,
      files: {
        ...state.files,
        [ae_field_name]: null,
      },
      [ae_field_name]: '',
    })
  }

  const allowSubmit = () => {
    const errors = []
    Object.entries(templateSchema.Fields).forEach(([_, item]) => {
      if (item.ae_field_name && item.required) {
        if (item.type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          if (
            typeof state.imageIdxs[item.ae_field_name] !== 'number' &&
            !state[item.ae_field_name]
          ) {
            errors.push(`${item.ae_field_name} not entered.`)
          }
        } else if (!state[item.ae_field_name]) {
          errors.push(`${item.ae_field_name} not entered.`)
        }
      }
      if (item.ae_field_names && item.required) {
        if (!state[item.ae_field_names[0]])
          errors.push(`${item.ae_field_names[0]} not entered.`)
      }
    })
    if (!errors.length) return true
  }

  const onSubmit = (e) => {
    if (!allowSubmit()) return
    const parameters = {}
    Object.entries(templateSchema.Fields).forEach(([_, item]) => {
      const { ae_field_name, ae_field_names, type } = item
      if (ae_field_name) {
        if (type === FORM_TYPES.MEDIA_SLIDER_FORM) {
          const idx = state.imageIdxs[ae_field_name]
          const url = state[ae_field_name] || item.choices[idx].url
          parameters[ae_field_name] = url
        } else {
          parameters[ae_field_name] = state[ae_field_name]
        }
      }
      if (ae_field_names) {
        ae_field_names.forEach(
          (aeFieldName) => (parameters[aeFieldName] = state[aeFieldName])
        )
      }
    })
    const data = {
      user_id: user.profile[0].id,
      account_id: user.activeAccount,
      template_id: templateId,
      status: 'ready',
      parameters: JSON.stringify(parameters),
    }
    setState({ ...state, submitting: true })
    addRenderRequest(data, navigate)
  }

  const renderForm = (template) => {
    // Perform Validation (Joi) on the template schema
    return template.Fields.map((data, key) => (
      <RctCollapsibleCard heading={data.name} key={key} minHeight="600px">
        {data.type === FORM_TYPES.TEXT_FIELD_FORM && (
          <TextForm formSpec={data} parentState={state} handleChange={handleChange} />
        )}
        {data.type === FORM_TYPES.IMAGE_UPLOAD_FORM && (
          <ImageUploadForm
            formSpec={data}
            parentState={state}
            handleChange={onDrop}
            handleRemove={removeFile}
          />
        )}
        {data.type === FORM_TYPES.TIMING_PICKER_FORM && (
          <TimingPickerForm
            formSpec={data}
            parentState={state}
            handleChange={handleChange}
          />
        )}
        {data.type === FORM_TYPES.SOUND_UPLOAD_FORM && (
          <SoundUploadForm
            formSpec={data}
            parentState={state}
            handleChange={onSoundDrop}
          />
        )}
        {data.type === FORM_TYPES.COLOR_PICKER_FORM && (
          <ColorPickerForm
            formSpec={data}
            parentState={state}
            handleChange={(color) => handleColorChange(color, data.ae_field_name)}
          />
        )}
        {data.type === FORM_TYPES.SWATCH_PICKER_FORM && (
          <SwatchPickerForm
            formSpec={data}
            parentState={state}
            handleChange={(color) => handleSwatchChange(color, data)}
          />
        )}
        {data.type === FORM_TYPES.MEDIA_SLIDER_FORM && (
          <MediaSliderForm
            formSpec={data}
            parentState={state}
            handleChange={(e, imgIdx) => handleImgClick(imgIdx, data.ae_field_name, e)}
          />
        )}
      </RctCollapsibleCard>
    ))
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipe: true,
    touchMove: true,
    swipeToSlide: true,
    arrows: true,
  }

  return (
    <div className="session-slider" style={{ paddingBottom: '50px' }}>
      <div>
        Previous Render Values:{' '}
        <AvForm>
          <AvGroup>
            <AvField type="select" name="prevRenders" onChange={loadPrevRender}>
              <option value="" disabled>
                -- select prev values --
              </option>
              {prevRenders &&
                prevRenders.map((render) => (
                  <option key={render.created_date} value={render.template_hash}>
                    {moment(render.created_date).format('MM/DD/YYYY h:mm a')}
                    {render.name && ` - ${render.name}`}
                  </option>
                ))}
            </AvField>
          </AvGroup>
        </AvForm>
      </div>
      <AvForm onSubmit={onSubmit} style={{ paddingTop: '10px' }}>
        <MediaQuery maxDeviceWidth={991}>
          {(matches) => (
            <Slider {...{ ...settings, draggable: matches }}>
              {renderForm(templateSchema)}
            </Slider>
          )}
        </MediaQuery>
        <Tooltip
          placement="bottom"
          title="Please complete all form fields before submitting the 
            template for rendering."
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
              type="submit"
              disabled={!allowSubmit()}
            >
              {state.submitting ? (
                <CircularProgress size={20} />
              ) : (
                'Next Step: Render It!'
              )}
            </Button>
          </span>
        </Tooltip>
      </AvForm>
    </div>
  )
}

export default connect(null, { addRenderRequest })(FormSlider)
