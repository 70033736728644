import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { 
  getFilenameFromUrl,
  getFileExtension,
  getSmallVideoUrl
} from '../../util/pathUtils';

const MediaPreview = ({ url, controls = false, smallPreview }) => {
  const filename = getFilenameFromUrl(url);
  const fileExt = getFileExtension(filename);
  return (
    <ImageContainer>
      { ['png', 'jpg', 'jpeg'].includes(fileExt) &&
        <Image src={url} alt='preview' />
      }
      { ['mp4', 'mov'].includes(fileExt)  &&
        <Video key={url} width='100%' controls={controls}>
          <source
            src={smallPreview 
              ? getSmallVideoUrl(url) + '#t=1'
              : url + '#t=1'
            }
            type='video/mp4'/>
        </Video>
      }
    </ImageContainer>
  );
}

MediaPreview.propTypes = {
  controls: PropTypes.bool,
  url: PropTypes.string.isRequired,
  smallPreview: PropTypes.bool.isRequired,
};

const ImageContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 50.5% 0 0 0;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const Video = styled.video`
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export default MediaPreview;