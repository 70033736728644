import React, {useEffect} from 'react';

export const TouchSceneRenderer = ({id, config}) => {

    useEffect(() => {
        if (config) {
            window.location.href = `${process.env.TOUCH_APP_URL}?appid=${id}`;
        }
    }, [config])

    return null
}
