// sidebar nav links
export default {
	category1: [
		{
			"menu_title": "sidebar.dashboard",
			"menu_icon": "ti-dashboard",
			"child_routes": [
				{
					"menu_title": "sidebar.ecommerce",
					"path": "/app/dashboard/ecommerce",
				}
			]
		}
	]
}
