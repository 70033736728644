import { Route, Routes } from 'react-router-dom'

import Authorization from '../../../Auth/AuthHoc'
import UserRoles from '../../../constants/UserRoles'

// async components
import {
  AsyncAdminTemplatesListComponent,
  AsyncAdminTemplateEditComponent,
} from 'components/AsyncComponent/AsyncComponent'

const { SUPER_ADMIN } = UserRoles

const AdminTemplatesListComponent = Authorization([SUPER_ADMIN])(
  AsyncAdminTemplatesListComponent
)
const AdminTemplateEditComponent = Authorization([SUPER_ADMIN])(
  AsyncAdminTemplateEditComponent
)

const Forms = () => (
  <div className="content-wrapper">
    <Routes>
      <Route path="templates-list" element={<AdminTemplatesListComponent />} />
      <Route path="template-edit/:id" element={<AdminTemplateEditComponent />} />
      <Route path="template-edit" element={<AdminTemplateEditComponent />} />
    </Routes>
  </div>
)

export default Forms
