import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { WebSocketModule } from 'data/api/websocket'
import { AUTH_CONFIG } from '../Auth/auth-variables'

const useWebSocket = (onMessage?: (data: any) => void) => {
  const dispatch = useDispatch()
  const { activeAccount } = useSelector((state) => state.authUser)

  const genericHandler = (dataFromServer) => {
    if (dataFromServer.action === 'dispatch') {
      if (['local', 'development'].includes(process.env.ENVIRONMENT)) {
        console.log('!!!Dispatch', dataFromServer)
      }
      const specialDispatch = () => {
        return (dispatch, getState) => {
          const state = getState()
          const { authUser } = state
          dataFromServer.auth = authUser
          dispatch(dataFromServer)
        }
      }
      dispatch(specialDispatch())
    }
  }

  useEffect(() => {
    const api = WebSocketModule.getInstance({
      url:
        AUTH_CONFIG.websocketUrl ||
        'wss://' + AUTH_CONFIG.apiEndpoint.replace('http://', '').replace('https://', ''),
      activeAccount,
    })

    if (['local', 'development'].includes(process.env.ENVIRONMENT)) {
      console.log('In useEffect for account', activeAccount)
      window.doDispatch = genericHandler
    }

    api.addHandler(genericHandler)
    // add callback provided by component
    api.addHandler(onMessage)

    api.connect()

    return () => {
      api.close()
      api.removeHandler(genericHandler)
      api.removeHandler(onMessage)
      delete window.doDispatch
    }
  }, [activeAccount])
}

export { useWebSocket }
