import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Check from '@mui/icons-material/Check'
import { SORT_OPTIONS } from 'routes/cms/library/types'

interface IProps {
  handleToggle: (value: number | string) => () => void
  checked: string
}

export const SortBy = ({ handleToggle, checked }: IProps) => {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        padding: 0,
      }}
      dense
    >
      {Object.values(SORT_OPTIONS)?.map((option) => {
        const labelId = `checkbox-list-label-${option}`

        return (
          <ListItemButton
            key={option}
            role={undefined}
            onClick={handleToggle(option)}
            dense
            sx={{ padding: '0 8px' }}
          >
            <ListItemText
              id={labelId}
              primary={option}
              sx={{ '& span': { font: 'normal 400 14px/18px DMSans', color: '#000000' } }}
            />
            <ListItemIcon sx={{ minWidth: 20 }}>
              {checked === option && <Check color="primary" />}
            </ListItemIcon>
          </ListItemButton>
        )
      })}
    </List>
  )
}
