import { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { TextField, Select } from 'formik-mui'
import { Formik, Form, Field } from 'formik'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import * as Yup from 'yup'

import { RoleIds } from 'constants/UserRoles'
import AdminApi from '../../../../data/api/admin'
const adminApi = new AdminApi()

const profileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  lastName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  email: Yup.string().email('This must be a valid email address.').required('Required'),
  password: Yup.string().min(6, 'Password is too short').required(),
  companyId: Yup.string().required(),
  role: Yup.string().required(),
})

const AddUser = ({ user }) => {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    adminApi.getCompanies().then(setCompanies)
  }, [])

  return (
    <div className="p-20">
      <Row>
        <Col xs="1" sm="3" />
        <Col xs="10" sm="6">
          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <h2 className="mb-4">Add New User Details</h2>
            <Formik
              enableReinitialize
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                role: '',
                companyId: get(companies, '0.id', '').toString(),
              }}
              validationSchema={profileSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  setError('')
                  await adminApi.addUser(values)
                  setSubmitting(false)
                  navigate('/app/admin/users/user-list')
                } catch (err) {
                  console.log(err)
                  console.log(err.response)
                  setError(err.response.data.data.message)
                }
              }}
            >
              {({ isValid, isSubmitting }) => (
                <Form>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item sm={3} className="text-right">
                      Username/Email
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="email"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Username/Email"
                      />
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      First Name
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="firstName"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="First Name"
                      />
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      Last Name
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="lastName"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      Temporary Password
                    </Grid>
                    <Grid item sm={9}>
                      <Field
                        component={TextField}
                        name="password"
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Temporary Password"
                      />
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      Company
                    </Grid>
                    <Grid item sm={9}>
                      <FormControl fullWidth variant="outlined">
                        <Field component={Select} name="companyId" native>
                          {companies.map((c) => (
                            <option key={c.name} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} className="text-right">
                      User Role
                    </Grid>
                    <Grid item sm={9}>
                      <FormControl fullWidth variant="outlined">
                        <Field component={Select} name="role" native>
                          <option value="" />
                          <option value={RoleIds.user}>User</option>
                          <option value={RoleIds.customer_admin}>Customer Admin</option>
                          {(user.isSuperAdmin || user.isAgencyAdmin) && (
                            <option value={RoleIds.agency_admin}>Agency Admin</option>
                          )}
                          {user.isSuperAdmin && (
                            <option value={RoleIds.super_admin}>Super Admin</option>
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3} />
                    <Grid item sm={9} className="d-flex align-items-center">
                      <Button
                        variant="contained"
                        color="primary"
                        className="text-white m-10"
                        disabled={!isValid || isSubmitting}
                        type="submit"
                      >
                        Add
                      </Button>
                      {isSubmitting && <CircularProgress />}
                      <div className="text-danger font-sm">{error}</div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Col>
        <Col xs="1" sm="3" />
      </Row>
    </div>
  )
}

export default AddUser
