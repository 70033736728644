import { SyntheticEvent, useState } from 'react'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'

import { Menu, Accordion } from './StyledComponents'
import { TypeFilter } from './TypeFilter'
import { CategoryFilter } from './CategoryFilter'
import { CollectionFilter } from './CollectionFilter'
import { TagFilter } from './TagFilter'
import { AspectRatioFilter } from './AspectRatioFilter'
import { SortBy } from './SortBy'
import { Filters, FilterSections, GroupingItemArray } from 'contexts/library-context'

interface IProps {
  anchorEl: null | HTMLElement
  handleClose: () => void
  handleToggle: (type: FilterSections) => (value: number | string) => () => void
  changeTagJoin: (value: 'and' | 'or') => void
  clearFilters: () => void
  categories: GroupingItemArray
  collections: GroupingItemArray
  tags: GroupingItemArray
  types: GroupingItemArray
  aspectRatios: GroupingItemArray
  tagJoin: 'and' | 'or'
  filters: Filters
  sort: string
  skip?: FilterSections[]
}

export const FilterDropdown = ({
  anchorEl,
  handleClose,
  handleToggle,
  changeTagJoin,
  clearFilters,
  categories,
  collections,
  tags,
  types,
  aspectRatios,
  tagJoin,
  filters,
  sort,
  skip = [],
}: IProps) => {
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const filtersPresent = Object.values(filters).some((i) => i?.length)

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const options = [
    { name: 'Sort by', type: FilterSections.SORT, Component: SortBy },
    { name: 'File Type', type: FilterSections.TYPE, Component: TypeFilter, items: types },
    {
      name: 'Collection',
      type: FilterSections.COLLECTION,
      Component: CollectionFilter,
      items: collections,
    },
    {
      name: 'Category',
      type: FilterSections.CATEGORY,
      Component: CategoryFilter,
      items: categories,
    },
    {
      name: 'Aspect Ratio',
      type: FilterSections.ASPECT_RATIO,
      Component: AspectRatioFilter,
      items: aspectRatios,
    },
    // { name: 'Color', type: FilterSections.COLOR, Component: ColorFilter },
    { name: 'All Tags', type: FilterSections.TAG, Component: TagFilter, items: tags },
  ].filter((i) => !skip.includes(i.type))

  return (
    <Menu
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {options.map(({ name, type, Component, items }) => (
        <Accordion.Root
          key={name}
          expanded={expanded === name}
          onChange={handleChange(name)}
        >
          <Accordion.Summary>
            <Typography sx={{ font: 'normal 400 16px/18px DMSans', color: '#000000' }}>
              {name}
            </Typography>
          </Accordion.Summary>
          <Accordion.Details>
            <Component
              handleToggle={handleToggle(type)}
              checked={
                type === FilterSections.SORT ? sort : type && filters ? filters[type] : []
              }
              items={items}
            />
          </Accordion.Details>
        </Accordion.Root>
      ))}
      {filtersPresent && (
        <MenuItem sx={{ mt: 1 }} onClick={clearFilters}>
          <Typography>Clear All</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ position: 'absolute', right: 10 }}
          >
            <Typography>And</Typography>
            <Switch
              defaultChecked={tagJoin === 'or'}
              sx={{ marginLeft: '-4px !important', marginRight: '-8px !important' }}
              onClick={(e) => {
                e.stopPropagation()
                changeTagJoin(e.target.checked ? 'or' : 'and')
              }}
            />
            <Typography>Or</Typography>
          </Stack>
        </MenuItem>
      )}
    </Menu>
  )
}
