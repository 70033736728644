import React, { Fragment } from 'react';
import ImageGallery from 'react-image-gallery';
import { 
  getFilenameFromUrl,
  getFileExtension,
  getSmallVideoUrl
} from '../../util/pathUtils';
import "react-image-gallery/styles/css/image-gallery.css";

const renderItem = (type) => ({ original }) => {
  const filename = getFilenameFromUrl(original);
  const fileExt = getFileExtension(filename);
  return (
    <Fragment>
      { ['png', 'jpg'].includes(fileExt) &&
          <div>
            <img src={original} alt='preview' />
             <div class="image-gallery-thumbnail-label"></div>
          </div>
      }
      { fileExt === 'mp4' &&
        <video width='100%' 
          controls={type !== 'thumbnail'} 
          autoPlay={type !== 'thumbnail'}>
          <source src={getSmallVideoUrl(original) + '#t=0.5'} type='video/mp4'/>
        </video>
      }
    </Fragment>
  )
}

const MediaSliderForm = ({ formSpec, parentState, handleChange }) => 
  <div>
    <ImageGallery id={formSpec.ae_field_name} 
      items={formSpec.choices.map((image, idx) => ({
        original: image.url,
        thumbnail: image.url,
        renderItem: renderItem('main'),
        renderThumbInner: renderItem('thumbnail'),
        thumbnailWidth: Number(image.width),
        thumbnailHeight: Number(image.height),
        thumbnailTitle: image.url,
        thumbnailAlt: image.url, 
        // isSelected: (idx === parentState.imageIdxs[formSpec.ae_field_name])
      }))} 
      showPlayButton={false}
      autoPlay={false}
      showFullscreenButton={false}
      // showNav={false}
      onThumbnailClick={handleChange}
      onSlide={(idx) => handleChange('', idx)}
    />
    <p style={{ position: 'absolute', bottom: 20, left: 50 }}>
      { parentState[formSpec.ae_field_name] && `Selected: ${decodeURIComponent(
          parentState[formSpec.ae_field_name].split('/')[parentState[formSpec.ae_field_name]
            .split('/').length - 1])}` 
      }
      { !parentState[formSpec.ae_field_name] && 
        'No image selected, please click on one of the thumbnails to select.'
      }
    </p>
  </div>
export default MediaSliderForm;