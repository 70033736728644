import Api from './api';

export default class SoftwareReleasesApi extends Api {
  async post(data) {
    const url = 'software-releases'
    return this.postResource(url, data);
  }

  // Read
  async get(query = '') {
    const url = 'software-releases'
    return this.getResource(url, query);
  }
  
  async getById(id, query) {
    const screen = await this.getResource(`software-releases/${id}`, query); 
    return screen[0];
  }

  // Update
  async update(id, data) {
    return this.patchResource(`software-releases/${id}`, data);
  }

  // Delete
  async delete(id) {
    return this.deleteResource(`software-releases/${id}`)
  }

}
