import { combineReducers } from 'redux'
import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import settings from 'data/reducers/settings'
import sidebarReducer from 'data/reducers/SidebarReducer'
import authUserReducer from 'data/reducers/AuthUserReducer'
import templateReducer from 'data/reducers/TemplateReducer'
import mediaReducer from 'data/reducers/MediaReducer'
import stylesReducer from 'data/reducers/StylesReducer'
import screensReducer from 'data/reducers/ScreensReducer'
import { baseApi } from './services/baseApi'

const appReducer = combineReducers({
  settings,
  sidebar: sidebarReducer,
  authUser: authUserReducer,
  templates: templateReducer,
  media: mediaReducer,
  styles: stylesReducer,
  screens: screensReducer,
  [baseApi.reducerPath]: baseApi.reducer,
})

const rootReducer = (state: RootState, action: PayloadAction): RootState => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }

  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
