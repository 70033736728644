/* eslint-disable */
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import * as mediaActions from '../data/actions/MediaActions';
import * as templateActions from '../data/actions/TemplateActions';
import * as authActions from '../data/actions/AuthActions';
import * as stylesActions from '../data/actions/StylesActions';
import * as screensActions from '../data/actions/ScreensActions';

const useActions = actions => deps => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      Object.keys(actions).reduce(
        (acc, key) => ({
          ...acc,
          [key]:
            typeof actions[key] !== 'string'
              ? bindActionCreators(actions[key], dispatch)
              : null,
        }),
        {},
      ),
    deps ? [dispatch, ...deps] : deps,
  );
}

export const useTemplateActions = useActions(templateActions);
export const useMediaActions = useActions(mediaActions);
export const useAuthActions = useActions(authActions);
export const useStylesActions = useActions(stylesActions);
export const useScreensActions = useActions(screensActions);
