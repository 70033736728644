import { baseApi } from './baseApi'
import { AccountId, Account } from 'store/types'

export const tagsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getShareAccounts: builder.query<Account[], AccountId>({
      query: ({ accountId }) => ({
        url: `/v2/${accountId}/shares/accounts-to-share`,
      }),
    }),
    getTagShares: builder.query<Account[], AccountId & { tagId: number }>({
      query: ({ accountId, tagId }) => ({
        url: `/v2/${accountId}/shares/${tagId}`,
      }),
      providesTags: ['SharedAccounts'],
    }),
    shareWithAccount: builder.mutation<
      void,
      AccountId & { targetAccount: number; tagId: number }
    >({
      query: ({ accountId, targetAccount, tagId }) => ({
        method: `PUT`,
        url: `/v2/${accountId}/shares/${targetAccount}/${tagId}`,
      }),
      invalidatesTags: () => ['SharedAccounts'],
    }),
    unshareWithAccount: builder.mutation<
      void,
      AccountId & { targetAccount: number; tagId: number }
    >({
      query: ({ accountId, targetAccount, tagId }) => ({
        method: `DELETE`,
        url: `/v2/${accountId}/shares/tags/${tagId}/account/${targetAccount}`,
      }),
      invalidatesTags: () => ['Tags'],
    }),
    createMediaShareKey: builder.mutation<void, AccountId & { assetId: number }>({
      query: ({ accountId, assetId }) => ({
        method: `POST`,
        url: `/v2/${accountId}/media/${assetId}/share-key`,
      }),
    }),
    revokeMediaShareKey: builder.mutation<void, AccountId & { assetId: number }>({
      query: ({ accountId, assetId }) => ({
        method: `DELETE`,
        url: `/v2/${accountId}/media/${assetId}/share-key`,
      }),
    }),
  }),
})

export const {
  useGetShareAccountsQuery,
  useShareWithAccountMutation,
  useGetTagSharesQuery,
  useLazyGetTagSharesQuery,
  useUnshareWithAccountMutation,
  useCreateMediaShareKeyMutation,
  useRevokeMediaShareKeyMutation,
} = tagsApi
