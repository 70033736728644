import React from 'react'
import { chooseComponent } from './fields'

export const EditorPane = ({ config, onFocus, onBlur, onChange, page, ...rest }) => {
  const sorted = Object.keys(config).sort((a, b) => {
    const _a = config[a].weight || 1000
    const _b = config[b].weight || 1000

    if (_a < _b) {
      return -1
    } else if (_a > _b) {
      return 1
    } else {
      return 0
    }
  })

  return sorted.map((key) => {
    const component = chooseComponent(config, key)
    if (!component) return false

    const [Component, type] = component
    const params = {
      elementKey: key,
      key: `${page}-${key}`,
      config: config[key],
      onFocus: (payload) => {
        onFocus(key, payload)
      },
      onBlur: () => onBlur(key),
      onChange: (value) => onChange(key, value),
      type: type,
      ...rest,
    }
    return (
      Component && (
        <Component {...params} className="control-field">
          {key}
        </Component>
      )
    )
  })
}
