import { WebPageSceneEditor, WebPageSceneRenderer } from './WebPageScene'
import { WeatherSceneEditor, WeatherSceneRenderer } from './WeatherScene'
import { StocksSceneEditor2, StocksSceneRenderer2 } from './StocksScene'
import { NewsSceneEditor, NewsSceneRenderer } from './NewsScene'
import { TouchSceneEditor, TouchSceneRenderer } from './TouchScene'

export const types = {
  WEB_PAGE: 'WEB_PAGE',
  WEATHER: 'WEATHER',
  STOCKS: 'STOCKS',
  STOCKS2: 'STOCKS 2',
  NEWS: 'NEWS',
  TOUCH: 'TOUCH',
}

export const RenderSceneEditor = (props) => {
  const {type} = props;

  const SceneTypeMap = {
    [types.WEB_PAGE]: <WebPageSceneEditor {...props} />,
    [types.WEATHER]: <WeatherSceneEditor {...props} />,
    [types.STOCKS]: <StocksSceneEditor2 {...props} />,
    [types.STOCKS2]: <StocksSceneEditor2 {...props} />,
    [types.NEWS]: <NewsSceneEditor {...props} />,
    [types.TOUCH]: <TouchSceneEditor {...props} />,
  }

  return SceneTypeMap[type] || <div/>
}

export const renderScene = ({ id, type, config }) => {

  const SceneTypeMap = {
    [types.WEB_PAGE]: <WebPageSceneRenderer {...config} />,
    [types.WEATHER]: <WeatherSceneRenderer {...config} />,
    [types.STOCKS]: <StocksSceneRenderer2 {...config} />,
    [types.STOCKS2]: <StocksSceneRenderer2 {...config} />,
    [types.NEWS]: <NewsSceneRenderer {...config} />,
    [types.TOUCH]: <TouchSceneRenderer config={config} id={id} />,
  }

  return SceneTypeMap[type] || <div/>
}
