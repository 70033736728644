import { createTheme } from '@mui/material/styles'
import AppConfig from 'constants/AppConfig'
import appClasses from './classes.ts'

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [`Roboto`, `Arial`, `sans-serif`],
        },
        'html, body, #root': {
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: `100%`,
          font: `inherit`,
          verticalAlign: `baseline`,
          fontFamily: `Roboto, sans-serif`,
        },
        'h1, h2, h3, h4, h5, h6, h7, p, ul': {
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: `100%`,
          font: `inherit`,
          verticalAlign: `baseline`,
        },
        a: {
          textDecoration: `none`,
          color: `#3F51B5`,
        },
        html: {
          height: `100%`,
          fontSize: `100%`,
          WebkitTextSizeAdjust: `100%`,
          fontVariantLigatures: `none`,
          textRendering: `optimizeLegibility`,
          fontSmoothing: `antialiased`,
          textShadow: `rgba(0, 0, 0, 0.01) 0 0 1px`,
        },
        body: {
          height: `100%`,
          lineHeight: 1,
          backgroundColor: `#F2F5F8`,
        },
        table: { borderCollapse: `collapse`, borderSpacing: `0` },
        '#app': {
          display: `flex`,
          flexFlow: `column`,
          height: `100%`,
          '@supports (top: constant(safe-area-inset-top))': {
            '--safe-area-inset-top': `constant(safe-area-inset-top)`,
            '--safe-area-inset-bottom': `constant(safe-area-inset-bottom)`,
            paddingTop: `var(--safe-area-inset-top)`,
          },
          '@supports (top: env(safe-area-inset-top))': {
            '--safe-area-inset-top': `env(safe-area-inset-top)`,
            '--safe-area-inset-bottom': `env(safe-area-inset-bottom)`,
            paddingTop: `var(--safe-area-inset-top)`,
          },
        },
        ...appClasses,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: `none`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: `none`,
        },
      },
    },
  },
  typography: {
    h6: {
      fontSize: `24px !important`,
      lineHeight: `32px !important`,
      fontWeight: `400`,
    },
    h7: {
      fontSize: `20px !important`,
      lineHeight: `30px !important`,
      fontWeight: `400`,
    },
  },
  palette: {
    primary: {
      main: AppConfig.themeColors.primary,
    },
    secondary: {
      main: AppConfig.themeColors.aesendPurple,
    },
    background: {
      default: `#F2F5F8`,
    },
    lightBackground: {
      main: `#FAFAFA`,
    },
    personal: {
      main: `#991E1D`,
    },
    identify: {
      main: `#E65609`,
    },
    documents: {
      main: `#FDA609`,
    },
    medical: {
      main: `#D9000D`,
    },
    plan: {
      main: `#4CAF50`,
    },
    border: {
      main: `#b1b1b1`,
    },
    headerText: {
      main: `rgba(0, 0, 0, 0.87)`,
    },
    secondaryGrey: {
      main: `#00000067`,
    },
    toggleButtonBackground: {
      main: `#F2F5F8`,
    },
    aesendPurple: {
      main: AppConfig.themeColors.aesendPurple,
    },
    aesendBlue: {
      main: AppConfig.themeColors.aesendBlue,
    },
    danger: {
      main: AppConfig.themeColors.danger,
    },
  },
})

export default theme
