import React, { useState } from 'react'
import { Container } from 'reactstrap'
import Modal from 'react-responsive-modal'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import LaptopIcon from '@mui/icons-material/LaptopMac'
import FilmIcon from '@mui/icons-material/Theaters'
import CloudSourcesIcon from 'assets/img/Cloud-Transfer.svg'

import TabPanel from './components/TabPanel'
import MediaUpload from './components/MediaUpload'
import PriorRenders from './components/PriorRenders'
import CloudContentUpload from './components/CloudContentUpload'
import { confirm } from 'components/confirmation'
import { useUser } from '../../Auth/usePermitted'
import MediaLibrarySelect from 'components/MediaUploadModal/components/MediaLibrarySelect'

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
  value: index,
})

const TYPE_MEDIA_LIBRARY = 'media-library'
const TYPE_FILE_UPLOAD = 'files'
const TYPE_CLOUD_SOURCES = 'cloud-sources'
const TYPE_PRIOR_RENDERS = 'prior-renders'

const MediaUploadModal = ({
  open,
  close,
  accountId,
  userId,
  currentFolderId,
  device = true,
  priorRenders = true,
  cloudSources = true,
  mediaLibrary = true,
  multiple = true,
  onSelect,
  mediaLibraryFilter,
  uploadPreSignMethod,
}) => {
  const [activeTab, setActiveTab] = useState(TYPE_FILE_UPLOAD)
  const [showUploadWarning, setShowUploadWarning] = useState(false)
  const [cancelSource, setCancelSource] = useState()
  const user = useUser()

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  if (!open) {
    // when popup is closed the component might not be detached and if it's not, it saves its state - the active tab, so I'm resetting the state to its initial values
    if (activeTab !== TYPE_FILE_UPLOAD) {
      setActiveTab(TYPE_FILE_UPLOAD)
      setShowUploadWarning(false)
      setCancelSource(undefined)
    }
    return null
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        if (showUploadWarning) {
          confirm('', {
            title: 'Please Confirm',
            description:
              "We're still uploading your files to the media library. " +
              'Would you like to cancel the upload or allow the uploader to continue?',
            noAction: 'Continue',
            yesAction: 'Cancel',
          }).then(() => {
            cancelSource.cancel('Operation canceled by user')
            close()
          })
        } else {
          close()
        }
      }}
      closeIcon={
        <svg fill="currentColor" viewBox="3 3 30 30" width={28} height={28}>
          <path
            d="M26 12 L24 10 L18 15.88 L12 10 L10 12 L15.88 18 L10 24 L12 26 L18 20.12 L24 26 L26 24 L20 18 Z"
            stroke="none"
            fill="white"
            strokeLinecap="round"
          />
        </svg>
      }
      styles={{
        closeButton: {
          cursor: 'pointer',
          backgroundColor: '#46c8f0',
          borderRadius: '20px',
          position: 'absolute',
          right: '-12px',
          top: '-12px',
          fontColor: '#FFF',
        },
        modal: {
          overflow: 'visible',
          borderRadius: '10px',
          maxWidth: 'unset',
          padding: 0,
        },
      }}
    >
      <StyledContainer>
        <div style={{ borderBottom: '1px solid #ccc' }}>
          <h2>Add Media</h2>
        </div>
        <div style={{ background: '#F4F7FA', flex: 1, display: 'flex' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={handleChange}
            aria-label="Add media variants"
          >
            {device && (
              <StyledTab
                label="My Device"
                icon={<LaptopIcon />}
                {...a11yProps(TYPE_FILE_UPLOAD)}
              />
            )}
            {priorRenders && (
              <StyledTab
                label="Prior Renders"
                icon={<FilmIcon />}
                {...a11yProps(TYPE_PRIOR_RENDERS)}
              />
            )}
            {mediaLibrary && (
              <StyledTab
                label="Media Library"
                icon={<i className={'ti-gallery media-library-icon'} />}
                {...a11yProps(TYPE_MEDIA_LIBRARY)}
              />
            )}
            {cloudSources && (
              <StyledTab
                label="Cloud Sources"
                icon={<CloudSourcesIconImg src={CloudSourcesIcon} />}
                {...a11yProps(TYPE_CLOUD_SOURCES)}
              />
            )}
          </Tabs>
          <TabPanel value={activeTab} index={TYPE_FILE_UPLOAD}>
            <MediaUpload
              accountId={accountId}
              close={close}
              setShowUploadWarning={setShowUploadWarning}
              setCancelSource={setCancelSource}
              currentFolderId={currentFolderId}
              multiple={multiple}
              onSelect={onSelect ? (item) => onSelect(item, activeTab) : undefined}
              dropZoneFileTypes={'image/*, video/*'}
              uploadPreSignMethod={uploadPreSignMethod}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={TYPE_MEDIA_LIBRARY}>
            <MediaLibrarySelect
              user={user}
              open={true}
              multiple={multiple}
              onSelect={onSelect ? (item) => onSelect(item, activeTab) : undefined}
              mainButton={'Use media'}
              filter={mediaLibraryFilter}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={TYPE_PRIOR_RENDERS}>
            <PriorRenders
              accountId={accountId}
              currentFolderId={currentFolderId}
              close={close}
              multiple={multiple}
              onSelect={onSelect ? (item) => onSelect(item, activeTab) : undefined}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={TYPE_CLOUD_SOURCES}>
            <CloudContentUpload
              accountId={accountId}
              userId={userId}
              currentFolderId={currentFolderId}
              close={close}
              multiple={multiple}
              onSelect={
                onSelect
                  ? ({ assets }) => {
                      onSelect(assets, activeTab)
                    }
                  : undefined
              }
            />
          </TabPanel>
        </div>
      </StyledContainer>
    </Modal>
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  //width: 70vw;
  min-width: 63vw;
  max-width: 63vw;
  height: 70vh;
  padding: 0;

  i.media-library-icon {
    font-size: 25px;
  }

  > div {
    max-width: 70vw;
  }

  .MuiTabs-vertical {
    max-width: 160px;
    min-width: 160px;
    background: #e2e2e2;
  }

  h2 {
    line-height: 2rem;
    padding-left: 20px;
  }
`

const StyledTab = styled(Tab)`
  &&&&&& {
    background: ${({ selected }) => (selected ? '#F4F7FA' : 'transparent')} !important;
    box-shadow: ${({ selected }) =>
      selected && '0px 0px 7px #999, 0px 0px 5px #999'} !important;
  }
`

const CloudSourcesIconImg = styled.img`
  width: 32px;
  height: 32px;
  filter: invert(30%) sepia(8%) saturate(1768%) hue-rotate(191deg) brightness(90%)
    contrast(89%); // yeah, could just replace the value in CSS but found this: https://codepen.io/sosuke/pen/Pjoqqp
`

export default MediaUploadModal
export { TYPE_MEDIA_LIBRARY, TYPE_FILE_UPLOAD, TYPE_CLOUD_SOURCES, TYPE_PRIOR_RENDERS }
