import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import AddIcon from '@mui/icons-material/AddCircle'
import CollapseIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import OutlinedInput from '@mui/material/OutlinedInput'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import InputAdornment from '@mui/material/InputAdornment'
import { capitalize, TextField, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Search from '@mui/icons-material/Search'
import Expand from '@mui/icons-material/ExpandMore'
import Kebab from '@mui/icons-material/MoreVert'

import { Dispatch, GroupingItem } from 'contexts/library-context'
import { TagNamespace } from 'store/types'
import { confirm } from 'components/confirmation'
import deleteLogo from 'assets/img/delete.svg'
import PencilIcon from 'assets/img/pencil.svg'
import { SharedIcon } from './utils/SharedIcon'

interface IProps {
  open: boolean
  dispatch: Dispatch
  type: TagNamespace
  items: GroupingItem[]
  currentItemId?: number | string
  createGroupingTag: (name: string, type: TagNamespace) => Promise<void>
  deleteGroupingTag: (tagId: number) => Promise<void>
  renameGroupingTag: (tagId: number, name: string) => Promise<void>
  unshareTag: (sourceAccount: number, tagId: number) => Promise<void>
}

const { CATEGORY, COLLECTION, TYPE } = TagNamespace

export const GroupingListPanel = ({
  open,
  dispatch,
  type,
  items,
  currentItemId,
  createGroupingTag,
  renameGroupingTag,
  deleteGroupingTag,
  unshareTag,
}: IProps) => {
  const navigate = useNavigate()
  const [typeButton, setTypeButton] = useState<null | HTMLElement>()
  const [kebabButton, setKebabButton] = useState<null | HTMLElement>()
  const [search, setSearch] = useState('')
  const [newOpen, setNewOpen] = useState(false)
  const [newItem, setNewItem] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [menuContextItem, setMenuContextItem] = useState<GroupingItem>()
  const [rename, setRename] = useState<number>()
  const [newName, setNewName] = useState('')
  const typeOpen = Boolean(typeButton)
  const kebabOpen = Boolean(kebabButton)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus()
      inputRef.current?.click()
    }
  }, [inputRef.current, rename])

  const handleClickType = (event: React.MouseEvent<HTMLButtonElement>) =>
    setTypeButton(event.currentTarget)
  const handleCloseType = () => setTypeButton(null)

  const switchTo = (type: TagNamespace) => () => {
    navigate(`/app/cms/library/${type}`)
    handleCloseType()
  }

  const handleClickKebab = (event: React.MouseEvent<HTMLButtonElement>) =>
    setKebabButton(event.currentTarget)
  const handleCloseKebab = () => setKebabButton(null)

  const handlePanelOpen = () =>
    dispatch({ type: 'setGroupingListPanelOpen', payload: !open })

  const handleChange = (id: number) => navigate(`/app/cms/library/${type}/${id}`)

  const handleCancelClick = () => {
    setNewOpen(false)
    setNewItem('')
    setError('')
  }
  return (
    <Box
      sx={{
        minWidth: '50px',
        gridArea: 'sidebar',
        paddingTop: '20px',
      }}
    >
      <Card
        elevation={0}
        sx={{
          flex: 1,
          width: open ? '25vw' : '50px',
          height: open ? 'calc(100vh - 215px)' : '80px',
          maxWidth: 'min(25vw, 350px)',
          borderRadius: 3,
          padding: 2,
          ...(!open && {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }),
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!open ? (
            <IconButton
              color="inherit"
              onClick={handlePanelOpen}
              edge="start"
              sx={{ ml: 0 }}
            >
              <MenuIcon color="primary" />
            </IconButton>
          ) : (
            <>
              <Box sx={{ flex: 1 }}>
                <Button
                  onClick={handleClickType}
                  sx={{ color: 'black', fontWeight: '600 !important', fontSize: '1rem' }}
                >
                  {type === CATEGORY && 'Categories'}
                  {type === COLLECTION && 'Collections'}
                  {type === TYPE && 'Types'}
                  <Expand />
                </Button>
                <Menu
                  anchorEl={typeButton}
                  open={typeOpen}
                  onClose={handleCloseType}
                  sx={{ '& .MuiPaper-root': { borderRadius: 2 } }}
                >
                  <MenuItem onClick={switchTo(TagNamespace.CATEGORY)}>Category</MenuItem>
                  <MenuItem onClick={switchTo(TagNamespace.COLLECTION)}>
                    Collections
                  </MenuItem>
                  <MenuItem onClick={switchTo(TagNamespace.TYPE)}>Types</MenuItem>
                </Menu>
                {[CATEGORY, COLLECTION].includes(type) && (
                  <Button sx={{ ml: 2 }} onClick={() => setNewOpen(true)}>
                    <AddIcon sx={{ mr: 1 }} />{' '}
                    <Typography sx={{ font: 'normal normal 700 14px/18px DMSans' }}>
                      New {type.toLocaleLowerCase()}
                    </Typography>
                  </Button>
                )}
              </Box>
              <IconButton color="inherit" onClick={handlePanelOpen} edge="start">
                <CollapseIcon />
              </IconButton>
            </>
          )}
        </Box>
        {open && (
          <>
            <OutlinedInput
              fullWidth
              value={search}
              notched={false}
              margin="dense"
              placeholder={`Search ${capitalize(type.toLowerCase())}`}
              onChange={(e) => setSearch(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              sx={{
                mt: 1,
                background: 'white',
                '& input': { paddingTop: 1, paddingBottom: 1 },
              }}
            />
            {newOpen && (
              <>
                <Box display="flex" alignItems="flex-start" mt={2}>
                  <TextField
                    autoFocus
                    fullWidth
                    value={newItem}
                    margin="dense"
                    placeholder={`${capitalize(type.toLowerCase())} Name...`}
                    onChange={(e) => setNewItem(e.target.value.slice(0, 45))}
                    sx={{
                      mt: 0,
                      background: 'white',
                      '& input': { paddingTop: 1, paddingBottom: 1 },
                    }}
                    helperText={`${newItem.length}/45`}
                  />
                  <LoadingButton
                    variant="contained"
                    disableElevation
                    disabled={!newItem?.length}
                    loading={loading}
                    sx={{ ml: 1 }}
                    onClick={async () => {
                      setLoading(true)
                      setError('')
                      try {
                        const result = await createGroupingTag(newItem, type)
                        setNewItem('')
                        setNewOpen(false)
                        const newItemId = result?.find((i) => i.name === newItem)?.id

                        if (newItemId) {
                          navigate(`/app/cms/library/${type}/${newItemId}`)
                        }
                      } catch (err) {
                        setError(
                          `There was a problem creating the ${type.toLowerCase()}.  Please ensure you entered a unique name`
                        )
                      } finally {
                        setLoading(false)
                      }
                    }}
                  >
                    Create
                  </LoadingButton>
                  <Button onClick={handleCancelClick}>Cancel</Button>
                </Box>
                {error && (
                  <Typography
                    sx={{
                      font: 'normal 400 14px/18px DMSans',
                      color: 'error.main',
                    }}
                  >
                    {error}
                  </Typography>
                )}
              </>
            )}
            <List
              sx={{
                maxHeight: 'calc(100vh - 280px)',
                overflowY: 'auto',
                paddingBottom: 12,
              }}
            >
              {items
                ?.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
                ?.filter((item) =>
                  item.label.toLowerCase().includes(search?.toLowerCase())
                )
                ?.map((item) => (
                  <ListItem
                    key={item.value}
                    disablePadding
                    sx={{
                      '.Mui-selected': { color: 'primary.main' },
                      '& :hover': {
                        '.more': {
                          visibility: 'visible',
                        },
                      },
                    }}
                  >
                    {rename === item.value ? (
                      <Box display={'flex'} alignItems={'center'} sx={{ flex: 1 }}>
                        <OutlinedInput
                          autoFocus
                          ref={inputRef}
                          fullWidth
                          value={newName}
                          notched={false}
                          margin="dense"
                          placeholder={`New name...`}
                          onChange={(e) => setNewName(e.target.value)}
                          onKeyPress={async (e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              setLoading(true)
                              await renameGroupingTag(item.value, newName)
                              setRename(undefined)
                              setLoading(false)
                            }
                          }}
                          sx={{
                            background: 'white',
                            '& input': { paddingTop: 1, paddingBottom: 1 },
                          }}
                        />
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <IconButton
                            onClick={async () => {
                              setLoading(true)
                              await renameGroupingTag(item.value, newName)
                              setRename(undefined)
                              setLoading(false)
                            }}
                            disableRipple
                            sx={{
                              opacity:
                                newName === item.label || !newName?.length ? '.3' : '1',
                            }}
                            disabled={newName === item.label || !newName?.length}
                          >
                            <DoneIcon htmlColor="green" />
                          </IconButton>
                        )}
                        <IconButton
                          disableRipple
                          sx={{ padding: '1px' }}
                          onClick={() => setRename(undefined)}
                        >
                          <CloseIcon htmlColor="red" />
                        </IconButton>{' '}
                      </Box>
                    ) : (
                      <ListItemButton
                        selected={item.value === currentItemId}
                        onClick={() => handleChange(item.value)}
                        sx={{ borderRadius: 1, justifyContent: 'space-between', pr: 1 }}
                      >
                        <ListItemText>
                          {item.label} {item.shared ? <SharedIcon /> : <></>}
                        </ListItemText>
                        <ListItemText sx={{ flex: 'unset' }}>{item.count}</ListItemText>
                        {[CATEGORY, COLLECTION].includes(type) && (
                          <IconButton
                            sx={{ ml: 1, p: '1px', visibility: 'hidden' }}
                            className="more"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleClickKebab(e)
                              setMenuContextItem(item)
                            }}
                          >
                            <Kebab color="primary" />
                          </IconButton>
                        )}
                      </ListItemButton>
                    )}
                  </ListItem>
                ))}
            </List>
          </>
        )}
        <Menu
          anchorEl={kebabButton}
          open={kebabOpen}
          onClose={handleCloseKebab}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2,
            },
          }}
        >
          {!menuContextItem?.shared ? (
            [
              <MenuItem
                key="rename"
                onClick={(e) => {
                  handleCloseKebab()
                  e.stopPropagation()
                  console.log(menuContextItem)
                  setRename(menuContextItem!.value)
                  setNewName(menuContextItem!.label)
                }}
              >
                <Box sx={{ width: 30 }}>
                  <img style={{ padding: '2px 10px 2px 2px' }} src={PencilIcon} />
                </Box>
                Rename
              </MenuItem>,
              <MenuItem
                key="delete"
                onClick={async (e) => {
                  handleCloseKebab()
                  e.stopPropagation()
                  try {
                    await confirm(type.toLocaleLowerCase())
                    deleteGroupingTag(menuContextItem!.value)
                  } catch {}
                }}
              >
                <Box sx={{ width: 30 }}>
                  <img style={{ padding: '2px 10px 2px 2px' }} src={deleteLogo} />
                </Box>
                Delete
              </MenuItem>,
            ]
          ) : (
            <MenuItem
              onClick={async (e) => {
                handleCloseKebab()
                e.stopPropagation()
                try {
                  await confirm(type.toLocaleLowerCase())
                  unshareTag(menuContextItem.accountId, menuContextItem.value)
                } catch {}
              }}
            >
              <Box sx={{ width: 30 }}>
                <img style={{ padding: '2px 10px 2px 2px' }} src={deleteLogo} />
              </Box>
              Unshare
            </MenuItem>
          )}
        </Menu>
      </Card>
    </Box>
  )
}
