import React, { useState } from 'react';
import { AvForm, AvGroup, AvField, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const ContactForm = ({ onSubmit, loading, user }) => {
  const [state, setState] = useState({
    topic: '',
    name: '',
		email: '',
    title: '',
    request: '',
    errors: null,
  })
  const { topic, name, email, title, request } = state

  return (
    <AvForm onSubmit={onSubmit}>
      <AvField type="select" name="topic" label="Topic">
        <option>Login and Account issue</option>
        <option>Template or rendering issue</option>
        <option>UI/UX issue</option>
        <option>Media Library issue</option>
        <option>Signage or Player issue</option>
        <option>Sales</option>
        <option>Feature Request</option>
        <option>General inquiry</option>

      </AvField>
      {!user && (
        <>
          <AvGroup className="has-wrapper">
            <AvInput
              type="text"
              value={name}
              name="name"
              id="name"
              className="has-input input-lg"
              placeholder="Enter your name"
              required
              onChange={(e) => setState({ ...state, name: e.target.value })}
            />
            <span className="has-icon"><i className="ti-user"></i></span>
            <AvFeedback style={{ textAlign: 'left' }}>
              Please make sure to enter your name
            </AvFeedback>
          </AvGroup>
          <AvGroup className="has-wrapper">
            <AvInput
              type="email"
              value={email}
              id="email"
              name="email"
              className="has-input input-lg"
              placeholder="Enter email address"
              required
              validate={{email: true}}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
            <AvFeedback style={{ textAlign: 'left' }}>Invalid email</AvFeedback>
            <span className="has-icon"><i className="ti-email"></i></span>
          </AvGroup>
        </>
      )}
      <AvGroup className="has-wrapper">
        <AvInput
          type="text"
          value={title}
          id="title"
          name="title"
          className="has-input input-lg"
          placeholder="Enter message title"
          required
          onChange={(e) => setState({ ...state, title: e.target.value })}
        />
        <AvFeedback style={{ textAlign: 'left' }}>
          Please make sure to enter a title
        </AvFeedback>
      </AvGroup>
      
      <AvGroup className="has-wrapper">
        <AvInput
          value={request}
          type="textarea"
          rows="10"
          id="request"
          name="request"
          className="has-input input-lg"
          placeholder="Request..."
          required
          style={{ height: 150 }}
          onChange={(e) => setState({ ...state, request: e.target.value })}
        />
        <AvFeedback style={{ textAlign: 'left' }}>Required</AvFeedback>
      </AvGroup>
      <AvGroup className="mb-15">
        <Button
          className="text-white btn-block w-100"
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          type="submit"
        >
            {loading
              ? <CircularProgress size={16} />
              : <div>Send</div>
            }
        </Button>
      </AvGroup>
    </AvForm>
  )
}

export default ContactForm
