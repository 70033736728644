import React from 'react'
import Card from '@mui/material/Card'
import CardContent from './CustomCardContent'
import {
  Checkbox,
  Chip,
  Collapse,
  Grid,
  IconButton,
  SxProps,
  Typography,
  Button,
  TextField,
  CircularProgress,
  styled,
  Tooltip,
} from '@mui/material'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import { AssetInfo } from 'routes/cms/library/types'
import TileAsset from './TileAsset'
import { useState } from 'react'
import ContextMenu, { ContextMenuOption } from './ContextMenu'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { useLibrary } from 'contexts/library-context'
import pinIcon from 'assets/img/pin.svg'
import pinFilledIcon from 'assets/img/pin-filled.svg'
import { AssetType } from 'store/types'
import { SharedIcon } from '../utils/SharedIcon'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DeleteButton } from './DeleteButton'

const ProcessingIcon = ({ className }: { className?: string }) => {
  return <AutorenewIcon className={className} />
}

const StyledProcessingIcon = styled(ProcessingIcon)(({ theme }) => ({
  animation: `nfLoaderSpin infinite 1000ms linear`,
  transformBox: 'fill-box',

  '@keyframes nfLoaderSpin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}))

export enum SelectModes {
  SINGLE_WITH_REMOVE = 'SINGLE_WITH_REMOVE',
  MULTIPLE = 'MULTIPLE',
}

type TileProps = {
  title: string
  selectMode?: SelectModes
  selectExclusionTypes?: AssetType[]
  isPicked?: boolean
  isShared?: boolean
  isProcessing?: boolean
  assetInfo: AssetInfo
  checkbox?: {
    checked: boolean
    onChange: (checked: boolean) => any
  }
  caption?: string
  hashTags?: string[]
  sx?: SxProps
  pin?: {
    pinned: boolean
    onClick: () => void
  }
  contextMenuProps?: {
    options: ContextMenuOption[]
  }
  onClick: (e?: React.MouseEvent) => any
  height: number
  id: number
  kind: string
  assetType: number
  onTitleSaveClick?: (newName: string) => void
  onTileCancelClick?: () => void
  createdAt: string
}

const Tile = ({
  selectMode,
  selectExclusionTypes,
  isPicked,
  isShared,
  isProcessing,
  title,
  caption,
  hashTags,
  assetInfo,
  checkbox,
  pin,
  contextMenuProps,
  onClick,
  sx,
  height,
  id,
  kind,
  assetType,
  onTitleSaveClick,
  onTileCancelClick,
  createdAt,
}: TileProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [renameValue, setRenameValue] = useState(title)
  const {
    state: { editingTileTitle, loadingTiles },
  } = useLibrary()

  const { assetId, assetType: assetTypeId } = editingTileTitle || {}
  const isLoading = loadingTiles?.some(
    (loadingTile) => loadingTile?.assetId === id && loadingTile?.assetType === assetType
  )
  const isEditingTitle = assetId === id && assetTypeId === assetType
  const isCardBlocked = isLoading || isProcessing || isEditingTitle
  const TEN_MINUTES_AGO = Date.now() - 600000;
  const isProcessingExpired = isProcessing && new Date(createdAt).getTime() < TEN_MINUTES_AGO
  
  const onDeleteClick = () => {
    const deleteFn = contextMenuProps?.options.find(opt => opt.label === 'Delete')
    deleteFn?.onClick()
  }
  return (
    <Card
      sx={{
        cursor: isCardBlocked ? 'default' : 'pointer',
        maxHeight: !isProcessing ? '180px' : undefined,
        overflow: 'visible',
        boxShadow: 0,
        position: 'relative',
        background: 'transparent',
        zIndex: isEditingTitle ? 991 : isOpen ? 990 : 1,
        width: '100%',
        '&:hover': {
          '& .pin,& .checkbox': {
            visibility: 'visible',
          },
          '& .add-asset': {
            width: '90px !important',
          },
          '& .add-asset p': {
            visibility: 'visible !important',
          },
        },
        ...sx,
      }}
      onMouseOver={() => {
        setIsOpen(true)
      }}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isLoading ||
        (isProcessing && (
          <Grid
            zIndex={100}
            justifyContent="center"
            alignItems="center"
            container
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              background: 'rgba(255,255,255,.7)',
              borderRadius: '15px',
            }}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ marginRight: '10px' }} />
            ) : (
              isProcessingExpired ? <Tooltip title="Failed to save."><WarningAmberIcon htmlColor='red' fontSize='large'/></Tooltip> : <StyledProcessingIcon />
            )}
          </Grid>
        ))}
      {checkbox && !isCardBlocked && (
        <Checkbox
          sx={(theme) => ({
            position: 'absolute',
            color: '#FFFFFF',
            '&.Mui-checked': {
              color: '#FFFFFF',
            },
            left: '10px',
            top: '10px',
            height: '24px',
            width: '24px',
            visibility: 'visible',
            [theme.breakpoints.up('lg')]: {
              visibility: 'hidden',
            },
            '&.checked': {
              visibility: 'visible',
            },
            zIndex: '50',
          })}
          checked={checkbox.checked}
          onChange={(event) => {
            checkbox.onChange(event.target.checked)
          }}
          color="default"
          className={`checkbox ${checkbox.checked ? 'checked' : ''}`}
        />
      )}
      {selectMode && !selectExclusionTypes?.includes(assetType) && (
        <Button
          className="add-asset"
          variant="contained"
          sx={{
            color: 'primary.main',
            position: 'absolute',
            left: '15px',
            top: '15px',
            height: '24px',
            visibility: 'visible',
            padding: '15px',
            background: 'white',
            border: '1px solid #ccc',
            borderRadius: '50px',
            transition: 'width 0.1s ease-out',
            width: '30px !important',
            minWidth: 'unset !important',
            zIndex: 1,
            '&:hover': {
              background: 'white',
              color: 'primary.main',
            },
          }}
          onClick={onClick}
          disableRipple
          disableElevation
        >
          {selectMode === SelectModes.SINGLE_WITH_REMOVE && isPicked ? (
            <Remove sx={{}} color="primary" />
          ) : (
            <Add sx={{}} color="primary" />
          )}
          <Typography
            className="add-asset"
            sx={{ width: 0, transition: 'width 0.1s', visibility: 'hidden' }}
          >
            {selectMode === SelectModes.SINGLE_WITH_REMOVE && isPicked ? 'Remove' : 'Add'}
          </Typography>
        </Button>
      )}
      {pin && !isCardBlocked && (
        <IconButton
          className={`pin`}
          sx={(theme) => ({
            position: 'absolute',
            right: '10px',
            top: '10px',
            height: '24px',
            width: '24px',
            zIndex: '50',
            visibility: 'visible',
            [theme.breakpoints.up('lg')]: {
              visibility: 'hidden',
            },
            padding: '15px',
            background: 'white',
          })}
          onClick={() => pin?.onClick()}
          disableRipple
        >
          <img src={pin?.pinned ? pinFilledIcon : pinIcon} />
        </IconButton>
      )}
      <TileAsset
        height={height}
        onClick={!isCardBlocked ? onClick : undefined}
        assetInfo={{ ...assetInfo }}
        roundBorders
        kind={kind === 'Playlist' && kind}
        iconSize={50}
      />
      <Collapse
        sx={{
          position: 'relative',
          background: 'white',
          borderRadius: '0 0 15px 15px',
          border: '1px solid #0000001a',
          borderTop: 'unset',
          zIndex: '100',
        }}
        orientation="vertical"
        in={isLoading || isProcessing ? false : isEditingTitle || isOpen}
        collapsedSize={36}
      >
        <CardContent onClick={!isCardBlocked ? onClick : undefined}>
          {isProcessingExpired && !isLoading ? 
            <DeleteButton onClick={onDeleteClick}/> 
              : contextMenuProps && !isCardBlocked && (
            <ContextMenu 
              sx={{
                  zIndex: 100,
                  position: 'absolute',
                  right: '0',
                  top: '2px',
                }}
              options={contextMenuProps.options}
            />
          )}
          {isEditingTitle ? (
            <Grid container alignItems="center" wrap="nowrap" sx={{ width: '100%' }}>
              <TextField
                variant="standard"
                value={renameValue}
                onChange={(e) => setRenameValue(e.target.value)}
                size="medium"
                fullWidth
                autoFocus
                placeholder="New Name"
                sx={{ minWidth: '70%' }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    onTitleSaveClick && onTitleSaveClick(renameValue)
                  }
                }}
              />
              {isLoading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  onClick={() => onTitleSaveClick && onTitleSaveClick(renameValue)}
                  disableRipple
                  sx={{
                    padding: '1px',
                    opacity: title === renameValue ? '.3' : '1',
                  }}
                  disabled={title === renameValue}
                >
                  <DoneIcon htmlColor="green" />
                </IconButton>
              )}
              <IconButton
                disableRipple
                sx={{ padding: '1px' }}
                onClick={() => {
                  onTileCancelClick && onTileCancelClick()
                  setRenameValue(title)
                }}
              >
                <CloseIcon htmlColor="red" />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              columnGap=".2rem"
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-start"
              paddingRight={isShared || isLoading ? `2rem` : '0'}
              marginBottom="6px"
            >
              <Typography
                className={'ellipsis'}
                sx={{
                  maxWidth: '90%',
                  fontFamily: 'DMSans',
                  fontSize: '0.85rem',
                  whiteSpace: 'nowrap',
                  opacity: isLoading || isProcessing ? '.5' : '1',
                }}
                variant="h5"
                component="div"
              >
                {renameValue}
              </Typography>
              {isLoading ? <CircularProgress size={12} /> : <></>}
              {isShared ? <SharedIcon /> : <></>}
            </Grid>
          )}
          {caption && (
            <Typography
              sx={{ fontFamily: 'DMSans', fontSize: '14px' }}
              variant="body2"
              color="text.secondary"
            >
              {caption}
            </Typography>
          )}
          {hashTags && (
            <Grid container gap={'10px'} sx={{ marginTop: '10px' }}>
              {hashTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  sx={{
                    background: '#DBEEFC',
                    padding: '5px 0',
                    fontFamily: 'DMSans',
                    fontSize: '14px',
                  }}
                />
              ))}
            </Grid>
          )}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default Tile
