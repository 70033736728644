const nameSortAsc  = (a, b) => a.name > b.name ? 1 : -1;
const nameSortDesc = (a, b) => b.name < a.name ? -1 : 1;
const dateSortAsc  = (a, b) => new Date(a.created_date) - new Date(b.created_date) ? 1 : -1;
const dateSortDesc = (a, b) => new Date(a.created_date) - new Date(b.created_date) ? -1 : 1;

export const sortMap = {
  1: nameSortAsc,
  2: nameSortDesc,
  3: dateSortAsc,
  4: dateSortDesc,
}