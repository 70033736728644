/** @jsxImportSource @emotion/react */
import { Reorder } from 'framer-motion'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Image from '@mui/icons-material/Image'
import Grip from '@mui/icons-material/ViewCompact'
import { Button } from '@mui/material'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'

import { MediaListEntry } from 'backend-v2/src/modules/library/dtos/library.dto'
import { ASSET_TYPE_IDS } from 'components/Library/constants'
import { AssetFitSelect } from './Playlists/assetFitSelect'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { PlaylistContentItem } from 'backend-v2/src/modules/library/services/types/lists'
import { AssetDuration } from './Playlists/assetDuration'
import FileIcon from './Tile/FileIcon'
import StopWatch from 'assets/img/stopwatch.svg'
import DeleteIcon from 'assets/img/delete.svg'
import Arrow from 'assets/img/GroupingContentArrow.svg'
import { useState } from 'react'

const GrayButton = styled(Button)`
  border-color: rgba(0, 0, 0, 0.25);
  font-weight: bold !important;
`

interface IProps {
  isEdit: boolean
  playlist: PlaylistContentItem[]
  setPlaylist: (prev: MediaListEntry<any>[]) => MediaListEntry<any>[]
  onClose?: () => void
  onSave: () => Promise<void>
  setPreviewModalOpen: (v: boolean) => void
  name: string
  onDelete: (idx: number) => void
  setName: (name: string) => void
  description: string
  setDescription: (name: string) => void
  updateAssetFit: (idx: number, fit: string) => void
  updateAssetDuration: (idx: number, duration: number) => void
  isLoading?: boolean
  representativeAsset: AssetIdentifier
  setRepresentativeAsset: (asset: AssetIdentifier) => void
}

export const PlaylistContentPanel = ({
  isEdit,
  playlist,
  setPlaylist,
  setPreviewModalOpen,
  onClose,
  onSave,
  onDelete,
  name,
  setName,
  description,
  setDescription,
  updateAssetFit,
  updateAssetDuration,
  isLoading,
  representativeAsset,
  setRepresentativeAsset,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [index, setIndex] = useState<number>()

  const formatTime = (hours: number, minutes: number, seconds: number) => {
    const localeStringConfig = {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }
    const hFormatted = hours.toLocaleString('en-US', localeStringConfig)
    const mFormatted = minutes.toLocaleString('en-US', localeStringConfig)
    const sFormatted = seconds.toLocaleString('en-US', localeStringConfig)

    return `${hFormatted}:${mFormatted}:${sFormatted}`
  }
  const secondsToHms = (d: number) => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.round((d % 3600) % 60)

    return { h, m, s }
  }

  const getTotalRumTimeinHHMMSS = () => {
    const { h, m, s } = secondsToHms(
      playlist.reduce((accumulator, currentValue) => {
        return currentValue.duration + accumulator
      }, 0)
    )
    return formatTime(h, m, s)
  }

  const getDuration = (duration: number) => {
    const { h, m, s } = secondsToHms(duration)

    return `${h ? `${h}h ` : ''}${m ? `${m}m ` : ''}${s ? `${s}s` : ''}`
  }

  return (
    <Box sx={{ minWidth: '50px', gridArea: 'sidebar', paddingTop: 2 }}>
      <AssetDuration
        anchorEl={anchorEl}
        initialDuration={playlist[index]?.duration}
        handleClose={(duration) => {
          if (index !== undefined) {
            updateAssetDuration(index, duration)
          }
          setAnchorEl(null)
        }}
      />
      <Card
        elevation={0}
        sx={{
          flex: 1,
          height: 'calc(100vh - 165px)',
          borderRadius: 3,
          padding: 2,
          position: 'relative',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography sx={{ fontWeight: 600 }}>{name || 'Create Playlist'}</Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
            edge="start"
            sx={{ position: 'absolute', top: 2, right: 2 }}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            value={name}
            size="small"
            margin="dense"
            label="Name"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            sx={{
              mb: 1,
            }}
          />
          <TextField
            fullWidth
            size="small"
            value={description}
            margin="dense"
            label="Description"
            placeholder="Description"
            onChange={(e) => setDescription(e.target.value)}
            sx={{}}
          />
          <Typography sx={{ fontWeight: 400 }}>
            Total run time: {playlist.length > 0 ? getTotalRumTimeinHHMMSS() : '00:00:00'}{' '}
            (hhh:mm:ss)
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            height: '100%',
            flex: 1,
            ...(playlist?.length === 0 && {
              justifyContent: 'center',
              alignItems: 'center',
            }),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {playlist?.length === 0 ? (
            <img src={Arrow} />
          ) : (
            <Reorder.Group
              axis="y"
              layoutScroll
              values={playlist}
              onReorder={setPlaylist}
              style={{ overflowY: 'scroll', marginTop: 8, marginBottom: 8 }}
            >
              {playlist.map((asset, idx) => (
                <Reorder.Item
                  key={asset.panelId}
                  value={asset}
                  style={{ position: 'relative', background: 'white' }}
                >
                  <Divider />
                  <ListItem
                    component="div"
                    sx={{
                      paddingY: 1,
                      paddingX: 0,
                      overflowX: 'hidden',
                      '& .MuiListItemSecondaryAction-root': {
                        transform: 'translateY(-30%)',
                      },
                    }}
                    secondaryAction={
                      <>
                        <Tooltip title="Click to set cover image">
                          <IconButton
                            edge="end"
                            aria-label="Representative Image"
                            disabled={asset.source !== 'mediaLibrary'}
                            onClick={() =>
                              setRepresentativeAsset({
                                assetId: asset.id,
                                assetType: ASSET_TYPE_IDS.Media,
                              })
                            }
                            sx={{ mr: 0.5 }}
                          >
                            <Image
                              sx={{
                                width: '16px',
                                height: '16px',
                                color:
                                  asset.id === representativeAsset?.assetId
                                    ? 'primary.main'
                                    : '#CCCCCC',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          edge="end"
                          aria-label="Timing"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorEl(e.currentTarget)
                            setIndex(idx)
                          }}
                          sx={{ mr: 0.1 }}
                        >
                          <img src={StopWatch} alt="timing" height="18px" width="18px" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => onDelete(asset.panelId)}
                        >
                          <img src={DeleteIcon} alt="delete" height="18px" width="18px" />
                        </IconButton>
                      </>
                    }
                  >
                    <Grip
                      sx={{
                        transform: 'rotate(90deg) translateY(2px)',
                        cursor: 'grab',
                      }}
                    />
                    {idx + 1}
                    <ListItemAvatar>
                      <Box
                        sx={{
                          width: 75,
                          height: 45,
                          mr: 2,
                          ml: 1,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {asset.thumbnailUrl ? (
                          <img
                            src={asset.thumbnailUrl}
                            alt="thumbnail"
                            css={{ maxWidth: 75, maxHeight: 45 }}
                          />
                        ) : asset.source === 'scenes' ? (
                          <Box
                            sx={{
                              background: '#3363C5',
                              borderRadius: '5px',
                              width: '75px',
                              height: '45px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ color: 'white' }}>App</Typography>
                          </Box>
                        ) : (
                          <FileIcon height="45px" width="75px" type="Image" />
                        )}
                      </Box>
                    </ListItemAvatar>
                    <Box>
                      <ListItemText
                        primary={asset.title}
                        primaryTypographyProps={{
                          sx: { fontSize: '0.9rem', fontWeight: '600' },
                        }}
                      />
                      <ListItemText
                        primary={getDuration(asset.duration)}
                        sx={{ m: 0 }}
                        primaryTypographyProps={{
                          sx: { fontSize: '0.8rem', fontWeight: '400' },
                        }}
                      />
                      <AssetFitSelect
                        value={asset.fit}
                        onSelect={(fit) => updateAssetFit(idx, fit)}
                      />
                    </Box>
                  </ListItem>
                  <Divider />
                </Reorder.Item>
              ))}
            </Reorder.Group>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GrayButton
              variant="outlined"
              sx={{ width: '100%' }}
              disableElevation
              disabled={playlist.length === 0}
              onClick={() => setPreviewModalOpen(true)}
            >
              Preview
            </GrayButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              sx={{ width: '100%' }}
              disableElevation
              disabled={playlist.length === 0 || !name}
              onClick={onSave}
              loading={isLoading}
            >
              {isEdit ? 'Save' : 'Create'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}
