import {
  TableRow as MuiTableRow,
  TableCell,
  IconButton,
  TableHead,
  TableBody,
  Collapse,
  TableContainer,
  Paper,
  Table,
  Checkbox,
  TableSortLabel,
  Box,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { visuallyHidden } from '@mui/utils'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { useEffect, useState } from 'react'

type Row = {
  id: string | number
  type: number
  values: {
    [key: string]: JSX.Element | string
  }
  onRowClick?: (asset: AssetIdentifier) => any
}

type CollapsibleRowProps = Row & {
  tableHasCheckbox: boolean
  checkedItems?: AssetIdentifier[]
  onRowCheckClick?: (asset: AssetIdentifier, checked: boolean) => any
}
type LibraryTableProps = {
  header: (string | { label: string; id: string })[]
  rows: Row[]
  setCheckedElement: (checkedElement: AssetIdentifier | AssetIdentifier[]) => any
  checkedElements: AssetIdentifier[]
  onRowClick?: (asset: AssetIdentifier) => any
  hideContextMenu?: boolean
}

const TableRow = ({
  values,
  onRowClick,
  id,
  type,
  checkedItems,
  onRowCheckClick,
  tableHasCheckbox,
}: CollapsibleRowProps) => {
  return (
    <>
      <MuiTableRow sx={{ cursor: 'pointer' }}>
        {tableHasCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={checkedItems?.some(
                ({ assetType, assetId }) => assetType === type && assetId === id
              )}
              onChange={(e) => {
                e.stopPropagation()
                onRowCheckClick &&
                  onRowCheckClick({ assetId: id, assetType: type }, e.target.checked)
              }}
            />
          </TableCell>
        )}
        {Object.entries(values).map(([rowKey, rowValue], index) => (
          <TableCell
            key={rowKey}
            component="th"
            scope="row"
            sx={{
              padding: '10px',
              paddingLeft: '18px',
              font: 'normal 400 14px/18px DMSans',
              position: 'relative'
            }}
            onClick={() => onRowClick && onRowClick({ assetId: id, assetType: type })}
          >
            {rowValue}
          </TableCell>
        ))}
      </MuiTableRow>
    </>
  )
}

const LibraryTable = ({
  header,
  rows,
  checkedElements,
  setCheckedElement,
  onRowClick,
  hideContextMenu,
}: LibraryTableProps) => {
  const [order, setOrder] = useState<'asc' | 'desc'>()
  const [orderBy, setOrderBy] = useState<keyof Row['values']>()

  const handleRequestSort = (property: keyof Row['values']) => {
    const isAsc = orderBy === property && order === 'asc'
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isAsc ? 'desc' : isDesc ? undefined : 'asc')
    setOrderBy(isDesc ? undefined : property)
  }

  const tableHasCheckbox = Boolean(checkedElements) && Boolean(setCheckedElement)
  const isAllChecked = checkedElements?.length === rows?.length
  const onSelectAllClick = () => {
    if (checkedElements.length === 0 || checkedElements.length === rows.length) {
      setCheckedElement(rows.map(({ type, id }) => ({ assetType: type, assetId: id })))
    } else {
      setCheckedElement(
        rows
          .filter(
            ({ id, type }) =>
              !checkedElements.some((el) => el.assetId === id && el.assetType === type)
          )
          .map(({ type, id }) => ({ assetType: type, assetId: id }))
      )
    }
  }

  const handleRowCheckClick = (asset: AssetIdentifier, isChecked: boolean) => {
    setCheckedElement(asset)
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  type Order = 'asc' | 'desc'

  function stableSort<T>(array: readonly Row[], comparator: (a: Row, b: Row) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [Row, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].values, b[0].values)
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  return (
    <TableContainer
      elevation={0}
      component={Paper}
      sx={{ borderRadius: '15px', marginTop: '15px', padding: '0 15px' }}
    >
      <Table aria-label="collapsible table">
        <TableHead sx={{ background: 'white' }}>
          <MuiTableRow>
            {tableHasCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isAllChecked}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>
            )}
            {header.map((headerCell) => (
              <TableCell
                key={typeof headerCell === 'string' ? headerCell : headerCell.id}
                sx={{
                  padding: '15px',
                  font: 'normal 400 14px/18px DMSans',
                }}
              >
                {typeof headerCell === 'string' ? (
                  headerCell
                ) : (
                  <TableSortLabel
                    active={orderBy === headerCell.id}
                    direction={orderBy === headerCell.id ? order : 'asc'}
                    onClick={() => handleRequestSort(headerCell.id)}
                  >
                    <>
                      {headerCell.label}
                      {orderBy === headerCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </>
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </MuiTableRow>
        </TableHead>
        <TableBody>
          {(order && orderBy
            ? stableSort(rows, getComparator(order, orderBy))
            : rows
          ).map((row) => (
            <TableRow
              key={row.id}
              {...row}
              checkedItems={checkedElements}
              onRowCheckClick={handleRowCheckClick}
              onRowClick={onRowClick}
              tableHasCheckbox={tableHasCheckbox}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LibraryTable
