import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Button from '@mui/material/Button'
import { Label } from 'reactstrap'
import CircularProgress from '@mui/material/CircularProgress'
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation'
import styled from 'styled-components'

import { PLATFORMS, STAGES } from 'util/constants'
import FileDropzone from 'components/fileDropzone'
import SoftwareReleasesApi from 'data/api/softwareReleases'
import FileUploadApi from '../../../../data/api/fileUpload'

const fileUploadApi = new FileUploadApi()
const softwareReleasesApi = new SoftwareReleasesApi()

const styles = {
  labelStyle: { color: '#464D69CC', marginBottom: '0px', minWidth: 72 },
  subLabelStyle: { color: '#464D69CC', marginBottom: '0px', minWidth: 72, fontSize: 12 },
}

const EditForm = ({ isEdit, id }) => {
  const navigate = useNavigate()
  const [values, setValues] = useState({
    software_name: '',
    platform: 'win',
    stage: 'Alpha',
    version: '',
    installer_key: '',
    updater_key: '',
    launcher_key: '',
    release_notes: '',
    feature_list: '',
  })
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (id) {
      softwareReleasesApi.getById(id).then(setValues)
    }
  }, [id])

  const handleChange = (fieldName) => (e) => {
    e.preventDefault()
    setValues({ ...values, [fieldName]: e.target.value })
  }

  const handleSubmit = async () => {
    setSaving(true)
    const submission = _.pick(
      values,
      'software_name',
      'platform',
      'stage',
      'version',
      'installer_key',
      'updater_key',
      'launcher_key',
      'release_notes',
      'feature_list'
    )
    if (isEdit) {
      await softwareReleasesApi.update(id, submission)
    } else {
      await softwareReleasesApi.post(submission)
    }
    setSaving(false)
    navigate('/app/admin/software-releases/list')
  }

  const onDrop = (type) => async (file, setUploadProgress) => {
    const { signedPutUrl } = await fileUploadApi.getPresignedSoftwareReleaseUploadUrl(
      values.version,
      values.platform,
      file.name
    )
    const uploadConfig = {
      onUploadProgress: (progressEvent) => {
        const progressUpload = (progressEvent.loaded * 100) / progressEvent.total
        setUploadProgress(progressUpload)
      },
    }
    await fileUploadApi.uploadFileToS3(signedPutUrl, file, uploadConfig)
    setValues((p) => ({
      ...p,
      [type]: `${values.version}/${values.platform}/${file.name}`,
    }))
  }

  return (
    <AvForm onValidSubmit={handleSubmit} style={{ flex: 1 }}>
      <AvField
        name="software_name"
        label="Software Name"
        type="text"
        errorMessage="Invalid Name"
        onChange={handleChange('software_name')}
        value={values.software_name}
        validate={{
          required: { value: true },
          minLength: { value: 3 },
        }}
      />
      <StyledGroup>
        <Label for="style" style={styles.labelStyle}>
          Platform:
        </Label>
        <AvInput
          id="platform"
          name="platform"
          type="select"
          onChange={handleChange('platform')}
          value={values.platform}
        >
          {PLATFORMS.map((name) => (
            <option value={name} key={name}>
              {name}
            </option>
          ))}
        </AvInput>
      </StyledGroup>
      <StyledGroup>
        <Label for="style" style={styles.labelStyle}>
          Stage:
        </Label>
        <AvInput
          id="stage"
          name="stage"
          type="select"
          onChange={handleChange('stage')}
          value={values.stage}
        >
          {STAGES.map((name) => (
            <option value={name} key={name}>
              {name}
            </option>
          ))}
        </AvInput>
      </StyledGroup>
      <AvField
        name="version"
        label="Software Version"
        type="text"
        errorMessage="Invalid Version"
        onChange={handleChange('version')}
        value={values.version}
        validate={{
          required: { value: true },
          minLength: { value: 3 },
        }}
      />
      <AvField
        name="release_notes"
        label="Release Notes"
        type="textarea"
        rows={3}
        onChange={handleChange('release_notes')}
        value={values.release_notes}
      />
      <AvField
        name="feature_list"
        label="Feature List"
        type="textarea"
        rows={3}
        onChange={handleChange('feature_list')}
        value={values.feature_list}
      />
      {values.version && values.platform && (
        <div className="d-flex flex-row">
          <FileDropzone
            bucket="aesend-installers"
            keyPrefix={`${values.version}/${values.platform}`}
            fileName="aesendPlayer.msi"
            url={values.installerUrl}
            accept=".msi"
            showDate={true}
            onDrop={onDrop('installer_key')}
            style={{ flex: 1, minHeight: 140, maxWidth: 232 }}
            showDownload
          />
          <FileDropzone
            bucket="aesend-installers"
            keyPrefix={`${values.version}/${values.platform}`}
            fileName="update.zip"
            url={values.updaterUrl}
            accept=".zip"
            showDate={true}
            onDrop={onDrop('updater_key')}
            style={{ flex: 1, minHeight: 140, maxWidth: 232 }}
            showDownload
          />
          <FileDropzone
            bucket="aesend-installers"
            keyPrefix={`${values.version}/${values.platform}`}
            fileName="aesend_launcher.exe"
            url={values.launcherUrl}
            accept=".exe"
            showDate={true}
            onDrop={onDrop('launcher_key')}
            style={{ flex: 1, minHeight: 140, maxWidth: 232 }}
            showDownload
          />
        </div>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: '20px' }}
      >
        {saving ? <CircularProgress color="secondary" size={22} /> : <div>SAVE</div>}
      </Button>
    </AvForm>
  )
}

const StyledGroup = styled(AvGroup)`
  margin-bottom: 15px;
  align-items: center;
`

export default EditForm
