import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import LinesEllipsis from 'react-lines-ellipsis'

const styles = {
  card: {
    maxWidth: 200,
  },
  media: {
    height: 100,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
}

const FilePreview = ({ classes, thumbnailUrl, files, onDelete, updateDate }) => {
  // console.log(thumbnailUrl)
  let filename = thumbnailUrl
    ? thumbnailUrl.split('/')[thumbnailUrl.split('/').length - 1]
    : ''

  filename = decodeURIComponent(filename)
  const filenameDisplay = updateDate ? `${filename} (${updateDate})` : filename

  const fileLabel = files
    ? `${files[0].name.replace(/[^0-9a-zA-Z.]/g, '_')} - ${
        Math.round(files[0].size / 10000) / 100
      } MB`
    : filenameDisplay || 'None'

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <span style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}>
        <IconButton
          tooltip="remove"
          hoveredstyle={{ cursor: 'pointer' }}
          sx={{
            background: 'linear-gradient(45deg, #2196f399 30%, #21cbf399 90%)',
            height: '30px',
            width: '30px',
            padding: '3px',
          }}
          size="large"
        >
          <Close sx={{ color: 'white' }} onClick={onDelete} />
        </IconButton>
      </span>
      <Card sx={{ height: '100%', display: 'flex', alignItems: 'flex-end' }}>
        {(['png', 'jpg', 'peg'].includes(filename.slice(-3).toLowerCase()) ||
          (files && files[0].type.slice(0, 5) === 'image')) && (
          <CardMedia image={thumbnailUrl} className={classes.media} />
        )}
        {(filename.slice(-3).toLowerCase() === 'mp4' ||
          (files && files[0].type.slice(0, 5) === 'video')) && (
          <CardMedia src="/">
            <video width="100%" controls>
              <source src={thumbnailUrl + '#t=0.5'} type="video/mp4" />
            </video>
          </CardMedia>
        )}
        {filename.slice(-3).toLowerCase() === 'zip' && (
          <CardMedia
            sx={{ height: '85px', margin: '15px' }}
            image={require('assets/img/zipFile-3.png')}
            title={filename}
          />
        )}

        <CardContent sx={{ padding: '10px', width: '100%' }}>
          <Tooltip title={fileLabel}>
            <div>
              <LinesEllipsis text={fileLabel.split('?')?.[0]} />
            </div>
          </Tooltip>
        </CardContent>
      </Card>
    </div>
  )
}

FilePreview.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  thumbnailUrl: PropTypes.string,
  files: PropTypes.array,
  onDelete: PropTypes.func,
  updateDate: PropTypes.string,
}

export default withStyles(styles)(FilePreview)
