import { FileType } from 'routes/cms/library/types'

const backgroundColors = new Map<FileType, string>()
backgroundColors.set('Audio', '#9D3DD7')
backgroundColors.set('File', '#3363C5')
backgroundColors.set('PDF', '#9F2415')
backgroundColors.set('App', '#CE2E68')
backgroundColors.set('Playlist', '#00B19C')
backgroundColors.set('Spreadsheet', '#387A47')
backgroundColors.set('Image', '#CE2E68')
backgroundColors.set('Video', '#1A6BE3')
backgroundColors.set('WEB_PAGE', '#3363C5')
backgroundColors.set('WEATHER', '#3363C5')
backgroundColors.set('NEWS', '#3363C5')
backgroundColors.set('STOCKS', '#3363C5')

export default backgroundColors
