import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, Route, Routes, Navigate } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { IntlProvider } from 'react-intl'

// App locale
import AppLocale from '../lang'

//Main App
import MainApp from 'routes'

// app signin
import AppSignIn from './Signin'
import AppSignUp from './Signup'
import Contact from './Contact'
import PairingCode from './PairingCode'
import RenderScene from '../routes/cms/scenes/render'

import { logoutUser } from '../data/actions/AuthActions'
import { getTemplates, getTemplateCategories, getUserInfo } from 'data/actions'
import {
  AsyncTermsConditionComponent,
  AsyncPrivacyComponent,
} from 'components/AsyncComponent/AsyncComponent'
import { baseApi } from 'store/services/baseApi'
import { useAppDispatch } from 'hooks/useRedux'
import Callback from 'components/Callback/Callback'
import NewConditionsModal from 'components/NewConditionsModal/NewConditionsModal'
import SharedMediaViewer from 'routes/cms/library/SharedMediaViewer'
import { useAgreeToTermsMutation, useLazyGetMeQuery } from 'store/services/user'
import AppConfig from 'constants/AppConfig'
import GoogleCallback from 'components/Google/Callback'

const { termsAndConditionLastUpdated } = AppConfig

const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state.authUser.user)
  const { locale } = useSelector(({ settings }) => settings)
  const [showNewConditionsModal, setShowNewConditionsModal] = useState(false)
  const currentAppLocale = AppLocale[locale.locale]
  const [getMe] = useLazyGetMeQuery()
  const [agreeToTerms] = useAgreeToTermsMutation()

  const checkTermsAgreed = async () => {
    const me = await getMe().unwrap()
    const userAcceptedTermsAndPolicy =
      me?.termsAgreedDate &&
      new Date(me?.termsAgreedDate).getTime() >
        new Date(termsAndConditionLastUpdated).getTime()

    setShowNewConditionsModal(!userAcceptedTermsAndPolicy)
  }

  const onConfirm = async () => {
    await agreeToTerms()
    checkTermsAgreed()
  }

  useEffect(() => {
    dispatch(baseApi.util.resetApiState())
  }, [])

  useEffect(() => {
    if (user) {
      initialize()
      if (location.pathname === '/') {
        return navigate('/app/cms/library')
      }
      checkTermsAgreed()
    } else if (location.pathname.includes('/app/')) {
      return navigate('/signin')
    }
  }, [user])

  const initialize = () => {
    dispatch(getTemplates({ ae_version: '2024' }))
    dispatch(getTemplateCategories())
    dispatch(getUserInfo())
  }

  if (user && new Date(user.idTokenPayload.exp * 1000) < new Date()) {
    dispatch(logoutUser(navigate))
  }

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <>
        <NotificationContainer leaveTimeout={2000} />
        <Routes>
          {user && (
            <Route
              path={`/app/*`}
              element={
                <>
                  {showNewConditionsModal && <NewConditionsModal onConfirm={onConfirm} />}
                  <MainApp user={user} />
                </>
              }
            />
          )}
          <Route path="/scene/:id" element={<RenderScene />} />
          <Route path={`shared-asset/:shareKey`} element={<SharedMediaViewer />} />
          <Route path="/signin" element={<AppSignIn />} />
          <Route path="/signup" element={<AppSignUp />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pairing-code" element={<PairingCode />} />
          <Route path="/terms-conditions" element={<AsyncTermsConditionComponent />} />
          <Route path="/privacy" element={<AsyncPrivacyComponent />} />
          <Route path="/callback" element={<Callback initialize={initialize} />} />
          <Route path="/google-callback" element={<GoogleCallback />} />
          <Route path="*" element={<Navigate to="/signin" replace />} />
        </Routes>
      </>
    </IntlProvider>
  )
}

export default App
