import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Navigate, useParams } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import FormSlider from 'components/FormSlider'
import FormSliderV2 from 'components/FormSlider.v2'
import TemplatePreviewModal from 'components/TemplatePreviewModal'
import NotesFromAuthorModal from 'components/NotesFromAuthorModal'

import infoButton from '../../../assets/img/Aesend-InfoButton.svg'

const TemplateCustomization = () => {
  const navigate = useNavigate()
  const { id: templateId } = useParams()
  const user = useSelector(({ authUser }) => authUser)
  const list = useSelector(({ templates }) => templates.list)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [authorNotesOpen, setAuthorNotesOpen] = useState(false)
  const [sidePanelParams, setSidePanelParams] = useState({})
  const [storyblocksAsset, setStoryblocksAsset] = useState()
  const [mediaLibraryAsset, setMediaLibraryAsset] = useState()
  const template = list?.find((templ) => templ.id == templateId)

  if (!template) {
    return <Navigate to="/app/templates/templates-list" />
  }

  const renderFormSlider = ({ id, schema, has_previews } = {}) => {
    if (!id) return <FormSliderV2 navigate={navigate} />
    const props = {
      navigate,
      user,
      templateId: id,
      templateSchema: schema,
      sidePanelParams,
      setSidePanelParams,
      mediaLibraryAsset,
      setMediaLibraryAsset,
      storyblocksAsset,
      setStoryblocksAsset,
      hasPreviews: has_previews,
    }
    const sliderMap = {
      '1.0': <FormSlider {...props} />,
      '2.0': <FormSliderV2 {...props} />,
    }
    return sliderMap[schema.version]
  }

  return (
    <Container style={{ marginLeft: sidePanelParams.open ? 10 : 'auto' }}>
      <Row>
        {!sidePanelParams.open && <Col xs="0" sm="3" />}
        <Col xs="12" sm="6">
          <TitleWrapper>
            {template.schema.notes && (
              <img
                src={infoButton}
                alt="info icon"
                onClick={() => setAuthorNotesOpen(true)}
              />
            )}
            <h2 style={{ fontWeight: 'bold', fontSize: 20, margin: 0 }}>
              "{template?.name}"
            </h2>
          </TitleWrapper>
          {renderFormSlider(template)}
        </Col>
        <Col xs="0" sm="3" style={{ marginLeft: 30 }} />
      </Row>
      <TemplatePreviewModal
        footerEnable
        open={previewOpen}
        template={template}
        close={() => setPreviewOpen(false)}
      />
      <NotesFromAuthorModal
        open={authorNotesOpen}
        close={() => setAuthorNotesOpen(false)}
        template={template}
        setPreviewOpen={setPreviewOpen}
      />
    </Container>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    cursor: pointer;
  }
`

export default TemplateCustomization
