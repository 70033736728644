import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';

import RenderButtonInactive from '../../assets/img/render-btn-inactive.png';
import RenderButtonActive from '../../assets/img/render-btn-active.png';

const styles = theme => ({
});

const RenderButton = ({ active, submitting, classes }) => {
  return (
    <RenderButtonBase>
      <Tooltip
        placement="right" 
        title={(() => {
          if (submitting) {
            return 'Preparing render'
          } else if (active) {
            return 'Proceed with rendering'
          } 
          return 'Please complete all form fields before submitting the ' +
            'template for rendering.'
        })()}
      >
        <span>
          <ButtonBase
            focusRipple
            disabled={submitting}
            type='submit'
          >
              { submitting &&
                <CircularProgress 
                  style={{
                    position: 'absolute',
                    right: 0,
                    width: 56,
                    height: 56,
                    color: '#ccc'
                  }}
                />
              }
              { active
                ? <img src={RenderButtonActive} alt='' style={{ maxWidth: 200 }}/>
                : <img src={RenderButtonInactive} alt='' style={{ maxWidth: 200 }}/>
              }
          </ButtonBase>
        </span>
      </Tooltip>
    </RenderButtonBase>
  );
}

RenderButton.defaultProps = {
  active: false,
}

const RenderButtonBase = styled.div`
  position: absolute;
  top: 10px;
  right: -34px;
  z-index: 1;
  -webkit-appearance: none; 
  :hover {
    cursor: pointer;
  }
`;

export default withStyles(styles)(RenderButton);