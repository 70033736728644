import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import StocksApi from 'data/api/stocks'
import background from 'assets/img/StocksBG-web.jpg'
import stocksGraphic from 'assets/img/STOCKStext.svg'
import { hexToRgbA } from 'helpers/helpers'
import { colMap, portraitColMap } from './constants'
import Slider from '@mui/material/Slider'
import useUpdatableState from '@landisdesign/use-updatable-state'
import cls from 'classnames'

const stocksApi = new StocksApi()

export const StocksSceneRenderer2 = ({
  stocks,
  portrait,
  tileMode,
  backgroundColorOverlay,
  style = {},
  size = 50,
  gutter = 50,
}) => {
  const [stockData, setStockData] = useState()
  const [refresh, setRefresh] = useState(0)
  const [refresh2, setRefresh2] = useState(0)
  const [clientDims, setClientDims] = useState({width: 0, height: 0})
  const [localSize, setLocalSize] = useUpdatableState(size)
  const ref = useRef(null)
  const dimension = clientDims?.height
    ? Math.max(clientDims?.height, clientDims?.width)
    : 0
  const { headlineFont, subheadFont, bodyFont } = style
  const isPortrait = (clientDims?.height > clientDims?.width) || portrait
  const cols = isPortrait
    ? portraitColMap[stockData?.length || 1]
    : colMap[stockData?.length || 1]

  const rows = Math.ceil(stockData?.length / cols)

  console.log('WHcd', clientDims)

  const tileWidth = clientDims
    ? isPortrait
      ? Math.min(
        (clientDims.width / cols) - dimension / 40,
        (0.6 * clientDims.height / rows) - dimension / 40,
      )
      : Math.min(
        (0.6 * clientDims.height / rows) - dimension / 40,
        (clientDims.width / cols) - dimension / 40,
      )
    : 0

  useEffect(() => {
    if (!stocks?.length) return
    const getStockData = async () => {
      const results = await stocksApi.get(stocks)
      const sorted = stocks.map((symbol) => {
        return results.find((resultItem) => resultItem.symbol === symbol.toUpperCase())
      })
      setStockData(sorted)
    }
    getStockData()
    setTimeout(() => setRefresh(p => (p + 1)), 600000)
  }, [stocks, refresh])

  useLayoutEffect(() => {
    const container = ref.current
    const size = container?.getBoundingClientRect()

    if (!size) {
      const to = setTimeout(() => setRefresh2(p => (p + 1)), 100)
      return () => {
        clearTimeout(to);
      }
    }

    setClientDims(size)
  }, [refresh2])

  const stockPanel = index => {
    const quote = stockData?.[index]

    return (
      <div key={quote?.symbol + index} className="inner-tile">
        <div className="symbol">
          {quote?.symbol}
        </div>
        <div className="long-name">
          {quote?.longName?.replace(',', '')?.replace('Inc.', '')?.slice(0, 21)}
        </div>
        <div className="quote">
          {quote?.regularMarketPrice < 10000 ? Math.round(quote?.regularMarketPrice * 100) / 100 : parseInt(quote?.regularMarketPrice, 10)}
          <div className={quote.regularMarketChange < 0 ? 'down' : 'up'}>
            {quote?.regularMarketChange > 0 && '+'}
            {quote?.regularMarketChange?.toFixed(2)}
          </div>
        </div>
      </div>
    )
  }

  if (!stockData) return <div/>

  return (
    <Container
      ref={ref}
      id="container"
      className={cls(tileMode, {vertical: clientDims.height > clientDims.width })}
      dimension={dimension}
      backgroundColorOverlay={backgroundColorOverlay}
      tileWidth={tileWidth}
      headlineFont={headlineFont}
      subheadFont={subheadFont}
      bodyFont={bodyFont}
      cols={cols}
      rows={rows}
      size={localSize}
      gutter={gutter}
      style={{
        backgroundColor: hexToRgbA(backgroundColorOverlay, 0.7),
      }}
    >
      <StocksText src={stocksGraphic} alt="stocks-text"/>
      {/*<GridList*/}
      {/*  cols={cols}*/}
      {/*  spacing={dimension / 40}*/}
      {/*  style={{ flex: 1 }}*/}
      {/*  cellHeight="auto"*/}
      {/*>*/}
      {/*  {stockData.map((stock, idx) => stockPanel(idx))}*/}
      {/*</GridList>*/}
      <div className="grid">
        {stockData.map((stock, idx) => {
          return stockPanel(idx)
        })}
      </div>

      {/*<SliderStyled onChange={handleSliderChange} defaultValue={50} aria-label="Disabled slider"/>*/}
    </Container>
  )
}

const SliderStyled = styled(Slider)`
  position: absolute;
  bottom: 0;
  z-index: 10000;
`

const Container = styled.div`
  --dimension: ${({ dimension }) => `${dimension}px`};
  --headline-font: ${({ headlineFont }) => `${headlineFont}`};
  --subhead-font: ${({ subheadFont }) => `${subheadFont}`};
  --body-font: ${({ bodyFont }) => `${bodyFont}`};
  --cols: ${({ cols }) => `${cols}`};
  --rows: ${({ rows }) => `${rows}`};

  --size: ${({ size }) => `${size}`};
  --gutter: ${({ gutter }) => `${gutter}`};

  --tile-width-vw: calc((var(--dimension) / 10) * var(--size) / 50);

  position: relative;
  overflow: hidden;

  &.dark {
    .inner-tile {
      background: black;
      color: white;

      .long-name {
        color: #fff;
      }
    }
  }
  
  &.vertical {
    align-items: end;
  }


  display: flex;
  align-items: end;
  justify-content: center;
  flex: 1;
  height: 100%;
  background-size: cover;
  background-image: url(${background});
  background-blend-mode: overlay;

  .MuiGridList-root {
    justify-content: center;
  }

  .grid {
    display: flex;
    width: 80%;
    justify-content: space-around;
    align-content: space-around;
    flex-wrap: wrap;
    height: 80%;
    //margin: 10.5% auto 0;
    z-index: 1000;
  }

  .inner-tile {

    //height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: calc(var(--tile-width-vw) / 7);
    min-width: var(--tile-width-vw);
    height: var(--tile-width-vw);

    margin-left: calc((var(--tile-width-vw) / 2) * var(--gutter) / 50);
    margin-right: calc((var(--tile-width-vw) / 2) * var(--gutter) / 50);

    //paddingTop: calc(var(--tile-width) / 7);
    //paddingBottom: calc(var(--dimension) / 45);

    background: white;
    color: #3d3d3d;

    .symbol {
      font-family: var(--headline-font);
      line-height: 1;
      //font-size: calc(var(--tile-width) / 7);
      //line-height: calc(var(--tile-width) / 5 - 4);
      //font-size: 7vmin;
      font-size: calc(var(--tile-width-vw) * 0.2);
      font-weight: bold;
    }

    .long-name {
      font-family: var(--subhead-font);
      color: #000;
      //font-size: calc(var(--tile-width) / 10);
      //font-size: 3vmin;
      font-size: calc(var(--tile-width-vw) * 0.1);
      white-space: nowrap;
    }

    .quote {
      font-family: var(--headline-font);
      //font-size: calc(var(--tile-width) / 2.5);
      //font-size: 11vmin;
      font-size: calc(var(--tile-width-vw) * 0.31);
      line-height: 1;
      //padding-top: 4vmin;
      padding-top: calc(var(--tile-width-vw) * 0.04);
      margin: 0 calc(var(--tile-width-vw) * 0.1);

      > div {
        font-family: var(--body-font);
        //font-size: calc(var(--tile-width) / 8);
        //font-size: 4vmin;
        font-size: calc(var(--tile-width-vw) * 0.15);
        text-align: right;

        &.up {
          color: #009245;
        }

        &.down {
          color: #ff0000;
        }
      }
    }
  }
`


const StocksText = styled.img`
  //display: none;
  position: absolute;
  top: 10%;
  left: 20%;
  width: 65%;
`
