import { LibraryEntryKind } from 'backend-v2/src/modules/library/dtos/library.dto'

export interface AssetUser {
  id: number
  firstName: string
  lastName: string
  photoUrl: string
}
export interface AssetUsers {
  [userId: number]: AssetUser
}

export type Asset = {
  title: string
  hashTags: { name: string; id: number | string }[]
  tags: number[]
  usedIn: { playlists: number[]; screens: number[] }
  playlistCount?: number
  screenCount?: number
  kind: LibraryEntryKind
  assetInfo: AssetInfo
  category: number
  collection: number
  id: number
  uploadedBy: string
  createdAt: string
  updatedAt: string
  isPinned: boolean
  isShared: boolean
  sharedWith?: string[]
  createdBy?: AssetUser
}

export type PlaylistAsset = {
  id: number
  title: string
  kind: 'Playlist'
  assetInfo: {
    aspectRatio: number
    description: string
  }
  createdAt: string
  updatedAt: string
  tags: number[]
}

export type V1PlaylistContentItem = {
  id: number
  name: string
  order: number
  duration: number
  source: 'mediaLibrary' | 'scenes'
}
export type V1PlaylistApiItem = {
  id: number
  accountId: number
  aspectRatio: number
  name: string
  description: string
  content: V1PlaylistContentItem[]
  created_date: string
  updated_date: string
}

export type FileType =
  | 'Audio'
  | 'Video'
  | 'Image'
  | 'Spreadsheet'
  | 'File'
  | 'PDF'
  | 'App'
  | 'Playlist'

export type MediaType =
  | 'video/mp4'
  | 'video/quicktime'
  | 'video/webm'
  | 'image/png'
  | 'image/jpeg'
  | 'image/svg+xml'
  | 'audio/x-m4a'
  | 'audio/wav'
  | 'audio/mpeg'
  | 'application/pdf'
  | 'application/vnd.ms-excel'
  | 'application/postscript'
  | 'application/mxf'
  | 'application/vnd.openxmlformats-officedocument'

export type AppType = 'WEATHER' | 'STOCKS' | 'NEWS' | 'WEB_PAGE'
export type AssetInfo = {
  type: FileType
  thumbnailUrl?: string
  previewUrl?: string
  originalUrl: string
  mediaType: MediaType
  aspectRatio: number
  appType?: AppType
}

export type CurrentGrouping = { value: number; label: string }

export const getFileTypeFromMediaType = (mediaType?: MediaType): FileType => {
  if (mediaType) {
    if (mediaType.indexOf('audio') > -1) {
      return 'Audio'
    }
    if (mediaType.indexOf('image') > -1) {
      return 'Image'
    }
    if (mediaType.indexOf('video') > -1) {
      return 'Video'
    }
    if (mediaType.indexOf('pdf') > -1) {
      return 'PDF'
    }
    if (mediaType.indexOf('excel') > -1) {
      return 'Spreadsheet'
    }
  }
  return 'File'
}

export enum SORT_OPTIONS {
  aToZAsc = 'A-Z asc',
  aToZDesc = 'A-Z desc',
  dateAsc = 'Date asc',
  dateDesc = 'Date desc',
}
