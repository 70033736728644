import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import MediaPreview from './mediaPreview';

const MediaPickStrip = ({ choices, onSelect, selected }) => 
  <ScrollingContainer style={{ height: 120 }}>
    <Container>
      { choices && choices.length
          ? choices.map(({ url }, idx) =>
              <PreviewContainer key={idx}
                onClick={() => onSelect(idx)} 
                selected={selected === idx}>
                <MediaPreview url={url} smallPreview />
                <SelectWrapper
                  onClick={() => onSelect(idx)}
                  selected={selected === idx}>
                  {selected === idx ? 'Selected ' : 'Select'}
                </SelectWrapper>
              </PreviewContainer>
            )
          : 'No Media Present' }
    </Container>
  </ScrollingContainer>

MediaPickStrip.propTypes = {
  choices: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

const ScrollingContainer = styled(Scrollbars)`
  display: flex;
  border: 2px dashed #C7C7C7;
  height: 110px;
  background-color: #E1E1E1;
  border-radius: 5px;
  overflow-x: auto;
  > div {
    display: flex;
    flex: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  height: 100%;  
  font-family: sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  padding: 5px 5px 20px 5px;
  text-align: center;
`;

const PreviewContainer = styled.div`
  max-width: 150px;
  max-height: 100px;
  width: 150px;
  margin-right: 5px;
  cursor: pointer;
  border: ${({selected}) => selected ? '5px solid #6cc9f9' : 'thin solid #ccc'};
  border-radius: 5px;
  padding: 2px;
  color: ${({selected}) => selected ? '#888' : '#00000000'};
  :hover {
    color: #888;
  }
`
const SelectWrapper = styled.div`
  color: inherit;
`;

export default MediaPickStrip;
