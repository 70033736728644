import crypto from 'crypto-js'

const SECRET = 'yozTer8cZzvMCJ5'

type ENV_TYPE = 'local' | 'development' | 'production'

const ENVIRONMENT: ENV_TYPE = ['local', 'development', 'production'].includes(
  process.env.ENVIRONMENT ?? ''
)
  ? process.env.ENVIRONMENT
  : 'local'

// const SB_KEYS = {
//   SB_PUBLIC_KEY: '',
//   SB_PRIVATE_KEY: '',
// };
// const encryptedKeys = crypto.AES.encrypt(
//   JSON.stringify(SB_KEYS),
//   SECRET,
// ).toString();
// console.log(encryptedKeys);

const SB_ENC_KEYS =
  'U2FsdGVkX18sdZw+avNGUUdouyx+Uh17TiLbBJ3W9su6eRa6bWtH+4IEwaVFIg2LIEOQQ+M2hCRwOGo/pBAqXN0ThcmNC0xYoM98mCSZ+NzXE5lKH5Pt2w5GXVHAGFI5kwJtZFoPDFUIryBL9RNBmuaOGe2MjbrA7ASrnBAbJd1KEnC++lPnHvJ5Rn9liNITbjsz0UZ7yKDgOMrmGYAft2QAzrxbN05MMP1ZO5xDrzdTdMemhVzvgmjJ2jN25N6f'
const decryptedKeysBytes = crypto.AES.decrypt(SB_ENC_KEYS, SECRET)
const decryptedKeys = JSON.parse(decryptedKeysBytes.toString(crypto.enc.Utf8))
// console.log(decryptedKeys);

// mark's aesend google client ID
const GOOGLE_CLIENT_ID =
  '118529564928-asjcvf861dlk93l079r5l89q948e4k6a.apps.googleusercontent.com'

// arman's google client ID
// const GOOGLE_CLIENT_ID =
//   '223488989440-3du8kvdvi4fuuq9meq9bvj6gaot5namp.apps.googleusercontent.com'

// Any changes to local IPs also require changes in Auth0 config...
const configOriginal = {
  local: {
    URL_BASE: 'http://localhost:3001',
    // API_BASE: 'http://localhost:3000',
    API_BASE: 'https://api-dev.aesend.com',
    API_BASE_V2: 'https://080xieoaqd.execute-api.us-west-2.amazonaws.com/dev',
    AUTH0_DOMAIN: 'aesend-dev.auth0.com',
    AUTH0_CLIENT_ID: 'rbXYVGzdZnINqpskK8MgyanOwvPRdHaj',
    WEBSOCKET_URL: 'wss://gqs909jp49.execute-api.us-west-2.amazonaws.com/dev',
    GOOGLE_CLIENT_ID,
    ...decryptedKeys,
  },
  development: {
    URL_BASE: 'https://webapp-dev.aesend.com',
    API_BASE: 'https://api-dev.aesend.com',
    API_BASE_V2: 'https://080xieoaqd.execute-api.us-west-2.amazonaws.com/dev',
    // API_BASE_V2: 'https://api-dev-v2.aesend.com',
    AUTH0_DOMAIN: 'aesend-dev.auth0.com',
    AUTH0_CLIENT_ID: 'rbXYVGzdZnINqpskK8MgyanOwvPRdHaj',
    WEBSOCKET_URL: 'wss://gqs909jp49.execute-api.us-west-2.amazonaws.com/dev',
    GOOGLE_CLIENT_ID,
    ...decryptedKeys,
  },
  production: {
    URL_BASE: 'https://webapp.aesend.com',
    API_BASE: 'https://api.aesend.com',
    API_BASE_V2: 'https://api-v2.aesend.com',
    AUTH0_DOMAIN: 'scenefactory.auth0.com',
    AUTH0_DOMAIN_CUSTOM: 'auth0.aesend.com',
    AUTH0_CLIENT_ID: 'carD52MoYSpgw9mDn7cYiibPtf6OYcAI',
    GOOGLE_CLIENT_ID,
    ...decryptedKeys,
  },
}

const config = {
  URL_BASE: process.env.URL_BASE || configOriginal[ENVIRONMENT]?.URL_BASE,
  API_BASE: process.env.API_BASE || configOriginal[ENVIRONMENT]?.API_BASE,
  API_BASE_V2: process.env.API_BASE_V2 || configOriginal[ENVIRONMENT]?.API_BASE_V2,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN || configOriginal[ENVIRONMENT]?.AUTH0_DOMAIN,
  AUTH0_CLIENT_ID:
    process.env.AUTH0_CLIENT_ID || configOriginal[ENVIRONMENT]?.AUTH0_CLIENT_ID,
  AUTH0_DOMAIN_CUSTOM: process.env.AUTH0_DOMAIN_CUSTOM,
  WEBSOCKET_URL: process.env.WEBSOCKET_URL || configOriginal[ENVIRONMENT]?.WEBSOCKET_URL,
  GOOGLE_CLIENT_ID:
    process.env.GOOGLE_CLIENT_ID || configOriginal[ENVIRONMENT]?.GOOGLE_CLIENT_ID,
  ...decryptedKeys,
}

const URL_BASE = config.URL_BASE
const API_BASE = config.API_BASE
const API_BASE_V2 = config.API_BASE_V2

export const AUTH_CONFIG = {
  customDomain: config.AUTH0_DOMAIN_CUSTOM,
  domain: config.AUTH0_DOMAIN,
  clientId: config.AUTH0_CLIENT_ID,
  callbackUrl: `${URL_BASE}/callback`,
  websocketUrl: config.WEBSOCKET_URL,
  urlBase: URL_BASE,
  dbConnectionName: 'Username-Password-Authentication',
  apiEndpoint: API_BASE,
  apiEndpointV2: API_BASE_V2,
  sbBaseUrl: 'https://api.videoblocks.com',
  sbPublicKey: config.SB_PUBLIC_KEY,
  sbPrivateKey: config.SB_PRIVATE_KEY,
  googleClientId: config.GOOGLE_CLIENT_ID,
}
