import React, { useState, useEffect } from 'react'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Row, Col, Label } from 'reactstrap'
import styled from 'styled-components'

import { types } from '../index'
import { logoLocations, currentTimeLocations } from '../constants'
import Preview from '../common/Preview'
import { getStyleColors } from '../common/utils'
import ColorSwatch from 'routes/cms/scenes/addEditStyle/colorSwatch'

const labelStyle = { color: '#464D69CC', marginBottom: '0px', minWidth: 72 }
const topics = [
  'General',
  'Business',
  'Entertainment',
  'Health',
  'Science',
  'Sports',
  'Technology',
]

export const NewsSceneEditor = ({ name, config, onSave, saving, sceneUrl, styles }) => {
  delete config?.sceneName
  const [formData, setFormData] = useState({
    sceneName: name,
    logoLocation: '',
    textColor: '#fff',
    number: 3,
    duration: 15,
    showCurrentTime: '',
    showBackgroundVideo: false,
    topic: topics[0],
    keywords: '',
    styleId: '',
    ...config,
  })
  const style = styles.find((s) => s.id == formData.styleId)?.config
  const styleColors = getStyleColors(style)

  useEffect(() => {
    if (config) {
      setFormData((prev) => ({ ...prev, ...config }))
    }
  }, [config])

  const handleChange = (field) => (e) =>
    setFormData((prev) => ({
      ...prev,
      [field]: typeof e?.target?.value === 'string' ? e.target.value : e,
    }))

  const save = () =>
    onSave({
      name: formData.sceneName,
      type: types.NEWS,
      config: {
        ...formData,
        stocks: getNews(),
      },
    })

  const getNews = () => {
    const stocks = formData.stocks?.split
      ? formData.stocks?.split(',')?.filter((i) => i)
      : formData.stocks?.length
      ? formData.stocks
      : []
    if (stocks?.length) {
      return stocks
    }
    return []
  }

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col sm="7" style={{ display: 'block' }}>
          <h2>{name ? 'Edit' : 'New'} News Scene</h2>
          <br />
          <AvForm>
            <Row>
              <Col sm="5">
                <StyledGroup>
                  <Label for="name" style={labelStyle}>
                    Name
                  </Label>
                  <AvInput
                    type="text"
                    value={formData.sceneName}
                    name="name"
                    id="name"
                    required
                    onChange={handleChange('sceneName')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    Please enter a name for the scene
                  </AvFeedback>
                </StyledGroup>
              </Col>
              <Col sm="4">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Style
                  </Label>
                  <AvInput
                    id="styleId"
                    name="styleId"
                    type="select"
                    onChange={handleChange('styleId')}
                    validate={{ min: { value: 1 } }}
                    value={Number(formData.styleId)}
                  >
                    <option value="">None</option>
                    {styles &&
                      styles.map((style) => (
                        <option value={style.id} key={style.id}>
                          {style.name}
                        </option>
                      ))}
                  </AvInput>
                </AvGroup>
              </Col>
              <Col sm="3">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Show Logo
                  </Label>
                  <AvInput
                    id="logoLocation"
                    name="logoLocation"
                    type="select"
                    onChange={handleChange('logoLocation')}
                    value={formData.logoLocation}
                  >
                    {logoLocations.map((location) => (
                      <option value={location.value} key={location.value}>
                        {location.label}
                      </option>
                    ))}
                  </AvInput>
                </AvGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Topic
                  </Label>
                  <AvInput
                    id="topic"
                    name="topic"
                    type="select"
                    onChange={handleChange('topic')}
                    value={formData.topic}
                  >
                    {topics.map((topic) => (
                      <option value={topic} key={topic}>
                        {topic}
                      </option>
                    ))}
                  </AvInput>
                </AvGroup>
              </Col>
              <Col sm="4">
                <StyledGroup>
                  <Label for="number" style={labelStyle}>
                    Number of Stories
                  </Label>
                  <AvInput
                    type="number"
                    value={formData.number}
                    name="number"
                    id="number"
                    required
                    onChange={handleChange('number')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    How many stories to display
                  </AvFeedback>
                </StyledGroup>
              </Col>
              <Col sm="3">
                <StyledGroup>
                  <Label for="name" style={labelStyle}>
                    Duration (s)
                  </Label>
                  <AvInput
                    type="number"
                    value={formData.duration}
                    name="duration"
                    id="duration"
                    required
                    onChange={handleChange('duration')}
                  />
                  <AvFeedback style={{ textAlign: 'left' }}>
                    How long should each story be shown
                  </AvFeedback>
                </StyledGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <StyledGroup>
                  <Label for="name" style={labelStyle}>
                    Keywords
                  </Label>
                  <AvInput
                    type="text"
                    value={formData.keywords}
                    name="keywords"
                    id="keywords"
                    onChange={handleChange('keywords')}
                  />
                </StyledGroup>
              </Col>
              <Col sm="4">
                <AvGroup>
                  <Label for="style" style={labelStyle}>
                    Show Current Time
                  </Label>
                  <AvInput
                    id="showCurrentTime"
                    name="showCurrentTime"
                    type="select"
                    onChange={handleChange('showCurrentTime')}
                    value={formData.showCurrentTime}
                  >
                    {currentTimeLocations.map((location) => (
                      <option value={location.value} key={location.value}>
                        {location.label}
                      </option>
                    ))}
                  </AvInput>
                </AvGroup>
              </Col>
              <Col sm="3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 10 }}>Text Color:</span>
                  <ColorSwatch
                    size="sm"
                    colors={['#000', '#FFF']}
                    onClick={handleChange('textColor')}
                    selected={formData.textColor}
                    style={{ background: '#ddd' }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 10 }}>Background Color Overlay:</span>
                  <ColorSwatch
                    size="sm"
                    colors={['#555', ...styleColors]}
                    onClick={handleChange('backgroundColorOverlay')}
                    selected={formData.backgroundColorOverlay}
                    style={{ background: 'transparent' }}
                    separator={styleColors?.length && 1}
                  />
                </div>
              </Col>
              <Col sm="4">
                <FormControlLabel
                  style={{ marginBottom: 0 }}
                  control={
                    <Checkbox
                      checked={formData.showBackgroundVideo}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          showBackgroundVideo: e.target.checked,
                        }))
                      }
                      value="showBackgroundVideo"
                    />
                  }
                  label="Show background video"
                />
              </Col>
            </Row>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={save}
              style={{ marginTop: '20px' }}
              disabled={!formData.sceneName}
            >
              {saving ? (
                <CircularProgress color="secondary" size={22} />
              ) : (
                <div>SAVE</div>
              )}
            </Button>
          </AvForm>
        </Col>
        <Col sm="5" style={{ paddingTop: 35, minHeight: 500 }}>
          <Preview saving={saving} sceneUrl={sceneUrl} />
        </Col>
        <Col sm="2" />
      </Row>
    </div>
  )
}

const StyledGroup = styled(AvGroup)`
  margin-bottom: 5px;
  align-items: center;
`
