import Api from './api';

export default class StylesApi extends Api {
  async post(data, accountId) {
    const url = accountId ? `${accountId}/styles` : 'styles'
    return this.postResource(url, data);
  }

  // Read
  async get(query, accountId) {
    const url = accountId ? `${accountId}/styles` : 'styles'
    return this.getResource(url, query);
  }
  
  async getById(id, query) {
    const screen = await this.getResource(`styles/${id}`, query); 
    return screen[0];
  }

  // Update
  async update(id, data) {
    return this.patchResource(`styles/${id}`, data);
  }

  // Delete
  async delete(id) {
    return this.deleteResource(`styles/${id}`)
  }

}