import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

interface IProps {
  value: string
  onSelect: (value: string) => void
}

export const AssetFitSelect = ({ value, onSelect }: IProps) => (
  <FormControl
    variant="standard"
    hiddenLabel
    sx={{
      mt: 0,
      display: 'flex',
      flexWrap: 'wrap',
      '& .MuiInputBase-formControl': {
        margin: 0,
        width: '100px',
        color: 'primary.main',
        fontSize: '0.85rem',
        '&::before': {
          borderBottom: 'none !important',
        },
        '& svg': {
          color: 'primary.main',
        },
      },
    }}
    margin="dense"
  >
    <Select
      variant="standard"
      native
      value={value}
      onChange={(e) => onSelect(e.target.value)}
    >
      <option value="scale">Scale to fit</option>
      <option value="stretch">Stretch</option>
      <option value="zoom">Zoom to fit</option>
    </Select>
  </FormControl>
)
