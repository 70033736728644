import {
  GET_TEMPLATES,
  GET_TEMPLATE_CATEGORIES,
  CREATE_RENDER_REQUEST,
  SET_TEMPLATE_SORT,
} from 'data/actions/types'

// initial state
const INIT_STATE = {
  list: null,
  categories: null,
  templateSort: '1',
  renderRequest: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // get todos
    case GET_TEMPLATES:
      return { ...state, list: payload }

    case GET_TEMPLATE_CATEGORIES:
      return { ...state, categories: payload }

    case CREATE_RENDER_REQUEST:
      return { ...state, renderRequest: payload }

    case SET_TEMPLATE_SORT:
      return { ...state, templateSort: payload }

    default:
      return state
  }
}
