import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import LinearProgress from '@mui/material/LinearProgress';
import { FormText, Row, Col } from 'reactstrap';
import { AvGroup } from 'availity-reactstrap-validation';

import aeIcon from '../../assets/img/aesend-icon.svg';

const SoundUploadForm = ({ theme, formSpec, parentState, handleChange, handleRemove }) => {
  const { ae_field_name, scene, label, user_prompt, file_specs } = formSpec;
  const fileUrl = parentState[ae_field_name];
  const filename = fileUrl && decodeURIComponent(fileUrl.substr(fileUrl.lastIndexOf('/') + 1))

  return (
    <div style={{ textAlign: 'left', marginTop: 10 }}>
      <Row>
        <Col sm='4'>
          <span style={{
            color: scene === 0
              ? theme.palette.aesendPurple.main
              : 'black',
            fontWeight: 'bold' }}>
            {label}
          </span>
          { parentState.uploading && <LinearProgress /> }
          { !parentState.files[ae_field_name] && !fileUrl &&
            <Dropzone
              multiple={false}
              accept='audio/*'
              onDrop={files => handleChange(files, ae_field_name)}
            >
              { ({ getRootProps, isDragActive }) =>
                <DropzoneContainer id='dropzone' isDragActive={isDragActive}
                  {...getRootProps()}>
                  <img src={aeIcon} alt='ae icon' id='dropzone'
                    style={{ maxWidth: 75, margin: 'auto' }} />
                  Drop your audio file here...
                </DropzoneContainer>
              }
            </Dropzone>
          }
          { fileUrl && <div style={{ textAlign: 'right' }}>
              <ReactPlayer
                url={fileUrl}
                controls
                width='100%'
                height='60%'
              />
              <a href='#' style={{ margin: '2px 10px 5px 0px'}} onClick={(e) => {
                e.preventDefault();
                handleRemove(ae_field_name)}}>reset</a>
            </div>
          }
        </Col>
        <Col sm='8'>
          <AvGroup style={{ paddingTop: '30px'}}>
            <FormText color="muted">
              {user_prompt}
            </FormText>
          </AvGroup>
          { parentState[ae_field_name]
            ? <p>
                File uploaded: { !parentState[ae_field_name] ?
                  parentState.files[ae_field_name] &&
                  parentState.files[ae_field_name].map((f,i) =>
                    <li key={i}>{f.name} - {Math.round(f.size/10000)/100} MB</li>)
                  :
                  filename
                }
              </p>
            : <p>{file_specs}</p>
          }
        </Col>
      </Row>
    </div>
  );
}

SoundUploadForm.propTypes = {
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  parentState: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

const DropzoneContainer = styled.div`
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  font-size: 10px;
  text-align: center;
  padding: 10px;
  background-color: ${({isDragActive}) => isDragActive ? '#1b75b550' : '#FFF' };
  border-radius: 5px;
  border: 2px dashed #C7C7C7;
`;

export default SoundUploadForm;
