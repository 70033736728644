import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Autosuggest from 'react-autosuggest'
import StocksApi from 'data/api/stocks'

import { SymbolsList } from './SymbolsList'

import { List, ListItem, ListItemIcon, ListItemText, Popper, } from '@mui/material'

import { Launch as LaunchIcon } from '@mui/icons-material'

const stocksApi = new StocksApi()

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = async (value) => {
  // value = 'app'
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength < 2) {
    return [];
  }

  const suggestions = await stocksApi.getAutocomplete(inputValue)

  if (suggestions?.ResultSet?.Result) {
    return suggestions.ResultSet.Result;
  }

  return [];
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => {
  return suggestion.symbol.toLowerCase();
};

const renderSuggestion = suggestion => (
  <ListItem button>
    <ListItemText primary={suggestion.name}
                  secondary={suggestion.symbol + " : " + suggestion.exchDisp + " (" + suggestion.typeDisp + ")"} />
    <ListItemIconStyled>
      <LaunchIcon />
    </ListItemIconStyled>
  </ListItem>
);


export const StocksSearch = ({ value, onChange }) => {
  const [suggestions, setSuggestions] = useState([])
  const [input, setInputValue] = useState('')
  const listRef = useRef(null);


  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    setSuggestions(await getSuggestions(value))
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  const unScroll = () => {
    if (listRef.current) {
      listRef.current.scrollTo(0, 0);
    }
  }

  const onInputChange = (e, { newValue, method }) => {
      setInputValue(newValue)
      unScroll();
  }

  const onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
    // console.log(suggestion, suggestionValue, suggestionIndex, sectionIndex, method)
      let newArray = [...value];
      newArray.push(suggestion.symbol)
      onChange([...new Set(newArray)]);
      setInputValue('')
  }

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Type to search',
    value: input,
    onChange: onInputChange,
  };

  const renderContainer = ({ containerProps, children }) => {
    const ref = document.getElementById('stocks-search');
    return (
      <Popper
        open={children !== null}
        // open={true}
        anchorEl={ref}
        placement="bottom-start"
        {...containerProps}
      >
        <ListStyled dense width={600} ref={listRef}>
          {children}
        </ListStyled>
      </Popper>
    )
  }

  const renderInputComponent = inputProps => {
    if (inputProps) {
      inputProps.className += ' form-control';
    }

    return (
      <div>
        <label>Add Stock Symbol</label>
        <input {...inputProps} />
      </div>
    )
  }

  console.log(value);

  return (
    <>
      <SymbolsList value={value} onChange={onChange}/>
      {!value.length && <NothingHere>Use field below to search and add stock symbol into the list</NothingHere>}
      <SearchField id='stocks-search'>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderContainer}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
        />
      </SearchField>
    </>
  )

}

const ListStyled = styled(List)`
  width: 450px;
  overflow-x: hidden;
  max-height: 300px;
  border-radius: 4px;
  box-shadow: 1px 1px 12px grey;
  background-color: #FFF;
`

const SearchField = styled.div`
`
const ListItemIconStyled = styled(ListItemIcon)`
  transform: scaleX(-1);
`

const NothingHere = styled.div`
  border: 1px solid #cf6e6e;
  padding: 19px;
  margin: 16px 0;
  text-align: center;
`
