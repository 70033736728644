import React from 'react';
import styled from 'styled-components';
import logo from './logo.svg';
import cls from 'classnames';

const filters = {
  videos: {
    orientation: {
      caption: 'Orientation',
      type: 'radio',
      default: 'all',
      variants: {
        all: {
          caption: 'All Orientations',
          value: 'all'
        },
        landscape: 'Landscape',
        portrait: 'Portrait',
        square: 'Square'
      }
    },
    size: {
      caption: 'Size',
      type: 'radio',
      default: 'all',
      variants: {
        all: {
          caption: 'All Sizes',
        },
        large: '4K',
        medium: 'Full HD',
        small: 'HD'
      }
    }
  },
  photos: {
    orientation: {
      caption: 'Orientation',
      type: 'radio',
      default: 'all',
      variants: {
        all: {
          caption: 'All Orientations',
          value: 'all'
        },
        landscape: 'Landscape',
        portrait: 'Portrait',
        square: 'Square'
      }
    },
    size: {
      caption: 'Size',
      type: 'radio',
      default: 'all',
      variants: {
        all: {
          caption: 'All Sizes',
        },
        large: 'Large',
        medium: 'Medium',
        small: 'Small'
      }
    },
    color: {
      caption: 'Color',
      type: 'radio',
      default: 'all',
      variants: {
        all: {
          caption: 'All Colors'
        },
        red: 'Red',
        orange: 'Orange',
        yellow: 'Yellow',
        green: 'Green',
        turquoise: 'Turquoise',
        blue: 'Blue',
        violet: 'Violet',
        pink: 'Pink',
        brown: 'Brown',
        black: 'Black',
        gray: 'Gray',
        white: 'White'
      }
    }
  }
}

class infoProperties {
  constructor(subject) {
    this.subject = subject
  }

  getInfo() {
    return [
      {
        caption: 'By',
        display: <div><a target="_blank" href={ this.subject.user.url }>{ this.subject.user.name }</a></div>
      },
      {
        caption: 'Duration',
        display: this.subject.duration
      },
      {
        caption: 'Resolution',
        display: `${ this.subject.width }x${ this.subject.height }`
      },
    ]
  }
}

const getFilterResolution = (filters) => {
  let result = undefined;

  if (filters.size) {
    switch (filters.size) {
      case 'small': result = 720; break;
      case 'medium': result = 1080; break;
      case 'large': result = 2160; break;
    }
  }

  return result;
}

const words = (type) => {
  return type === 'videos' ? 'Videos by Pexels' : 'Photos by Pexels'
}

const Logo = ({ type }) => {

  const _words = words(type)

  return (
    <Attribution className="attribution pexels">
      <a href="https://www.pexels.com" target="_blank">
        <img src={ logo } alt={ _words }/>
        <span>{ _words }</span>
      </a>
    </Attribution>
  )
}

const LogoImg = ({ type }) => {
  const _words = words(type)

  return (
    <img src={ logo } alt={ _words }/>
  )
}

const renderResolutionLabel = (item) => <><span>{ item.height }p</span> <span>{ item.quality.toUpperCase() }</span></>

const VideoLabel = ({ item, className }) => {
  return (
    <span className={ cls("asset-label", className) }>
      <span className="width">
        { item.resolution.height }p
      </span>

      <span className="quality">
        { item.resolution.quality.toUpperCase() }
      </span>
    </span>
  )
}

const PhotoLabel = ({ item }) => {
  return (
    <>
      { item.alt }
    </>
  )
}

const renderAssetLabel = ({ item, className }) => {
  return item.type === 'photo' ? <PhotoLabel item={ item } className={ className }/> :
    <VideoLabel item={ item } className={ className }/>;
}


const Attribution = styled.div`
  padding: 8px 0;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 200px;
  }

  img {
    width: 30px;
  }

  span {
    color: #676767;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, roboto, oxygen, cantarell, helvetica neue, ubuntu, sans-serif;
    font-size: 15px;
    margin-left: 8px;
    font-weight: bold;
  }
`
const configuration = {
  filters,
  logo,
  Logo,
  LogoImg,
  renderResolutionLabel,
  renderAssetLabel,
  infoProperties,
  getFilterResolution
}

export { configuration }
