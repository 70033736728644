import cls from "classnames";
import { Icon, IconButton } from "@mui/material";
import React from "react";

const PreviewLink = ({
                         onPreview,
                         previewText = "Preview",
                         copyLinkAria = "Copy preview link",
                         copyIcon = "content_copy",
                         classNames
                     }) => {
    return (
        <div className={ cls('preview-block', classNames) }>
            <a onClick={ () => onPreview() } className="a-preview">{ previewText }</a>
            <IconButton
                aria-label={ copyLinkAria }
                onClick={ () => onPreview(true) }
                size="large">
                <Icon>{ copyIcon }</Icon>
            </IconButton>
        </div>
    );
}

export { PreviewLink };
