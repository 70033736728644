import { useMemo, useState, memo } from 'react'
import { Route, Routes } from 'react-router-dom'

import RctHorizontalLayout from 'components/RctHorizontalLayout'
import UserProfile from 'components/UserProfile'
import RenderScene from './cms/scenes/render'
import ContactModal from 'components/ContactForm/ContactModal'
import Templates from './templates'
import Admin from './admin'
import Cms from './cms'
import {
  AsyncTermsConditionComponent,
  AsyncPrivacyComponent,
} from 'components/AsyncComponent/AsyncComponent'
import { UploadHandler } from 'components/Library/Uploads'
import {
  useAssetUpload,
  AssetUploadContext,
  AssetUploadContextApi,
} from 'contexts/asset-upload-context'

const AppRoutes = memo(({ user, scroll }) => (
  <Routes>
    <Route path={`templates/*`} element={<Templates />} />
    <Route path={`cms/*`} element={<Cms scroll={scroll} />} />
    <Route path={`admin/*`} element={<Admin />} />
    <Route path={`user-management/*`} element={<UserProfile user={user} />} />
    <Route path={`privacy`} element={<AsyncPrivacyComponent />} />
    <Route path={`terms-conditions`} element={<AsyncTermsConditionComponent />} />
  </Routes>
))

const MainApp = ({ user }) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const { api, uploadState } = useAssetUpload()

  const scroll = useMemo(
    () => ({ scrolledToBottom, setScrolledToBottom }),
    [scrolledToBottom]
  )

  return (
    <AssetUploadContextApi.Provider value={api}>
      <ContactModal
        open={contactModalOpen}
        close={() => setContactModalOpen(false)}
        user={user}
      />
      <AssetUploadContext.Provider value={{ uploadState }}>
        <UploadHandler />
      </AssetUploadContext.Provider>
      <Routes>
        <Route path={`scene/:id`} element={<RenderScene />} />
      </Routes>
      <RctHorizontalLayout
        setScrolledToBottom={setScrolledToBottom}
        openContactModal={() => setContactModalOpen(true)}
      >
        {<AppRoutes user={user} scroll={scroll} />}
      </RctHorizontalLayout>
    </AssetUploadContextApi.Provider>
  )
}

export default MainApp
