import React, { useState } from 'react'
import styled from 'styled-components'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'

import AssetPreview from './assetPreview'
import MediaApi from 'data/api/media'
import MediaContentSearch from 'components/MediaContentModal/mediaContentSearch'
import { useProvider } from 'components/MediaContentModal/providers/useProvider'
import { configuration } from 'components/MediaContentModal/providers/pexels'
import { Container } from './Container'

const NO_ASSETS = 'no-assets'

const mediaApi = new MediaApi()
const uploadVariants = [
  {
    provider: 'pexels',
    type: 'videos',
    image: configuration.LogoImg({ type: 'videos' }),
    name: (
      <div className="variant-name">
        <div className="name">Pexels</div>
        <div className="type">Videos</div>
      </div>
    ),
  },
  {
    provider: 'pexels',
    type: 'photos',
    image: configuration.LogoImg({ type: 'photos' }),
    name: (
      <div className="variant-name">
        <div className="name">Pexels</div>
        <div className="type">Images</div>
      </div>
    ),
  },
]

const MediaVariant = ({
  provider,
  type,
  image,
  name,
  setSidePanelParams,
  showName = true,
}) => {
  return (
    <Button
      className="variant"
      variant="outlined"
      color="primary"
      onClick={() => setSidePanelParams({ open: true, provider, type })}
    >
      {image}
      {showName && name}
    </Button>
  )
}

const UploadVariants = ({
  uploadVariants,
  selectedAssets = [],
  setSidePanelParams,
  variant,
}) => {
  let show = false
  if (variant === NO_ASSETS && selectedAssets.size === 0) {
    show = true
  }
  if (variant !== NO_ASSETS && selectedAssets.size > 0) {
    show = true
  }

  if (!show) {
    return null
  }

  return (
    <div className="variants">
      {uploadVariants.map((variant) => {
        return (
          <MediaVariant
            key={variant.provider + variant.type}
            provider={variant.provider}
            type={variant.type}
            image={variant.image}
            name={variant.name}
            selectedAssets={selectedAssets}
            setSidePanelParams={setSidePanelParams}
          />
        )
      })}
    </div>
  )
}

const CloudContentUpload = ({
  accountId,
  userId,
  currentFolderId,
  close,
  provider = 'pexels',
  multiple = true,
  onSelect,
}) => {
  const [sidePanelParams, setSidePanelParams] = useState({})
  const [selectedAssets, setSelectedAssets] = useState(new Map())
  const [loading, setLoading] = useState(false)

  const [providerConfig] = useProvider(provider)
  const RenderAssetLabel = providerConfig.renderAssetLabel

  const removeAsset = (id) => {
    setSelectedAssets((current) => {
      const result = new Map(current)
      result.delete(id)

      return result
    })
  }

  const uploadMedia = async (assets) => {
    setLoading(true)
    const data = {
      currentFolderId,
      assets: Array.from(assets.values()),
      source: 'mediaContent',
      accountId,
    }
    if (onSelect && typeof onSelect === 'function') {
      await onSelect(data)
    } else {
      await mediaApi.import(data)
    }
    close()
  }

  const selectAsset = (asset) => {
    setSidePanelParams({ open: false, aeFieldName: null })

    if (multiple) {
      setSelectedAssets((current) => {
        const result = new Map(current)
        result.set(asset.id, asset)

        return result
      })
    } else {
      const newAssets = new Map(selectedAssets)
      newAssets.set(asset.id, asset)

      return uploadMedia(newAssets)
    }
  }

  return (
    <Container>
      <div className="table">
        <h1>Selected Media</h1>
        {/*{(!selectedAssets || !selectedAssets.length) && (*/}
        {/*  <span><br/><br/>*/}
        {/*    No media have been added.  Click the Add More button to select Media Content assets.*/}
        {/*  </span>*/}
        {/*)}*/}
        {Array.from(selectedAssets.values()).map((asset) => (
          <div key={asset.id} className="asset">
            <AssetPreview url={asset.previewUrl} maxWidth={100} maxHeight={50} />
            <StyledAssetLabel className="label">
              {providerConfig.renderAssetLabel ? (
                <RenderAssetLabel item={asset} />
              ) : (
                <div>
                  {asset.title} <br />
                  <span style={{ fontSize: 'x-small' }}>
                    (asset.format?.replace('_', ' ')} selected)
                  </span>
                </div>
              )}
            </StyledAssetLabel>
            <IconButton onClick={() => removeAsset(asset.id)} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        ))}
        <UploadVariants
          uploadVariants={uploadVariants}
          setSidePanelParams={setSidePanelParams}
          selectedAssets={selectedAssets}
          variant={NO_ASSETS}
        />
      </div>
      {loading && <LinearProgress />}
      {selectedAssets.size > 0 && (
        <div className="footer">
          <UploadVariants
            uploadVariants={uploadVariants}
            setSidePanelParams={setSidePanelParams}
            selectedAssets={selectedAssets}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              return uploadMedia(selectedAssets)
            }}
            disabled={loading}
          >
            Upload
          </Button>
        </div>
      )}
      <StyledDrawer
        open={sidePanelParams.open}
        onClose={() => setSidePanelParams({})}
        anchor="right"
      >
        {sidePanelParams.open && (
          <MediaContentSearch
            open={sidePanelParams.open}
            provider={sidePanelParams.provider}
            type={sidePanelParams.type}
            onSelect={selectAsset}
            accountId={accountId}
            userId={userId}
            multiple={multiple}
          />
        )}
      </StyledDrawer>
    </Container>
  )
}

const StyledDrawer = styled(Drawer)`
  > div {
    width: 50%;
  }
`

const StyledAssetLabel = styled.div`
  .quality {
    text-transform: uppercase;
    margin-left: 8px;
  }
`
export default CloudContentUpload
