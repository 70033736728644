import Api from './api';

export default class TemplateApi extends Api {
  // Templates
  // Create
  async post(data) {
    return this.postResource('templates', data);
  }

  async copyAssets(sourceTemplate, sessionId, fullCopy) {
    let url = `templates/assets/${sessionId}?templateId=${sourceTemplate}`;
    if (fullCopy) {
      url = url + `&fullCopy=${fullCopy}`
    }
    return this.postResource(url);
  }

  // Read
  async get(query, accountId) {
    const url = accountId ? `${accountId}/templates` : 'templates'
    return this.getResource(url, query);
  }

  async getById(id, query) {
    const template = await this.getResource(`templates/${id}`, query); 
    return template[0];
  }

  // Update
  async update(id, data) {
    return this.patchResource(`templates/${id}`, data);
  }

  // Delete
  async delete(id) {
    return this.deleteResource(`templates/${id}`)
  }

  // Template Categories
  async getCategories(accountId) {
    const url = accountId
      ? `${accountId}/templates/categories`
      : 'templates/categories'
    return this.getResource(url);
  }

  // Template Render Requests
  // Create
  async addRenderRequest(data, accountId) {
    const url = accountId ? `${accountId}/render-requests` : 'render-requests';
    return this.postResource(url, data);
  }

  // Read
  async getRenderDetails(id='', query, accountId) {
    const url = accountId
      ? `${accountId}/render-requests/${id}`
      : `render-requests/${id}`
    return this.getResource(url, query);
  }

  // Update
  async updateRenderRequest(id, data) {
    return this.patchResource(`render-requests/${id}`, data);
  }

  // Remove
  async deleteRenderRequest(id) {
    return this.deleteResource(`render-requests/${id}`);
  }

}
