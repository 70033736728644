import styled from 'styled-components'

const Container = ({ children }) => {
  return <Styled>{children}</Styled>
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .variants {
    .variant {
      margin-right: 8px;
      padding: 12px;

      .variant-name {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      .name {
        font-size: 20px;
        font-weight: bold;
        line-height: 100%;
      }

      .type {
        line-height: 100%;
        color: #262a2e;
      }
    }
  }

  .table {
    flex: 1;

    .variants {
      margin-top: 1rem;
    }

    div.asset {
      display: flex;
      align-items: center;
      position: relative;
      background: white;
      border-bottom: 1px solid #ddd;
      padding: 5px 45px 5px 5px;

      button {
        position: absolute;
        right: 5px;
      }

      .label {
        .quality {
          text-transform: uppercase;
          margin-left: 8px;
        }
      }

      .label {
        margin-left: 10px;
        white-space: nowrap;
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .footer {
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 15px;
  }
`

const NoAssetsStyled = styled.div`
  padding-top: 60px;
  height: 100%;

  > p {
    opacity: 0.6;
    font-size: smaller;
    font-style: italic;
  }

  > div.with-button {
    margin-top: 20vh;
    text-align: center;
  }
`

const AssetsScroll = styled.div`
  overflow-y: scroll;
  max-height: 50vh;
`

export { Container, NoAssetsStyled, AssetsScroll }
