import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import MediaContentSearch from 'components/MediaContentModal/mediaContentSearch'
// import StoryblocksSearch from 'components/StoryblocksModal/storyBlocksSearch'
import { useState } from 'react'
import CloudSources, { CloudSourcesEnum, cloudSourcesInformation } from './CloudSources'
import MyDevice from './MyDevice'
import PriorRenders from './PriorRenders'
import Library from './Library'
import { useCreateAssetMutation } from 'store/services/library'
import { AddMediaMethod, LibraryEntryKind } from 'store/types'
import { SourceSection, AssetObject } from 'components/FormSlider.v2'

export enum Sources {
  MyDevice = 'My Device',
  Renders = 'Prior Renders',
  CloudSources = 'Cloud Sources',
  Library = 'Library',
}

type AllowedSources = {
  [key in Sources]?: boolean
}

const DEFAULT_SOURCES: AllowedSources = {
  [Sources.MyDevice]: true,
  [Sources.Renders]: true,
  [Sources.CloudSources]: true,
  [Sources.Library]: false,
}

type MediaUploadModal2Props = {
  isOpen: boolean
  onClose: () => any
  pickerMode?: boolean
  accountId: number
  userId: string
  currentTag?: string | number
  defaultTab?: Sources
  title?: string
  multiple?: boolean
  allowedSources?: AllowedSources
  onUploadAssets?: (assets: File[]) => any
  onSelect?: (assets: AssetObject[], type: SourceSection) => any
  uploadPreSignMethod?: (
    accountId: number,
    name: string,
    mediaType: string
  ) => Promise<string>
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  height: '80vh',
  bgcolor: 'white',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.15)',
  borderRadius: '15px',
  p: 4,
  overflow: 'scroll',
}

const MediaUploadModal2 = ({
  isOpen,
  pickerMode,
  onClose,
  defaultTab,
  title = 'Add Media',
  accountId,
  currentTag,
  onUploadAssets,
  multiple = true,
  allowedSources = DEFAULT_SOURCES,
  onSelect,
  uploadPreSignMethod,
}: MediaUploadModal2Props) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const scroll = { scrolledToBottom, setScrolledToBottom }
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 0.5
    if (bottom) {
      scroll.setScrolledToBottom(true)
    }
  }

  const [currentTab, setCurrentTab] = useState<Sources | CloudSourcesEnum>(
    defaultTab || Sources.MyDevice
  )
  const [createAsset] = useCreateAssetMutation()

  const onCloudSourceClick = (source: CloudSourcesEnum) => {
    setCurrentTab(source)
  }

  const importMedia = async (assets) => {
    createAsset({
      accountId: Number(accountId),
      tagIdList: currentTag ? [Number(currentTag)] : [],
      method: AddMediaMethod.IMPORT,
      media: Array.from(assets.values()).map((asset) => ({
        ...asset,
        kind: LibraryEntryKind.MEDIA,
      })),
    })
    onClose()
  }

  const selectAsset = (asset) => {
    const newAssets = new Map()
    newAssets.set(asset.id, asset)

    return importMedia(newAssets)
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style} onScroll={handleScroll}>
        <Grid
          container
          wrap="nowrap"
          direction="column"
          alignItems="center"
          height="100%"
        >
          <Typography
            id="modal-modal-title"
            variant="subtitle1"
            sx={{ fontWeight: 'bold' }}
            component="h3"
            marginBottom={'20px'}
          >
            {title}
          </Typography>
          <Grid container columnGap=".5rem" marginBottom={'20px'}>
            {Object.entries(Sources)
              .filter(([, value]) => allowedSources[value])
              .map(([key, value]) => {
                const isSelected = currentTab === value
                return (
                  <Button
                    sx={{
                      background: isSelected ? '#F2F5F8' : '',
                      fontSize: '16px',
                      borderRadius: '4px',
                      color: isSelected ? '' : 'black',
                    }}
                    onClick={() => setCurrentTab(value as Sources)}
                    disableRipple
                    key={key}
                  >
                    {value}
                  </Button>
                )
              })}
            {!Object.values(Sources).includes(currentTab as Sources) && (
              <Button
                sx={{
                  background: '#F2F5F8',
                  fontSize: '16px',
                  borderRadius: '4px',
                }}
                disableRipple
              >
                {
                  cloudSourcesInformation[
                    currentTab as keyof typeof cloudSourcesInformation
                  ].label
                }
              </Button>
            )}
          </Grid>
          {currentTab === Sources.MyDevice && (
            <MyDevice
              pickerMode={pickerMode}
              multiple={multiple}
              currentTag={currentTag}
              onDoneClick={(files) => {
                onSelect
                  ? onSelect(files, 'files')
                  : onUploadAssets &&
                    files &&
                    files?.length > 0 &&
                    onUploadAssets(files as unknown as File[])
                onClose()
              }}
              accountId={accountId}
              uploadPreSignMethod={uploadPreSignMethod}
            />
          )}
          {currentTab === Sources.Renders && (
            <PriorRenders accountId={accountId} currentTag={currentTag} close={onClose} />
          )}
          {currentTab === Sources.CloudSources && (
            <CloudSources
              onCloudSourceClick={onCloudSourceClick}
              accountId={Number(accountId)}
            />
          )}
          {currentTab === CloudSourcesEnum.pexels && (
            <MediaContentSearch
              onSelect={
                pickerMode && onSelect
                  ? (asset) => onSelect([asset], 'cloud-sources')
                  : selectAsset
              }
              multiple={multiple}
            />
          )}
          {currentTab === Sources.Library && (
            <Library onSelect={onSelect} accountId={accountId} scroll={scroll} />
          )}
        </Grid>
      </Box>
    </Modal>
  )
}

export default MediaUploadModal2
