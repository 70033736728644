
export const getFilenameFromUrl = url => {
  let filename = url && decodeURIComponent(url.split('/')[url.split('/').length - 1]);
  return filename.indexOf('?') !== -1
    ? filename.substr(0, filename.indexOf('?'))
    : filename
}

export const getFileExtension = filename =>
  filename.split('.')[filename.split('.').length - 1].toLowerCase();

export const getSmallVideoUrl = url => {
  const parts = url.split('.');
  parts.splice(parts.length - 2, 1, `${parts[parts.length-2]}-small`);
  return parts.join('.').replace('mov', 'mp4');
}

export const getFileType = url => {
  if (!url) return {}
  let filename = url && decodeURIComponent(url.substr(url.lastIndexOf('/') + 1))
  
  filename = filename.indexOf('?') !== -1
    ? filename.substr(0, filename.indexOf('?'))
    : filename

  const extension = filename && filename.split('.').pop().toLowerCase();

  if (['png','jpg','gif', 'jpeg'].includes(extension)) {
    return { filename, type: `image/${extension}` };
  } else if (['mp4','mov','webm'].includes(extension)) {
    return { filename, type: `video/${extension}`};
  } else {
    return { filename, type: `other/${extension}`};
  }
}