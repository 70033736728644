import React from 'react';
import styled from 'styled-components'

const getLogoLocation = locationCode => {
  if (locationCode === 'tl') {
    return { top: '5vmin', left: '5vmin' }
  }
  if (locationCode === 'tr') {
    return { top: '5vmin', right: '5vmin' }
  }
  if (locationCode === 'bl') {
    return { bottom: '13vmin', left: '9vmin' }
  }
  return { bottom: '5vmin', right: '5vmin' }
}

const LogoDisplay = ({ logoLocation, logoUrl }) =>
  logoLocation
    ? (
      <LogoWrapper style={getLogoLocation(logoLocation)}>
        <img
          alt="logo"
          src={logoUrl}
          style={{ maxWidth: '10vw', maxHeight: '10vh' }}
        />
      </LogoWrapper>
    ) : (
      <></>
    )

const LogoWrapper = styled.div`
  position: absolute;
  padding: 3vmin;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 10;
`

export default LogoDisplay
