import React from 'react';
import { Label, FormText } from 'reactstrap';
import { AvGroup, AvField, AvFeedback } from 'availity-reactstrap-validation';

const TimingPickerForm = ({ formSpec, parentState, handleChange }) => 
  <div>
    <img src={formSpec.guide_image || 'https://react.semantic-ui.com/images/wireframe/image-text.png'}
      alt='img' width='100%' 
      style={{ paddingBottom: '30px', display: 'block', margin: 'auto' }} />
    <AvGroup>
      <Label for='text'>Input for {formSpec.name}</Label>
        <AvField type='select' name={formSpec.ae_field_name}
          onChange={handleChange} value={parentState[formSpec.ae_field_name]}>
          <option value='' disabled>-- choose timer --</option>
          { formSpec.choices && formSpec.choices.map(item => 
            (!formSpec.timing_choices ||
            formSpec.timing_choices.includes(item.label.split(' ')[0])) &&
            <option key={item.label} value={item.value}>
              {item.label !== '0 secs' ? item.label : 'Remove this segment'}
            </option>
          )}
        </AvField>
      <AvFeedback>This field is required.</AvFeedback>
      <FormText>
        Please specify how long this scene should be displayed
      </FormText>
    </AvGroup>
  </div>

export default TimingPickerForm;