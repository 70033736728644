import Api from './api'

export default class AdminApi extends Api {
  createAccount(data) {
    return this.postResource('admin/accounts', data)
  }

  updateAccount(id, data) {
    return this.putResource(`admin/accounts/${id}`, data)
  }

  getAccounts() {
    return this.getResource('admin/accounts')
  }

  getAccount(id) {
    return this.getResource(`admin/accounts/${id}`)
  }

  deleteAccount(id) {
    return this.deleteResource(`admin/accounts/${id}`)
  }

  getCompanies() {
    return this.getResource('admin/companies')
  }

  getCompanyAccounts(id) {
    return this.getResource(`admin/companies/${id}`)
  }

  updateAccountTemplates(data) {
    return this.postResource('admin/template-mapping', data)
  }

  updateAccountUsers(data) {
    return this.postResource('admin/user-mapping', data)
  }

  replaceAccountUsers(data) {
    return this.putResource('admin/user-mapping', data)
  }

  replaceCompanyAccounts(id, data) {
    return this.putResource(`admin/companies/${id}/accounts`, data)
  }

  addUser(data) {
    return this.postResource('admin/users', data)
  }

  editUser(id, data) {
    return this.putResource(`admin/users/${id}`, data)
  }

  deleteUser(id) {
    return this.deleteResource(`admin/users/${id}`)
  }

  getUsers() {
    return this.getResource('admin/users')
  }

  getUser(id) {
    return this.getResource(`admin/users/${id}`)
  }

  getUserCompanies(id) {
    return this.getResource(`admin/users/${id}/companies`)
  }

  verifyUserEmail(id) {
    return this.patchResource(`admin/users/${id}/verify-email`)
  }

  sendVerificationEmail(id) {
    return this.getResource(`admin/users/${id}/send-verify-email`)
  }

  revokeAccess(id) {
    return this.putResource(`admin/users/${id}/revoke-access`)
  }

  syncWithSendgrid() {
    return this.postResource('admin/users/sync-send-grid', {})
  }

  upgradeAllPlayers(immediate = false) {
    return this.postResource('admin/screens/upgrade', { immediate })
  }
}
