import React from 'react'
import { get } from 'lodash'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { TextField } from 'formik-mui'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useAuthActions } from 'hooks/useActions'

const passwordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('Please enter your password')
    .min(6, ' Password must contain at least 6 characters'),
  newPassword: Yup.string()
    .required('Please enter your password')
    .min(6, ' Password must contain at least 6 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
    .required('Please repeat your password'),
})

const PasswordUpdate = ({ user }) => {
  const authActions = useAuthActions([])
  const sub = get(user, 'idTokenPayload.sub')
  const isAuth0User = sub.startsWith('auth0') // vs. Google, etc
  const userProvider = sub.split('|')[0]

  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <h2 className="mb-4">Password</h2>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={passwordSchema}
        onSubmit={authActions.changePwd}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <Grid container spacing={3} alignItems="center">
              <Grid item sm={3} className="text-right">
                Current Password
              </Grid>
              <Grid item sm={9}>
                <Field
                  component={TextField}
                  name="currentPassword"
                  fullWidth
                  variant="outlined"
                  type="password"
                  label="Current Password"
                  disabled={!isAuth0User}
                />
              </Grid>
              <Grid item sm={3} className="text-right">
                New password
              </Grid>
              <Grid item sm={9}>
                <Field
                  component={TextField}
                  name="newPassword"
                  fullWidth
                  variant="outlined"
                  type="password"
                  label="New Password"
                  disabled={!isAuth0User}
                />
              </Grid>
              <Grid item sm={3} className="text-right">
                Confirm Password
              </Grid>
              <Grid item sm={9}>
                <Field
                  component={TextField}
                  name="confirmPassword"
                  fullWidth
                  variant="outlined"
                  type="password"
                  label="Confirm Password"
                  disabled={!isAuth0User}
                />
              </Grid>
              <Grid item sm={3} />
              <Grid
                item
                sm={9}
                className="d-flex align-items-center justify-content-between"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="text-white m-10"
                  disabled={!isValid || !isAuth0User}
                  type="submit"
                >
                  Update
                </Button>
                {!isAuth0User && (
                  <div className="text-right font-sm text-muted">
                    Cannot change {userProvider} password
                  </div>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default PasswordUpdate
