import axios from 'axios'
import { merge } from 'lodash'
import { AUTH_CONFIG } from '../../Auth/auth-variables'

export default class Api {
  /**
   * @param [idToken]
   */
  constructor(idToken?: string) {
    const id_token = idToken || localStorage.getItem('id_token')
    this.config = {
      headers: {
        Authorization: `Bearer ${id_token}`,
      },
    }
  }

  getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    }
  }

  async getResource(
    name: string,
    query: string | { [code: string]: string } = '',
    _throw = false
  ) {
    if (typeof query === 'object') {
      query = this.stringifyParams(query)
    }
    try {
      const { data } = await axios.get(
        `${AUTH_CONFIG.apiEndpoint}/${name}?${query}`,
        this.getConfig()
      )
      if (data.data) return data.data
      return data
    } catch (err) {
      console.log(err)
      if (_throw) {
        throw err
      }
    }
  }

  async postResource(name, body, config = {}) {
    try {
      const { data } = await axios.post(
        `${AUTH_CONFIG.apiEndpoint}/${name}`,
        body,
        merge(this.getConfig(), config)
      )
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async patchResource(name, body) {
    try {
      const { data } = await axios.patch(
        `${AUTH_CONFIG.apiEndpoint}/${name}`,
        body,
        this.getConfig()
      )
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async putResource(name, body) {
    try {
      const { data } = await axios.put(
        `${AUTH_CONFIG.apiEndpoint}/${name}`,
        body,
        this.getConfig()
      )
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async postFormData(name, formData) {
    try {
      const config = { ...this.getConfig() }
      config.headers['Content-Type'] = 'multipart/form-data'

      const { data } = await axios.post(
        `${AUTH_CONFIG.apiEndpoint}/${name}`,
        formData,
        config
      )
      return data.data
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async deleteResource(name, query = '') {
    console.log(name)
    try {
      await axios.delete(`${AUTH_CONFIG.apiEndpoint}/${name}?${query}`, this.getConfig())
      return 'success'
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  stringifyParams(params) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')
  }
}
