import React from 'react'
import styled from 'styled-components'
import FolderIcon from '@mui/icons-material/Folder'

const FolderView = ({ currentFolderId, folders, onClick, search }) => {
  return (
    <Container>

      {folders
        .filter(f => !currentFolderId ? !f.parent : f.parent === currentFolderId)
        .map(folder => (
          <div
            className="pl-3 col-sm-12 col-md-6 col-xl-6"
            key={`${folder.name}-${folder.id}`}
          >
            <Folder onClick={() => onClick(folder.id)}>
              <div className="p-3 d-flex align-items-center">
                <FolderIcon />
              </div>
              <div style={{ justifyContent: 'space-between', flex: 1 }}>
                {folder.name}
              </div>
            </Folder>
          </div>
        ))}
    </Container>
  )
}

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`

const Folder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dadce0;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  background: white;
  padding-right: 5px;
`

export default FolderView
