import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from '@mui/material'
import { useState } from 'react'

const NewConditionsModal = ({ onConfirm }: { onConfirm: () => void }) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <Modal open>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30vw',
          bgcolor: 'background.paper',
          border: '1px solid grey',
          boxShadow: 24,
          p: '25px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Change in Terms & Conditions and Privacy Policy
        </Typography>
        <Typography id="modal-modal-description" sx={{ mb: '20px' }}>
          We have updated our{' '}
          <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a href="/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          . Please review the links to the these documents and click the checkbox below
        </Typography>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            }
            label={
              <Typography>
                I agree to the aesend<sup>®</sup> Terms & Conditions and Privacy Policy.
              </Typography>
            }
          />
          <Button
            disabled={!isChecked}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}

export default NewConditionsModal
