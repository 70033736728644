import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { SxProps } from '@mui/material'

interface IProps {
  handleToggle: (value: number | string) => () => void
  checked: number[] | string[]
  items: { label: string; value: number | string }[]
  sx?: SxProps
}

export const CheckboxList = ({ handleToggle, checked = [], items, sx = {} }: IProps) => {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        padding: 0,
        ...sx,
      }}
      dense
    >
      {items
        ?.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
        ?.map(({ label, value }) => {
          const labelId = `checkbox-list-label-${value}`

          return (
            <ListItem key={value} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleToggle(value)}
                dense
                sx={{ padding: '0 8px' }}
              >
                <ListItemIcon sx={{ minWidth: 20 }}>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    sx={{ padding: 0.5 }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& span': { font: 'normal 400 14px/18px DMSans', color: '#000000' },
                  }}
                  id={labelId}
                  primary={label}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
    </List>
  )
}
