import {useSelector} from "react-redux";

export const useUser = () => {
    return useSelector(state => state.authUser);
}

export const usePermitted = (allowedRoles) => {
    const user = useUser();
    const { profile, loading } = user;
    const roles = profile && profile.map(profile => profile.role_name);
    const permitted = allowedRoles.some(item => roles && roles.includes(item));

    return {permitted, profile, loading, user};
}
