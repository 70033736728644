import { useLocalLibrarySearch } from 'hooks/useLocalLibrarySearch'
import { FilterControl, StickyWrapper } from 'components/Library'
import GroupAssetRenderer from 'routes/cms/library/groupAssetRenderer'
import { SelectModes } from 'components/Library/Tile'
import { Grouping } from 'components/Library/types'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { Box } from '@mui/material'
import { AssetObject, SourceSection } from 'components/FormSlider.v2'
import { useMediaActions } from 'hooks/useActions'

interface IProps {
  accountId: number
  onSelect: (assets: AssetObject[], type: SourceSection) => void
  scroll: { scrolledToBottom: boolean; setScrolledToBottom: (v: boolean) => void }
}

const Library = ({ onSelect, accountId, scroll }: IProps) => {
  const mediaActions = useMediaActions([])
  const { libAssets, state, dispatch, fetching } = useLocalLibrarySearch({
    scroll,
    user: { activeAccount: accountId },
    fetchTags: true,
  })

  const onAssetSelect = async (asset: AssetIdentifier) => {
    if (asset.assetType !== 0) return
    const assetDetails = libAssets.find(
      (a) => a.id === asset.assetId && a.kind === 'Media'
    )
    const url = await mediaActions.getPresignedUrl(asset.assetId)
    const selectedAsset: AssetObject = {
      id: asset.assetId,
      previewUrl: assetDetails?.assetInfo.previewUrl,
      thumbnail_url: assetDetails?.assetInfo.thumbnailUrl,
      title: assetDetails?.title,
      media_type: assetDetails?.assetInfo.mediaType,
      url,
    }
    onSelect([selectedAsset], 'media-library')
  }

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <StickyWrapper top={-33} sx={{ background: 'white', padding: '0 !important' }}>
        <FilterControl
          selectMode
          viewMode={state.viewMode}
          zoom={state.zoom}
          sort={state.sort}
          search={state.search}
          dispatch={dispatch}
          tagJoin={state.tagJoin}
          filters={state.filters}
          categories={state.categories}
          collections={state.collections}
          tags={state.tags}
          types={state.types}
          aspectRatios={state.aspectRatios}
          sx={{ flex: 1 }}
        />
      </StickyWrapper>
      <GroupAssetRenderer
        hideContextMenu
        selectMode={SelectModes.SINGLE_WITH_REMOVE}
        pickedAssets={[]}
        selectedGroup={Grouping.all}
        selectedViewMode={state.viewMode}
        files={[...libAssets]}
        fetching={fetching}
        onAssetClick={onAssetSelect}
        zoom={state.zoom}
      />
    </Box>
  )
}

export default Library
