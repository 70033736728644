/**
 * Signin Firebase
 */

import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Link } from 'react-router-dom'
import { Form, FormGroup, Input } from 'reactstrap'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'
import QueueAnim from 'rc-queue-anim'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

// app config
import AppConfig from 'constants/AppConfig'
import { useAuthActions } from 'hooks/useActions'

import SpotDirectLogo from 'assets/img/SpotDirectLogo.svg'

const Signin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [state, setState] = useState({
    email: '',
    password: '',
    forgotMode: false,
    errors: null,
  })
  const { loading } = useSelector((state) => state.authUser)
  const authActions = useAuthActions()
  const { email, password } = state

  const account = location.search.split('=')[1]

  /**
   * On User Login
   */
  const onUserLogin = (event, errors, values) => {
    if (!errors.length) authActions.signinUser({ email, password })
  }

  /**
   * On User Sign Up
   */
  const onUserSignUp = () => navigate(`/signup${account ? `?account=${account}` : ``}`)

  const onResetPwd = async (e) => {
    e.preventDefault()
    if (email !== '') {
      await authActions.resetPwd(email, navigate)
      setState({
        ...state,
        forgotMode: false,
      })
    }
  }

  return (
    <QueueAnim type="bottom" duration={2000}>
      <div className="rct-session-wrapper">
        {loading && <LinearProgress />}
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container" stlye={{ position: 'relative' }}>
              <div style={{ position: 'relative', top: '30px', left: '80%' }}>
                <a className="mr-15 text-black" onClick={onUserSignUp}>
                  Create New account?
                </a>
                <Button variant="contained" className="btn-light" onClick={onUserSignUp}>
                  Sign Up
                </Button>
              </div>
              <div className="session-logo" style={{ marginBottom: '10px' }}>
                <Link to="/">
                  <img
                    src={account === 'spotDirect' ? SpotDirectLogo : AppConfig.appLogo}
                    alt="session-logo"
                    className="img-fluid"
                    width="200"
                    style={{ paddingBottom: 10 }}
                  />
                </Link>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className="session-inner-wrapper">
          <div className="container">
            <div className="row row-eq-height">
              <div className="col-sm-2 col-md-2 col-lg-2" />
              <div className="col-sm-7 col-md-7 col-lg-8">
                <div className="session-body text-center">
                  {!state.forgotMode ? (
                    <div>
                      <div className="session-head mb-30">
                        <h2 className="font-weight-bold">
                          Login to{' '}
                          {account === 'spotDirect' ? 'Spot Direct' : AppConfig.brandName}
                        </h2>
                        <p className="mb-0">template based video customization</p>
                      </div>
                      <AvForm onSubmit={onUserLogin}>
                        <AvGroup className="has-wrapper">
                          <AvInput
                            type="email"
                            value={email}
                            name="user-mail"
                            id="user-mail"
                            className="has-input input-lg"
                            placeholder="Enter Email Address"
                            validate={{ email: true }}
                            required
                            onChange={(event) =>
                              setState({
                                ...state,
                                email: event.target.value,
                              })
                            }
                          />
                          <span className="has-icon">
                            <i className="ti-email"></i>
                          </span>
                          <AvFeedback style={{ textAlign: 'left' }}>
                            That is not a valid email...
                          </AvFeedback>
                        </AvGroup>
                        <AvGroup className="has-wrapper">
                          <AvInput
                            value={password}
                            type="Password"
                            name="user-pwd"
                            id="pwd"
                            className="has-input input-lg"
                            placeholder="Password"
                            required
                            onChange={(event) =>
                              setState({
                                ...state,
                                password: event.target.value,
                              })
                            }
                          />
                          <span className="has-icon">
                            <i className="ti-lock"></i>
                          </span>
                          <AvFeedback style={{ textAlign: 'left' }}>
                            That is not a valid password...
                          </AvFeedback>
                        </AvGroup>

                        <AvGroup className="mb-15">
                          <Button
                            color="primary"
                            className="btn-block text-white w-100"
                            variant="contained"
                            size="large"
                            type="submit"
                          >
                            {loading ? (
                              <CircularProgress size={16} />
                            ) : (
                              <div>Sign in</div>
                            )}
                          </Button>
                        </AvGroup>
                      </AvForm>
                      <Link to="/contact?signin" style={{ float: 'left' }}>
                        Need help?
                      </Link>
                      <Link
                        to="#"
                        style={{ float: 'right' }}
                        onClick={() =>
                          setState({
                            ...state,
                            forgotMode: true,
                          })
                        }
                      >
                        Forgot Password?
                      </Link>
                      <div style={{ textAlign: 'center' }}>
                        <p style={{ display: 'inline-block', marginLeft: 35, marginBottom: 10 }}>
                          or sign in with
                        </p>
                      </div>
                      <Fab
                        className="btn-facebook mr-15 mb-20 text-white"
                        style={{ height: 20, width: 35 }}
                        onClick={() => authActions.signinUserWithFacebook(navigate)}
                      >
                        <i className="zmdi zmdi-facebook"></i>
                      </Fab>
                      <Fab
                        className="btn-google mr-15 mb-20 text-white"
                        style={{ height: 20, width: 35 }}
                        onClick={() => authActions.signinUserWithGoogle(navigate)}
                      >
                        <i className="zmdi zmdi-google"></i>
                      </Fab>
                      <p className="text-muted">
                        By signing up you agree to {AppConfig.brandName}
                      </p>
                      <p className="mb-0">
                        <a
                          target="_blank"
                          href="/terms-conditions"
                          className="text-muted"
                        >
                          Terms of Service
                        </a>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="session-head mb-30">
                        <h2 className="font-weight-bold">
                          Reset your {AppConfig.brandName} Password
                        </h2>
                        <p className="mb-0">
                          Enter your email to begin the reset process
                        </p>
                      </div>
                      <Form>
                        <FormGroup className="has-wrapper">
                          <Input
                            type="mail"
                            value={email}
                            name="user-mail"
                            id="user-mail"
                            className="has-input input-lg"
                            placeholder="Enter Email Address"
                            onChange={(event) =>
                              setState({
                                ...state,
                                email: event.target.value,
                              })
                            }
                          />
                          <span className="has-icon">
                            <i className="ti-email"></i>
                          </span>
                        </FormGroup>
                        <Button
                          color="primary"
                          className="btn-block text-white w-100"
                          variant="contained"
                          size="large"
                          type="submit"
                          onClick={onResetPwd}
                        >
                          Reset Password
                        </Button>
                        <Link
                          to="#"
                          style={{ float: 'right', paddingTop: '10px' }}
                          onClick={() =>
                            setState({
                              ...state,
                              forgotMode: false,
                            })
                          }
                        >
                          Back to Login?
                        </Link>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueueAnim>
  )
}

export default Signin
