import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Preloader = ({loading, maxWidth, maxHeight}) => {
    return (
        <PreloaderStyled $loading={loading} className="sqloader" $width={maxWidth} $height={maxHeight}>
            <div/>
            <div/>
            <div/>
        </PreloaderStyled>
    )
}

const PreloaderStyled = styled.div`
  display: ${({$loading}) => $loading ? 'flex' : 'none'};
  max-width: ${({$width}) => $width + 'px'};
  max-height: ${({$height}) => $height + 'px'};
`;

Preloader.propTypes = {
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    loading: PropTypes.bool
};

export default Preloader;
