import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'
import InputAdornment from '@mui/material/InputAdornment'

interface IProps {
  anchorEl: HTMLButtonElement | null
  initialDuration: number
  handleClose: (duration: number) => void
}

export const AssetDuration = ({ anchorEl, handleClose, initialDuration }: IProps) => {
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    if (!anchorEl) {
      setSeconds(0)
      setMinutes(0)
    } else {
      setSeconds(Math.round(initialDuration % 60))
      setMinutes(Math.floor(initialDuration / 60))
    }
  }, [anchorEl])

  return (
    <Popover
      id="duration-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => handleClose(minutes * 60 + seconds)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': { padding: 1, display: 'flex', alignItems: 'center' },
      }}
    >
      <TextField
        autoFocus
        value={minutes}
        onChange={(e) => setMinutes(Number(e.target.value))}
        margin="dense"
        size="small"
        id="minutes"
        variant="outlined"
        sx={{ maxWidth: 90, mr: 1 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">min</InputAdornment>,
        }}
      />
      :
      <TextField
        value={seconds}
        onChange={(e) => setSeconds(Number(e.target.value))}
        margin="dense"
        size="small"
        id="seconds"
        variant="outlined"
        sx={{ maxWidth: 80, ml: 1 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">sec</InputAdornment>,
        }}
      />
    </Popover>
  )
}
