import { IconButton, Menu, MenuItem } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'
import { useState } from 'react'
import Check from '@mui/icons-material/Check'
import { SORT_OPTIONS } from 'routes/cms/library/types'

type OrderDropdownProps = {
  value: string
  onChange: (value: string) => void
}
const OrderDropdown = ({ value, onChange }: OrderDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onMenuItemClick = (value: string) => {
    onChange(value)
    handleClose()
  }

  return (
    <>
      <IconButton
        sx={{
          padding: '5px',
          marginLeft: 2,
          borderRadius: '5px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: Boolean(value) ? 'primary.main' : undefined,
          color: Boolean(value) ? 'white' : undefined,
        }}
        onClick={handleClick}
      >
        <SortIcon />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {Object.values(SORT_OPTIONS)?.map((option) => (
          <MenuItem key={option} onClick={() => onMenuItemClick(option)}>
            {option}
            {value === option && <Check color="primary" />}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default OrderDropdown
