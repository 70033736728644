export const BackAsset = ({ fillColor }: { fillColor?: string }) => (
  <>
    <div
      style={{
        width: '85%',
        borderRadius: '15px 15px 0 0',
        background: fillColor || '#DFE6ED',
        opacity: '.4',
        height: '6px',
      }}
    />
    <div
      style={{
        width: '90%',
        borderRadius: '15px 15px 0 0',
        background: fillColor || '#DFE6ED',
        opacity: '.7',
        height: '8px',
      }}
    />
  </>
)
