import { TileWithMenu } from './TileWithMenu'
import { Asset } from 'routes/cms/library/types'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { SxProps } from '@mui/material'

interface IProps {
  assets: Asset[]
  toggleCheckedItem: (v: AssetIdentifier[]) => any
  checkedItems: number[]
  onAssetClick: (v: number) => void
  unPin: (assetIds: AssetIdentifier[]) => void
  pin: (assetIds: AssetIdentifier[]) => void
  sx?: SxProps
  openShareModal?: (assets: AssetIdentifier[] | number[]) => void
  openDownloadModal?: ({ asset }: { asset: AssetIdentifier }) => void
  openTrimCropModal?: ({ asset }: { asset: AssetIdentifier }) => void
  cloneAsset?: ({ asset }: { asset: AssetIdentifier }) => void
  updateAsset?: ({ asset }: { asset: AssetIdentifier }) => void
  deleteAsset?: ({ asset }: { asset: AssetIdentifier }) => void
}

export const TileList = ({ assets, ...props }: IProps) => {
  return (
    <>
      {assets.map((asset) => (
        <TileWithMenu key={`${asset.kind}-${asset.id}`} {...asset} {...props} />
      ))}
    </>
  )
}
