import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import _ from 'lodash'

import navLinks from './NavLinks'

const HorizontalMenu = ({ location }) => {
  const navigate = useNavigate()

  return (
    <Box
      className="horizontal-menu"
      sx={{
        display: 'flex',
        pl: 2,
        height: '3rem',
        left: 0,
        right: 0,
        zIndex: 99,
        boxShadow: 'none !important',
        backgroundColor: 'unset !important',
      }}
    >
      <ul className="list-unstyled nav" style={{ flex: 1, flexWrap: 'nowrap' }}>
        {navLinks.category1.map((menu) => (
          <li key={menu.menu_title} className="nav-item">
            <a
              role="button"
              className={`nav-link no-arrow ${
                location.pathname === menu.path ? 'active' : ''
              }`}
              onClick={() => navigate(menu.path)}
            >
              <span className="menu-title">{menu.menu_title}</span>
            </a>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export default HorizontalMenu
