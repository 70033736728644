import { Chip, Grid, Box, TextField, CircularProgress, IconButton } from '@mui/material'
import { Gallery } from 'react-grid-gallery'
import { useNavigate } from 'react-router-dom'

import LibraryTable from 'components/LibraryTable/LibraryTable'
import { Grouping, ViewMode } from 'components/Library/types'
import FileIcon from 'components/Library/Tile/FileIcon'
import { GalleryGroupTileWrapper } from 'components/Library/Tile/GroupTile'
import { YYYYMMDD_hhMMSSA } from 'helpers/helpers'
import { AssetType, LibraryEntryKind, TagNamespace } from 'store/types'
import { Asset, getFileTypeFromMediaType, SORT_OPTIONS } from '../types'
import { GroupingItem, useLibrary } from 'contexts/library-context'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { NotificationManager } from 'react-notifications'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { GridListing } from 'components/Library'
import folder from 'assets/img/folder.svg'
import {
  ASSET_TYPE_IDS,
  ASSET_TYPE_IMAGES,
  ASSET_COLORS,
} from 'components/Library/constants'
import { SelectModes } from 'components/Library/Tile'
import TileAsset from 'components/Library/Tile/TileAsset'
import ArrowCollection from 'assets/img/no-collections.svg'
import ArrowCategory from 'assets/img/no-categories.svg'
import ContextMenu, { ContextMenuOption } from 'components/Library/Tile/ContextMenu'
import PencilIcon from 'assets/img/pencil.svg'
import CopyIcon from 'assets/img/copy.svg'
import TagIcon from 'assets/img/tag.svg'
import SaveIcon from 'assets/img/save.svg'
import ShareIcon from 'assets/img/share.svg'
import DeleteIcon from 'assets/img/delete.svg'
import CropIcon from 'assets/img/CropIcon.svg'
import ReorderIcon from 'assets/img/reorder.svg'
import React, { useState } from 'react'

type GroupAssetRendererProps = {
  sort?: SORT_OPTIONS
  selectMode?: SelectModes
  selectExclusionTypes?: AssetType[]
  pickedAssets?: AssetIdentifier[]
  selectedGroup: Grouping
  selectedViewMode: ViewMode
  files: Asset[]
  checkedAsset?: AssetIdentifier[]
  toggleCheckedAsset?: (v: AssetIdentifier[]) => any
  onAssetClick: (asset: AssetIdentifier) => any
  pin?: (assetIds: AssetIdentifier[]) => void
  unPin?: (assetIds: AssetIdentifier[]) => void
  openDownloadModal?: ({ asset }: { asset: AssetIdentifier }) => void
  openShareModal?: ({ asset }: { asset: AssetIdentifier }) => void
  openTrimCropModal?: ({ asset }: { asset: AssetIdentifier }) => void
  cloneAsset?: ({ asset }: { asset: AssetIdentifier }) => void
  updateAsset?: ({ asset }: { asset: AssetIdentifier }) => void
  deleteAsset?: ({
    asset,
  }: {
    asset: AssetIdentifier | AssetIdentifier[]
  }) => Promise<void>
  renameGroupingTag?: (tagId: number, name: string) => void
  deleteGroupingTag?: (tagId: number) => void
  zoom?: number
  checkedGroup?: number[]
  toggleCheckedGroup?: (v: number | number[]) => any
  fetching?: boolean
  hideContextMenu?: boolean
}

const sortByNameAsc = (a: GroupingItem, b: GroupingItem) => {
  if (a.label.toUpperCase() < b.label.toUpperCase()) {
    return -1
  }
  if (a.label.toUpperCase() > b.label.toUpperCase()) {
    return 1
  }
  return 0
}
const sortByDateAsc = (a: GroupingItem, b: GroupingItem) => {
  if (a.createdAt < b.createdAt) {
    return -1
  }
  if (a.createdAt > b.createdAt) {
    return 1
  }
  return 0
}

const sortGrouping = (assets: GroupingItem[], sortType?: SORT_OPTIONS) => {
  switch (sortType) {
    case SORT_OPTIONS.aToZAsc:
      return assets.sort(sortByNameAsc)
    case SORT_OPTIONS.aToZDesc:
      return assets.sort(sortByNameAsc).reverse()
    case SORT_OPTIONS.dateAsc:
      return assets.sort(sortByDateAsc)
    case SORT_OPTIONS.dateDesc:
      return assets.sort(sortByDateAsc).reverse()
    default:
      return assets
  }
}

const allAssetsTableColumns = [
  'Preview',
  { label: 'Name', id: 'name' },
  { label: 'Last Modified', id: 'lastModified' },
  { label: 'Type', id: 'type' },
  'Tags',
  'Actions'
]
const groupingTableColumns = ['Preview', 'Name', 'Items']

const SelectedGrid = ({
  selectMode,
  selectExclusionTypes,
  pickedAssets,
  viewMode,
  files,
  fetching,
  checkedAsset,
  toggleCheckedAsset,
  onAssetClick,
  pin,
  unPin,
  zoom,
  openDownloadModal,
  openShareModal,
  openTrimCropModal,
  cloneAsset,
  updateAsset,
  deleteAsset,
  hideContextMenu,
}: {
  selectMode?: SelectModes
  selectExclusionTypes?: AssetType[]
  pickedAssets?: AssetIdentifier[]
  zoom: number
  viewMode: ViewMode
  files: Asset[]
  fetching?: boolean
  checkedAsset: AssetIdentifier[]
  toggleCheckedAsset: (v: AssetIdentifier | AssetIdentifier[]) => any
  onAssetClick: (asset: AssetIdentifier) => any
  unPin?: (assets: AssetIdentifier[]) => void
  pin?: (assets: AssetIdentifier[]) => void
  openShareModal?: (asset: AssetIdentifier) => void
  openDownloadModal?: (asset: AssetIdentifier) => void
  openTrimCropModal?: (asset: AssetIdentifier) => void
  cloneAsset?: (asset: AssetIdentifier) => void
  updateAsset?: (asset: AssetIdentifier) => void
  deleteAsset?: (asset: AssetIdentifier) => void
  hideContextMenu?: boolean
}) => {
  const getFilePreview = (file: Asset) => {
    const fileType = getFileTypeFromMediaType(file.assetInfo.mediaType)
    if (
      (fileType === 'Video' || fileType === 'Image') &&
      (file.assetInfo.thumbnailUrl || file.assetInfo.previewUrl)
    ) {
      return (
        <img
          width="60px"
          src={file.assetInfo.thumbnailUrl || file.assetInfo.previewUrl}
          style={{ borderRadius: '4px' }}
        />
      )
    } else {
      return <FileIcon height="25px" width="25px" type={fileType} />
    }
  }

  const navigate = useNavigate()
  const { dispatch, state } = useLibrary()
  const { editingTileTitle } = state
  const { assetId, assetType: assetTypeId } = editingTileTitle || {}
  const [renameValue, setRenameValue] = useState('')

    const onTitleSaveClick = async (newName: string, asset: Asset, assetType: any) => {
        if (updateAsset) {
          dispatch({
            type: 'setLoadingTiles',
            payload: { assetType: assetType, assetId: asset.id },
          })
          dispatch({ type: 'setEditingTileTitle', payload: undefined })
          await updateAsset({
            asset: { assetType: assetType, assetId: asset.id },
            data: { name: newName },
          })
            .unwrap()
            .then(() => dispatch({ type: 'clearLoadingTiles' }))
            .then(() =>
              {NotificationManager.success(`Successfuly updated.`, `Success`, 3000)
              setRenameValue('')}
            )
            .catch(() =>
              NotificationManager.error(
                `There was an error performing this action. Try again.`,
                `Error`,
                3000
              )
            )
        }
      }

    const onTileCancelClick = () => {
      dispatch({ type: 'setEditingTileTitle', payload: undefined })
    }
  
  return viewMode === 'grid' ? (
    <GridListing
      selectMode={selectMode}
      selectExclusionTypes={selectExclusionTypes}
      pickedAssets={pickedAssets}
      checkedItems={checkedAsset}
      toggleCheckedItem={toggleCheckedAsset}
      sx={{ mt: 1 }}
      assets={files}
      fetching={fetching}
      onAssetClick={onAssetClick}
      unPin={unPin}
      pin={pin}
      zoom={zoom}
      openDownloadModal={openDownloadModal}
      openShareModal={openShareModal}
      openTrimCropModal={openTrimCropModal}
      cloneAsset={cloneAsset}
      updateAsset={updateAsset}
      deleteAsset={deleteAsset}
      hideContextMenu={hideContextMenu}
    />
  ) : (
    <LibraryTable
      hideContextMenu={hideContextMenu}
      header={allAssetsTableColumns}
      setCheckedElement={toggleCheckedAsset}
      checkedElements={checkedAsset}
      onRowClick={Boolean(editingTileTitle) ? ()=>{} : onAssetClick}
      rows={files.map((asset) => {
        const assetType: number = ASSET_TYPE_IDS[asset.kind]
        const contextMenuPropsOptions: ContextMenuOption[] = [
            {
              label: 'Edit',
              icon: ReorderIcon,
              skip: ![LibraryEntryKind.PLAYLIST, LibraryEntryKind.APP].includes(asset.kind),
              onClick: () => asset.kind === LibraryEntryKind.APP ? navigate(`/app/cms/scenes/edit/${asset.id}`) : navigate(`/app/cms/library/playlist/${asset.id}`),
            },
            {
              label: 'Rename',
              icon: PencilIcon,
              skip: asset.isShared,
              onClick: () => {
                dispatch({
                  type: 'setEditingTileTitle',
                  payload: { assetType: assetType, assetId: asset.id },
                })
              },
            },
            {
              label: 'Duplicate',
              icon: CopyIcon,
              onClick: () => cloneAsset?.({ asset: { assetType: assetType, assetId: asset.id } }),
              skip: [LibraryEntryKind.APP].includes(asset.kind),
            },
            {
              label: 'Tag',
              icon: TagIcon,
              onClick: () => {
                dispatch({ type: 'setFocusedItems', payload: [{ assetId: asset.id, assetType }] })
                dispatch({ type: 'setOpenedModal', payload: 'ManageGroupings' })
                dispatch({ type: 'setManageGroupingsType', payload: TagNamespace.TAG })
              },
            },
            {
              label: 'Download',
              icon: SaveIcon,
              skip: ['App', 'Playlist'].includes(asset.kind),
              onClick: () => openDownloadModal?.({ assetType: assetType, assetId: asset.id }),
            },
            {
              label: 'Trim/Crop',
              icon: CropIcon,
              skip: !asset.assetInfo?.mediaType?.includes('video'),
              onClick: () => {
                openTrimCropModal
                  ? openTrimCropModal({ assetType: assetType, assetId: asset.id })
                  : () => {}
              },
            },
            {
              label: 'Share',
              icon: ShareIcon,
              skip: asset.isShared,
              onClick: () => openShareModal?.({ assetType: assetType, assetId: asset.id }),
            },
            {
              label: 'Delete',
              icon: DeleteIcon,
              skip: asset.isShared,
              onClick: () => deleteAsset?.({ asset: { assetType: assetType, assetId: asset.id } }),
            },
          ].filter((i) => !i.skip) 
        const isEditingTitle = assetId === asset.id && assetTypeId === assetType
        return {
        id: asset.id,
        type: ASSET_TYPE_IDS[asset.kind],
        values: {
          preview:
            asset.kind === 'Media' ? (
              getFilePreview(asset)
            ) : (
              <TileAsset
                height={75}
                iconSize={12}
                onClick={() => {}}
                cardSx={{
                  width: '75px',
                  '& svg': {
                    height: '20px',
                    width: '20px',
                    transform: 'translateY(-4px)',
                  },
                }}
                assetInfo={{ ...asset.assetInfo }}
                kind={asset.kind === 'Playlist' && asset.kind}
              />
            ),
          name: isEditingTitle ? <Grid container alignItems="center" wrap="nowrap" sx={{ width: '100%' }}>
              <TextField
                variant="standard"
                value={renameValue}
                onChange={(e) => setRenameValue(e.target.value)}
                size="medium"
                fullWidth
                autoFocus
                placeholder="New Name"
                sx={{ minWidth: '70%' }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    onTitleSaveClick && onTitleSaveClick(renameValue, asset, assetType)
                  }
                }}
              />
                <IconButton
                  onClick={() => onTitleSaveClick && onTitleSaveClick(renameValue, asset, assetType)}
                  disableRipple
                  sx={{
                    padding: '1px',
                    opacity: asset.title === renameValue || renameValue.length === 0 ? '.3' : '1',
                  }}
                  disabled={asset.title === renameValue || renameValue.length === 0}
                >
                  <DoneIcon htmlColor="green" />
                </IconButton>
              <IconButton
                disableRipple
                sx={{ padding: '1px' }}
                onClick={() => {
                  onTileCancelClick && onTileCancelClick()
                  setRenameValue('')
                }}
              >
                <CloseIcon htmlColor="red" />
              </IconButton>
            </Grid> : asset.title,
          lastModified: YYYYMMDD_hhMMSSA(asset.createdAt),
          type:
            asset.kind === 'Media'
              ? getFileTypeFromMediaType(asset.assetInfo.mediaType)
              : asset.kind,
          tags: (
            <Grid container gap={'5px'}>
              {asset.hashTags.map(({ name, id }) => (
                <Chip
                  key={id}
                  label={`#${name}`}
                  sx={{
                    background: '#DBEEFC',
                    padding: '5px 0',
                    fontFamily: 'DMSans',
                    fontSize: '14px',
                  }}
                />
              ))}
            </Grid>
          ),
          actions: <ContextMenu options={contextMenuPropsOptions} />
        },
      }})}
    />
  )
}

const GroupAssetRenderer = ({
  selectMode,
  selectExclusionTypes,
  pickedAssets,
  selectedGroup,
  selectedViewMode,
  files,
  unPin,
  pin,
  openDownloadModal,
  openShareModal,
  openTrimCropModal,
  cloneAsset,
  updateAsset,
  deleteAsset,
  checkedAsset,
  toggleCheckedAsset,
  onAssetClick,
  renameGroupingTag,
  deleteGroupingTag,
  zoom,
  sort,
  checkedGroup,
  toggleCheckedGroup,
  fetching,
  hideContextMenu,
}: GroupAssetRendererProps) => {
  const { state } = useLibrary()
  const navigate = useNavigate()
  if (selectedGroup === Grouping.all) {
    return (
      <SelectedGrid
        selectMode={selectMode}
        selectExclusionTypes={selectExclusionTypes}
        pickedAssets={pickedAssets}
        checkedAsset={checkedAsset}
        toggleCheckedAsset={toggleCheckedAsset}
        files={files}
        fetching={fetching}
        viewMode={selectedViewMode}
        onAssetClick={onAssetClick}
        unPin={unPin}
        pin={pin}
        zoom={zoom}
        openDownloadModal={openDownloadModal}
        openShareModal={openShareModal}
        openTrimCropModal={openTrimCropModal}
        cloneAsset={cloneAsset}
        updateAsset={updateAsset}
        deleteAsset={deleteAsset}
        hideContextMenu={hideContextMenu}
      />
    )
  }
  return (
    <>
      {selectedGroup === Grouping.category &&
        (!!state.categories?.length || state.showAddNewGroup) && (
          <>
            {selectedViewMode === 'grid' ? (
              <Box>
                <Gallery
                  margin={10}
                  images={sortGrouping(state.categories, sort)
                    .filter((i) =>
                      state.search
                        ? i.label.toLowerCase().includes(state.search.toLowerCase())
                        : i
                    )
                    .map((i) => ({
                      src: i.thumbnailUrl ? i.thumbnailUrl : i.count > 0 ? folder : '',
                      asset: i.thumbnailUrl ? i.thumbnailUrl : i.count > 0 ? folder : '',
                      height: 1000,
                      width: 1778,
                      title: i.label,
                      type: 'category',
                      id: i.value,
                    }))}
                  enableImageSelection={false}
                  rowHeight={50 * (zoom ?? 5)}
                  thumbnailImageComponent={(p) => (
                    <GalleryGroupTileWrapper
                      {...p}
                      onClick={(id) => navigate(`category/${id}`)}
                      renameGroupingTag={renameGroupingTag}
                      deleteGroupingTag={deleteGroupingTag}
                      checkedGroup={checkedGroup}
                      toggleCheckedGroup={toggleCheckedGroup}
                    />
                  )}
                  tileViewportStyle={(asset) => ({
                    overflow: 'unset',
                    height: asset.item.scaledHeight,
                    width: asset.item.viewportWidth,
                  })}
                />
              </Box>
            ) : (
              <LibraryTable
                header={groupingTableColumns}
                onRowClick={({ assetId }) => navigate(`category/${assetId}`)}
                rows={sortGrouping(state.categories, sort).map(
                  ({ label, value, count, thumbnailUrl }) => {
                    return {
                      id: value,
                      values: {
                        preview: thumbnailUrl && (
                          <img
                            width="60px"
                            src={thumbnailUrl}
                            style={{ borderRadius: '4px' }}
                          />
                        ),
                        name: label,
                        items: count,
                      },
                    }
                  }
                )}
              />
            )}
          </>
        )}
      {selectedGroup === Grouping.category &&
        !state.categories?.length &&
        !state.showAddNewGroup && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img
              src={ArrowCategory}
              style={{ position: 'relative', top: '-120px', right: '-200px' }}
            />
          </Box>
        )}

      {selectedGroup === Grouping.collection &&
        (!!state.collections?.length || state.showAddNewGroup) && (
          <>
            {selectedViewMode === 'grid' ? (
              <Gallery
                margin={10}
                images={sortGrouping(state.collections, sort)
                  .filter((i) =>
                    state.search
                      ? i.label.toLowerCase().includes(state.search.toLowerCase())
                      : i
                  )
                  .map((i) => ({
                    src: i.thumbnailUrl ? i.thumbnailUrl : i.count > 0 ? folder : '',
                    asset: i.thumbnailUrl ? i.thumbnailUrl : i.count > 0 ? folder : '',
                    height: 1000,
                    width: 1778,
                    title: i.label,
                    type: 'collection',
                    id: i.value,
                    shared: i.shared,
                  }))}
                enableImageSelection={false}
                rowHeight={50 * (zoom ?? 5)}
                thumbnailImageComponent={(p) => (
                  <GalleryGroupTileWrapper
                    {...{ ...p }}
                    onClick={(id) => navigate(`collection/${id}`)}
                    renameGroupingTag={renameGroupingTag}
                    deleteGroupingTag={deleteGroupingTag}
                    checkedGroup={checkedGroup}
                    toggleCheckedGroup={toggleCheckedGroup}
                    openShareModal={openShareModal}
                  />
                )}
                tileViewportStyle={(asset) => ({
                  overflow: 'unset',
                  height: asset.item.scaledHeight,
                  width: asset.item.viewportWidth,
                })}
              />
            ) : (
              <LibraryTable
                header={groupingTableColumns}
                onRowClick={({ assetId }) => navigate(`collection/${assetId}`)}
                rows={sortGrouping(state.collections, sort).map(
                  ({ label, value, count, thumbnailUrl }) => {
                    return {
                      id: value,
                      values: {
                        preview: thumbnailUrl && (
                          <img
                            width="60px"
                            src={thumbnailUrl}
                            style={{ borderRadius: '4px' }}
                          />
                        ),
                        name: label,
                        items: count,
                      },
                    }
                  }
                )}
              />
            )}
          </>
        )}
      {selectedGroup === Grouping.collection &&
        !state.collections?.length &&
        !state.showAddNewGroup && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img
              src={ArrowCollection}
              style={{ position: 'relative', top: '-120px', right: '-200px' }}
            />
          </Box>
        )}
      {selectedGroup === Grouping.type && (
        <>
          {selectedViewMode === 'grid' ? (
            <Gallery
              margin={10}
              images={state.types.map(({ value, label }) => ({
                icon: ASSET_TYPE_IMAGES[label],
                color: ASSET_COLORS[label],
                height: 1000,
                width: 1778,
                title: label,
                type: 'Type',
                id: value,
              }))}
              enableImageSelection={false}
              rowHeight={50 * (zoom ?? 5)}
              thumbnailImageComponent={(p) => (
                <GalleryGroupTileWrapper
                  {...{ ...p }}
                  onClick={(id) => navigate(`type/${id}`)}
                />
              )}
              tileViewportStyle={(asset) => ({
                overflow: 'unset',
                height: asset.item.scaledHeight,
                width: asset.item.viewportWidth,
              })}
            />
          ) : (
            <LibraryTable
              header={groupingTableColumns}
              onRowClick={({ assetId }) => navigate(`type/${assetId}`)}
              rows={state.types.map(({ label, value, count }) => {
                const img = ASSET_TYPE_IMAGES[label]
                return {
                  id: value,
                  onRowClick: (id) => {},
                  values: {
                    preview: img && (
                      <Box
                        sx={{
                          borderRadius: '4px',
                          background: '#A7A8AA',
                          width: '60px',
                          height: '60px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img width="20px" src={img} />
                      </Box>
                    ),
                    name: label,
                    items: count,
                  },
                }
              })}
            />
          )}
        </>
      )}
    </>
  )
}
export default GroupAssetRenderer
