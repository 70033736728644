import { SxProps } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Tile, { SelectModes } from 'components/Library/Tile'
import { ContextMenuOption } from 'components/Library/Tile/ContextMenu'
import PencilIcon from 'assets/img/pencil.svg'
import CopyIcon from 'assets/img/copy.svg'
import TagIcon from 'assets/img/tag.svg'
import SaveIcon from 'assets/img/save.svg'
import ShareIcon from 'assets/img/share.svg'
import DeleteIcon from 'assets/img/delete.svg'
import CropIcon from 'assets/img/CropIcon.svg'
import ReorderIcon from 'assets/img/reorder.svg'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { NotificationManager } from 'react-notifications'
import { ASSET_TYPE_IDS } from 'components/Library/constants'
import { useLibrary } from 'contexts/library-context'
import { TagNamespace, LibraryEntryKind, AssetType } from 'store/types'
import { AssetInfo } from 'routes/cms/library/types'
import React from 'react'

interface IProps {
  assetInfo: AssetInfo
  toggleCheckedItem?: (v: AssetIdentifier) => void
  checkedItems?: AssetIdentifier[]
  onAssetClick: (asset: AssetIdentifier) => void
  unPin?: (assetIds: AssetIdentifier[]) => void
  pin?: (assetIds: AssetIdentifier[]) => void
  sx?: SxProps
  height?: number
  title: string
  hashTags: { name: string; id: number }[]
  selectMode?: SelectModes
  selectExclusionTypes?: AssetType[]
  isPicked?: boolean
  isPinned?: boolean
  isShared?: boolean
  id: number
  kind: LibraryEntryKind
  usedIn: { playlists: number[]; screens: number[] }
  openDownloadModal: (asset: AssetIdentifier) => void
  openShareModal: (asset: AssetIdentifier) => void
  openTrimCropModal: (asset: AssetIdentifier) => void
  cloneAsset: (asset: AssetIdentifier) => void
  updateAsset: (asset: AssetIdentifier) => void
  deleteAsset: (asset: AssetIdentifier) => void
  hideContextMenu?: boolean
  createdAt: string
}

export const TileWithMenu = ({
  height,
  title,
  hashTags,
  assetInfo,
  id,
  kind,
  isPinned,
  isShared,
  usedIn,
  checkedItems,
  toggleCheckedItem,
  onAssetClick,
  pin,
  unPin,
  openDownloadModal,
  openShareModal,
  openTrimCropModal,
  cloneAsset,
  updateAsset,
  deleteAsset,
  selectMode,
  selectExclusionTypes,
  isPicked,
  sx,
  hideContextMenu,
  createdAt
}: IProps) => {
  const navigate = useNavigate()
  const assetType: number = ASSET_TYPE_IDS[kind]
  const { dispatch } = useLibrary()
  const playlistCount = usedIn?.playlists?.length
  const screenCount = usedIn?.screens?.length
  const isProcessing = assetInfo.previewUrl === '__processing__'
  const contextMenuPropsOptions: ContextMenuOption[] = [
    {
      label: 'Edit',
      icon: ReorderIcon,
      skip: ![LibraryEntryKind.PLAYLIST, LibraryEntryKind.APP].includes(kind),
      onClick: () => kind === LibraryEntryKind.APP ? navigate(`/app/cms/scenes/edit/${id}`) : navigate(`/app/cms/library/playlist/${id}`),
    },
    {
      label: 'Rename',
      icon: PencilIcon,
      skip: isShared,
      onClick: () => {
        dispatch({
          type: 'setEditingTileTitle',
          payload: { assetType: assetType, assetId: id },
        })
      },
    },
    {
      label: 'Duplicate',
      icon: CopyIcon,
      onClick: () => cloneAsset({ asset: { assetType: assetType, assetId: id } }),
      skip: [LibraryEntryKind.APP].includes(kind),
    },
    {
      label: 'Tag',
      icon: TagIcon,
      onClick: () => {
        dispatch({ type: 'setFocusedItems', payload: [{ assetId: id, assetType }] })
        dispatch({ type: 'setOpenedModal', payload: 'ManageGroupings' })
        dispatch({ type: 'setManageGroupingsType', payload: TagNamespace.TAG })
      },
    },
    {
      label: 'Download',
      icon: SaveIcon,
      skip: ['App', 'Playlist'].includes(kind),
      onClick: () => openDownloadModal({ assetType: assetType, assetId: id }),
    },
    {
      label: 'Trim/Crop',
      icon: CropIcon,
      skip: !assetInfo?.mediaType?.includes('video'),
      onClick: () => {
        openTrimCropModal
          ? openTrimCropModal({ assetType: assetType, assetId: id })
          : () => {}
      },
    },
    {
      label: 'Share',
      icon: ShareIcon,
      skip: isShared,
      onClick: () => openShareModal({ assetType: assetType, assetId: id }),
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      skip: isShared,
      onClick: () => deleteAsset({ asset: { assetType: assetType, assetId: id } }),
    },
  ].filter((i) => !i.skip)

  return (
    <Tile
      selectMode={selectMode}
      selectExclusionTypes={selectExclusionTypes}
      isPicked={isPicked}
      isShared={isShared}
      isProcessing={isProcessing}
      height={height}
      key={`${title}-${id}`}
      hashTags={hashTags?.map((tag) => tag.name)}
      title={title}
      caption={
        kind !== LibraryEntryKind.PLAYLIST
          ? `${playlistCount || 0} playlists/${screenCount || 0} screens`
          : ''
      }
      checkbox={
        toggleCheckedItem
          ? {
              checked: Boolean(
                checkedItems?.find((i) => i.assetId === id && i.assetType === assetType)
              ),
              onChange: () => toggleCheckedItem({ assetId: id, assetType }),
            }
          : undefined
      }
      pin={
        pin && unPin
          ? {
              pinned: isPinned,
              onClick: () =>
                isPinned
                  ? unPin([{ assetId: id, assetType }])
                  : pin([{ assetId: id, assetType }]),
            }
          : undefined
      }
      assetInfo={assetInfo}
      createdAt={createdAt}
      id={id}
      kind={kind}
      assetType={assetType}
      contextMenuProps={
        hideContextMenu
          ? undefined
          : {
              options: contextMenuPropsOptions,
            }
      }
      onClick={(e) => {
        e!.stopPropagation()
        onAssetClick({ assetId: id, assetType })
      }}
      onTitleSaveClick={async (newName: string) => {
        if (updateAsset) {
          dispatch({
            type: 'setLoadingTiles',
            payload: { assetType: assetType, assetId: id },
          })
          dispatch({ type: 'setEditingTileTitle', payload: undefined })
          await updateAsset({
            asset: { assetType: assetType, assetId: id },
            data: { name: newName },
          })
            .unwrap()
            .then(() => dispatch({ type: 'clearLoadingTiles' }))
            .then(() =>
              NotificationManager.success(`Successfuly updated.`, `Success`, 3000)
            )
            .catch(() =>
              NotificationManager.error(
                `There was an error performing this action. Try again.`,
                `Error`,
                3000
              )
            )
        }
      }}
      onTileCancelClick={() => {
        dispatch({ type: 'setEditingTileTitle', payload: undefined })
      }}
      sx={{ ...sx }}
    />
  )
}
