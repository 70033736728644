import { Route, Routes } from 'react-router-dom'
import Authorization from '../../../Auth/AuthHoc'
import UserRoles from 'constants/UserRoles'

// async components
import {
  AsyncAdminAccountListComponent,
  AsyncAdminAccountEditComponent,
} from 'components/AsyncComponent/AsyncComponent'

const { SUPER_ADMIN, AGENCY_ADMIN, CUSTOMER_ADMIN } = UserRoles

const AccountListComponent = Authorization([CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN])(
  AsyncAdminAccountListComponent
)
const AccountEditComponent = Authorization([CUSTOMER_ADMIN, AGENCY_ADMIN, SUPER_ADMIN])(
  AsyncAdminAccountEditComponent
)

const Forms = () => (
  <div className="content-wrapper">
    <Routes>
      <Route path="account-list" element={<AccountListComponent />} />
      <Route path="account-edit/:id" element={<AccountEditComponent />} />
      <Route path="account-edit" element={<AccountEditComponent />} />
    </Routes>
  </div>
)

export default Forms
