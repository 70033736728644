import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { NotificationManager } from 'react-notifications';

import FileUploadApi from '../../data/api/fileUpload';
import FilePreview from './filePreview';

const fileUploadApi = new FileUploadApi();

const dropzoneStyle = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  padding: '20px',
  margin: '15px',
  backgroundColor: '#E1E1E1',
  borderRadius: '5px',
  minHeight: '100px',
  border: '2px dashed #C7C7C7',
}

const FileDropzone = ({
  accept,
  url,
  bucket,
  keyPrefix,
  onDrop,
  fileName,
  style,
  showDownload,
  showDate,
}) => {
  const [state, setState] = useState({
    files: null,
    thumbnailUrl: null,
    uploading: false,
  })
  const [uploadProgress, setUploadProgress] = useState()

  useEffect(() => {
    if (url && !state.thumbnailUrl) {
      setState(s => ({ ...s, thumbnailUrl: url }));
    }
  }, [url, state])

  const handleDrop = async files => {
    const filesWithPreview = files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    setState(s => ({
      ...s,
      files,
      thumbnailUrl: filesWithPreview[0].preview,
      uploading: true,
    }));

    try {
      await onDrop(files[0], setUploadProgress)
      setState(s => ({ ...s, uploading: false }));
    } catch (err) {
      console.log(err);
      alert('File failed to upload.');
    }
  }

  const clearImage = async () => {
    try {
      setState(s => ({ ...s, files: null, thumbnailUrl: null }));
      if (state.files) {   // deleting from temp cache
        const filename = state.files[0].name;
        await fileUploadApi.deleteFile(
          bucket,
          keyPrefix || '',
          filename,
        );
      }
    } catch (err) {
      console.log(err);
      alert('There was an issue removing the file.');
    }
  }

  const alert = () => NotificationManager.error(
    `Please upload a file of type ${accept}`,
    'Incorrect file type',
    3000,
  )

  return (
    <div style={style}>
      {(!state.files && !state.thumbnailUrl)
      ?
        <Dropzone
          multiple={false}
          accept={accept || 'image/*, audio/*, video/*,,.zip'}
          onDropAccepted={handleDrop}
          onDropRejected={alert}
          style={dropzoneStyle}
        >
          { ({ getRootProps, isDragActive }) =>
              <DropzoneContainer
                id='dropzone'
                isDragActive={isDragActive}
                {...getRootProps()}
              >
                Drop your {fileName} here...
              </DropzoneContainer>
          }
        </Dropzone>
        :
        <div style={{ marginTop: '10px', padding: '5px', position: 'relative', height: '100%' }}>
          { showDownload && (
            <Tooltip title="Download file">
              <IconButton
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 5,
                  zIndex: 1,
                }}
                size="large">
                <a
                  href={url}
                  download
                  style={{ lineHeight: 0.5, color: '#888' }}
                >
                  <GetAppIcon />
                </a>
              </IconButton>
            </Tooltip>
          )}
          { state.uploading &&
            <LinearProgress
              variant={uploadProgress && "determinate"}
              value={uploadProgress}
            />
          }
          <FilePreview
            thumbnailUrl={state.thumbnailUrl}
            files={state.files}
            onDelete={clearImage}
            updateDate={state.updateDate}
          />
        </div>
      }
    </div>
  );
}

const DropzoneContainer = styled.div`
  font-family: sans-serif;
  text-align: center;
  padding: 20px;
  margin: 15px;
  background-color: ${({isDragActive}) => isDragActive ? '#F1F1F1' : '#E1E1E1'};
  transition: background-color 0.3s;
  border-radius: 5px;
  min-height: 100px;
  border: 2px dashed #C7C7C7;
`;

FileDropzone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  fileName: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  keyPrefix: PropTypes.string,
  url: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showDate: PropTypes.bool,
  style: PropTypes.object,
  showDownload: PropTypes.bool,
};

export default FileDropzone;
