import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import BrowserUpdatedIcon from '@mui/icons-material/Update'
import SecurityUpdateIcon from '@mui/icons-material/SystemUpdate'
import LinearProgress from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles'
import { NotificationManager } from 'react-notifications'

import SoftwareReleasesApi from 'data/api/softwareReleases'
import AdminApi from 'data/api/admin'
import CustomToolbar from './customToolbar'
import { confirm } from 'components/confirmation'

const softwareReleasesApi = new SoftwareReleasesApi()
const adminApi = new AdminApi()

const SoftwareReleasesListing = ({ user }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [softwareReleases, setSoftwareReleases] = useState([])
  const [sortDirection, setSortDirection] = useState('desc')
  const [sortColumn, setSortColumn] = useState('')

  useEffect(() => {
    setLoading(true)
    softwareReleasesApi.get('').then((result) => {
      setSoftwareReleases(result)
      setLoading(false)
    })
  }, [])

  const deleteScene = async (id) => {
    confirm('Software Release')
      .then(() => softwareReleasesApi.delete(id))
      .then(() => setSoftwareReleases((l) => l.filter((i) => i.id !== id)))
  }

  const options = {
    filter: true,
    print: false,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: false,
    rowsPerPage: 100,
    customToolbar: () => <CustomToolbar navigate={navigate} />,
    onColumnSortChange: (column, direction) => {
      setSortColumn(column)
      setSortDirection(direction === 'ascending' ? 'asc' : 'desc')
      console.log(column, direction)
    },
  }

  const getMuiTheme = () =>
    createTheme(
      adaptV4Theme({
        overrides: {
          MuiTableCell: {
            root: {
              // padding: 4,
              paddingLeft: 20,
            },
          },
          MUIDataTableBodyCell: {
            root: {
              '&:nth-child(5)': {
                minWidth: 150,
              },
              '&:nth-child(6)': {
                width: 50,
              },
              '&:nth-child(7)': {
                width: 50,
              },
            },
          },
          MUIDataTableToolbar: {
            actions: {
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            },
          },
        },
      })
    )

  return (
    <div style={{ flex: 1 }}>
      {loading && <LinearProgress />}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<Typography variant="title">Software Releases</Typography>}
            data={
              loading
                ? [['Loading Data...']]
                : softwareReleases?.map((s, idx) => [
                    s.software_name,
                    s.platform,
                    s.stage,
                    s.version,
                    moment.utc(s.created_date, 'YYYY-MM-DDTHH:mm:ssUTC').format('lll'),
                    <div className="d-flex flex-row justify-content-end">
                      {s.stage === 'Latest Stable' && (
                        <>
                          <Tooltip title="Immediately deploy release on all screen players">
                            <IconButton
                              onClick={() =>
                                confirm('', {
                                  description: `Are you sure you want to release v${s.version} to all screen players effective immediately?`,
                                  noAction: 'No',
                                  title: 'Confirm Upgrade',
                                  yesAction: 'Yes, Proceed',
                                })
                                  .then(() => adminApi.upgradeAllPlayers(true))
                                  .then(() =>
                                    NotificationManager.success(
                                      `Upgrade directive sent.`,
                                      `Success`,
                                      3000
                                    )
                                  )
                              }
                              style={{ marginRight: 20 }}
                              size="large"
                            >
                              <SecurityUpdateIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Deploy release on all screen players at night">
                            <IconButton
                              onClick={() =>
                                confirm('', {
                                  description: `Are you sure you want to release v${s.version} to all screen players tonight?`,
                                  noAction: 'No',
                                  title: 'Confirm Upgrade',
                                  yesAction: 'Yes, Proceed',
                                })
                                  .then(() => adminApi.upgradeAllPlayers())
                                  .then(() =>
                                    NotificationManager.success(
                                      `Upgrade directive sent.`,
                                      `Success`,
                                      3000
                                    )
                                  )
                              }
                              style={{ marginRight: 20 }}
                              size="large"
                            >
                              <BrowserUpdatedIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      <IconButton
                        onClick={() =>
                          navigate(`/app/admin/software-releases/add-edit/${s.id}`)
                        }
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </div>,
                    <IconButton onClick={() => deleteScene(s.id)} size="large">
                      <DeleteIcon />
                    </IconButton>,
                  ])
            }
            columns={[
              {
                name: 'Software Name',
                options: {
                  sort: true,
                  filter: false,
                  // sortDirection: sortColumn === 'Template Name' ? sortDirection : null,
                },
              },
              {
                name: 'Platform',
                label: 'Platform',
                options: { sort: true, filter: true },
              },
              { name: 'Stage', label: 'Stage', options: { sort: true, filter: true } },
              {
                name: 'Version',
                label: 'Version',
                options: { sort: true, filter: true },
              },
              {
                name: 'Created Date',
                options: {
                  sort: true,
                  filter: false,
                  sortDirection: sortColumn === 'Created Date' ? sortDirection : null,
                },
              },
              {
                name: '',
                options: { sort: false, filter: false },
              },
              {
                name: '',
                options: { sort: false, filter: false },
              },
            ]}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

export default SoftwareReleasesListing
