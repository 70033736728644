/**
 * Admin Templates Routes
 */
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Authorization from '../../../Auth/AuthHoc'
import UserRoles from '../../../constants/UserRoles'
import AddUser from './add-user'
import EditUser from './edit-user'

// async components
import {
  AsyncAdminUserListComponent,
  AsyncAdminUserCompaniesComponent,
} from 'components/AsyncComponent/AsyncComponent'

const { SUPER_ADMIN, AGENCY_ADMIN, CUSTOMER_ADMIN } = UserRoles

const AdminUserListComponent = Authorization([SUPER_ADMIN, AGENCY_ADMIN, CUSTOMER_ADMIN])(
  AsyncAdminUserListComponent
)
const AdminUserCompaniesComponent = Authorization([SUPER_ADMIN])(
  AsyncAdminUserCompaniesComponent
)
const AddUserComponent = Authorization([SUPER_ADMIN, AGENCY_ADMIN, CUSTOMER_ADMIN])(
  AddUser
)
const EditUserComponent = Authorization([SUPER_ADMIN, AGENCY_ADMIN, CUSTOMER_ADMIN])(
  EditUser
)

const Forms = () => (
  <div className="content-wrapper">
    <Routes>
      <Route path="user-list" element={<AdminUserListComponent />} />
      <Route path=":userId/companies" element={<AdminUserCompaniesComponent />} />
      <Route path="add-user" element={<AddUserComponent />} />
      <Route path="edit-user/:userId" element={<EditUserComponent />} />
    </Routes>
  </div>
)

export default Forms
