import * as actions from '../actions/types';

// initial state
const INIT_STATE = {
  list: []
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // get todos
    case actions.FETCH_SCREENS_SUCCESS:
      return { ...state, list: payload };

    default: return state;
  }
}
