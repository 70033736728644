import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'

import StocksApi from 'data/api/stocks'
import background from 'assets/img/StocksBG-web.jpg'
import stocksGraphic from 'assets/img/STOCKStext.svg'
import { hexToRgbA } from 'helpers/helpers'
import { colMap, portraitColMap } from './constants'

const stocksApi = new StocksApi()

export const StocksSceneRenderer = ({
  stocks,
  portrait,
  tileMode,
  backgroundColorOverlay,
  style = {},
}) => {
  const [stockData, setStockData] = useState()
  const [refresh, setRefresh] = useState(0)
  const [clientDims, setClientDims] = useState()
  const gotDimensions = useRef(false)
  const dimension = clientDims?.height
    ? Math.max(clientDims?.height, clientDims?.width)
    : 0
  const { headlineFont, subheadFont, bodyFont } = style
  const isPortrait = clientDims?.height > clientDims?.width || portrait
  const cols = isPortrait
    ? portraitColMap[stockData?.length || 1]
    : colMap[stockData?.length || 1]

  const rows = Math.ceil(stockData?.length / cols)

  const tileWidth = clientDims
    ? isPortrait
      ? Math.min(
          clientDims.width / cols - dimension / 40,
          (0.6 * clientDims.height) / rows - dimension / 40
        )
      : Math.min(
          (0.6 * clientDims.height) / rows - dimension / 40,
          clientDims.width / cols - dimension / 40
        )
    : 0

  useEffect(() => {
    if (!stocks?.length) return
    const getStockData = async () => {
      const results = await stocksApi.get(stocks)
      setStockData(results)
    }
    getStockData()
    setTimeout(() => setRefresh((p) => p + 1), 600000)
  }, [stocks, refresh])

  useEffect(() => {
    if (!gotDimensions.current) {
      const container = document.getElementById('container')
      if (!container) return
      const height = document.getElementById('container').clientHeight
      const width = document.getElementById('container').clientWidth
      gotDimensions.current = true
      setClientDims({ width, height })
    }
  })

  const stockPanel = (index) => {
    const quote = stockData?.[index]

    return (
      <StyledGridListTile key={quote?.symbol + index}>
        <InnerTile
          style={{
            borderRadius: dimension / 50,
            width: tileWidth,
            height: tileWidth,
            paddingTop: tileWidth / 7,
            paddingBottom: dimension / 45,
            background: tileMode === 'dark' ? 'black' : 'white',
            color: tileMode === 'dark' ? 'white' : '#3d3d3d',
          }}
        >
          <div
            style={{
              fontFamily: headlineFont,
              fontSize: tileWidth / 7,
              lineHeight: `${tileWidth / 5 - 4}px`,
              fontWeight: 'bold',
            }}
          >
            {quote?.symbol}
          </div>
          <div
            style={{
              fontFamily: subheadFont,
              color: tileMode === 'dark' ? '#ccc' : '#636363',
              fontSize: tileWidth / 10,
              whiteSpace: 'nowrap',
            }}
          >
            {quote?.longName?.replace(',', '')?.replace('Inc.', '')?.slice(0, 11)}
          </div>
          <div style={{ fontFamily: headlineFont, fontSize: tileWidth / 7 }}>
            {quote?.regularMarketPrice}
            <div
              style={{
                fontFamily: bodyFont,
                fontSize: tileWidth / 8,
                textAlign: 'right',
                color: quote?.regularMarketChange < 0 ? '#ff0000' : '#009245',
              }}
            >
              {quote?.regularMarketChange > 0 && '+'}
              {quote?.regularMarketChange?.toFixed(2)}
            </div>
          </div>
        </InnerTile>
      </StyledGridListTile>
    )
  }

  if (!stockData) return <div />

  return (
    <Container
      id="container"
      style={{
        backgroundColor: hexToRgbA(backgroundColorOverlay, 0.7),
        paddingTop: dimension / 5.5,
        paddingBottom: dimension / 20,
        paddingLeft: !isPortrait ? (0.5 * cols * dimension) / 14 : dimension / 14,
        paddingRight: !isPortrait ? (0.5 * cols * dimension) / 14 : dimension / 14,
      }}
    >
      <StocksText src={stocksGraphic} alt="stocks-text" />
      <ImageList
        cols={cols}
        spacing={dimension / 40}
        style={{ flex: 1 }}
        cellHeight="auto"
      >
        {stockData.map((stock, idx) => stockPanel(idx))}
      </ImageList>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex: 1;
  height: 100%;
  background-size: cover;
  background-image: url(${background});
  background-blend-mode: overlay;

  .MuiGridList-root {
    justify-content: center;
  }
`

const StyledGridListTile = styled(ImageListItem)`
  .MuiGridListTile-tile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const InnerTile = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StocksText = styled.img`
  position: absolute;
  top: 15%;
  left: 20%;
  width: 65%;
`
