import { FETCH_STYLES_START, FETCH_STYLES_SUCCESS, FETCH_STYLES_ERROR } from './types'
import StylesApi from '../api/styles'

const stylesApi = new StylesApi()

/**
 * Redux Action Get Styles
 */
export const getStyles = () => async (dispatch, getState) => {
  const {
    authUser: { activeAccount },
  } = getState()
  dispatch({ type: FETCH_STYLES_START })
  try {
    const response = await stylesApi.get('', activeAccount)
    dispatch({ type: FETCH_STYLES_SUCCESS, payload: response })
  } catch (error) {
    console.log(error)
    dispatch({ type: FETCH_STYLES_ERROR, payload: error })
  }
}
