import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import UploadIcon from '@mui/icons-material/CloudUpload'

const acceptedFileTypes =
  '.mts, image/jpeg, image/png, video/*, application/pdf, application/mxf, ' +
  'video/avchd-stream, video/MP2T, ' +
  'audio/mpeg, audio/wav, audio/*, ' +
  'application/illustrator, application/postscript, image/x-eps, ' +
  'application/msword, ' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
  'application/vnd.ms-excel, ' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ' +
  'application/vnd.ms-powerpoint, ' +
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'

const FileDropzone = ({
  onDrop,
  onDropRejected,
  background = 'transparent',
  height,
  title,
  uploading,
  acceptedTypes,
  maxFiles,
  multiple
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: acceptedTypes || acceptedFileTypes,
    maxFiles,
    multiple
  })

  // the onDragOver prop below is needed due to conflict with DnD lib
  return (
    <Wrapper height={height} onDragOver={e => e.stopPropagation()}>
      <DropzoneContainer background={background} {...getRootProps()}>
        <UploadIcon style={{ marginBottom: 30 }} fontSize="large" />
        <p>{title || 'Upload'}</p>
        <p className="mb-0">Drag and Drop here or </p>
        <button className="link-btn" onClick={e => e.preventDefault()}>
          Browse files
        </button>
        <input
          type="file"
          className="file-input"
          {...getInputProps()}
        />
        {uploading && <span className="spinner-border spinner-border-sm" />}
      </DropzoneContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: ${({ height }) => (height ? height : '100%')};
`

const DropzoneContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 3px dashed #bcd5e3;
  text-align: center;
  background-color: ${({ background }) => background || '#fff'};
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  transition: 0.3s;

  .link-btn {
    color: #0b9ed9;
    text-decoration: underline;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 500;
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }

  .spinner-border {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
`

export default FileDropzone
