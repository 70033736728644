import React from 'react'
import { Container } from 'reactstrap'
import Modal from 'react-responsive-modal'
import previewButton from '../../assets/img/Buttons-Custom-Preview.svg'

const NotesFromAuthorModal = ({ open, close, template, setPreviewOpen }) => (
  <Modal
    open={open}
    onClose={() => close()}
    closeIcon={
      <svg fill="currentColor" viewBox="3 3 30 30" width={28} height={28}>
        <path
          d="M26 12 L24 10 L18 15.88 L12 10 L10 12 L15.88 18 L10 24 L12 26 L18 20.12 L24 26 L26 24 L20 18 Z"
          stroke="none"
          fill="white"
          strokeLinecap="round"
        />
      </svg>
    }
    styles={{
      closeButton: {
        cursor: 'pointer',
        backgroundColor: '#46c8f0',
        borderRadius: '20px',
        position: 'absolute',
        right: '-12px',
        top: '-12px',
        fontColor: '#FFF',
      },
      modal: {
        overflow: 'visible',
        borderRadius: '10px',
        padding: '10px',
        minWidth: '40%',
      },
    }}
  >
    <Container style={{ marginTop: 20 }}>
      <img
        style={{
          width: 100,
          height: 30,
          cursor: 'pointer',
          position: 'absolute',
          top: 26,
          right: 30,
        }}
        src={previewButton}
        alt="preview button"
        onClick={() => setPreviewOpen(true)}
      />
      <div
        style={{ padding: 20, maxHeight: 500, overflowY: 'scroll' }}
        dangerouslySetInnerHTML={{
          __html: template.schema.notes,
        }}
      />
    </Container>
  </Modal>
)

export default NotesFromAuthorModal
