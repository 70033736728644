export default theme => {
  // console.log(theme)
  return ({
    leftIconButton: {
      position: 'absolute',
      left: '-50px',
      top: '345px',
      color: 'white'
    },
    rightIconButton: {
      position: 'absolute',
      right: '-50px',
      top: '345px',
      color: 'white'
    },
    masterSceneTitle: {
      color: theme.palette.aesendPurple.main,
      fontWeight: 'bold'
    },
    normalSceneTitle: {
      color: theme.palette.grey[400],
      fontWeight: 'bold'
    },
    masterFormWrapper: {
      border: `1px solid ${theme.palette.aesendPurple.main}`,
      borderRadius: 10,
    },
    sceneFormWrapper: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 10,
    },
    '@global': {
      '.scene-slider-content': {
        margin: '10px',
        boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
        marginBottom: 20
      },
      '.slick-next':{
        right: '-20px',
      },
      '.slick-prev':{
        left: '-32px',
      },
      '.slick-next:before': {
        zIndex: 9999999,
        color: '#555',
        height: '30px',
        width: '30px',
        fontSize: '32px',
        // content: 'unset',
      },
      '.slick-prev:before': {
        zIndex: 9999999,
        color: '#555',
        height: '30px',
        width: '30px',
        fontSize: '32px',
        // content: 'unset',
      },
      '.slick-next:focus, .slick-next:hover': {
        color: 'black',
      },
      '.slick-prev:focus, .slick-next:hover': {
        color: 'black',
      },
      '.circle-picker': {
        display: 'flex', 
        justifyContent: 'center'
      },
      '.slick-dots li': {
        margin: '0 3',
        width: '8px'
      },
      '.slick-dots li.slick-active button:before': {
        color: '#0876DE',
        fontSize: '10px',
      },
      '.slick-dots': {
        paddingRight: '20px',
        paddingTop: '10px',
      }
    },
  }) 
};