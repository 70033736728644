import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormText } from 'reactstrap'
import { AvGroup } from 'availity-reactstrap-validation'
import { Row, Col } from 'reactstrap'

import AssetPreview from './assetPreview'
import { getFileType } from 'util/pathUtils'
// import VideoClippingModal from 'components/VideoClippingModal';
import MediaUploadModal from 'components/MediaUploadModal2'
import useUpdatableState from '@landisdesign/use-updatable-state'
import Button from '@mui/material/Button'
import UploadIcon from '@mui/icons-material/CloudUpload'
import { Sources } from 'components/MediaUploadModal2'
import { SourceSection, AssetObject } from './index'

interface IProps {
  selectUploadedFile: (asset: AssetObject, section: SourceSection) => void
}

const ImageUploadForm = ({
  imageUrl,
  theme,
  formSpec,
  activeAccount,
  handleRemove,
  selectUploadedFile,
  file,
  handleTrimCropChange,
  uploadPreSignMethod,
}: IProps) => {
  const [videoTrimOpen, setVideoTrimOpen] = useState(false)
  const { ae_field_name, scene, label, user_prompt, file_specs } = formSpec
  const [uploadedFileUrl, setUploadedFileUrl] = useUpdatableState(imageUrl)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const { type, filename } = getFileType(imageUrl)

  const {
    overlay_crop_origin_x: cropX,
    overlay_crop_origin_y: cropY,
    overlay_crop_width: cropW,
    overlay_crop_height: cropH,
    overlay_image: overlayImage,
  } = formSpec

  const showTrimCrop = cropX && cropY && cropW && cropH && overlayImage && imageUrl

  return (
    <div style={{ textAlign: 'left', marginTop: 10 }}>
      <Row>
        <Col sm="8">
          <span
            style={{
              color: scene === 0 ? theme.palette.aesendPurple.main : 'black',
              fontWeight: 'bold',
            }}
          >
            {label}
          </span>
          <AvGroup>
            <FormText color="muted">{user_prompt}</FormText>
          </AvGroup>
          {uploadedFileUrl ? (
            <p className="text-truncate" style={{ whiteSpace: 'pre-wrap' }}>
              File uploaded:{' '}
              {file ? (
                <>
                  {file.name}{' '}
                  {file.size && <>- {Math.round(file.size / 10000) / 100} MB</>}
                </>
              ) : (
                filename
              )}
            </p>
          ) : (
            <p>{file_specs}</p>
          )}
        </Col>
        <Col sm="4" style={{ paddingTop: 30, position: 'relative' }}>
          <MediaUploadModal
            pickerMode
            accountId={activeAccount}
            onSelect={(assets: AssetObject[], type: SourceSection) => {
              selectUploadedFile(assets[0], type)
              setUploadModalOpen(false)
            }}
            allowedSources={{
              [Sources.MyDevice]: true,
              [Sources.Library]: true,
              [Sources.CloudSources]: true,
            }}
            multiple={false}
            isOpen={uploadModalOpen}
            onClose={() => setUploadModalOpen(false)}
            // mediaLibraryFilter={(items) =>
            //   items.filter((item) =>
            //     item.media_type?.startsWith('video/') ||
            //     item.media_type?.startsWith('image/')
            //       ? item
            //       : false
            //   )
            // }
            uploadPreSignMethod={uploadPreSignMethod}
          />
          {uploadedFileUrl && (
            <AssetPreview
              url={uploadedFileUrl}
              type={type}
              handleRemove={() => handleRemove(ae_field_name)}
              maxWidth={150}
              maxHeight={225}
            />
          )}
          {!uploadedFileUrl && file && (
            <AssetPreview
              url={file.preview}
              type={file.type}
              handleRemove={() => handleRemove(ae_field_name)}
            />
          )}
          {!uploadedFileUrl && !file && (
            <Button
              variant="outlined"
              disableElevation
              style={{ minWidth: 120 }}
              startIcon={<UploadIcon />}
              onClick={() => setUploadModalOpen(true)}
            >
              Upload
            </Button>
          )}
          {/* { showTrimCrop &&
            <>
              <Tooltip title="Trim/Crop Video">
                <IconButton
                  style={{ position: 'absolute', bottom: -10 }}
                  onClick={() => setVideoTrimOpen(p => !p)}
                >
                  <CameraRollIcon />
                </IconButton>
              </Tooltip>
              <VideoClippingModal
                zoomToCrop
                open={videoTrimOpen}
                close={() => setVideoTrimOpen(false)}
                url={imageUrl}
                cropRect={{
                  x: cropX,
                  y: cropY,
                  w: cropW,
                  h: cropH,
                }}
                maskImage={overlayImage}
                submitResult={params => {
                  console.log(params)
                  handleTrimCropChange(ae_field_name, params)
                  setVideoTrimOpen(false)
                }}
              />
            </>
          } */}
        </Col>
      </Row>
    </div>
  )
}

ImageUploadForm.propTypes = {
  imageUrl: PropTypes.string,
  activeAccount: PropTypes.number.isRequired,
  selectUploadedFile: PropTypes.func.isRequired,
  file: PropTypes.object,
  handleTrimCropChange: PropTypes.func,
  uploadPreSignMethod: PropTypes.func,
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  uploadProgress: PropTypes.number,
  handleRemove: PropTypes.func.isRequired,
}

export default ImageUploadForm
