import React, { useState } from 'react'
import { Button, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap'
import { Grid, Paper } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { EditorPane } from 'components/SceneComponents/TouchScene/EditorPane'
import { types } from 'components/SceneComponents'
import { useUser } from '../../../Auth/usePermitted'
import { useMessaging } from 'components/SceneComponents/TouchScene/useMessaging'
import './sceneEditor.scss'
import { AppsList } from 'components/SceneComponents/TouchScene/fields/components/appsList'
import { SaveAsModal } from 'components/SceneComponents/TouchScene/saveAsModal'
import { ACTION_PUBLISH, ACTION_SAVE, ACTION_SAVE_A_COPY } from './constants'
import { PreviewLink } from 'components/SceneComponents/common/PreviewLink'
import { NotificationManager } from 'react-notifications'
import MediaUploadModal, { Sources } from '../../MediaUploadModal2'
import { LibraryProvider } from '../../../contexts/library-context'

const angularAppUrl = process.env.TOUCH_APP_URL

const Navigator = ({ routes = [], onChange, value }) => {
  const _onChange = (event) => {
    typeof onChange === 'function' && onChange(event)
  }

  if (!routes.length) {
    return null
  }

  return (
    <>
      <Form.Label>Navigate to</Form.Label>
      <AppsList onChange={_onChange} routes={routes} selection={value}/>
    </>
  )
}

const cleanUp = (data) => {
  Object.keys(data).forEach((key) => {
    if (data[key].hasOwnProperty('app_generic_settings')) {
      delete data[key].app_generic_settings
    }
  })

  return data
}

export const TouchSceneEditor = ({
  name,
  config,
  onSave,
  onPublish,
  onSaveACopy,
  onPreview,
  saving,
}) => {
  const [sidePanelParams, setSidePanelParams] = useState({})
  const [hereSaving, setHereSaving] = useState(false)
  const [usedMedia, setUsedMedia] = useState(config.app_generic_settings?.usedMedia || {})
  const [currentRoute, setCurrentRoute] = useState(undefined)
  const [isExternalUrl, setIsExternalUrl] = useState(false)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [focusedField, setFocusedField] = useState({})

  const [saveAsVisible, setSaveAsVisible] = useState({
    visible: false,
    name: '',
    text: '',
  })

  const user = useUser()
  const __saving = saving || hereSaving
  const appGenericSettings = config?.app_generic_settings

  const onPullConfigSave = async ({ data, action = ACTION_SAVE, newName, overwrite = false }) => {
    let method
    let successMessage
    switch (action) {
      case ACTION_SAVE:
        console.log('%cI AM  SAVING', 'color: red; background: yellow; font-size: 30px;')
        method = onSave
        successMessage = 'The scene was successfully saved!'
        break
      case ACTION_PUBLISH:
        console.log('%cI AM  PUBLISHING', 'color: green; background: yellow; font-size: 30px;')
        method = onPublish
        successMessage = 'The scene was successfully published!'
        break
      case ACTION_SAVE_A_COPY:
        console.log('%cI AM SAVING A COPY', 'color: blue; background: yellow; font-size: 30px;', newName)
        method = onSaveACopy
        successMessage = 'A copy of the scene was successfully saved!'
        break
    }

    data.app_generic_settings.usedMedia = usedMedia
    data.app_generic_settings.appName = newName || sceneName
    let stayOnPage = true
    try {
      data = cleanUp(data)
      stayOnPage = await method.call(
        null,
        {
          name: newName || sceneName,
          type: types.TOUCH,
          config: data,
        },
        overwrite
      )

      NotificationManager.success(successMessage)

      if (stayOnPage !== false) {
        setSceneName(newName || sceneName)
      }
    } catch (err) {
      if (err?.response?.status === 409) {
        setSaveAsVisible({
          visible: true,
          action,
          name: newName || sceneName,
          /*err?.response?.data?.data?.message ||*/
          text: (
            <>
              <p className="text-warning">This name is already used!</p>
              <p>
                Please change the name and click <strong>"Save"</strong>
                <br/>
                OR
                <br/>
                click <strong>"Duplicate"</strong> to use same name again
              </p>
            </>
          ),
        })
      } else {
        NotificationManager.error(
          err.response?.message || err.message,
          'Something went wrong'
        )
      }
    }

    // if (stayOnPage !== false) {
    setHereSaving(false)
    // }
  }

  const _clickSave = () => {
    setHereSaving(true)
    requestConfigPull(async (data) => {
      await onPullConfigSave({ data, action: ACTION_SAVE })
    })
  }

  const _clickPublish = () => {
    setHereSaving(true)
    requestConfigPull(async (data) => {
      await onPullConfigSave({ data, action: ACTION_PUBLISH })
    })
  }

  const _clickSaveACopy = () => {
    setHereSaving(true)
    const newName = `Copy of ${sceneName}`
    requestConfigPull(async (data) => {
      await onPullConfigSave({ data, action: ACTION_SAVE_A_COPY, newName })
    })
  }

  /**
   * @param action {string}
   * @param newName
   * @param force
   * @private
   */
  const _onCloseSaveAsModal = ({ action, newName, force }) => {
    setSaveAsVisible({ visible: false, name: '', text: '' })
    console.log(action)

    if (action) {
      setHereSaving(true)
      requestConfigPull(async (data) => {
        await onPullConfigSave({
          data,
          action,
          newName,
          overwrite: force,
        })
      })
    }
  }

  const { sendMessage, screenConfig, requestConfigPull, setScreenConfig } = useMessaging(
    config,
    ({ data }) => {
      const { event, payload } = data

      switch (event) {
        case 'navigatedTo':
          setCurrentRoute(convertAngularUrlToConfigUrl(payload))
          setIsExternalUrl(checkIsExternalUrl(payload))
          break
      }
    }
  )

  const [sceneName, setSceneName] = useState(name || '')

  const checkIsExternalUrl = (url) => {
    return url.startsWith(appGenericSettings.externalUrlPrefix)
  }

  const convertAngularUrlToConfigUrl = (angularUrl) => {
    let result = angularUrl === '/' ? '/attract-loop' : angularUrl

    if (checkIsExternalUrl(result)) {
      result = result.replace(
        appGenericSettings.externalUrlPrefix,
        '%%external-url-prefix%%/'
      )
    }

    return result
  }

  /**
   * @param asset
   * @param params
   */
  const setAsset = (asset, params) => {
    const { elementKey, type: mediaType } = params || focusedField // here we obtain a target element to be updated in the angular config

    const usedMediaKey = [screenConfig.page, elementKey].join('.')

    const mediaItem = { ...asset, type: mediaType }

    if (asset.id) {
      mediaItem.mediaId = asset.id
      delete mediaItem.id
    }

    usedMedia[usedMediaKey] = mediaItem
    setUsedMedia({ ...usedMedia })

    screenConfig.config[elementKey].value = asset.url
    setScreenConfig({ ...screenConfig })

    sendMessage({ event: 'change', target: elementKey, payload: asset.url }) //send the new value over the fence
  }

  const setMediaContentAsset = (asset) => {
    asset.url = asset.resolution.link
    delete asset.previewUrl

    setAsset(asset)
  }

  const onFocus = (element, payload) => {
    sendMessage({ event: 'focus', target: element, payload })
  }

  const onBlur = (element) => {
    sendMessage({ event: 'blur', target: element })
  }

  const onChange = (element, value) => {
    sendMessage({ event: 'change', target: element, payload: value })
  }

  const navigateToRoute = (event) => {
    sendMessage({ event: 'navigate', payload: event.target.value })
  }

  return (
    <div className="touch-scene-editor wrapper">
      {saveAsVisible.visible && (
        <SaveAsModal
          visible={saveAsVisible.visible}
          name={saveAsVisible.name}
          text={saveAsVisible.text}
          action={saveAsVisible.action}
          onClose={_onCloseSaveAsModal}
        />
      )}
      <Grid container spacing={2} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} className="controls">
          <Paper>
            <Form.Label>Version Name</Form.Label>
            <Form.Control
              value={sceneName || ''}
              onChange={(event) => {
                event.persist()
                setSceneName(event.target.value)
              }}
            />
            <InputGroup className="save-group">
              <Button type="submit" variant="primary" onClick={_clickSave}>
                {__saving ? (
                  <CircularProgress color="secondary" size={22}/>
                ) : (
                  <div>Save draft</div>
                )}
              </Button>

              <DropdownButton
                as={InputGroup.Append}
                variant="primary"
                alignRight={true}
                menuAlign="left"
                title=""
              >
                <Dropdown.Item onClick={_clickPublish}>Publish</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item onClick={_clickSaveACopy}>Save a copy...</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
            <PreviewLink onPreview={onPreview}/>
            <hr/>
            <Navigator
              routes={appGenericSettings.routes}
              onChange={navigateToRoute}
              value={currentRoute}
            />
            <hr/>

            {screenConfig.config && !isExternalUrl && (
              <EditorPane
                config={screenConfig.config}
                page={screenConfig.page}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                setSidePanelParams={setSidePanelParams}
                openLibrary={(focusedField) => {
                  setFocusedField(focusedField)
                  setUploadModalOpen(true)
                }}
                setAsset={setAsset}
                appSettings={appGenericSettings}
              />
            )}
            {isExternalUrl && (
              <div>This content is controlled outside of this application.</div>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8} className="scene">
          <iframe src={angularAppUrl} height={720} width={405} id="angular-scene"/>
        </Grid>
      </Grid>

      <LibraryProvider>
        <MediaUploadModal
          pickerMode
          accountId={user.activeAccount}
          onSelect={(assets, type) => {
            setAsset(assets[0])
            setUploadModalOpen(false)
          }}
          allowedSources={{
            [Sources.MyDevice]: true,
            [Sources.Library]: true,
            [Sources.CloudSources]: true,
          }}
          multiple={false}
          isOpen={uploadModalOpen}
          onClose={() => {
            setFocusedField({})
            setUploadModalOpen(false)
          }}
          mediaLibraryFilter={(items) =>
            items.filter((item) =>
              item.media_type?.startsWith('video/') ||
              item.media_type?.startsWith('image/')
                ? item
                : false
            )
          }
          uploadPreSignMethod={() => {}}
        />
      </LibraryProvider>
    </div>
  )
}
