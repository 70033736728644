import { GroupingItemArray } from 'contexts/library-context'
import { CheckboxList } from './CheckboxList'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'

import { useFilteredItems } from './useFilteredItems'

interface IProps {
  handleToggle: (value: number | string) => () => void
  checked: number[] | string[]
  items: GroupingItemArray
}

export const TagFilter = ({ handleToggle, checked, items }: IProps) => {
  const { search, setSearch, filteredItems } = useFilteredItems(items)

  return (
    <>
      <OutlinedInput
        fullWidth
        value={search}
        notched={false}
        margin="dense"
        placeholder="Search Tags"
        onChange={(e) => setSearch(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        sx={{
          my: 1,
          background: 'white',
          '& input': { paddingTop: 1, paddingBottom: 1 },
        }}
      />
      <CheckboxList
        handleToggle={handleToggle}
        checked={checked}
        items={filteredItems}
        sx={{ maxHeight: 200, overflowY: 'auto' }}
      />
    </>
  )
}
