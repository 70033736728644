import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material'
import Close from '@mui/icons-material/Close'
import Delete from '@mui/icons-material/DeleteOutline'
import Grip from '@mui/icons-material/ViewCompact'
import { Reorder } from 'framer-motion'

import { ConfigurableGroupings, GroupingList } from './types'

interface IProps {
  open: ConfigurableGroupings | null
  onClose: () => void
  onSave: ({ add, remove }: { add?: GroupingList; remove?: GroupingList }) => void
  groups: GroupingList
}

const titleMap = {
  category: 'Categories',
  collection: 'Collections',
}

export const SetupGroupings = ({ open, onClose, onSave, groups = [] }: IProps) => {
  const [items, setItems] = useState([...groups])
  const [newItem, setNewItem] = useState('')

  useEffect(() => {
    setItems([...groups])
  }, [open])

  const add = () => {
    setItems((p) => [...p, { label: newItem, value: 0 }])
    setNewItem('')
  }

  const save = () => {
    const remove = groups.filter((g) => !items.find((i) => i.value === g.value))
    const add = items.filter((i) => !groups.find((g) => g.value === i.value))
    onSave({ add, remove })
    onClose()
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={!!open}
      onClose={onClose}
      sx={{ '.MuiPaper-root': { borderRadius: 2 } }}
    >
      <DialogTitle
        sx={{ fontSize: '1rem !important', fontWeight: 'bold', textAlign: 'center' }}
      >
        {groups.length === 0 ? 'Setup' : 'Edit'} {open ? titleMap[open] : ''}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 4, right: 4 }}
          color="primary"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 2 }}>
        <DialogContentText>
          New Category
          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 3 }}>
            <TextField
              fullWidth
              sx={{ marginRight: 2 }}
              size="small"
              placeholder="Start typing..."
              onChange={(e) => setNewItem(e.target.value)}
            />
            <Button disableElevation variant="contained" onClick={add}>
              Add
            </Button>
          </Box>
          <Reorder.Group
            style={{ overflowY: 'scroll' }}
            axis="y"
            values={items}
            onReorder={setItems}
          >
            {items.map((group) => (
              <Reorder.Item as="div" key={group.value} value={group}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    background: 'white',
                  }}
                >
                  <Grip
                    sx={{ transform: 'rotate(90deg) translateX(6px) translateY(5px)' }}
                  />
                  <Box sx={{ width: '100%' }}>
                    Category Name
                    <Box
                      sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 1 }}
                    >
                      <TextField
                        fullWidth
                        sx={{ marginRight: 2 }}
                        size="small"
                        value={group.label}
                        disabled
                      />
                      <IconButton
                        onClick={() => {
                          console.log(group.label)
                          setItems((p) => [...p.filter((p) => p.label !== group.label)])
                        }}
                      >
                        <Delete color="primary" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Reorder.Item>
            ))}
          </Reorder.Group>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={save}
          variant="contained"
          disableElevation
          sx={{ minWidth: '100px' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
