import React from 'react';
import { Label, FormText } from 'reactstrap';
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

const TextForm = ({ formSpec, parentState, handleChange }) => 
  <div>
    <img src={formSpec.guide_image || 'https://react.semantic-ui.com/images/wireframe/image-text.png'}
      alt='img' width='100%' 
      style={{ paddingBottom: '30px', display: 'block', margin: 'auto' }} />
    <AvGroup>
      <Label for='text'>Input for {formSpec.name}</Label>
      <AvInput
        type="textarea"
        rows={5}
        name={formSpec.ae_field_name}
        id={formSpec.name}
        value={parentState[formSpec.ae_field_name] || ''}
        placeholder=""
        required={formSpec.required}
        maxLength={formSpec.length}
        onChange={handleChange}
      />
      <AvFeedback>This field is required.</AvFeedback>
      <FormText>
        This will be rendered in the {formSpec.name} field.  Line breaks will be rendered
        the same as it appears in the text field.
        ({(parentState[formSpec.ae_field_name] && 
          parentState[formSpec.ae_field_name].length) || 0 }/{formSpec.length} 
        {' '}characters max.) {formSpec.required && ' - required field'}
      </FormText><br/>
    </AvGroup>
  </div>

export default TextForm;