
export const importAll = r => {
  let images = {};
  r.keys().forEach(item => {
      images[item.replace('./', '')] = r(item);
  });
  return images;
}

export const copyToClip = str => {
  const listener = e => {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

let last = 0;
export const uniqId = (prefix) => {
  return prefix + last++;
}
