import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import logo from 'assets/img/aesend-logo.svg'
import queryString from 'query-string'

const PairingCode = () => {
  const location = useLocation()
  const parsed = queryString.parse(location.search)

  return (
    <Container>
      <img src={logo} alt="logo" />
      <p>
        Pairing Code - Log in at <a href="https://webapp.aesend.com">webapp.aesend.com</a>{' '}
        and create a screen with this pairing code:
      </p>
      <div className="code">{parsed?.code}</div>
      <div className="status">{parsed?.status}</div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;

  img {
    width: 22%;
    margin-bottom: 5%;
  }

  .code {
    font-size: calc(13vh);
    font-weight: bold;
    color: black;
    letter-spacing: calc(1vh);
  }

  .status {
    font-size: 2vmin;
    font-weight: light;
    text-align: center;
  }
`

export default PairingCode
