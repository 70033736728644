

export default {
  USER: 'user',
  CUSTOMER_ADMIN: 'customer_admin',
  AGENCY_ADMIN: 'agency_admin',
  SUPER_ADMIN: 'super_admin',
}

export const RoleIds = {
  user: 3,
  customer_admin: 2,
  agency_admin: 4,
  super_admin: 1,
}