import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from 'reactstrap';
import { AvGroup, AvField } from 'availity-reactstrap-validation';

const TimingPickerForm = ({ theme, formSpec, parentState, handleChange }) => 
  <div>
    <HeaderRow>
      <Label for='text'>Time Your Scene</Label>
      <div>Click the stopwatch to close Timing Setup.</div>
    </HeaderRow>
    <AvGroup>
      <Label for='text'>Time:</Label>
      <AvField
        type='select'
        name={formSpec.ae_field_name}
        onChange={handleChange}
        value={parentState[formSpec.ae_field_name]}>
        <option value='' disabled>-- choose timer --</option>
        { parentState.mediaTimingChoices
            .filter(t => t.scene === formSpec.scene)
            .map(t => 
              <option key={t.ae_field_name} value={parentState[t.ae_field_name]}>
                {`${t.ae_field_name} - ${t.duration?.toFixed(2)} seconds`}
              </option>
            )
        }
        { formSpec.choices && formSpec.choices.map(item => 
          (!formSpec.timing_choices ||
            formSpec.timing_choices.includes(item.label.split(' ')[0]) ||
            formSpec.timing_choices.includes(Number(item.label.split(' ')[0]))
          ) &&
          <option
            key={item.label}
            value={item.label === '0 secs' && formSpec.custom_zero_track
              ? formSpec.custom_zero_track
              : item.value
            }
          >
            {item.label !== '0 secs' ? item.label : 'Remove this segment'}
          </option>
        )}
      </AvField>
    </AvGroup>
  </div>

TimingPickerForm.propTypes = {
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  parentState: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 30px;
  > div {
    font-weight: normal;
    font-size: 0.7rem;
    width: 120px;
    justify-content: left;
    text-align: right;
  }
`;

export default TimingPickerForm;