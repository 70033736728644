import { GroupingItemArray } from 'contexts/library-context'
import { CheckboxList } from './CheckboxList'

interface IProps {
  handleToggle: (value: number | string) => () => void
  checked: number[] | string[]
  items: GroupingItemArray
}

export const TypeFilter = ({ handleToggle, checked, items }: IProps) => {
  return <CheckboxList handleToggle={handleToggle} checked={checked} items={items} />
}
