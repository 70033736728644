import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { persistStore } from 'redux-persist'
import theme from './theme'
import './lib/reactifyCss'

import App from './container/App'
import store from './store'

const persistor = persistStore(store)

if (process.env.ENVIRONMENT !== 'production') {
  document.title = 'aesend - dev'
}

const MainApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </Router>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

export default hot(MainApp)
