import { useAppSelector } from 'hooks/useRedux'
import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import uuid from 'uuid/v4'

import { MediaType } from 'routes/cms/library/types'
import { AddMediaMethod, LibraryEntryKind, LibraryFileUploadRequest } from 'store/types'
import axios from 'axios'
import FileUploadApi from 'data/api/fileUpload'
import { useCreateAssetMutation } from 'store/services/library'

export type Dispatch = (action: Action) => void
export type AssetState = 'pending' | 'uploading' | 'complete' | 'error'
export type AssetUploadState = LibraryFileUploadRequest & {
  progress: number
  state: AssetState
  type: MediaType
  uuid: string
}
export interface State {
  assets: AssetUploadState[]
}

type ActionTypes = 'addAssets' | 'updateAssetState' | 'updateAssetProgress'

type Action = {
  type: ActionTypes
  payload?: any
}

type UploadContextApiType =
  | {
      addAssets: (payload: LibraryFileUploadRequest[]) => void
      updateAssetProgress: (payload: { uuid: string; progress: number }) => void
      updateAssetState: (payload: { uuid: string | string[]; state: AssetState }) => void
      removeAsset: (payload: { uuid: string }) => void
      clearAll: () => void
    }
  | undefined

type UploadContextDataType =
  | {
      uploadState: State
    }
  | undefined

const AssetUploadContext = createContext<UploadContextDataType>(undefined)
const AssetUploadContextApi = createContext<UploadContextApiType>(undefined)

const initialState: State = {
  assets: [],
}

const CancelToken = axios.CancelToken
const fileUploadApi = new FileUploadApi()

const useAssetUpload = () => {
  const [state, setState] = useState(initialState)
  const [createAsset] = useCreateAssetMutation()
  const accountId = useAppSelector((state) => state.authUser.activeAccount)

  const getPresignedUrl = (filename: string, mediaType: string) => {
    return fileUploadApi.getPresignedUploadUrl(accountId, filename, mediaType)
  }

  const uploadMedia = async (files: AssetUploadState[]) => {
    const source = CancelToken.source()
    const presignedUrls = await Promise.all(
      files.map((file) => {
        const ts = Math.round(new Date().getTime() / 1000)
        const split = file.name.split('.')
        const ext = split.pop()
        const name = split.join('.') + '-upload-' + ts + '.' + ext
        return getPresignedUrl(name, file.type)
      })
    )
    try {
      updateAssetState({
        uuid: files.map((file) => file.uuid || ''),
        state: 'uploading',
      })
      await Promise.all(
        presignedUrls.map(async ({ key, signedPutUrl }, idx) => {
          const currentUuid = files[idx].uuid
          const uploadConfig = {
            headers: { 'Content-Type': files[idx]?.type },
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const progressUpload = (progressEvent.loaded * 100) / progressEvent.total
              if (currentUuid) {
                updateAssetProgress({
                  uuid: currentUuid,
                  progress: progressUpload,
                })
              }
            },
            cancelToken: source.token,
          }
          await fileUploadApi.uploadFileToS3(signedPutUrl, files[idx], uploadConfig)
          await createAsset({
            accountId: Number(accountId),
            tagIdList: files[idx].tags ?? [],
            method: AddMediaMethod.UPLOAD,
            media: [
              {
                name: files[idx].name,
                media_type: files[idx].type,
                s3_key: key,
                kind: LibraryEntryKind.MEDIA,
              },
            ],
          })
          updateAssetState({ uuid: files[idx].uuid, state: 'complete' })
        })
      )
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    if (state.assets.some((asset) => asset.state === 'pending')) {
      uploadMedia(state.assets.filter((asset) => asset.state === 'pending'))
    }
  }, [state.assets])

  const addAssets = (payload: LibraryFileUploadRequest[]) => {
    setState({
      assets: [
        ...state.assets,
        ...payload.map((file) =>
          Object.assign(file, {
            progress: 0,
            state: 'pending' as AssetState,
            uuid: uuid(),
          })
        ),
      ],
    })
  }
  const updateAssetProgress = (payload: { uuid: string; progress: number }) => {
    setState({
      assets: state.assets.map((asset) => {
        if (asset.uuid === payload.uuid) {
          return Object.assign(asset, {
            progress: payload.progress,
          })
        }
        return asset
      }),
    })
  }

  const updateAssetState = (payload: { uuid: string | string[]; state: AssetState }) => {
    const uuids = Array.isArray(payload.uuid) ? payload.uuid : [payload.uuid]

    setState({
      assets: state.assets.map((asset) => {
        if (uuids.includes(asset?.uuid || '')) {
          return Object.assign(asset, {
            state: payload.state,
          })
        }
        return asset
      }),
    })
  }
  const removeAsset = (payload: { uuid: string }) => {
    setState({
      assets: state.assets.filter((asset) => asset.uuid !== payload.uuid),
    })
  }
  const clearAll = () => {
    setState(initialState)
  }

  const api = useMemo(
    () => ({
      addAssets,
      updateAssetProgress,
      updateAssetState,
      removeAsset,
      clearAll,
    }),
    []
  )

  return { api, uploadState: state }
}

const useAssetUploadData = () => {
  const context = useContext(AssetUploadContext)
  if (context === undefined) {
    throw new Error('useAssetUploadData must be used within a AssetUploadContextProvider')
  }
  return context
}

const useAssetUploadApi = () => {
  const context = useContext(AssetUploadContextApi)
  if (context === undefined) {
    throw new Error(
      'useAssetUploadApi must be used within a AssetUploadContextApiProvider'
    )
  }
  return context
}

export {
  useAssetUpload,
  AssetUploadContext,
  AssetUploadContextApi,
  useAssetUploadData,
  useAssetUploadApi,
}
