import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { SwatchesPicker } from 'react-color';

const SwatchPickerForm = ({ formSpec, parentState, handleChange }) => {
  const swatches = formSpec.choices.map(item => item.split(',').map(c => `#${c}`));
  const colors = _.zip(...swatches);  // transpose 2D array to put into form needed for SwatchPicker
  const chosenColor = parentState[formSpec.ae_field_names[0]];
  let selectedIdx;
  if (chosenColor) {
    colors.forEach((swatch, idx) => {
      if (swatch.includes('#' + parentState[formSpec.ae_field_names[0]]))
        selectedIdx = idx;
    })
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <img src={formSpec.guide_image || 'https://react.semantic-ui.com/images/wireframe/image-text.png'}
        alt='img' width='100%' 
        style={{ paddingBottom: '10px', display: 'block', margin: 'auto' }} />
      <Row>
        <Col sm="7" style={{ marginLeft: '20px'}}>
          <p>Choose your color theme:</p>
          <SwatchesPicker colors={colors} 
            style={{ margin: '20px' }} 
            width='100%' height='300' 
            color={chosenColor}
            onChangeComplete={handleChange}
          />
        </Col>
        <Col sm="4">
          <p>Selected theme:</p>
            { chosenColor &&
              <SwatchesPicker colors={[colors[selectedIdx]]}
                style={{ margin: '20px' }} width='100%' height='300'
              />
            }
        </Col>
      </Row>
    </div>
  )
}
export default SwatchPickerForm;