import { Grid, IconButton, Typography } from '@mui/material'
import { useCanva, CanvaButton } from 'hooks/useCanva'
import DropboxIcon from 'assets/img/cloud-source-icons/dropbox-icon.svg'
import GoogleDriveIcon from 'assets/img/cloud-source-icons/google-drive-icon.svg'
import IStockIcon from 'assets/img/cloud-source-icons/iStock-icon.svg'
import PexelIcon from 'assets/img/cloud-source-icons/pexel-icon.svg'
import ShutterstockIcon from 'assets/img/cloud-source-icons/shutterstock-icon.svg'
import StoryblocksIcon from 'assets/img/cloud-source-icons/storyblocks-icon.svg'
import OneDriveIcon from 'assets/img/cloud-source-icons/one-drive-icon.svg'
import UnsplashIcon from 'assets/img/cloud-source-icons/unsplash-icon.svg'
import MediaApi from 'data/api/media'

const mediaApi = new MediaApi()

export enum CloudSourcesEnum {
  dropbox = 'dropbox',
  oneDrive = 'oneDrive',
  googleDrive = 'googleDrive',
  shutterStock = 'shutterStock',
  iStock = 'iStock',
  storyBlocks = 'storyBlocks',
  pexels = 'pexels',
  unsplash = 'unsplash',
}

export const cloudSourcesInformation = {
  [CloudSourcesEnum.pexels]: { icon: PexelIcon, label: 'Pexels', disabled: false },
  [CloudSourcesEnum.dropbox]: { icon: DropboxIcon, label: 'Dropbox', disabled: true },
  [CloudSourcesEnum.oneDrive]: { icon: OneDriveIcon, label: 'One Drive', disabled: true },
  [CloudSourcesEnum.googleDrive]: {
    icon: GoogleDriveIcon,
    label: 'Google Drive',
    disabled: true,
  },
  [CloudSourcesEnum.shutterStock]: {
    icon: ShutterstockIcon,
    label: 'Shutter Stock',
    disabled: true,
  },
  [CloudSourcesEnum.iStock]: { icon: IStockIcon, label: 'iStock', disabled: true },
  [CloudSourcesEnum.storyBlocks]: {
    icon: StoryblocksIcon,
    label: 'Storyblocks',
    disabled: true,
  },
  [CloudSourcesEnum.unsplash]: { icon: UnsplashIcon, label: 'Unsplash', disabled: true },
}

type CloudSourceProps = {
  onCloudSourceClick: (souce: CloudSourcesEnum) => any
  accountId: number
}

const CloudSources = ({ onCloudSourceClick, accountId }: CloudSourceProps) => {
  const canvaApi = useCanva()

  return (
    <Grid container direction="column" justifyContent="space-around">
      <Typography sx={{ alignSelf: 'center', marginBottom: '20px' }}>
        Select cloud source to log in:
      </Typography>
      <Grid container direction="row" rowGap={'2rem'}>
        {Object.entries(cloudSourcesInformation).map(
          ([key, { icon, disabled }], index) => (
            <Grid
              key={index}
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={() => onCloudSourceClick(key as CloudSourcesEnum)}
                disableRipple
                sx={{ padding: 0, opacity: disabled ? '.3' : '1' }}
                disabled={disabled}
              >
                <img width="200px" src={icon} />
              </IconButton>
            </Grid>
          )
        )}
        <Grid item xs={6} container justifyContent="center" alignItems="center" mt={2}>
          <CanvaButton
            onClick={() => {
              canvaApi.current.createDesign({
                design: {
                  dimensions: {
                    width: 3840,
                    height: 2160,
                  }
                },
                onDesignOpen: ({ designId }) => {
                  // Triggered when editor finishes loading and opens a new design.
                  // You can save designId for future use.
                  console.log('DESIGN OPEN')
                },
                onDesignPublish: async ({ exportUrl, designId }) => {
                  await mediaApi.import({
                    source: 'canva',
                    assetId: designId,
                    url: exportUrl,
                    accountId,
                  })
                },
                onDesignClose: () => {
                  // Triggered when editor is closed.
                  console.log('EDITOR CLOSED')
                },
              })
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default CloudSources
