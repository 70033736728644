import Api from './api';

export default class TagsApi extends Api {

  async post(data, accountId) {
    const url = accountId ? `${accountId}/tags` : 'tags'
    return this.postResource(url, data);
  }

  // Read
  async get(query, accountId) {
    const url = accountId ? `${accountId}/tags` : 'tags'
    return this.getResource(url, query);
  }

  // Delete
  async delete(id) {
    return this.deleteResource(`tags/${id}`)
  }

}
