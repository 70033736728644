import './style.css'
import { slideInLeft, slideInRight, fadeOut } from 'react-animations'
import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes, css } from 'styled-components'

import NewsApi from 'data/api/news'
import LogoDisplay from '../common/LogoDisplay'
import TimeDisplay from '../common/TimeDisplay'

const newsApi = new NewsApi()
const BGVID =
  'https://aesend-resources.s3-us-west-2.amazonaws.com/background-graphics/NewsAppAni1.mp4'
const fontSizes = {
  6: '21vh',
  7: '18vh',
  8: '16vh',
  10: '11vh',
  13: '9vh',
}

export const NewsSceneRenderer = ({
  logoLocation,
  number,
  duration,
  showCurrentTime,
  backgroundColorOverlay,
  showBackgroundVideo,
  textColor,
  topic,
  keywords,
  style = {},
}) => {
  const [newsData, setNewsData] = useState()
  const [refresh, setRefresh] = useState(0)
  const [clientDims, setClientDims] = useState()
  const [displayIdx, setDisplayIdx] = useState()
  const [fading, setFading] = useState(false)
  const [reset, setReset] = useState(false)
  const gotDimensions = useRef(false)
  const topicRef = useRef()
  const dimension = clientDims?.height
    ? Math.max(clientDims?.height, clientDims?.width)
    : 0
  const { headlineFont, subheadFont, bodyFont } = style
  const portrait = clientDims?.height > clientDims?.width
  const durationMs = duration * 1000

  useEffect(() => {
    const getNewsData = async () => {
      const results = await newsApi.get(topic, keywords)
      // console.log(results)
      setNewsData(results.slice(0, number))
      setDisplayIdx(0)
      if (number > 1) {
        setTimeout(() => setFading(true), durationMs)
      }
    }
    getNewsData()
    setTimeout(() => setRefresh((p) => p + 1), 600000)
  }, [topic, refresh])

  useEffect(() => {
    if (fading) {
      setTimeout(() => {
        setReset(true)
      }, 2000)
    }
  }, [fading])

  useEffect(() => {
    if (reset) {
      let newIndex
      if (number > displayIdx + 1) {
        newIndex = displayIdx + 1
      } else {
        newIndex = 0
      }
      setFading((v) => !v)
      setDisplayIdx(newIndex)
      setTimeout(() => {
        setReset((v) => !v)
      }, 1000)
    }
  }, [reset])

  useEffect(() => {
    const timeToFade = number > displayIdx + 1 ? durationMs : durationMs + 1000
    setTimeout(() => setFading(true), timeToFade)
  }, [displayIdx])

  useEffect(() => {
    if (!gotDimensions.current) {
      const container = document.getElementById('container')
      if (!container) return
      const height = document.getElementById('container').clientHeight
      const width = document.getElementById('container').clientWidth
      gotDimensions.current = true
      setClientDims({ width, height })
    }
  })

  if (!newsData) return <div />

  return (
    <Container
      portrait={portrait}
      id="container"
      style={{ backgroundColor: backgroundColorOverlay || '#555' }}
    >
      <TimeDisplay showCurrentTime={showCurrentTime} style={{ color: textColor }} />
      <LogoDisplay logoLocation={logoLocation} logoUrl={style.logoUrl} />
      {showBackgroundVideo && (
        <video playsInline autoPlay muted loop id="bgvid">
          <source src={BGVID} type="video/mp4" />
        </video>
      )}
      <TopicText
        ref={topicRef}
        style={{ fontFamily: headlineFont, fontSize: fontSizes[topic.length] }}
      >
        {topic}
      </TopicText>
      {!reset && (
        <>
          <SubSection fading={fading}>
            <div className="imageWrapper">
              <img
                src={newsData[displayIdx]?.image}
                alt="headline"
                style={{
                  marginBottom: portrait ? null : 'auto',
                  marginLeft: portrait ? 'auto' : null,
                  marginRight: portrait ? 'auto' : null,
                  animation: `breath ${duration + 0.5}s infinite`,
                }}
              />
            </div>
          </SubSection>
          <SubSection style={{ padding: '5vmin' }} fading={fading}>
            <SubSection
              style={{
                justifyContent: portrait ? 'flex-start' : 'center',
                alignItems: 'center',
              }}
            >
              <Headline
                style={{
                  fontFamily: headlineFont,
                  color: textColor,
                  fontSize: `${Math.min(
                    6 / (newsData[displayIdx]?.title?.length / 80),
                    6
                  )}vmin`,
                }}
              >
                {newsData[displayIdx]?.title}
              </Headline>
              <Body
                style={{
                  fontFamily: bodyFont,
                  color: textColor,
                  fontSize: `${Math.min(
                    Math.max(3 / (newsData[displayIdx]?.description?.length / 120), 2),
                    3.5
                  )}vmin`,
                }}
              >
                {newsData[displayIdx]?.description}
              </Body>
            </SubSection>
          </SubSection>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: ${({ portrait }) => (portrait ? 'column' : 'row')};
  position: relative;
  align-items: stretch;
  flex: 1;
  height: 100%;
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }
`

const fade = keyframes`${fadeOut}`
const animation = css`
  ${fade} 3s
`
const slideInLeftAnimation = keyframes`${slideInLeft}`

const SubSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  max-height: 100vh;
  overflow: hidden;
  animation: ${({ fading }) => fading && animation};

  .imageWrapper {
    display: flex;
    flex: 1;
    align-items: center;
    animation: 1.5s ${slideInLeftAnimation};

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      margin-top: auto;
      transform-origin: center right;
    }
  }
`

const Headline = styled.div`
  padding: 2vmin;
  color: white;
  top: 5vh;
  left: 10vw;
  width: 100%;
  animation: 1s ${slideInLeftAnimation};
`

const slideInRightAnimation = keyframes`${slideInRight}`
const Body = styled.div`
  font-size: 3vmin;
  bottom: 15vh;
  left: 5vw;
  width: 66%;
  color: rgba(255, 255, 255, 0.8);
  padding: 2vmin;
  width: 100%;
  animation: 1s ${slideInRightAnimation};
`

const TopicText = styled.div`
  position: absolute;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  bottom: 0;
  height: 100%;
  transform: rotate(180deg);
  opacity: 0.3;
  color: #7c7c7c;
  font-weight: bold;
  padding: 10vmin 0;
  z-index: 1;
`
