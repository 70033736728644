/**
 * Auth User Reducers
 */
import { get } from 'lodash'
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  SET_USER_PROFILE,
  UPDATE_USER_SUCCESS,
  SWITCH_ACTIVE_ACCOUNT,
} from 'data/actions/types'
import userRoles from 'constants/UserRoles'

/**
 * initial auth user
 */
const INIT_STATE = {
  user: null, //localStorage.getItem('user_id'),
  loading: false,
  profile: [],
  permissions: {},
  // isSuperAdmin: null,
  // isAgencyAdmin: null,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true }

    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload }

    case LOGIN_USER_FAILURE:
      return { ...state, loading: false }

    case LOGOUT_USER:
      return { ...state, user: null, profile: null, loading: false }

    case SIGNUP_USER:
      return { ...state, loading: true }

    case SIGNUP_USER_SUCCESS:
      return { ...state, loading: false }

    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false }

    case SET_USER_PROFILE:
      let profile = []
      if (action.payload) {
        profile = [...action.payload.userInfo]
      }
      const activeAccount = state.activeAccount
        ? { account_id: state.activeAccount }
        : profile.find((i) => i.access_level_name === 'owner')

      const isSuperAdmin = profile.some((i) => i.role_name === userRoles.SUPER_ADMIN)
      const isAgencyAdmin = profile.some((i) => i.role_name === userRoles.AGENCY_ADMIN)
      const isAccountAdmin = profile?.some(
        (i) => i.role_name === userRoles.CUSTOMER_ADMIN
      )

      return {
        ...state,
        profile,
        permissions: action.payload.permissions,
        activeAccount: get(activeAccount, 'account_id'),
        loading: false,
        isSuperAdmin,
        isAgencyAdmin,
        isAccountAdmin,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      }
    case SWITCH_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: Number(action.payload),
      }
    default:
      return state
  }
}
