import React, { Fragment, useState } from 'react'
import MediaLibrarySearch from 'components/MediaLibraryModal'
import styled from 'styled-components'
import Drawer from '@mui/material/Drawer'
import { Container, NoAssetsStyled } from './Container'
import AssetPreview from 'components/MediaUploadModal/components/assetPreview'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'

const MediaLibrarySelect = ({
  user,
  multiple = true,
  mainButton = 'Upload',
  onSelect,
  filter,
}) => {
  const [sidePanelParams, setSidePanelParams] = useState({
    open: !multiple,
    type: undefined,
  })
  const [assets, setAssets] = useState([])

  const removeAsset = (id) => {
    setAssets((assets) => assets.filter((a) => a.id !== id))
  }

  const addAsset = (asset) => {
    const newAssets = [...assets, asset]
    setAssets(newAssets)

    if (!multiple) {
      uploadMedia(newAssets)
      setSidePanelParams({})
    }
  }

  const uploadMedia = (what) => {
    if (!what) {
      what = assets
    }
    const standardUpload = (assets) => {
      console.warn(
        "NIH, didn't see the real applicability of this function at the moment of development",
        assets
      )
    }
    if (typeof onSelect === 'function') {
      onSelect(what, standardUpload)
    } else {
      standardUpload(what)
    }
  }

  const hasFiles = assets.length > 0

  return (
    <>
      <Container>
        <div className="table">
          <h1>Selected Media</h1>
          {!hasFiles && (
            <NoAssetsStyled>
              <p>
                No media have been added. Click the Add More button to select previously
                rendered media.
              </p>

              <div className="with-button">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setSidePanelParams({ open: true })}
                >
                  Select
                </Button>
              </div>
            </NoAssetsStyled>
          )}

          {hasFiles &&
            assets.map((file) => (
              <Fragment key={file.id}>
                <div className="asset">
                  <AssetPreview
                    url={file.thumbnail_url || file.url}
                    maxWidth={100}
                    maxHeight={50}
                  />
                  <div className="label">
                    <span title={file.title}>{file.title}</span>
                  </div>
                  <IconButton onClick={() => removeAsset(file.id)} size="large">
                    <CloseIcon />
                  </IconButton>
                </div>
              </Fragment>
            ))}
        </div>
        <div className="footer">
          {hasFiles ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setSidePanelParams({ open: true })}
            >
              Select
            </Button>
          ) : (
            <div />
          )}
          <Button variant="contained" color="primary" onClick={uploadMedia}>
            {mainButton}
          </Button>
        </div>
      </Container>
      <StyledDrawer
        open={sidePanelParams.open}
        onClose={() => setSidePanelParams({})}
        anchor="right"
      >
        <MediaLibrarySearch
          user={user}
          onSelect={addAsset}
          open={sidePanelParams.open || false}
          filter={filter}
        />
      </StyledDrawer>
    </>
  )
}

const StyledDrawer = styled(Drawer)`
  > div {
    width: 50%;
  }
`

export default MediaLibrarySelect
