import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import Modal from 'react-responsive-modal'
import noPreviewImg from './previewNotAvailable.png'

const styles = {
  arrowButtonCol: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
}

const RenderPreviewModal = ({ open, close, previewUrl }) => (
  <Modal
    open={open}
    onClose={() => close()}
    closeIcon={
      <svg fill="currentColor" viewBox="3 3 30 30" width={28} height={28}>
        <path
          d="M26 12 L24 10 L18 15.88 L12 10 L10 12 L15.88 18 L10 24 L12 26 L18 20.12 L24 26 L26 24 L20 18 Z"
          stroke="none"
          fill="white"
          strokeLinecap="round"
        />
      </svg>
    }
    styles={{
      closeButton: {
        cursor: 'pointer',
        backgroundColor: '#46c8f0',
        borderRadius: '20px',
        position: 'absolute',
        right: '-12px',
        top: '-12px',
        fontColor: '#FFF',
      },
      modal: {
        overflow: 'visible',
        borderRadius: '10px',
        padding: '10px',
      },
    }}
  >
    <Container style={{ marginTop: 20 }}>
      <Row>
        <Col xs="12">
          {previewUrl && previewUrl.slice(-3).toLowerCase() === 'mp4' && (
            <video width="100%" autoPlay muted controls playsInline key={previewUrl}>
              <source src={previewUrl + '#t=0.5'} type="video/mp4" />
            </video>
          )}
          {previewUrl && ['jpg', 'png'].includes(previewUrl.slice(-3).toLowerCase()) && (
            <img src={previewUrl} style={{ width: '100%' }} alt="preview" />
          )}
          {!previewUrl && (
            <img
              src={noPreviewImg}
              alt="no preview"
              style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto' }}
            />
          )}
        </Col>
      </Row>
    </Container>
  </Modal>
)

export default RenderPreviewModal
