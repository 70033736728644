import { CheckboxList } from './CheckboxList'
import { GroupingItemArray } from 'contexts/library-context'
interface IProps {
  handleToggle: (value: number | string) => () => void
  checked: number[] | string[]
  items: GroupingItemArray
}

export const AspectRatioFilter = ({ handleToggle, checked, items }: IProps) => (
  <CheckboxList handleToggle={handleToggle} checked={checked} items={items} />
)
