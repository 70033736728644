import Box from '@mui/material/Box'
import { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

export const ManageGroupingLayout = ({ children }: IProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `auto min(30vw, 400px)`,
        gridTemplateRows: '100px auto',
        gap: 2,
        gridTemplateAreas: `"filter sidebar"
          "content sidebar"`,
      }}
    >
      {children}
    </Box>
  )
}
