import ImageIcon from 'assets/img/LibraryTypes/file-image.svg'
import VideoIcon from 'assets/img/LibraryTypes/film.svg'
import FileIcon from 'assets/img/LibraryTypes/file-spreadsheet.svg'
import PlaylistIcon from 'assets/img/LibraryTypes/playlist.svg'
import AppIcon from 'assets/img/LibraryTypes/app.svg'
import AudioIcon from 'assets/img/LibraryTypes/audio.svg'

export const ASSET_TYPES = {
  VIDEO: 'Videos',
  STATIC_IMAGE: 'Images',
  FILE: 'Files',
  PLAYLIST: 'Playlists',
  APP: 'Apps',
  AUDIO: 'Audio',
}

export const ASSET_DISPLAY_STRINGS = {
  [ASSET_TYPES.VIDEO]: 'Videos',
  [ASSET_TYPES.STATIC_IMAGE]: 'Images',
  [ASSET_TYPES.FILE]: 'Files',
  [ASSET_TYPES.PLAYLIST]: 'Playlists',
  [ASSET_TYPES.APP]: 'Apps',
  [ASSET_TYPES.AUDIO]: 'Audio',
}

export const TYPE_ARRAY = Object.entries(ASSET_DISPLAY_STRINGS).map(([value, label]) => ({
  value,
  label,
}))

export const ASSET_TYPE_IMAGES = {
  [ASSET_TYPES.VIDEO]: VideoIcon,
  [ASSET_TYPES.STATIC_IMAGE]: ImageIcon,
  [ASSET_TYPES.FILE]: FileIcon,
  [ASSET_TYPES.PLAYLIST]: PlaylistIcon,
  [ASSET_TYPES.APP]: AppIcon,
  [ASSET_TYPES.AUDIO]: AudioIcon,
}

export const ASSET_COLORS = {
  [ASSET_TYPES.VIDEO]: '#1A6BE3',
  [ASSET_TYPES.STATIC_IMAGE]: '#E8900B',
  [ASSET_TYPES.FILE]: '#32BB26',
  [ASSET_TYPES.PLAYLIST]: '#00B19C',
  [ASSET_TYPES.APP]: '#CE2E68',
  [ASSET_TYPES.AUDIO]: '#9D3DD7',
}

export const ASPECT_RATIOS = ['16:9', '9:16', '1:1', '4:3', '5:4'].map((i) => ({
  value: i,
  label: i,
}))

export const ASSET_TYPE_IDS = {
  Media: 0,
  Playlist: 1,
  App: 2,
}

export const ASSET_KINDS = Object.fromEntries(
  Object.entries(ASSET_TYPE_IDS).map(([key, value]) => [value, key])
)
