/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const BOXED_LAYOUT = 'BOXED_LAYOUT';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const START_USER_TOUR = 'START_USER_TOUR';
export const STOP_USER_TOUR = 'STOP_USER_TOUR';
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV';

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const SWITCH_ACTIVE_ACCOUNT = 'SWITCH_ACTIVE_ACCOUNT';

// Template Actions
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATE_CATEGORIES = 'GET_TEMPLATE_CATEGORIES';
export const CREATE_RENDER_REQUEST = 'CREATE_RENDER_REQUEST';
export const SET_TEMPLATE_SORT = 'SET_TEMPLATE_SORT';

// Agency Sidebar
export const AGENCY_TOGGLE_MENU = 'AGENCY_TOGGLE_MENU';
export const CHANGE_AGENCY_LAYOUT_BG = 'CHANGE_AGENCY_LAYOUT_BG';

// Mail App
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const SET_EMAIL_AS_STAR = 'SET_EMAIL_AS_STAR';
export const READ_EMAIL = 'READ_EMAIL';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const FETCH_EMAILS = 'FETCH_EMAILS';
export const ON_SELECT_EMAIL = 'ON_SELECT_EMAIL';
export const UPDATE_EMAIL_SEARCH = 'UPDATE_EMAIL_SEARCH';
export const SEARCH_EMAIL = 'SEARCH_EMAIL';
export const ON_DELETE_MAIL = 'ON_DELETE_MAIL';
export const ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING = 'ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING';
export const GET_SENT_EMAILS = 'GET_SENT_EMAILS';
export const GET_INBOX = 'GET_INBOX';
export const GET_DRAFTS_EMAILS = 'GET_DRAFTS_EMAILS';
export const GET_SPAM_EMAILS = 'GET_SPAM_EMAILS';
export const GET_TRASH_EMAILS = 'GET_TRASH_EMAILS';
export const ON_EMAIL_MOVE_TO_FOLDER = 'ON_EMAIL_MOVE_TO_FOLDER';
export const SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILS';
export const UNSELECT_ALL_EMAILS = 'UNSELECT_ALL_EMAILS';
export const ON_SEND_EMAIL = 'ON_SEND_EMAIL';
export const EMAIL_SENT_SUCCESSFULLY = 'EMAIL_SENT_SUCCESSFULLY';
export const FILTER_EMAILS_WITH_LABELS = 'FILTER_EMAILS_WITH_LABELS';
export const ADD_LABELS_INTO_EMAILS = 'ADD_LABELS_INTO_EMAILS';

// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';

// Media
export const FETCH_MEDIA_START = 'FETCH_MEDIA_START';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const FETCH_MEDIA_ERROR = 'FETCH_MEDIA_ERROR';

export const UPDATE_MEDIA_START = 'UPDATE_MEDIA_START';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_ERROR = 'UPDATE_MEDIA_ERROR';

export const ADD_MEDIA_SUCCESS = 'ADD_MEDIA_SUCCESS';

export const DELETE_MEDIA_START = 'DELETE_MEDIA_START';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_ERROR = 'DELETE_MEDIA_ERROR';

export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

export const INSERT_TAG_START = 'INSERT_TAG_START';
export const INSERT_TAG_SUCCESS = 'INSERT_TAG_SUCCESS';
export const INSERT_TAG_ERROR = 'INSERT_TAG_ERROR';

export const DELETE_TAG_START = 'DELETE_TAG_START';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR';

export const ADD_TAGS_SUCCESS = 'ADD_TAGS_SUCCESS';

// Styles
export const FETCH_STYLES_START = 'FETCH_STYLES_START';
export const FETCH_STYLES_SUCCESS = 'FETCH_STYLES_SUCCESS';
export const FETCH_STYLES_ERROR = 'FETCH_STYLES_ERROR';

export const DELETE_STYLES_SUCCESS = 'DELETE_STYLES_SUCCESS';

export const FETCH_SCREENS_START = 'FETCH_SCREENS_START';
export const FETCH_SCREENS_SUCCESS = 'FETCH_SCREENS_SUCCESS';
export const FETCH_SCREENS_ERROR = 'FETCH_SCREENS_ERROR';
