import { ThumbnailImageProps } from 'react-grid-gallery'
import { AssetIdentifier } from 'backend-v2/src/modules/tags/dtos/add-tag.dto'
import { SxProps } from '@mui/material'
import { Asset } from 'routes/cms/library/types'
import { TileWithMenu } from './TileWithMenu'
import { SelectModes } from './index'
import { ASSET_TYPE_IDS } from 'components/Library/constants'
import { AssetType } from 'store/types'

interface IProps {
  assets: Asset[]
  selectMode?: SelectModes
  selectExclusionTypes?: AssetType[]
  pickedAssets?: AssetIdentifier[]
  toggleCheckedItem: (v: AssetIdentifier) => void
  checkedItems: number[]
  onAssetClick: (v: number) => void
  unPin: (assetIds: AssetIdentifier[]) => void
  pin: (assetIds: AssetIdentifier[]) => void
  openDownloadModal?: (asset: AssetIdentifier) => void
  openTrimCropModal?: (asset: AssetIdentifier) => void
  openShareModal?: (asset: AssetIdentifier) => void
  cloneAsset?: (asset: AssetIdentifier) => void
  updateAsset?: (asset: AssetIdentifier) => void
  deleteAsset: () => void
  sx?: SxProps
  hideContextMenu?: boolean
}

export const GalleryTileWrapper = (props: ThumbnailImageProps & IProps) => {
  const {
    item,
    toggleCheckedItem,
    onAssetClick,
    checkedItems,
    pin,
    unPin,
    openDownloadModal,
    openShareModal,
    openTrimCropModal,
    cloneAsset,
    updateAsset,
    deleteAsset,
    selectMode,
    selectExclusionTypes,
    pickedAssets,
    hideContextMenu,
  } = props

  return (
    <TileWithMenu
      {...item.asset}
      selectMode={selectMode}
      selectExclusionTypes={selectExclusionTypes}
      isPicked={
        !!pickedAssets?.find(
          ({ assetId, assetType }) =>
            assetId === item.asset.id && assetType === ASSET_TYPE_IDS[item.asset.kind]
        )
      }
      height={item.scaledHeight}
      toggleCheckedItem={toggleCheckedItem}
      onAssetClick={onAssetClick}
      checkedItems={checkedItems}
      pin={pin}
      unPin={unPin}
      openDownloadModal={openDownloadModal}
      openShareModal={openShareModal}
      openTrimCropModal={openTrimCropModal}
      cloneAsset={cloneAsset}
      updateAsset={updateAsset}
      deleteAsset={deleteAsset}
      hideContextMenu={hideContextMenu}
    />
  )
}
