export const logoLocations = [
  { label: 'Do Not Show', value: '' },
  { label: 'Top Left', value: 'tl' },
  { label: 'Top Right', value: 'tr' },
  { label: 'Bottom Left', value: 'bl' },
  { label: 'Bottom Right', value: 'br' },
]

export const currentTimeLocations = [
  { label: 'Do Not Show', value: '' },
  { label: 'Top Left', value: 'tl' },
  { label: 'Top Right', value: 'tr' },
  { label: 'Bottom Left', value: 'bl' },
  { label: 'Bottom Right', value: 'br' },
]