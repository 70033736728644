import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { SwatchesPicker } from 'react-color';

const SwatchPickerForm = ({ theme, formSpec, parentState, handleChange }) => {
  const swatches = formSpec.choices.map(item => item.split(',').map(c => `#${c}`));
  const colors = _.zip(...swatches);  // transpose 2D array to put into form needed for SwatchPicker
  const chosenColor = parentState[formSpec.ae_field_names[0]];
  let selectedIdx;
  if (chosenColor) {
    colors.forEach((swatch, idx) => {
      if (swatch.includes('#' + parentState[formSpec.ae_field_names[0]]))
        selectedIdx = idx;
    })
  }
  return (
    <div style={{ textAlign: 'center', marginTop: 10 }}>
      <Row>
        <Col sm="7" style={{ marginLeft: '20px'}}>
          <p>Choose your color theme:</p>
          <SwatchesPicker
            colors={colors} 
            style={{ margin: '20px' }} 
            width='100%' height='300' 
            color={chosenColor}
            onChangeComplete={handleChange}
          />
        </Col>
        <Col sm="4">
          <p>Selected theme:</p>
            { chosenColor &&
              <SwatchesPicker colors={[colors[selectedIdx]]}
                style={{ margin: '20px' }} width='100%' height='300'
              />
            }
        </Col>
      </Row>
    </div>
  )
}

SwatchPickerForm.propTypes = {
  theme: PropTypes.object,
  formSpec: PropTypes.object,
  parentState: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
}

export default SwatchPickerForm;