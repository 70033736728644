import React, { useState } from 'react';
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import  {Editor} from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';


const ConfigRichText = ({config, onFocus, onBlur, onChange, className}) => {
    const blocksFromHtml = htmlToDraft(config.value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const _updateState = (newState) => {
        setEditorState(newState);

        console.log(convertToRaw(newState.getCurrentContent()))
        typeof onChange === "function" && onChange(draftToHtml(convertToRaw(newState.getCurrentContent())))
    }

    return (
        // <div onClick={focusEditor}>
        //     <Editor
        //         editorRef={editor}
        //         editorState={editorState}
        //         handleKeyCommand={handleKeyCommand}
        //         onChange={setEditorState}/>
        // </div>
        <div className={className}>
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={_updateState}
                toolbar={{
                    options: [
                        'inline',
                        // 'blockType',
                        // 'fontSize',
                        // 'fontFamily',
                        'list',
                        'textAlign',
                        // 'colorPicker',
                        // 'link',
                        // 'embedded',
                        // 'emoji',
                        // 'image',
                        'remove',
                        'history'
                    ],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough', /*'monospace,'*/ 'superscript', 'subscript']
                    },
                    // list: { inDropdown: true },
                    // textAlign: { inDropdown: true },
                    // link: { inDropdown: true },
                    // history: { inDropdown: true },
                }}
            />
            {/*<textarea*/}
            {/*    disabled*/}
            {/*    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}*/}
            {/*/>*/}
        </div>

    );
}

export {ConfigRichText};

// import React, { Component } from 'react';
// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
//
//
