import { useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import {Link} from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import EditForm from './editForm';

const AddEditSoftware = () => {
  const {id} = useParams()

  const isEdit = !!id;

  return (
    <Container>
      <Row>
        <Col xs="0" sm="2">
          <Link to="/app/admin/software-releases/list">
            {'< back'}
          </Link>
        </Col>
        <Col xs="12" sm="8" >
          <h2 style={{ fontWeight: '400', fontSize: 20, margin: 0, marginBottom: 10 }}>
            {isEdit ? 'Edit Software Release' : 'Add New Software Release'} 
          </h2>        
          <Paper style={{ padding: 20 }}>
            <EditForm
              isEdit={isEdit}
              id={id}
            />
          </Paper>
        </Col>
        <Col xs="0" sm="2" style={{ marginLeft: 30 }} />
      </Row>
    </Container>
  )
}

export default AddEditSoftware;