import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Modal from 'react-responsive-modal';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';
import Next from '@mui/icons-material/KeyboardArrowRight';
import Prev from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import noPreviewImg from './previewNotAvailable.png';
import selectButton from '../../assets/img/Buttons-Custom-Select.svg';

const styles = {
  arrowButtonCol: {
    padding: 0, 
    display: 'flex', 
    alignItems: 'center',
  }
}

const TemplatePreviewModal = ({ flip, open, close, template }) => {
  const previewUrl = template && template.preview_url;
  return (
    <Modal
      open={open}
      onClose={() => close()}
      closeIcon={
        <svg fill="currentColor" viewBox="3 3 30 30" width={28} height={28}>
          <path
            d="M26 12 L24 10 L18 15.88 L12 10 L10 12 L15.88 18 L10 24 L12 26 L18 20.12 L24 26 L26 24 L20 18 Z"
            stroke="none"
            fill="white"
            strokeLinecap="round"
          />
        </svg>
      }
      styles={{ 
        closeButton: { 
          cursor: 'pointer',
          backgroundColor: '#46c8f0',
          borderRadius: '20px',
          position: 'absolute',
          right: '-12px',
          top: '-12px',
          fontColor: '#FFF'
        },
        modal: {
          overflow:'visible',
          borderRadius: '10px',
          padding: '10px'
        }
      }}>
        <Container style={{ marginTop: 20 }}>
          <Row>
            <Col xs="1" style={styles.arrowButtonCol}>
             {flip && 
                <IconButton component='span' onClick={() => flip(-1)} size="large">
                  <Prev style={{ transform: 'scale(1.8)' }} />
                </IconButton>
             }
            </Col>
            <StyledCol xs="10">
            { previewUrl && previewUrl.slice(-3).toLowerCase() === 'mp4' && 
              <video width='100%' autoPlay muted controls playsInline key={previewUrl}>
                <source src={previewUrl + '#t=0.5'} type='video/mp4'/>
              </video>
            }
            { previewUrl && 
              ['jpg','png'].includes(previewUrl.slice(-3).toLowerCase()) && 
                <img src={previewUrl} style={{ width: '100%' }} alt='preview' />
            }
            { !previewUrl &&
                <img src={noPreviewImg} alt='no preview' 
                  style={{ display: 'block', marginRight: 'auto',
                    marginLeft: 'auto' }} />
            }
            </StyledCol>
            <Col xs="1" style={styles.arrowButtonCol}>
              { flip &&
                <IconButton component='span' onClick={() => flip(1)} size="large">
                    <Next style={{ transform: 'scale(1.8)' }} />
                </IconButton>
              }
            </Col>
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'row' }}>
            <Col xs='1'>
            </Col>
            <Col>
              <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
                { template && template.name }
              </p>
              <p style={{ fontSize: '12px'}}>
                { template && template.description }
              </p>
            </Col>
            <Col>
            { flip && 
              <Link to={`/app/templates/template-customization/${
                template && template.id}`} >
                <ButtonBase
                  focusRipple
                  style={{ position: 'absolute', 
                  right: '50px', bottom: '20px' }}
                >
                  <span 
                    style={{
                      backgroundImage: `url(${selectButton})`,
                      width: 100, height: 35, backgroundRepeat: 'no-repeat',
                    }} />
                </ButtonBase>
              </Link>
            }
            </Col>
          </Row>
      </Container>
    </Modal>
  );
}

const StyledCol = styled(Col)`
  video, img {
    max-height: 80vh;
    max-width: 90vw;
  }
`

export default TemplatePreviewModal;
