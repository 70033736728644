export enum Grouping {
  all = 'all',
  type = 'type',
  category = 'category',
  collection = 'collection',
}
export type ConfigurableGroupings = Grouping.category | Grouping.collection
export type ViewMode = 'list' | 'grid'

export type GroupingListItem = {
  value: number
  label: string
}

export type GroupingList = GroupingListItem[]
