import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { confirmable, createConfirmation } from 'react-confirm';

const DEFAULT_OPTIONS = {
  description: 'Are you sure you want to remove this',
  noAction: 'No',
  title: 'Confirm Delete',
  yesAction: 'Yes, delete',
};

const getOption = (options, optionKey) =>
  ({ ...DEFAULT_OPTIONS, ...options }[optionKey]);

const ConfirmModal = ({
  confirmation,
  dismiss,
  options,
  proceed,
  show,
}) => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    open={show}
    onClose={dismiss}
  >
    <DialogTitle id="alert-dialog-title">
      {getOption(options, 'title')}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {getOption(options, 'description')} {confirmation ? `${confirmation}?` : ''}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={dismiss}>
        {getOption(options, 'noAction')}
      </Button>
      <Button autoFocus color="primary" onClick={proceed}>
        {getOption(options, 'yesAction')} {confirmation}
      </Button>
    </DialogActions>
  </Dialog>
);

const ConfirmDialog = confirmable(ConfirmModal);
const triggerConfirmation = createConfirmation(ConfirmDialog);

ConfirmModal.propTypes = {
  confirmation: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  options: PropTypes.object,
  proceed: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export const confirm = (value, options = {}) =>
  triggerConfirmation({ confirmation: value, options });

export default ConfirmDialog;
