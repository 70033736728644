import {
  FETCH_MEDIA_START,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_ERROR,
  UPDATE_MEDIA_START,
  UPDATE_MEDIA_SUCCESS,
  UPDATE_MEDIA_ERROR,
  DELETE_MEDIA_START,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_ERROR,
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR,
  INSERT_TAG_START,
  INSERT_TAG_SUCCESS,
  INSERT_TAG_ERROR,
  DELETE_TAG_START,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  ADD_TAGS_SUCCESS,
} from './types';
import MediaApi from '../api/media';
import TagsApi from '../api/tags';

const mediaApi = new MediaApi();
const tagsApi = new TagsApi();

/**
 * Redux Action Get Media
 */
export const getMedia = accountId => async dispatch => {
  dispatch({ type: FETCH_MEDIA_START });
  try {
    const response = await mediaApi.get(null, accountId);
    dispatch({ type: FETCH_MEDIA_SUCCESS, payload: response });
  } catch(error) {
    console.log(error);
    dispatch({ type: FETCH_MEDIA_ERROR, payload: error });
    // error handling
  }
}

export const deleteMedia = id => async dispatch => {
  dispatch({ type: DELETE_MEDIA_START, payload: id });
  try {
    await mediaApi.delete(id);
    dispatch({ type: DELETE_MEDIA_SUCCESS, payload: id });
  } catch(error) {
    console.log(error);
    dispatch({ type: DELETE_MEDIA_ERROR, payload: error });
    // error handling
  }
}

export const updateMedia = (id, data) => async dispatch => {
  dispatch({ type: UPDATE_MEDIA_START, payload: id });
  try {
    await mediaApi.update(id, data);
    dispatch({
      type: UPDATE_MEDIA_SUCCESS,
      payload: { id, data },
    });
  } catch(error) {
    console.log(error);
    dispatch({ type: UPDATE_MEDIA_ERROR, payload: error });
    // error handling
  }
}

export const move = ({ id, destination }) => async (dispatch, getState) => {
  const { authUser: { activeAccount } } = getState();
  try {
    dispatch({ type: UPDATE_MEDIA_START, payload: id });
    await mediaApi.move(id, destination)
    // dispatch({
    //   type: MOVE_MEDIA_SUCCESS,
    //   payload: { id, destination }
    // })
    // dispatch(getMedia(activeAccount))
  } catch (error) {
    console.log(error)
  }
}

export const alter = ({ id, params }) => async dispatch => {
  try {
    await mediaApi.alter(id, params)
  } catch (error) {
    console.log(error)
  }
}

export const clone = ({ accountId, id, params }) => async dispatch => {
  try {
    await mediaApi.clone(id, params, accountId)
  } catch (error) {
    console.log(error)
  }
}

/**
 * Redux Action Get Tags
 */
export const getTags = () => async (dispatch, getState) => {
  const { authUser: { activeAccount } } = getState();
  dispatch({ type: FETCH_TAGS_START });
  try {
    const response = await tagsApi.get(null, activeAccount);
    dispatch({ type: FETCH_TAGS_SUCCESS, payload: response });
  } catch(error) {
    console.log(error);
    dispatch({ type: FETCH_TAGS_ERROR, payload: error });
  }
}

export const insertTag = name => async (dispatch, getState) => {
  const { authUser: { activeAccount } } = getState();
  dispatch({ type: INSERT_TAG_START });
  try {
    const id = await tagsApi.post({ name }, activeAccount);
    dispatch({ type: INSERT_TAG_SUCCESS, payload: { id, name } });
  } catch(error) {
    console.log(error);
    dispatch({ type: INSERT_TAG_ERROR, payload: error });
  }
}

export const deleteTag = id => async dispatch => {
  dispatch({ type: DELETE_TAG_START });
  try {
    await tagsApi.delete(id);
    dispatch({ type: DELETE_TAG_SUCCESS, payload: id });
  } catch(error) {
    console.log(error);
    dispatch({ type: DELETE_TAG_ERROR, payload: error });
  }
}

export const addTags = (fileId, tags) => async dispatch => {
  try {
    await mediaApi.addTags(fileId, tags.map(t => t.id))
    dispatch({ type: ADD_TAGS_SUCCESS, payload: { id: fileId, tags } })
  } catch (error) {
    console.log(error)
  }
}

export const sharePublic = id => async dispatch => {
  try {
    const url = await mediaApi.getPublicUrl(id)
    return url
  } catch (err) {
    console.log(err)
  }
}

export const getPresignedUrl = (id, version) => async dispatch => {
  try {
    const url = await mediaApi.getPresignedUrl(id, version)
    return url
  } catch (err) {
    console.log(err)
  }
}