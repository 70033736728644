import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Card } from '@mui/material'

export const SortableItem = ({id, children, onClick}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const style = transform ? {
    transform: CSS.Transform.toString(transform),
    transition,
  } : undefined;


  return (
    <Card ref={setNodeRef} style={style} {...listeners} onClick={onClick} {...attributes}>
      {children}
    </Card>
  );
}
